import { ReactNode } from 'react';

import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react';

import { ssrTheme } from '@nocowanie/common-ui';

const ThemeProvider = ({
    theme,
    children,
}: {
    theme?: ChakraProviderProps['theme'];
    children: ReactNode;
}): JSX.Element => <ChakraProvider theme={theme ?? ssrTheme}>{children}</ChakraProvider>;

export default ThemeProvider;
