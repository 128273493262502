import { useState } from 'react';

import { Box, Button, UnorderedList } from '@chakra-ui/react';

import { ExpandCount, ListExpandableProps } from './list-expandable.props';

import { useTranslation } from '../../../hooks/use-translation';

export function ListExpandable<T>({
    items,
    renderItem,
    show = 4,
    ...rest
}: ListExpandableProps<T>): JSX.Element {
    const { t } = useTranslation('common', {
        keyPrefix: 'list-button',
    });
    const initialItems = show === ExpandCount['All'] ? items : items.slice(0, show);
    const [visibleItems, setVisibleItems] = useState(initialItems);
    const [more, setMore] = useState(false);

    const toggleList = () => {
        setMore(!more);
        setVisibleItems(!more ? items : initialItems);
    };

    return (
        <Box {...rest}>
            <UnorderedList listStylePos="inside">{visibleItems.map(renderItem)}</UnorderedList>
            {items.length > +show && (
                <Button
                    onClick={toggleList}
                    justifyContent="flex-start"
                    variant="link"
                    mt={2}
                    colorScheme="secondary"
                >
                    {more ? t('less') : t('more')}
                </Button>
            )}
        </Box>
    );
}
