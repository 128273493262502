import { ForwardedRef, useEffect, useMemo, useState } from 'react';

import {
    ComponentWithAs,
    forwardRef,
    HStack,
    IconButton,
    Input,
    useMultiStyleConfig,
    useNumberInput,
} from '@chakra-ui/react';

import { isNil } from 'lodash';

import { IconsLinear } from '@nocowanie/icons';

import { MobileNumberInputProps } from './mobile-number.input.props';
import { defaultMobileNumberInputTranslation } from './mobile-number.input.translation.model';

export const MobileNumberInput: ComponentWithAs<'input', MobileNumberInputProps> = forwardRef<
    MobileNumberInputProps,
    'input'
>((props: MobileNumberInputProps, componentRef: ForwardedRef<HTMLInputElement>) => {
    const [focused, setFocused] = useState(false);
    const {
        disableIncrement,
        disableDecrement,
        _placeholder,
        isRequired,
        value,
        translationData = defaultMobileNumberInputTranslation,
        step = 1,
        min,
        max,
        inputClassName,
        autoFocus,
    } = props;

    const [inputValue, setInputValue] = useState(value);

    useEffect(() => setInputValue(value), [value]);

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
        ...props,
        value: inputValue,
    });

    const isInvalid = useMemo(
        () => focused && (isRequired ?? false) && isNil(value),
        [focused, isRequired, value],
    );

    const inputProps = getInputProps({
        autoFocus,
        _placeholder,
        isRequired,
        value: inputValue,
        pattern: '^[0-9]+$',
    });
    const withMin = typeof min !== 'undefined';
    const withMax = typeof max !== 'undefined';

    // WARNING - there is an issue od Chakra (and React?)
    // that causes infinite spinners on rerender
    // PointerLeave event is not firing
    // https://github.com/chakra-ui/chakra-ui/issues/7606
    const incrementProps = getIncrementButtonProps({
        disabled: disableIncrement ?? false,
        onPointerDown: event => {
            event.preventDefault(); // Prevent default spinning behaviour, calculate value manually (pointerleave bug)

            let newValue = parseInt(`${inputValue}`) + step;
            newValue = isNaN(newValue) ? (withMin ? min : 0) : newValue; // incrementing from `undefined` should set value to `min` or `0`
            newValue = withMax ? Math.min(newValue, max) : newValue;

            if (newValue === inputValue) {
                return;
            }

            setInputValue(newValue);
            props.onChange && props.onChange(newValue.toString(), newValue);
        },
    });

    const decrementProps = getDecrementButtonProps({
        disabled: disableDecrement ?? false,
        onPointerDown: event => {
            event.preventDefault(); // Prevent default spinning behaviour, calculate value manually (pointerleave bug)

            let newValue = parseInt(`${inputValue}`) - step;
            newValue = isNaN(newValue) ? (withMin ? min : 0) : newValue; // decrementing from `undefined` should set value to `min` or `0`
            newValue = withMin ? Math.max(newValue, min) : newValue;

            if (newValue === inputValue) {
                return;
            }

            setInputValue(newValue);
            props.onChange && props.onChange(newValue.toString(), newValue);
        },
    });

    const themeStyles = useMultiStyleConfig('MobileNumberInput', {});
    const buttonColorScheme = themeStyles.button?.colorScheme as string;

    return (
        <HStack maxW="240px">
            <IconButton
                {...decrementProps}
                sx={themeStyles.button}
                aria-label={translationData.buttonDecreaseLabel}
                borderRadius="full"
                size="sm"
                variant="outline"
                colorScheme={buttonColorScheme}
                icon={<IconsLinear.Minus />}
            />
            <Input
                onBlurCapture={() => setFocused(true)}
                isInvalid={isInvalid}
                textAlign="center"
                width="50px"
                variant="flushed"
                colorScheme="blue"
                onPaste={event => event.preventDefault()}
                className={inputClassName}
                {...inputProps}
            />
            <IconButton
                {...incrementProps}
                sx={themeStyles.button}
                aria-label={translationData.buttonIncreaseLabel}
                borderRadius="full"
                size="sm"
                variant="outline"
                colorScheme={buttonColorScheme}
                icon={<IconsLinear.Add />}
            />
        </HStack>
    );
});
