import { parse } from 'qs';

import { getSlug } from './slug.helpers';

import { urlMap } from '../consts';

type QueryStringObject = Record<string, any>;

export const objectToParams = <T extends QueryStringObject>(params: T) => {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item, i) => searchParams.append(`${key}[${i}]`, String(item)));
        } else {
            searchParams.append(key, String(value));
        }
    });

    return searchParams;
};

// Parse query string into an object, according to https://www.rfc-editor.org/rfc/rfc1738 standard
// multiple values e.g. `?childrenAges[0]=1&childrenAges[1]=3` will be turned into an arrays
export const paramsToObject = (params: URLSearchParams) => {
    return parse(params.toString().replace(/^\?/, ''));
};

export const extractNumericId = (id: string): string | undefined => {
    const match = id.match(/\d+$/);
    return match?.[0];
};

export const getAccommodationUrl = (
    accommodationId: string,
    accommodationName: string,
    queryParams: QueryStringObject = {},
    domain = '/',
) => {
    const queryString = objectToParams(queryParams).toString();
    const query = queryString ? `?${queryString}` : '';
    return `${domain}${urlMap.reservation}${accommodationId}-${getSlug(
        accommodationName,
    )}/${query}`;
};

export const getCheckoutUrl = (queryParams: QueryStringObject = {}, domain = '/'): string => {
    const queryString = objectToParams(queryParams).toString();
    return `${domain}${urlMap.checkout}?${queryString}`;
};

export const getSummaryUrl = (
    reservationId: string,
    queryParams: QueryStringObject = {},
    domain = '/',
): string => {
    const { identifierCode, ...otherParams } = queryParams;
    const legacyQueryParams = {
        ...otherParams,
        token: identifierCode,
    };

    const queryString = objectToParams(legacyQueryParams).toString();
    return `${domain}${urlMap.summary}${reservationId}/?${queryString}`;
};

export const getPhoneNoHref = (phoneNo: string) => {
    return `tel:${phoneNo.replace(/\s/g, '')}`;
};
