/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Context_jsonld_read } from './Context_jsonld_read';
export type ListingSetting_jsonld_read = {
    readonly '@context'?: (string | Record<string, any>);
    readonly '@id'?: string;
    readonly '@type'?: string;
    id?: string;
    sortAlgorithm?: ListingSetting_jsonld_read.sortAlgorithm;
    context?: Array<Context_jsonld_read>;
    createdAt?: string;
    updatedAt?: string;
};
export namespace ListingSetting_jsonld_read {
    export enum sortAlgorithm {
        MIX = 'mix',
        NORMAL = 'normal',
    }
}

