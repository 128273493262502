export const urlMap: Record<string, string> = {
    home: '/',
    search: '/',
    reservation: 'rezerwuj/',
    checkout: 'potwierdz/',
    summary: 'podsumowanie/',
    join: 'dolacz-do-nocowanie/',
    partnerContactForm: 'https://forms.gle/uXgbtt7vh7t47ha76',
    login: 'logowanie/',
};
