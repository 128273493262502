import { IconProps, NocIconGenerator } from '../common';

export const CityBreak = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'CityBreak',
            path: (
                <>
                    <g
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        strokeWidth={1.5}
                        clipPath="url(#a)"
                    >
                        <path d="M5.43 4.819v.87M8.474 4.819v.87M11.52 4.819v.87M5.43 8.518v.87M8.474 8.518v.87M11.52 8.518v.87M2.208 21.792V3.079c0-.523.348-.87.87-.87h10.88c.522 0 .87.347.87.87v6.31M15.7 21.791v-3.307c0-.696-.61-1.306-1.306-1.306-.696 0-1.305.61-1.305 1.306v3.307M21.705 21.792H2.208" />
                        <path d="M7.865 21.791v-7.18H20.92v7.18M21.791 12H6.995v2.611H21.79V12ZM10.477 17.613v.436M18.31 17.613v.436" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M1.333 1.333h21.333v21.333H1.333z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
