import { IconProps, NocIconGenerator } from '../common';

export const AirConditioning = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'AirConditioning',
            path: (
                <>
                    <g
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M2.8 2.5h18.26s.87 0 .87.82v7.37s0 .82-.87.82H2.8s-.87 0-.87-.82V3.32s0-.82.87-.82ZM4.43 6h15M4.43 9h15M8.3 13c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M8.3 18.84c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47M15.57 13c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M15.57 18.84c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47M11.89 13.16c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M11.89 19c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
