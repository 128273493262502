export const menuLinkStyle = {
    fontSize: 'sm',
    lineHeight: 1.5,
    py: 2,
    px: 4,
    color: 'black',
    width: '100%',
    border: 'none',
    borderLeft: '0.25rem solid',
    borderLeftColor: 'transparent',

    _hover: {
        borderLeftColor: 'brand.300',
        textDecoration: 'none',
    },
};

export const Link = {
    variants: {
        menuLinkBase: {
            ...menuLinkStyle,
            _hover: {
                ...menuLinkStyle._hover,
                bg: 'sandYellow.50',
            },
            _focus: {
                bg: {
                    base: 'white',
                    md: 'sandYellow.50',
                },
            },
        },
        menuLinkInner: {
            ...menuLinkStyle,
            bg: {
                base: 'white',
                md: 'sandYellow.50',
            },
            _hover: {
                textDecoration: 'none',
                bg: {
                    base: 'sandYellow.50',
                    md: 'white',
                },
                borderLeftColor: {
                    base: 'transparent',
                    md: 'brand.300',
                },
            },
            py: 1.5,
        },
    },
};
