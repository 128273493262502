interface MobileNumberInputTranslationModel {
    buttonIncreaseLabel: string;
    buttonDecreaseLabel: string;
}

const defaultMobileNumberInputTranslation: Required<MobileNumberInputTranslationModel> = {
    buttonIncreaseLabel: 'Zwiększ',
    buttonDecreaseLabel: 'Zmniejsz',
};

export { type MobileNumberInputTranslationModel, defaultMobileNumberInputTranslation };
