import { OTEL_SERVICE_NAME } from './consts';
import { Span, trace, Tracer } from '@opentelemetry/api';

/**
 Get active tracer
 @return Tracer
**/
export const getTracer = (): Tracer =>
    trace.getTracer(process.env.OTEL_SERVICE_NAME ?? OTEL_SERVICE_NAME);

/**
 * Wrap function with OpenTelemetrySpan
 * @param spanName name for span representation in jaeager
 * @param fn function to invoke and measure
 * @return ReturnType<Fn>
 **/
export const withOTLPSpan = <Fn extends (span: Span) => unknown>(
    spanName: string,
    fn: Fn,
): ReturnType<Fn> => getTracer().startActiveSpan(spanName, fn);
