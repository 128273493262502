import { urlJoin } from 'url-join-ts';

import { ApiResponseModel, createGQLClient, GraphQLClient } from '@nocowanie/gql-core';
import { CoreSkeletonService } from '@nocowanie/core';

import { environment } from '@app/environments/environment';

import checkoutDetailsQuery from './checkout-details.query.graphql';
import createPaymentMutation from './create-payment.mutation.graphql';
import reservationDetailsQuery from './reservation-details.query.graphql';

import { apiTypes, SummaryInputDataModel } from '../models';

export class SummaryService extends CoreSkeletonService<string, apiTypes.Accommodation> {
    private static _instance?: SummaryService;
    private accommodationGqlClient: GraphQLClient;

    private constructor() {
        super({
            gqlOptions: {
                apiUrl: urlJoin(environment.CHECKOUT_API_END_POINT, 'api', 'graphql'),
            },
        });

        this.accommodationGqlClient = createGQLClient({
            apiUrl: urlJoin(environment.ACCOMMODATION_API_ENDPOINT, 'api', 'graphql'),
        });
    }

    public static get instance(): SummaryService {
        if (!this._instance) {
            this._instance = new SummaryService();
        }

        return this._instance;
    }

    public async loadReservationDetails(
        args: SummaryInputDataModel,
    ): Promise<ApiResponseModel<apiTypes.ReservationDetails>> {
        return await this.executeQuery<apiTypes.ReservationDetails>(
            'reservationDetails',
            reservationDetailsQuery,
            {
                ...args,
            },
        );
    }

    public async getAccommodationDetails(
        args: apiTypes.QueryAccommodationArgs,
    ): Promise<ApiResponseModel<apiTypes.Accommodation>> {
        return this.accommodationGqlClient.execQuery<apiTypes.Accommodation>(
            'accommodation',
            checkoutDetailsQuery,
            {
                ...args,
            },
        );
    }

    public async createPayment(
        args: apiTypes.CreateCardPaymentInitiationInput,
    ): Promise<ApiResponseModel<apiTypes.CreateCardPaymentInitiationPayload>> {
        return await this.executeQuery<apiTypes.CreateCardPaymentInitiationPayload>(
            'createCardPaymentInitiation',
            createPaymentMutation,
            {
                input: args,
            },
        );
    }
}
