/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Test } from '../models/Test';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TestService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Retrieves the collection of Test resources.
     * Retrieves the collection of Test resources.
     * @param page The collection page number
     * @returns Test Test collection
     * @throws ApiError
     */
    public apiTestsGetCollection(
        page: number = 1,
    ): CancelablePromise<Array<Test>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/tests',
            query: {
                'page': page,
            },
        });
    }
    /**
     * Creates a Test resource.
     * Creates a Test resource.
     * @param requestBody The new Test resource
     * @returns Test Test resource created
     * @throws ApiError
     */
    public apiTestsPost(
        requestBody: Test,
    ): CancelablePromise<Test> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/tests',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Retrieves a Test resource.
     * Retrieves a Test resource.
     * @param id Test identifier
     * @returns Test Test resource
     * @throws ApiError
     */
    public apiTestsIdGet(
        id: string,
    ): CancelablePromise<Test> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/tests/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Replaces the Test resource.
     * Replaces the Test resource.
     * @param id Test identifier
     * @param requestBody The updated Test resource
     * @returns Test Test resource updated
     * @throws ApiError
     */
    public apiTestsIdPut(
        id: string,
        requestBody: Test,
    ): CancelablePromise<Test> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/tests/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Removes the Test resource.
     * Removes the Test resource.
     * @param id Test identifier
     * @returns void
     * @throws ApiError
     */
    public apiTestsIdDelete(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/tests/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Updates the Test resource.
     * Updates the Test resource.
     * @param id Test identifier
     * @param requestBody The updated Test resource
     * @returns Test Test resource updated
     * @throws ApiError
     */
    public apiTestsIdPatch(
        id: string,
        requestBody: Test,
    ): CancelablePromise<Test> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/tests/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
}
