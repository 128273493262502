import {
    Accordion as ChakraAccordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Heading,
    Text,
} from '@chakra-ui/react';

import { AccordionProps } from './accordion.props';

export const Accordion = ({ title, heading, items, wrapperProps }: AccordionProps): JSX.Element => {
    return (
        <Box {...wrapperProps}>
            <Text fontSize="md">{title}</Text>
            <Heading size="lg" mb={6}>
                {heading}
            </Heading>
            <ChakraAccordion
                as="dl"
                allowToggle
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.200"
            >
                {items?.map(({ id, title, body }, i) => (
                    <AccordionItem
                        key={id}
                        borderTopWidth={i === 0 ? '0' : '1px'}
                        borderBottom="none"
                    >
                        <dt>
                            <AccordionButton>
                                <Text as="span" mr="auto" textAlign="left">
                                    {title}
                                </Text>
                                <AccordionIcon color={'secondary.400'} />
                            </AccordionButton>
                        </dt>
                        <AccordionPanel as="dd" pb={4}>
                            {body}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </ChakraAccordion>
        </Box>
    );
};
