import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { isNil } from 'lodash';

import { ApiResponseModel } from '@nocowanie/gql-core';
import { BaseStateModel, LoadingState } from '@nocowanie/core';

import { SliceNameEnum } from '@app/enums';

import { apiTypes, RequestAdditionalParamsModel } from '../../models';
import { ApiAccommodationService } from '../../services';

export interface AccommodationRatingsStateModel extends BaseStateModel {
    data: apiTypes.Maybe<apiTypes.RatingPageConnection>;
    pageToLoad: number;
}

const initialState: AccommodationRatingsStateModel = {
    loading: LoadingState.IDLE,
    data: null,
    pageToLoad: 1,
};

export const getRatingsFromAPIWithEs = async (
    args: Omit<apiTypes.QueryRatingsArgs, 'page'> & RequestAdditionalParamsModel,
    page = 1,
    limit = 10,
) => {
    const from = (page - 1) * limit;
    const result: any = await ApiAccommodationService.instance.loadAccommodationRatings(
        args.accommodationId,
        from,
        limit,
    );

    const ratings =
        result?.hits?.map(({ _source }: { _source: any }) => ({
            ..._source,
            average: Number(_source.average ?? 0),
        })) || [];

    const ratingCount = result?.total?.value || 0;

    return {
        data: {
            collection: !isNil(ratings) ? ratings : undefined,
            paginationInfo: {
                totalCount: ratingCount,
                itemsPerPage: limit,
                lastPage: Math.ceil(ratingCount / limit),
            },
        },
    };
};

const fetchRatingsThunk = createAsyncThunk<
    ApiResponseModel<apiTypes.RatingPageConnection>,
    Omit<apiTypes.QueryRatingsArgs, 'page'>
>(
    `${SliceNameEnum.AccommodationRatings}/fetch-accommodation-ratings`,
    async (args, { getState, rejectWithValue }): Promise<any> => {
        try {
            const state: any = getState();
            const newPage = state[SliceNameEnum.AccommodationRatings].pageToLoad;

            return await getRatingsFromAPIWithEs(args, newPage);
        } catch (err) {
            return rejectWithValue([err].flat());
        }
    },
);

const slice = createSlice({
    name: SliceNameEnum.AccommodationRatings,
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<Error>) => {
            const { payload } = action;

            state.loading = LoadingState.IDLE;
            state.errors = [payload];
        },
        clearErrors: state => {
            return {
                ...state,
                errors: null,
            };
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchRatingsThunk.pending, state => {
            state.loading = LoadingState.PENDING;
        });
        builder.addCase(fetchRatingsThunk.fulfilled, (state, { payload }) => {
            if (!payload) {
                return;
            }

            const { data } = payload;
            state.loading = LoadingState.IDLE;
            state.errors = null;

            if (!state.data || !state.data.collection) {
                state.data = data;
            } else {
                state.data = {
                    collection: [...state.data.collection, ...(data.collection || [])],
                    paginationInfo: data.paginationInfo,
                };
            }

            state.pageToLoad = state.pageToLoad + 1;
        });

        builder.addCase(fetchRatingsThunk.rejected, (state, action) => {
            state.errors = [action.error].flat();
            state.loading = LoadingState.IDLE;
        });
    },
});

const { actions: sliceActions } = slice;

const actions = {
    ...sliceActions,
    fetchRatingsThunk,
};

const selectSelfState = (state: {
    [SliceNameEnum.AccommodationRatings]: AccommodationRatingsStateModel;
}) => {
    return state[slice.name] ?? initialState;
};

const selectors = {
    selectLoading: createSelector(selectSelfState, state => state.loading),
    selectRatings: createSelector(selectSelfState, state => state.data?.collection || []),
    selectIsInitialDataLoaded: createSelector(selectSelfState, state => state.pageToLoad > 1),
    selectHasPagesToLoad: createSelector(selectSelfState, state => {
        const lastPage = state.data?.paginationInfo?.lastPage;
        return isNil(lastPage) || state.pageToLoad <= lastPage;
    }),
};

export { slice, actions, selectors };
