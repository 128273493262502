import { IconProps, NocIconGenerator } from '../common';

export const ParkingCircle = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'ParkingCircle',
            path: (
                <>
                    <g
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M.55 12c0 6.35 5.15 11.5 11.5 11.5s11.5-5.15 11.5-11.5S18.4.5 12.05.5.55 5.65.55 12Z" />
                        <path d="M16 9c0 1.66-1.34 3-3 3h-3V6h3c1.66 0 3 1.34 3 3ZM10 18v-6" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
