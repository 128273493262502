/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class HandleService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Run availability handler for given accommodation
     * Synchronizes availability for accommodation in elasticsearch
     * @param id Handle identifier
     * @returns void
     * @throws ApiError
     */
    public apiAvailabilityhandleIdPost(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/availability/handle/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
}
