/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Context_write } from './Context_write';
export type ListingSetting_write = {
    sortAlgorithm?: ListingSetting_write.sortAlgorithm;
    context?: Array<Context_write>;
};
export namespace ListingSetting_write {
    export enum sortAlgorithm {
        MIX = 'mix',
        NORMAL = 'normal',
    }
}

