import { IconProps, NocIconGenerator } from '../common';

export const ArrowRight = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'ArrowRight',
            path: (
                <path
                    stroke="#292D32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                    d="M14.43 5.93L20.5 12l-6.07 6.07M3.5 12h16.83"
                />
            ),
        }}
    />
);
