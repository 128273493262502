import React from 'react';

import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons';
import {
    Input as ChakraInput,
    InputGroup,
    InputLeftElement,
    InputRightElement,
} from '@chakra-ui/input';
import { Spinner } from '@chakra-ui/spinner';
import { ComponentWithAs } from '@chakra-ui/system';

import { InputProps } from './input.props';

export const Input: React.ForwardRefExoticComponent<
    Omit<any, 'ref'> & React.RefAttributes<ComponentWithAs<'input', InputProps>>
> = React.forwardRef<typeof ChakraInput, any>(
    (
        {
            label,
            errorMessage,
            isSubmitted = false,
            isLoading = false,
            showValidationIcons = true,
            inputProps = {},
            labelProps = {},
            leftIcon,
            className,
            children,
            isFullWidth,
            ...props
        }: InputProps,
        ref,
    ): React.ReactNode => {
        return (
            <FormControl className={`wc-input ${className ?? ''}`} {...props}>
                {label ? (
                    <FormLabel fontSize="sm" {...labelProps}>
                        {label}
                    </FormLabel>
                ) : null}
                <InputGroup flexFlow={isFullWidth ? 'column' : undefined}>
                    {showValidationIcons ? (
                        <InputLeftElement pointerEvents={'none'} h={'100%'}>
                            {errorMessage && <SmallCloseIcon boxSize={4} color="danger.500" />}
                            {isSubmitted && !errorMessage && (
                                <CheckIcon boxSize={3} color="success.500" />
                            )}
                        </InputLeftElement>
                    ) : (
                        leftIcon ?? null
                    )}

                    {children ?? <ChakraInput ref={ref} {...inputProps} />}

                    {isLoading ? (
                        <InputRightElement pointerEvents={'none'} h={'100%'}>
                            <Spinner color={'gray.600'} size={'sm'} mr={2} />
                        </InputRightElement>
                    ) : undefined}
                </InputGroup>

                <FormErrorMessage
                    {...(inputProps['data-testid']
                        ? { 'data-testid': `error-message-${inputProps['data-testid']}` }
                        : {})}
                >
                    {errorMessage}
                </FormErrorMessage>
            </FormControl>
        );
    },
);
