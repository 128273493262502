import { NextFetchEvent, NextMiddleware, NextRequest, NextResponse } from 'next/server';

import { urlJoin, urlJoinP } from 'url-join-ts';

import { getIdFromSlug, getSlug } from '@app/helpers/slug.helpers';
import { withOTLPSpan } from '@app/instrumentation/instrumentation.helpers';
import { EsSlugMiddlewareService } from '@app/services/es-slug-middleware.service';

import { MiddlewareFactory } from './middleware-factory.type';

interface CustomRedirectRule {
    source: string;
    destination: string;
    dynamicDestination?: (
        source: string,
        dynamicParams: Record<string, string>,
        req?: NextRequest,
    ) => Promise<string | boolean | 'NOT_FOUND'>;
    removeQueryParam?: string[];
    httpCode?: number;
}

const redirects: CustomRedirectRule[] = [
    {
        source: '/rezerwuj/:accommodationId/(cennik|dostepnosc|filmy|mapa|oferty|okolica|opinie|profil|rabaty|termin)/',
        destination: '/rezerwuj/:accommodationId/',
        httpCode: 308,
    },
    {
        source: '/rezerwuj/:accommodationId/zdjecia/',
        destination: '/rezerwuj/:accommodationId/',
        removeQueryParam: ['pid'],
        httpCode: 308,
    },
    {
        source: '/rezerwuj/:accommodationId/',
        destination: '/rezerwuj/:accommodationId/',
        httpCode: 308,
        dynamicDestination: async (source, dynamicParams, req?: NextRequest) => {
            const { accommodationId } = dynamicParams;
            const numericId = getIdFromSlug(accommodationId);

            const result = await withOTLPSpan(
                'GET SLUG DATA FROM ES',
                async span =>
                    await EsSlugMiddlewareService.instance
                        .loadSlugData(numericId ?? '')
                        .finally(() => span.end()),
            );

            const accommodationNameSlug = getSlug(result?._source?.name || '');

            if (!accommodationNameSlug) {
                return 'NOT_FOUND';
            }

            const newAccommodationIdValue = `${numericId}-${accommodationNameSlug}`;

            if (accommodationId === newAccommodationIdValue) {
                return Promise.resolve(false); // Same slug, no redirect needed
            }

            return Promise.resolve(urlJoin('rezerwuj', newAccommodationIdValue));
        },
    },
];

const getDynamicParamMap = (redirects: CustomRedirectRule[]) =>
    redirects.map(redirect => {
        const { source } = redirect;
        const redirectSegments = source.split('/');

        // Get dynamic param map e.g. `id` in `path/:id/etc/`
        // Dynamic param has to start with a colon
        return redirectSegments.map((segment, index) => {
            if (segment.startsWith(':')) {
                return segment.slice(1);
            }

            return '';
        });
    });

const dynamicRedirectsParams = getDynamicParamMap(redirects);

const getNewSearchParams = (
    searchParams: URLSearchParams,
    removeQueryParam: CustomRedirectRule['removeQueryParam'] = undefined,
): string => {
    // remove query string params if specified
    removeQueryParam?.forEach(queryParam => {
        if (searchParams.has(queryParam)) {
            searchParams.delete(queryParam);
        }
    });

    return searchParams.toString();
};

const getNewHref = (href: string, origin: string, searchParams: string): string => {
    // urlJoinP does not keep trailing slash by default - we need to add it manually to the end
    return urlJoinP(origin, [href, searchParams ? `?${searchParams}` : '/']);
};

export const middlewareRedirects: MiddlewareFactory =
    (next: NextMiddleware) => async (request: NextRequest, _next: NextFetchEvent) => {
        const res = await next(request, _next);

        if (res) {
            const newUrl = request.nextUrl.clone();
            const { href, origin, searchParams } = newUrl;
            const sourcePath = href.replace(origin, '');
            let redirectSourceSegments: Record<string, string> = {};

            const redirect = redirects.find((redirect, index) => {
                const {
                    source: redirectSource,
                    destination,
                    dynamicDestination,
                    removeQueryParam,
                } = redirect;

                const redirectSegments = redirectSource.split('/');
                const dynamicSegments = dynamicRedirectsParams[index];
                const sourceSegments = sourcePath.split('?')[0].split('/');
                const namedSourceSegments: Record<string, string> = {};

                const isSourceMatch = sourceSegments.every((segment, index) => {
                    if (segment.match(new RegExp(redirectSegments[index]))) {
                        return true;
                    } else if (dynamicSegments[index].length) {
                        namedSourceSegments[dynamicSegments[index] as string] = segment;

                        return true;
                    }
                    return false;
                });

                redirectSourceSegments = namedSourceSegments;

                if (!isSourceMatch) {
                    return false;
                }

                if (dynamicDestination) {
                    return true;
                } else {
                    let newDest = destination;

                    // Handle dynamic params
                    Object.entries(namedSourceSegments).forEach(segment => {
                        newDest = newDest.replace(`:${segment[0]}`, segment[1]);
                    });

                    const newSearchParams = getNewSearchParams(searchParams, removeQueryParam);
                    newUrl.href = getNewHref(newDest, origin, newSearchParams.toString());

                    return true;
                }
            });

            if (redirect) {
                if (redirect.dynamicDestination) {
                    const newDest = await redirect.dynamicDestination(
                        href,
                        redirectSourceSegments,
                        request,
                    );

                    if (typeof newDest === 'boolean' || newDest === '') {
                        // Redirect is not needed
                        return res;
                    }

                    if (newDest === 'NOT_FOUND') {
                        newUrl.pathname = '/404}';

                        return NextResponse.rewrite(newUrl);
                    }

                    const newSearchParams = getNewSearchParams(
                        searchParams,
                        redirect.removeQueryParam,
                    );
                    newUrl.href = getNewHref(newDest as string, origin, newSearchParams.toString());
                }

                return NextResponse.redirect(newUrl, {
                    status: redirect.httpCode ?? 307,
                });
            }
        }

        return res;
    };
