import { useMemo } from 'react';

import { findLast } from 'lodash';

import { CancellationPolicyType } from '@nocowanie/core';

import { apiTypes } from '@app/models';

export const usePolicyInfo = (
    cancellationRules: apiTypes.Maybe<apiTypes.CancellationRules> | undefined,
) => {
    return useMemo(() => {
        if (!cancellationRules?.rules?.length) {
            return {
                policyType: CancellationPolicyType.NoRefund,
            };
        }

        if (cancellationRules.freeCancellation) {
            const lastRuleWithFullRefund = findLast(
                cancellationRules.rules || [],
                rule => rule?.prepaidPercentage === 0,
            );

            return {
                policyType: CancellationPolicyType.FullRefund,
                policyDate: lastRuleWithFullRefund?.to,
            };
        }

        const lastRuleWithPartialRefund = findLast(
            cancellationRules.rules || [],
            rule => rule && rule?.prepaidPercentage > 0 && rule?.prepaidPercentage < 100,
        );

        if (lastRuleWithPartialRefund) {
            return {
                policyType: CancellationPolicyType.PartialRefund,
                policyDate: (lastRuleWithPartialRefund as apiTypes.CancellationRule)?.to,
            };
        }

        return {
            policyType: CancellationPolicyType.NoRefund,
        };
    }, [cancellationRules]);
};
