import { useTranslation } from 'next-i18next';

import { useCallback, useEffect, useState } from 'react';

import { Button } from '@chakra-ui/button';
import { Box, Divider, Flex, GridItem, HStack, Text, VStack } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';

import { urlHelpers } from '@nocowanie/core';
import { Avatar, GridWrapper, searchEngineIsMobileSsrBreakpoint } from '@nocowanie/common-ui';
import { IconsLinear } from '@nocowanie/icons';

import { SearchEngineWrapper } from '@app/components/molecules/search-engine-wrapper';
import { getDashboardLink, logOutUser } from '@app/helpers/navigation.helpers';
import { useUserProfile } from '@app/hooks';
import { NavGroupModel } from '@app/models';

import { AppHeaderLogo } from './app-header-logo';
import { AppHeaderOldProps } from './app-header-old.props';

import staticData from '../../../assets/data/static-data.json';
import { useAppHeaderHook } from '../app-header/app-header.hook';
import { Navigation, NavigationMobile } from '../navigation';
import navLinks from '../navigation/navigation-links.json';

export const AppHeaderOld = ({ isSimplified = true }: AppHeaderOldProps) => {
    const { t } = useTranslation('common', {
        keyPrefix: 'appHeader',
    });
    const { isMobileRes } = useAppHeaderHook();
    const { user } = useUserProfile();
    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const handleLogout = useCallback(() => {
        logOutUser();
    }, []);

    const handleCloseProfile = useCallback(() => {
        setIsProfileOpen(false);
    }, []);

    const handleOpenProfile = useCallback(() => {
        setIsProfileOpen(true);
    }, []);

    useEffect(() => {
        if (!isProfileOpen) {
            return;
        }

        window.addEventListener('scroll', handleCloseProfile);

        return () => {
            window.removeEventListener('scroll', handleCloseProfile);
        };
    }, [isProfileOpen]);

    const links = navLinks as NavGroupModel[];

    const [isMobileSearchEngineVersion] = useMediaQuery(
        `(max-width: ${searchEngineIsMobileSsrBreakpoint})`,
    );

    const HeaderRightSection = () =>
        !isMobileRes ? (
            user ? (
                <Menu
                    isOpen={isProfileOpen}
                    onOpen={handleOpenProfile}
                    onClose={handleCloseProfile}
                >
                    <MenuButton
                        as={Button}
                        rightIcon={<IconsLinear.ArrowDown2 fontSize={'sm'} />}
                        border={'none'}
                        _hover={{
                            bg: 'transparent',
                        }}
                        _active={{
                            bg: 'transparent',
                        }}
                        title={user.firstName || user.lastName || 'user'}
                    >
                        <Avatar src={user.avatar ?? undefined} />
                    </MenuButton>
                    <MenuList zIndex={5} color={'secondary.500'} fontSize={'sm'}>
                        <MenuItem
                            as={'a'}
                            href={getDashboardLink(user)}
                            _hover={{
                                bg: 'blue.50',
                                textDecoration: 'none',
                            }}
                            _focus={{
                                bg: 'blue.50',
                            }}
                        >
                            {t('userMenu.goToPanel')}
                        </MenuItem>
                        <MenuItem
                            onClick={handleLogout}
                            _hover={{
                                bg: 'blue.50',
                            }}
                            _focus={{
                                bg: 'blue.50',
                            }}
                        >
                            {t('userMenu.logOut')}
                        </MenuItem>
                    </MenuList>
                </Menu>
            ) : (
                <HStack gap={'gutterWidth'}>
                    <VStack fontSize={'xs'} align={'flex-start'} gap={1}>
                        <Text
                            as="a"
                            href={urlHelpers.getPhoneNoHref(staticData.navigation.hotline)}
                        >
                            <IconsLinear.Call mr={2} fontSize={'sm'} />
                            {staticData.navigation.hotline}
                        </Text>
                        <Text as="a" href={`mailto:${staticData.navigation.customerServiceEmail}`}>
                            <IconsLinear.Sms mr={2} fontSize={'sm'} />
                            {staticData.navigation.customerServiceEmail}
                        </Text>
                    </VStack>
                    <Divider
                        orientation={'vertical'}
                        borderColor={'gray.200'}
                        h={'auto'}
                        alignSelf={'stretch'}
                    />
                    <a color={'gray.900'} href={links[0].navLinks[0].url ?? '/'}>
                        {t('cta.logIn')}
                    </a>
                </HStack>
            )
        ) : null;

    return (
        <Box as={'header'} py={2} borderBottom={'1px solid'} borderBottomColor={'gray.200'}>
            <GridWrapper>
                {isSimplified ? (
                    <AppHeaderLogo />
                ) : (
                    <HStack as={GridItem} gridColumn={'1 / -1'} justify={'space-between'}>
                        <HStack gap={isMobileRes ? 0 : 'gutterWidth'}>
                            {isMobileRes ? (
                                <NavigationMobile
                                    menuLabel={t('menu.menuLabel')}
                                    closeLabel={t('menu.closeLabel')}
                                    user={user}
                                />
                            ) : null}
                            <AppHeaderLogo />
                            {!isMobileRes ? (
                                <Navigation triggerText={t('menu.planYourStay')} />
                            ) : null}
                        </HStack>
                        {isMobileSearchEngineVersion ? (
                            <Flex gap={4}>
                                <SearchEngineWrapper />
                                <HeaderRightSection />
                            </Flex>
                        ) : (
                            <>
                                <SearchEngineWrapper />
                                <HeaderRightSection />
                            </>
                        )}
                    </HStack>
                )}
            </GridWrapper>
        </Box>
    );
};
