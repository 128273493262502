import { sendGTMEvent } from '@next/third-parties/google';

import { useCallback } from 'react';

import {
    commonHelpers,
    DataLayerAccommodationEvent,
    DataLayerEventName,
    DataLayerOfferTypeEnum,
    DataLayerOriginEnum,
    DataLayerSearchResultsEvent,
    DataLayerStorageDataType,
    LocalStorageKey,
} from '@nocowanie/core';

import { getRatingDescValue } from '@app/helpers';
import { apiTypes } from '@app/models';
import { accommodationStaticSlice, useAppSelector } from '@app/state';

export function useDataLayerAccommodationEvents(accommodationId: string | null) {
    const commonEventData = useAppSelector(
        accommodationStaticSlice.selectors.selectAccommodationDataLayerData,
    );

    const getFacilities = useCallback(
        (internalAmenityGroups: apiTypes.Accommodation['internalAmenityGroups']) => {
            return internalAmenityGroups
                ? internalAmenityGroups
                      .filter(group => !!group?.label)
                      .map(group => group?.label)
                      .join(';')
                : undefined;
        },
        [],
    );

    const getOfferEventOrigin = useCallback((accommodationId: string | null) => {
        if (!accommodationId) return undefined;

        // by default we set origin - other
        let eventOrigin = DataLayerOriginEnum.Other;

        const storageKey = `${LocalStorageKey.DataLayerOfferOriginPrefix}${accommodationId}`;
        const lsItem = localStorage.getItem(storageKey) as DataLayerOriginEnum;
        const lsValue = lsItem ?? undefined;

        // if there is no referrer (if it's empty) or it's different than 'nocowanie.pl' - current website was entered from 'outside', origin - entrance
        if (!document.referrer || !document.referrer.includes('nocowanie.pl')) {
            eventOrigin = DataLayerOriginEnum.Entrance;
        } else {
            // if there is a LS key and the value of this LS key corresponds to the value from Enum - we set a similar event origin
            if (
                lsValue &&
                [DataLayerOriginEnum.SearchResults, DataLayerOriginEnum.SearchRedirect].includes(
                    lsValue,
                )
            ) {
                eventOrigin = lsValue;
            } else if (!lsValue) {
                const ssItem = sessionStorage.getItem(storageKey);

                // if there are no LS and SS keys - we leave the default event origin (other)
                if (!ssItem) {
                    return eventOrigin;
                }

                const { origin, referrer } = JSON.parse(ssItem);
                // if there is no LS key, but there is an SS key with a value and a referrer corresponding
                // to the one from the previous visit to the page (page refresh) - we set a similar event origin
                if (origin && referrer == document.referrer) {
                    eventOrigin = origin;
                }
            }
        }

        if (lsValue) {
            // if there is a LS key - we remove it
            localStorage.removeItem(storageKey);

            // if there is also document.referrer (redirection occurred from another subpage of the website)
            // then we add a sessionStorage key (needed to determine origin after refreshing the page)
            if (document.referrer) {
                const ssItem = {
                    referrer: document.referrer,
                    origin: lsValue,
                };
                sessionStorage.setItem(storageKey, JSON.stringify(ssItem));
            }
        }

        return eventOrigin;
    }, []);

    const getSearchResultsEventStorageData = useCallback(() => {
        let eventStorageData: DataLayerStorageDataType | undefined;
        const storageKey = LocalStorageKey.DataLayerSearchResults;
        const lsItem = localStorage.getItem(storageKey);
        const lsValue = lsItem ?? undefined;

        if (!lsValue || !commonHelpers.isJSON(lsValue)) return undefined;

        const { fraza: keyword, zrodlo: origin } = JSON.parse(lsValue);

        // if there is a LS key and the value of this LS key corresponds to the value from Enum - we set a similar event origin (and set current search phrase)
        if (
            keyword &&
            origin &&
            [DataLayerOriginEnum.Home, DataLayerOriginEnum.SearchRefine].includes(origin)
        ) {
            eventStorageData = {
                keyword: keyword,
                origin: origin,
            };
        }

        localStorage.removeItem(storageKey);

        return eventStorageData;
    }, []);

    const commonEventDataObj: Omit<DataLayerAccommodationEvent, 'event'> = {
        offerName: commonEventData?.offerName,
        offerId: accommodationId ?? undefined,
        offerProviderName: 'Nocowanie',
        offerProviderId: undefined,
        offerLocation: commonEventData.offerLocation,
        offerDestType: commonEventData.offerDestType,
        offerVerificationStatus: undefined,
        offerFacilities: getFacilities(commonEventData.internalAmenityGroups),
        offerRank: commonEventData?.ratingDescription
            ? getRatingDescValue(commonEventData.ratingDescription)
            : undefined,
        offerReviews: commonEventData?.ratingCount ?? undefined,
        offerPhotos: commonEventData.offerPhotos,
        offerMovies: 0,
        offerReservationModel: commonEventData.offerReservationModel,
    };

    const sendOfferDataLayerEvent = (): void => {
        const offerEventData: DataLayerAccommodationEvent = {
            event: DataLayerEventName.Offer,
            origin: getOfferEventOrigin(accommodationId),
            offerType: commonEventData.labels?.includes(
                apiTypes.Label['NocowanieCloneAccommodation'],
            )
                ? DataLayerOfferTypeEnum.OtaAbo
                : DataLayerOfferTypeEnum.Booking,
            offerLocationID: commonEventData.offerLocationID,
            ...commonEventDataObj,
        };

        sendGTMEvent(offerEventData);
    };

    const sendSearchResultsDataLayerEvent = (): void => {
        const evStorageData = getSearchResultsEventStorageData();

        if (!evStorageData) return;

        const searchResultsEventData: DataLayerSearchResultsEvent = {
            event: DataLayerEventName.SearchResults,
            pageDomain: 'nocowanie.pl',
            origin: evStorageData?.origin,
            searchOutputType: 'redirect',
            searchOutputCount: undefined,
            searchKeyword: evStorageData.keyword,
            searchLocation: commonEventData.offerLocation,
            searchLocationID: commonEventData.offerLocationID,
            searchHowLongBefore: undefined,
            searchDateFrom: undefined,
            searchDateTo: undefined,
            searchNights: undefined,
            searchAdults: undefined,
            searchKids: undefined,
            searchDestType: undefined,
            searchFacilities: undefined,
            searchRank: undefined,
        };

        sendGTMEvent(searchResultsEventData);
    };

    const sendPhoneRevealedDataLayerEvent = (): void => {
        const phoneRevealedEventData: DataLayerSearchResultsEvent = {
            event: DataLayerEventName.PhoneRevealed,
            origin: DataLayerOriginEnum.OfferImportantInfo,
            offerType: commonEventData.labels?.includes(
                apiTypes.Label['NocowanieCloneAccommodation'],
            )
                ? DataLayerOfferTypeEnum.OtaAbo
                : DataLayerOfferTypeEnum.Advertising,
            ...commonEventDataObj,
        };

        sendGTMEvent(phoneRevealedEventData);
    };

    return {
        sendOfferDataLayerEvent,
        sendPhoneRevealedDataLayerEvent,
        sendSearchResultsDataLayerEvent,
    };
}
