import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['box', 'icon']);

export const RangePicker = helpers.defineMultiStyleConfig({
    baseStyle: props => {
        const colors = props.theme.colors;
        return {
            cssVars: {
                '--day-in-range-bg': colors.brand['100'],
                '--day-range-selected': colors.brand['500'],
                '--day-range-hover': colors.brand['700'],
                '--datepicker-border-color': 'none',
            },
            icon: {
                color: 'brand.500',
            },
        };
    },
});
