import { NextParsedUrlQuery } from 'next/dist/server/request-meta';

import { differenceInCalendarDays } from 'date-fns';
import { isNil } from 'lodash';

import { dateHelpers, urlHelpers } from '@nocowanie/core';

import {
    Apartment,
    Maybe,
    ProvisionType,
    QueryAccommodationArgs,
    Variant,
} from '@app/models/api/api-types';

export const getDates = (checkIn?: Date | string, checkOut?: Date | string): [Date, Date] => {
    const checkInDate = new Date(
        checkIn && differenceInCalendarDays(new Date(), new Date(checkIn)) < 1
            ? new Date(checkIn).setHours(0, 0, 0)
            : dateHelpers.defaultCheckInDate(),
    );
    const checkOutDate = new Date(
        checkOut && differenceInCalendarDays(checkInDate, new Date(checkOut)) < 1
            ? new Date(checkOut).setHours(0, 0, 0)
            : checkIn
            ? dateHelpers.defaultCheckOutDate(checkInDate)
            : dateHelpers.defaultCheckOutDate(),
    );

    return [checkInDate, checkOutDate];
};

export const generateLegacySearchCriteriaParams = ({
    checkIn,
    checkOut,
    childrenAges,
    childrenCount,
    guestsCount,
}: Partial<QueryAccommodationArgs>): string => {
    const mappedParams = {
        'data[od]': checkIn,
        'data[do]': checkOut,
        miejsca_dorosli: guestsCount,
        miejsca_dzieci: childrenCount,
        wiek_dzieci: Array.isArray(childrenAges) ? childrenAges.join(',') : childrenAges,
    };

    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(mappedParams)) {
        if (isNil(value)) {
            continue;
        }
        params.set(key, value.toString());
    }

    return params.toString();
};

// Even though `value` parameter is defined, we don't use it. That's because this function is mainly used as yup transformer.
export const parseURIBoolean = (value: any, originalValue: any) => {
    // We treat mere presence of boolean parameter in uri as it having true value, regardless of what value it really has.
    if (isNil(originalValue) || originalValue === 'false') {
        return false;
    }
    return true;
};

export const parseURIOptionalNumber = (value: any, originalValue: any) => {
    if (Number.isNaN(value) || value < 0) {
        return null;
    }

    return value;
};

// yup transform function
// returns null, number or true
export const parseURINumberOrBoolean = (value: any, originalValue: any) => {
    if (value === 'true') {
        return value;
    }

    const parsedValue = parseInt(value);
    if (!isNaN(parsedValue) && parsedValue >= 0) {
        return parsedValue;
    }

    return null;
};

export const clearFalseParams = (params: object) => {
    // If any parameter has 'falsey' value, we want to omit it from the url
    return Object.fromEntries(
        Object.entries(params).filter(([key, value]) => !isNil(value) && value !== false),
    );
};

export const mapFiltersToParams = ({
    checkIn,
    checkOut,
    childrenCount,
    guestsCount,
    childrenAges,
}: QueryAccommodationArgs) => {
    return {
        checkInDate: checkIn,
        checkOutDate: checkOut,
        childrenCount: childrenCount,
        adultsCount: guestsCount,
        childrenAges: childrenAges,
    };
};

export const updateParams = (searchParams: string, state?: object) => {
    const params = new URLSearchParams(searchParams);

    return clearFalseParams({
        ...urlHelpers.paramsToObject(params),
        ...state,
    });
};

export const parseChildrenAges = (query: NextParsedUrlQuery): number[] | null => {
    const agesArray = parseArrayFromQueryParams<number>(query, 'childrenAges').map(age =>
        Math.min(Number(age), 17),
    );

    return agesArray.length > 0 ? agesArray.map(Number) : null;
};

export const parseArrayFromQueryParams = <T>(query: NextParsedUrlQuery, paramName: string): T[] => {
    const itemArray = Object.keys(query)
        .filter(key => key.startsWith(paramName))
        .map(key => query[key] as string | string[])
        .filter(value => value !== undefined)
        .flat();

    return itemArray as T[];
};

export const remapValueInRange = (
    value: number,
    inMin: number,
    inMax: number,
    outMin: number,
    outMax: number,
) => {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export function formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) {
        return;
    }

    const sanitizePhoneNumber = (number: string) => number.replace(/\s+/g, '');

    const number = phoneNumber.startsWith('+')
        ? sanitizePhoneNumber(phoneNumber.substring(3))
        : phoneNumber?.startsWith('00')
        ? sanitizePhoneNumber(phoneNumber.substring(4))
        : sanitizePhoneNumber(phoneNumber);
    const formattedNumber = `${setCountryCode(phoneNumber)} ${number.match(/.{1,3}/g)?.join(' ')}`;
    return formattedNumber;
}

export function setCountryCode(phoneNumber: string) {
    return phoneNumber?.startsWith('+')
        ? phoneNumber.substring(0, 3)
        : phoneNumber?.startsWith('00')
        ? `${phoneNumber.substring(0, 2).replace('00', '+')}${phoneNumber.substring(2, 4)}`
        : '+48';
}

export const formatTimeFromString = (timeString: string): string => {
    const segments = timeString.split(':');

    if (segments?.length <= 1) {
        return timeString;
    }

    const result = `${segments[0]}:${segments[1]}`;

    return result === '24:00' ? '00:00' : result;
};

export const getBestOffer = (
    apartment?: Maybe<Apartment>,
    options?: {
        withoutPrepayment?: boolean;
        freeCancel?: boolean;
        provision?: ProvisionType[];
    },
): null | Variant => {
    if (isNil(apartment)) {
        return null;
    }

    const withoutPrepayment = options?.withoutPrepayment ?? false;
    const freeCancel = options?.freeCancel ?? false;
    const provision = options?.provision;

    const filteredVariants = apartment.variants
        ?.filter((variant, index) => {
            if (!variant) {
                return false;
            }

            const isCancellationMatch = freeCancel
                ? variant.cancellationRules.freeCancellation
                : true;
            const isPrepaymentMatch = withoutPrepayment
                ? variant.cancellationRules.prepaidAmount === 0
                : true;
            const isProvisionMatch = provision?.length
                ? provision.includes(variant.provision.type)
                : true;

            return isCancellationMatch && isPrepaymentMatch && isProvisionMatch;
        })
        .sort((variantA, variantB) =>
            variantA && variantB && variantA.rate < variantB.rate ? -1 : 1,
        );

    return filteredVariants?.[0] || apartment.variants?.[0] || null;
};
