/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type User_jsonld = {
    readonly '@context'?: (string | Record<string, any>);
    readonly '@id'?: string;
    readonly '@type'?: string;
    id?: number | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    avatar?: string | null;
    type?: User_jsonld.type;
    activeAccommodationsCount?: number | null;
};
export namespace User_jsonld {
    export enum type {
        HOTELIER = 'hotelier',
        TOURIST = 'tourist',
        BOK = 'bok',
        ADMIN = 'admin',
        REDACTOR = 'redactor',
        SALESMAN = 'salesman',
        SUBACCOUNT = 'subaccount',
    }
}

