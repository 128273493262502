/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type User = {
    id?: number | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    avatar?: string | null;
    type?: User.type;
    activeAccommodationsCount?: number | null;
};
export namespace User {
    export enum type {
        HOTELIER = 'hotelier',
        TOURIST = 'tourist',
        BOK = 'bok',
        ADMIN = 'admin',
        REDACTOR = 'redactor',
        SALESMAN = 'salesman',
        SUBACCOUNT = 'subaccount',
    }
}

