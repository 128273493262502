import { SectionNameEnum } from '@app/enums';
import { environment } from '@app/environments/environment';
import { EsServiceBase } from '@app/services/abstracts/es-service.base';

import { EsResponseModel } from '../models/elastic-search';

export class EsHomepageService extends EsServiceBase {
    readonly #homepageBaseEsIndexName = 'noc-homepage';
    readonly #homepageBaseEsIndexVersion = environment.HOME_PAGE_ES_VERSION;

    private static _instance: EsHomepageService;

    protected indexName(version?: number): string {
        return `${this.#homepageBaseEsIndexName}-${version ?? this.#homepageBaseEsIndexVersion}`;
    }

    public static get instance(): EsHomepageService {
        if (!this._instance) {
            this._instance = new EsHomepageService();
        }

        return this._instance;
    }

    public async loadSectionData<T>(
        section: SectionNameEnum,
        indexVersion?: number,
    ): Promise<EsResponseModel<T>> {
        return await this.get<T>(this.indexName(indexVersion), section, '_doc', {});
    }
}
