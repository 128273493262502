import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['withBackground']);

export const SearchEngineInstantReservationCheckbox = helpers.defineMultiStyleConfig({
    baseStyle: {
        withBackground: {
            bg: 'orange.25',
            borderBottomRadius: 'xl',
            px: {
                base: 4,
                lg: 6,
            },
            py: {
                base: 3,
                md: 4,
            },
            _before: {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '100%',
                boxShadow: 'search-bar-shadow',
                borderBottomRadius: 'xl',
                zIndex: -1,
            },
        },
    },
});
