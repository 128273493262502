import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['icon']);

export const SearchLocation = helpers.defineMultiStyleConfig({
    baseStyle: {
        icon: {
            color: 'brand.500',
            pointerEvents: 'none',
            height: '100%',
        },
    },
});
