import { createSelector, createSlice } from '@reduxjs/toolkit';

import { FeatureToggleEnum } from '@nocowanie/core';
import { ftInitialState } from '@nocowanie/common-ui';

import { SliceNameEnum } from '@app/enums';
import { AppState } from '@app/state';

import { resetModalDataAction, setModalDataAction } from './common-ui-state.actions';

export type UiStateType = {
    ft: Partial<Record<FeatureToggleEnum, unknown>>;
    isMobileVersion: boolean;
    isSession: boolean | undefined;
    isPageDataLayerSent: boolean;
    modals: Record<string, unknown>;
};

export const initialState: UiStateType = {
    ft: ftInitialState,
    isMobileVersion: false,
    isPageDataLayerSent: false,
    isSession: undefined,
    modals: {},
};

export const uiStateSlice = createSlice({
    name: SliceNameEnum.CommonUiState,
    initialState,
    reducers: {
        initPage: state => {
            state.isPageDataLayerSent = false;
            state.modals = {};
        },
        setFeatureToggle: (state, action) => {
            state.ft = {
                ...state.ft,
                ...action.payload,
            };
        },
        setIsPageDataLayerSent: (state, action) => {
            state.isPageDataLayerSent = action.payload;
        },
        setIsSession: (state, action) => {
            state.isSession = action.payload;
        },
        setMobileVersion: (state, action) => {
            state.isMobileVersion = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(setModalDataAction, (state, { payload }) => {
            state.modals[payload.modalName] = payload.modalData;
        });
        builder.addCase(resetModalDataAction, (state, { payload }) => {
            state.modals[payload] = undefined;
        });
    },
});

export const {
    initPage,
    setFeatureToggle,
    setIsPageDataLayerSent,
    setIsSession,
    setMobileVersion,
} = uiStateSlice.actions;

const selectSelfState = (state: { [SliceNameEnum.CommonUiState]: UiStateType }): UiStateType => {
    return state[uiStateSlice.name] ?? initialState;
};

export const actions = {
    resetModalDataAction,
    setFeatureToggle,
    setMobileVersion,
    setModalDataAction,
};

export const selectors = {
    selectIsMobileVersion: createSelector(selectSelfState, state => state.isMobileVersion),
    selectFtActive: createSelector(
        [selectSelfState, (state: AppState, featureToggle: FeatureToggleEnum) => featureToggle],
        (state: UiStateType, featureToggle: FeatureToggleEnum) => state.ft[featureToggle] ?? false,
    ),
    selectModalData: <T>(modalName: string) =>
        createSelector(selectSelfState, state => state.modals[modalName] as T),
    selectIsPageDataLayerSent: createSelector(selectSelfState, state => state.isPageDataLayerSent),
    selectIsSession: createSelector(selectSelfState, state => state.isSession),
    selectUIFtActive: <T = unknown>(featureToggle: FeatureToggleEnum) =>
        createSelector(selectSelfState, state => (state.ft[featureToggle] ?? false) as T),
};

export default uiStateSlice.reducer;
