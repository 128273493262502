import { PartnerType, WpEventParams, WpPageType } from '@nocowanie/core';

import { sha256 } from '@app/helpers';
import { gdprQueue } from '@app/helpers/tracking.helpers';
import { useAppSelector, userProfileSlice } from '@app/state';

export function useWphPixelEvents(pageType: WpPageType, debug = false) {
    const user = useAppSelector(userProfileSlice.selectors.selectUser);

    async function getAdditionalParams(): Promise<WpEventParams> {
        return user && user.email && user.id
            ? {
                  uid: user.id,
                  em: await sha256(user.email),
              }
            : {};
    }

    const sendViewContentEvent = (params: WpEventParams = {}) => {
        gdprQueue({
            consents: [PartnerType.wpHolding],
            callback: async () => {
                const eventParams = {
                    content_type: 'Site',
                    content_name: pageType,
                    ...(await getAdditionalParams()),
                    ...params,
                };

                if (debug) {
                    console.info('[sendViewContentEvent]', eventParams);
                }

                window?.wph('track', 'ViewContent', eventParams);
            },
        });
    };

    return {
        sendViewContentEvent,
    };
}
