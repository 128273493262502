import { Flex, Spinner, Text } from '@chakra-ui/react';
const Loader = (): JSX.Element => {
    return (
        <Flex direction={'column'} align={'center'} justify={'center'} height={'100vh'}>
            <Spinner />
            <Text color={'secondary.500'} mt={1}>
                Trwa ładowanie...
            </Text>
        </Flex>
    );
};

export { Loader };
