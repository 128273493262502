import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, List, ListItem } from '@chakra-ui/layout';
import { Portal } from '@chakra-ui/portal';
import { mergeRefs } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { useCombobox, UseComboboxInputValueChange, UseComboboxSelectedItemChange } from 'downshift';
import { autoUpdate, size, useFloating } from '@floating-ui/react';

import { INPUT_EXCLUDED_KEYS, useThrottledCallback } from '@nocowanie/common-ui';

import { Input, InputProps } from './../../atoms/input';
import { AutocompleteConfig, defaultAutocompleteConfig } from './autocomplete.config';
import { AutocompleteProps } from './autocomplete.props';
import { AutocompleteBaseItemModel } from './autocomplete-base-item.model';
import { autocompleteItemFactory } from './autocomplete-item-factory';
import { useAutocomplete } from './use-autocomplete.hook';

import { GeographyTypeEnum } from '../../../enums';

export const Autocomplete = <T extends AutocompleteBaseItemModel>({
    items,
    onChange,
    onQueryChange,
    onSelectedItemChange,
    config,
    sx,
    initialQuery,
    query,
    setQuery,
    inputProps = {},
    isLoading = false,
    ...rest
}: AutocompleteProps<T>): JSX.Element => {
    const [isUserTyping, setIsUserTyping] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const { translationData }: Required<AutocompleteConfig> = {
        ...defaultAutocompleteConfig,
        ...config,
    };

    const styles = useMultiStyleConfig('Autocomplete', {});
    const { setPrevQuery, stateReducer } = useAutocomplete<T>({
        query,
        setQuery,
    });

    const { refs, floatingStyles } = useFloating({
        open: true,
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
        middleware: [
            size({
                apply({ availableWidth, availableHeight, elements, rects }) {
                    Object.assign(elements.floating.style, {
                        minWidth: `${rects.reference.width}px`,
                        maxWidth: `${Math.max(0, availableWidth)}px`,
                        maxHeight: `${Math.max(0, availableHeight - 8)}px`, // leave 8px margin from the bottom
                    });
                },
            }),
        ],
    });

    const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
        items: items || [],
        inputValue: query,
        initialInputValue: initialQuery || '',
        stateReducer,
        onInputValueChange: useCallback(
            (changes: UseComboboxInputValueChange<T>) => {
                if (changes.selectedItem?.type !== GeographyTypeEnum.accommodation) {
                    onChange?.(changes.inputValue);
                }
            },
            [onChange],
        ),
        itemToString: (item: T | null) => item?.name || '',
        onSelectedItemChange: useCallback(
            (changes: UseComboboxSelectedItemChange<T>) => {
                if (changes.selectedItem.type !== GeographyTypeEnum.accommodation) {
                    setPrevQuery(changes.selectedItem.name);
                    setQuery(changes.selectedItem.name);
                }

                onSelectedItemChange?.(changes);

                inputRef.current?.blur();
            },
            [onSelectedItemChange, setPrevQuery, setQuery],
        ),
    });

    const {
        onChange: onInputChange = undefined,
        ref,
        ...restInputProps
    } = (inputProps?.inputProps || {}) as any;

    const onThrottledInput = useThrottledCallback((event: KeyboardEvent) => {
        if (isUserTyping || INPUT_EXCLUDED_KEYS.includes(event.key)) {
            return;
        }

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        if (query === '') {
            setIsUserTyping(false);

            return;
        }

        setIsUserTyping(true);

        timerRef.current = setTimeout(() => {
            setIsUserTyping(false);
        }, 250);
    }, 250);

    const mergedInputProps: InputProps = {
        ...inputProps,
        isLoading: query !== '' && (isLoading || isUserTyping),
        showValidationIcons: false,
        inputProps: {
            className: 'wc-autocomplete-input',
            'data-testid': 'autocomplete-input',
            ...getInputProps({
                onChange: useCallback(
                    (event: React.ChangeEvent<HTMLInputElement>): void => {
                        if (event.target.value !== query) {
                            const trimmedValue = event.target.value.trim();

                            setQuery(event.target.value);

                            if (trimmedValue !== query.trim()) {
                                onQueryChange?.(trimmedValue);
                            }
                        }
                        onInputChange && onInputChange(event);
                    },
                    [onInputChange, onQueryChange, query, setQuery],
                ),
                onInput: onThrottledInput,
                placeholder: translationData.placeholder,
                ref: mergeRefs(inputRef, ref),
                ...restInputProps,
            }),
            type: 'search',
            sx: {
                ...inputProps.inputProps?.sx,
                '&::-webkit-search-cancel-button': {
                    WebkitAppearance: 'none',
                    display: 'none',
                },
            },
        },
    };

    const mergedListProps = useMemo(() => {
        return {
            sx: { ...styles.list, ...floatingStyles },
            ...getMenuProps(
                {
                    ref: refs.setFloating,
                },
                {
                    suppressRefError: true,
                },
            ),
        };
    }, [floatingStyles, getMenuProps, refs.setFloating, styles.list]);

    useEffect(() => {
        refs.setReference(inputRef.current);
    }, [refs]);

    return (
        <Box
            sx={{
                ...styles.wrapper,
                ...sx,
            }}
            data-testid={'autocomplete-wrapper'}
            {...rest}
        >
            <Input {...mergedInputProps} />
            <Portal appendToParentPortal={false}>
                <List
                    {...mergedListProps}
                    className={'wc-autocomplete-items'}
                    data-testid={'autocomplete-items'}
                >
                    {isOpen && Array.isArray(items) ? (
                        items.length ? (
                            items.map((item: T, index: number) => (
                                <React.Fragment key={item.id}>
                                    {autocompleteItemFactory(item.type, {
                                        name: item.name,
                                        caption: item.caption,
                                        type: item.type,
                                        url: item.path,
                                        query: query,
                                        itemCount: item?.details?.accommodationsCount,
                                        isHighlighted: highlightedIndex === index,
                                        translationData: translationData,
                                        ...getItemProps({
                                            item,
                                            index,
                                        }),
                                    })}
                                </React.Fragment>
                            ))
                        ) : !isLoading ? (
                            <ListItem sx={styles.noResults} data-testid={'autocomplete-no-results'}>
                                {translationData.noResults}
                            </ListItem>
                        ) : null
                    ) : undefined}
                </List>
            </Portal>
        </Box>
    );
};
