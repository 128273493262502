import { IconProps, IconsNocowanie } from '@nocowanie/icons';

import { InternalAmenityGroupEnum } from '@app/models/api/api-types';

export const getAmenityIcon = (type: InternalAmenityGroupEnum, iconConfig?: IconProps) => {
    const amenityIconsMap = {
        [InternalAmenityGroupEnum.Parking]:
            // Parking is the only icon we want to always show in a circle. For a consistent look, it has a separate circled version.
            iconConfig?.isRound || iconConfig?.withBorder ? (
                <IconsNocowanie.Parking {...iconConfig} />
            ) : (
                <IconsNocowanie.ParkingCircle {...iconConfig} />
            ),
        [InternalAmenityGroupEnum.Wifi]: <IconsNocowanie.Wifi {...iconConfig} />,
        [InternalAmenityGroupEnum.PetFriendly]: <IconsNocowanie.Pets {...iconConfig} />,
        [InternalAmenityGroupEnum.SwimmingPool]: <IconsNocowanie.SwimmingPool {...iconConfig} />,
        [InternalAmenityGroupEnum.Catering]: <IconsNocowanie.Meals {...iconConfig} />,
        [InternalAmenityGroupEnum.ChildrensArea]: <IconsNocowanie.Playground {...iconConfig} />,
        [InternalAmenityGroupEnum.Grill]: <IconsNocowanie.Grill {...iconConfig} />,
        [InternalAmenityGroupEnum.Jacuzzi]: <IconsNocowanie.Jacuzzi {...iconConfig} />,
        [InternalAmenityGroupEnum.AirConditioning]: (
            <IconsNocowanie.AirConditioning {...iconConfig} />
        ),
        [InternalAmenityGroupEnum.Kitchen]: <IconsNocowanie.Kitchen {...iconConfig} />,
        [InternalAmenityGroupEnum.Sauna]: <IconsNocowanie.Sauna {...iconConfig} />,
        [InternalAmenityGroupEnum.Tv]: <IconsNocowanie.Tv {...iconConfig} />,
        [InternalAmenityGroupEnum.Unknown]: null,
    };
    return amenityIconsMap[type];
};
