import { IconProps, NocIconGenerator } from '../common';

export const Send = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Send',
            path: (
                <>
                    <path
                        d="M9.51002 4.52638L18.07 8.80638C21.91 10.7264 21.91 13.8664 18.07 15.7864L9.51002 20.0664C3.75002 22.9464 1.40002 20.5864 4.28002 14.8364L5.15002 13.1064C5.37002 12.6664 5.37002 11.9364 5.15002 11.4964L4.28002 9.75638C1.40002 4.00638 3.76002 1.64638 9.51002 4.52638Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5.43994 12.2964H10.8399"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            ),
        }}
    />
);
