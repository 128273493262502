import { IconProps, NocIconGenerator } from '../common';

export const Facebook = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Facebook',
            path: (
                <>
                    <title>Facebook logo</title>
                    <path
                        fill="#fff"
                        d="M13.763 8.057V6.086c0-.296.014-.525.041-.687.028-.163.09-.322.188-.48.097-.157.255-.265.472-.326.218-.06.507-.09.867-.09h1.973V.56h-3.152c-1.824 0-3.133.433-3.93 1.299-.795.865-1.193 2.14-1.193 3.824v2.374H6.67V12h2.36v11.44h4.734V12h3.15l.418-3.943h-3.568Z"
                    />
                </>
            ),
        }}
    />
);
