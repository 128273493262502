import { GdprQueueParams, PartnerType } from '@nocowanie/core';

declare global {
    interface Window {
        WP: any;
        wph: any;
    }
}

export const gdprQueue = (params: GdprQueueParams) => {
    if (typeof window.WP === 'undefined') {
        window.WP = [];
    }

    const supportedConsents = [PartnerType.wpHolding, PartnerType.otherTrustedPartners];
    const isAllChecked = (consents: PartnerType[]) =>
        consents.every((consent: PartnerType) => window.WP?.gdpr.check('mr', consent));

    const handleTracking = (params: GdprQueueParams) => {
        const filteredConsents = params.consents.filter(
            (consent: PartnerType) => supportedConsents.indexOf(consent) !== -1,
        );

        window.WP?.gdpr.runAfterConsent(() => {
            if (isAllChecked(filteredConsents)) {
                params.callback();
            }
        });
    };

    window.WP.push(() => {
        handleTracking(params);
    });
};
