/**
 * Masks string with pattern, only first and last characterss are not replaced (or suffix if `text` is an email)
 * @param text
 * @param maskPattern
 */
export const maskedText = (text: string, maskPattern = '*'): string => {
    const [prefix, ...suffix] = text.split('@');
    const isEmail = text.includes('@');

    if (prefix.length <= 2) {
        return text;
    }

    return (
        `${prefix[0]}${maskPattern.repeat(prefix.length - 2)}${prefix[prefix.length - 1]}` +
        (isEmail ? `@${suffix}` : '')
    );
};
