import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['wrapper', 'list', 'noResults']);

export const Autocomplete = helpers.defineMultiStyleConfig({
    baseStyle: {
        wrapper: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignSelf: 'center',
        },
        list: {
            bgColor: 'white',
            w: '100%',
            boxShadow: 'md',
            borderRadius: 'md',
            zIndex: 'popover',
            overflow: 'auto',
        },
        noResults: {
            fontSize: 'sm',
            color: 'gray.700',
            display: 'block',
            p: 3,
        },
    },
});
