import { getAbsoluteUrl, getEnv, getEnvStringValue } from './env.helpers';

export const environment = {
    REDUX_DEV_TOOLS: getEnv<boolean | undefined>('NEXT_PUBLIC_REDUX_DEV_TOOLS'),
    CHECKOUT_API_END_POINT: getAbsoluteUrl(
        getEnvStringValue('NEXT_PUBLIC_CHECKOUT_API_ENDPOINT', 'CHECKOUT_API_ENDPOINT') ?? '',
    ),
    ACCOMMODATION_API_ENDPOINT: getAbsoluteUrl(
        getEnvStringValue('NEXT_PUBLIC_ACCOMMODATION_API_ENDPOINT', 'ACCOMMODATION_API_ENDPOINT') ??
            '',
    ),
    NOC_API_END_POINT: getAbsoluteUrl(
        getEnvStringValue('NEXT_PUBLIC_NOC_API_ENDPOINT', 'NOC_API_ENDPOINT') ?? '',
    ),
    CROSSSTORAGE_ENDPOINT: getEnvStringValue('NEXT_PUBLIC_CROSSSTORAGE_ENDPOINT'),
    API_DATE_FORMAT: getEnvStringValue('NEXT_PUBLIC_API_DATE_FORMAT'),
    GFX_SERVER: getEnvStringValue('NEXT_PUBLIC_GFX_SERVER'),
    CONSENTS_URLS: {
        privacyPolicy: getEnvStringValue('NEXT_PUBLIC_CONSENTS_URLS_PRIVACY_POLICY'),
        reservationTermsSzallas: getEnvStringValue(
            'NEXT_PUBLIC_CONSENTS_URLS_RESERVATION_TERMS_SZALLAS',
        ),
        privacyPolicySzallas: getEnvStringValue('NEXT_PUBLIC_CONSENTS_URLS_PRIVACY_POLICY_SZALLAS'),
    },
    NOCOWANIE_URL: getEnvStringValue('NEXT_PUBLIC_NOCOWANIE_URL'),
    NOCIMG_URL: getEnvStringValue('NEXT_PUBLIC_NOCIMG_URL'),
    FACEBOOK_APP_ID: getEnvStringValue('NEXT_PUBLIC_FACEBOOK_APP_ID'),
    GTM: {
        GTM_TAG_ID: getEnvStringValue('NEXT_PUBLIC_GTM_TAG_ID'),
        GTM_AUTH: getEnvStringValue('NEXT_PUBLIC_GTM_AUTH'),
        GTM_PREVIEW: getEnvStringValue('NEXT_PUBLIC_GTM_PREVIEW'),
    },
    WPH_PIXEL_ID: getEnvStringValue('NEXT_PUBLIC_WPH_PIXEL_ID'),
    SENTRY: {
        SENTRY_DNS: getEnvStringValue('NEXT_PUBLIC_SENTRY_DNS'),
        SENTRY_ENABLED: getEnv<boolean>('NEXT_PUBLIC_SENTRY_ENABLED'),
        SENTRY_ENVIRONMENT: getEnvStringValue(
            'NEXT_PUBLIC_SENTRY_ENVIRONMENT',
            'SENTRY_ENVIRONMENT',
        ),
        SENTRY_PROJECT_NAME: getEnvStringValue('NEXT_PUBLIC_SENTRY_PROJECT_NAME'),
    },
    FT: {
        szallas_online_payments: getEnv<boolean>('NEXT_PUBLIC_FT_SZALLAS_ONLINE_PAYMENTS'),
    },
    SSR_API_HOST: getAbsoluteUrl(
        getEnvStringValue('NEXT_PUBLIC_SSR_API_HOST', 'SSR_API_HOST') ?? '',
    ),
    ASSETS_PATH: getEnvStringValue('NEXT_PUBLIC_ASSETS_PATH') ?? '',
    CEPH_URL: getEnvStringValue('NEXT_PUBLIC_CEPH_URL') ?? '',
    SESSION_ID_COOKIE: getEnvStringValue('NEXT_PUBLIC_SESSION_ID_COOKIE'),
    SM_ENDPOINT: getEnvStringValue('SM_ENDPOINT') ?? '',
    SM_CLIENT_ID: getEnvStringValue('SM_CLIENT_ID') ?? '',
    SM_API_KEY: getEnvStringValue('SM_API_KEY') ?? '',
    SM_SHA: getEnvStringValue('SM_SHA') ?? '',
    SM_OWNER_EMAIL: getEnvStringValue('SM_OWNER_EMAIL') ?? '',
    AUTOCOMPLETE_ES_VERSION: getEnv<number | undefined>('NEXT_PUBLIC_AUTOCOMPLETE_ES_VERSION') ?? 2,
    HOME_PAGE_ES_VERSION: getEnv<number | undefined>('NEXT_PUBLIC_HOME_PAGE_ES_VERSION') ?? 1,
    ACCOMMODATION_WC_APP: getEnvStringValue('ACCOMMODATION_WC_APP'),
    REGISTRATION_WC_APP: getEnvStringValue('ACCOMMODATION_WC_APP'),
};
