import { useCallback, useEffect } from 'react';

import { AppModalsEnum } from '@app/enums';
import { commonUiStateSlice, useAppDispatch } from '@app/state';

type ModalDataItem = {
    modalName: AppModalsEnum;
    modalData: boolean;
};

const globalModalTypes: AppModalsEnum[] = [
    AppModalsEnum.ApartmentsList,
    AppModalsEnum.Amenities,
    AppModalsEnum.Description,
    AppModalsEnum.Gallery,
    AppModalsEnum.Location,
    AppModalsEnum.Reviews,
];

export const useGlobalModalFragmentSync = (modalsState: ModalDataItem[]) => {
    const dispatch = useAppDispatch();

    const syncModalState = useCallback((modals: ModalDataItem[]) => () => {
        const hash = window.location.hash.slice(1);
        const fragments = hash ? hash.split(',') : [];

        globalModalTypes.forEach(modalName => {
            const currentItem = modals.find(modal => modal.modalName === modalName);
            const currentValue = currentItem?.modalData;

            if (fragments.some(fragment => fragment.startsWith(modalName)) && !currentValue) {
                dispatch(
                    commonUiStateSlice.actions.setModalDataAction({
                        modalName,
                        modalData: true,
                    })
                );
            } else {
                if (currentValue) {
                    dispatch(commonUiStateSlice.actions.resetModalDataAction(modalName));
                }
            }
        });
    }, []);

    useEffect(() => {
        syncModalState(modalsState);
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        window.addEventListener('hashchange', syncModalState(modalsState), { signal: controller.signal });

        return () => controller.abort();
    }, [modalsState]);
};
