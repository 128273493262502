import { IconProps, NocIconGenerator } from '../common';

export const ArrowLeft = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'ArrowLeft',
            path: (
                <path
                    stroke="#292D32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                    d="M9.57 5.93L3.5 12l6.07 6.07M20.5 12H3.67"
                />
            ),
        }}
    />
);
