import { useEffect, useRef, useState } from 'react';

import { Box, Spinner } from '@chakra-ui/react';

import { Alert } from './../alert';
import { InfiniteScrollProps } from './infinite-scroll.props';

export const InfiniteScroll = ({
    children,
    onNextPage,
    errorMessage,
    isLoading = false,
    isError = false,
    loadDataOnInit = false,
    hasMorePages = true,
    wrapperProps,
    ...props
}: InfiniteScrollProps) => {
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(!loadDataOnInit);
    const observerTargetRef = useRef(null);

    useEffect(() => {
        if (!loadDataOnInit || (loadDataOnInit && isInitialDataLoaded)) {
            return;
        }

        onNextPage();
        setIsInitialDataLoaded(true);
    }, [isInitialDataLoaded, loadDataOnInit, onNextPage]);

    useEffect(() => {
        if (isLoading || (loadDataOnInit && !isInitialDataLoaded) || !hasMorePages) {
            return;
        }

        const target = observerTargetRef.current;

        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    if (hasMorePages) {
                        onNextPage();
                    }
                }
            },
            {
                threshold: 0,
                rootMargin: '0px 0px 0px 0px',
            },
        );

        target && observer.observe(target);

        return () => {
            target && observer.unobserve(target);
        };
    }, [
        hasMorePages,
        isInitialDataLoaded,
        isLoading,
        loadDataOnInit,
        observerTargetRef,
        onNextPage,
    ]);

    return (
        <Box {...wrapperProps}>
            <Box {...props}>{children}</Box>
            <Box ref={observerTargetRef} />
            {isLoading ? (
                <Box textAlign={'center'} mt={6}>
                    <Spinner />
                </Box>
            ) : null}
            {isError ? (
                <Alert status={'error'} mt={6}>
                    {errorMessage}
                </Alert>
            ) : null}
        </Box>
    );
};
