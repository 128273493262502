import { IconProps, NocIconGenerator } from '../common';

export const Mountains = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Mountains',
            path: (
                <>
                    <path
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="m4.652 20 8.06-15.435c.11-.176.254-.318.418-.416A1.03 1.03 0 0 1 13.66 4c.183 0 .364.051.528.149.164.098.308.24.42.416L22.666 20H4.652Z"
                    />
                    <path
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M4.652 20H1.333l3.319-6.288a1.35 1.35 0 0 1 .422-.411c.164-.099.343-.154.526-.161.183.007.362.062.526.161.163.099.307.239.422.41l.616 1.487M8.876 11.91l2.221 2.266a.851.851 0 0 0 .652.269.881.881 0 0 0 .631-.334l.609-.734a.875.875 0 0 1 .67-.335c.252 0 .493.12.67.335l.61.734a.881.881 0 0 0 .63.334.851.851 0 0 0 .652-.27l2.226-2.265"
                    />
                </>
            ),
        }}
    />
);
