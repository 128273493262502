/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Synchronize } from '../models/Synchronize';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SynchronizeService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Replaces the Synchronize resource.
     * Request synchronization of a hotel
     * @param hotelId Synchronize identifier
     * @param requestBody The updated Synchronize resource
     * @returns Synchronize Successful operation
     * @throws ApiError
     */
    public synchronize(
        hotelId: string,
        requestBody: Synchronize,
    ): CancelablePromise<Synchronize> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/elasticsearch/synchronize/{hotelId}',
            path: {
                'hotelId': hotelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request - Missing or invalid parameters`,
                401: `Unauthorized - Invalid or missing authentication`,
                500: `Internal Server Error - Unexpected server error occurred`,
            },
        });
    }
}
