import { RatingDescription } from '@app/models/api/api-types';

export const getRatingDescValue = (type: RatingDescription) => {
    const ratingDescMap = {
        [RatingDescription.Average]: 'Przeciętny',
        [RatingDescription.Decent]: 'Przyzwoity',
        [RatingDescription.Good]: 'Dobry',
        [RatingDescription.VeryGood]: 'Bardzo dobry',
        [RatingDescription.Sensational]: 'Rewelacyjny',
    };

    return ratingDescMap[type];
};
