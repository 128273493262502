import axios from 'axios';
import { urlJoin } from 'url-join-ts';

import { withOTLPSpan } from '@app/instrumentation/instrumentation.helpers';

type QueryStringObject = Record<string, any>;

export class NopApiServiceBase {
    protected get ApiHost(): string {
        return process.env.NOP_API_URL || '';
    }

    protected get ApiUsername(): string {
        return process.env.NOP_API_USERNAME || '';
    }

    protected get ApiPassword(): string {
        return process.env.NOP_API_PASSWORD || '';
    }

    protected async fetchNop<TModel>(url: string, queryParams?: QueryStringObject) {
        const requestUrl = urlJoin(this.ApiHost, url);
        const response = await withOTLPSpan('fetch Nop Api', async span => {
            span.setAttribute('Nop Api URL', url);

            if (queryParams) {
                span.setAttribute('Nop Api Query', JSON.stringify(queryParams));
            }

            return axios
                .post<TModel>(requestUrl, queryParams, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${Buffer.from(
                            `${this.ApiUsername}:${this.ApiPassword}`,
                        ).toString('base64')}`,
                        'X-API-Currency': process.env.NOP_API_CURRENCY,
                        'X-API-Locale': 'pl',
                        'X-API-Version': process.env.NOP_API_VERSION,
                    },
                })
                .finally(() => span.end());
        });

        return response.data;
    }
}
