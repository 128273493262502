import { PluraliseValueModel } from './common';

interface GuestsPickerTranslationModel {
    adultsLabel: string;
    roomsLabel: string;
    childLabel: string;
    childrenLabel: string;
    childrenAgesLabel: string;
    childrenAgesRequiredMessage: string;
    actionSubmit: string;
    actionCancel: string;
    mobileLabel: string;
    totalGuestLabel?: PluraliseValueModel;
    ageLabel?: PluraliseValueModel;
}

const defaultGuestsPickerTranslation: Required<GuestsPickerTranslationModel> = {
    roomsLabel: 'Pokoje',
    adultsLabel: 'Dorośli',
    childrenAgesLabel: 'Wiek dzieci',
    childLabel: 'Dziecko',
    childrenLabel: 'Dzieci',
    actionSubmit: 'Wybierz',
    actionCancel: 'Anuluj',
    mobileLabel: 'Liczba osób',
    childrenAgesRequiredMessage: 'Wprowadź wiek wszystkich dzieci',
    totalGuestLabel: {
        singular: 'osoba',
        plural: 'osoby',
        genitivePlural: 'osób',
    },
    ageLabel: {
        singular: 'rok',
        plural: 'lata',
        genitivePlural: 'lat',
    },
};

export { type GuestsPickerTranslationModel, defaultGuestsPickerTranslation };
