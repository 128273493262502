import { useState } from 'react';

interface UseDrawerStateReturn {
    isDrawerPending: boolean;
    openDrawer: () => void;
}

export const useDrawerState = (
    onDrawerOpen: () => void,
): UseDrawerStateReturn => {
    const [isDrawerPending, setIsDrawerPending] = useState<boolean>(false);

    const openDrawer = () => {
        setIsDrawerPending(true);

        if ('requestIdleCallback' in window) {
            requestIdleCallback(() => {
                onDrawerOpen();
                setIsDrawerPending(false);
            });
        } else {
            setTimeout(() => {
                onDrawerOpen();
                setIsDrawerPending(false);
            }, 0);
        }
    };

    return {
        isDrawerPending,
        openDrawer,
    };
};
