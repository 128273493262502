import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['button', 'footerContent']);

export const RangePickerDrawer = helpers.defineMultiStyleConfig({
    baseStyle: {
        button: {
            colorScheme: 'secondary',
        },
        footerContent: {
            textAlign: 'center',
        },
    },
});
