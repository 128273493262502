import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
    'container',
    'subHeader',
    'constactInfoBox',
    'userTypesWrapper',
    'userTypeBox',
    'emailLink',
    'hotlineAvailability',
    'checkOurOfferBox',
    'bgWrapper',
    'buttonColorScheme',
]);

export const Support = helpers.defineMultiStyleConfig({
    baseStyle: {
        bgWrapper: {
            bg: 'secondary.25',
        },
        container: {
            maxW: 'containerWidth',
            m: '0 auto',
            px: { base: 'gutterWidth', md: 'gutterWidthDesktop' },
            py: { base: 8, md: 16 },
            flexDirection: { base: 'column', lg: 'row' },
        },
        subHeader: {
            mb: 6,
            color: 'gray.900',
            fontSize: 'md',
            strong: {
                color: 'gray.900',
            },
        },
        contactInfoBox: {
            flexDirection: 'column',
            flex: '2',
            px: 0,
        },
        userTypesWrapper: {
            flexDirection: { base: 'column', lg: 'row' },
            gap: {
                base: 0,
                md: 2,
            },
        },
        userTypeBox: {
            alignItems: 'start',
            flexBasis: { base: '100%', lg: '50%' },
            mb: { base: 6, md: 0 },
        },
        emailLink: {
            fontSize: 'md',
            color: 'secondary.500',
        },
        hotlineAvailability: {
            ml: {
                base: 7,
                md: 0,
            },
            fontSize: 'xs',
            color: 'gray.600',
        },
        checkOurOfferBox: {
            bg: 'secondary.50',
            flex: '1',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
            minH: '280px',
            position: 'relative',
            p: 6,
            mt: 2,
            borderRadius: 'lg',
        },
        image: {
            position: 'absolute',
            width: '75%',
            height: '90%',
            right: '2rem',
        },
        buttonColorScheme: 'secondary',
    },
});
