import { useCallback, useState } from 'react';

interface UseSwipeGestureProps {
    threshold?: number;
    onSwipeUp?: () => void;
    onSwipeDown?: () => void;
}

export const DEFAULT_SWIPE_THRESHOLD = 40;

export const useSwipeGesture = ({
    threshold = DEFAULT_SWIPE_THRESHOLD,
    onSwipeUp,
    onSwipeDown,
}: UseSwipeGestureProps) => {
    const [touchStartPosition, setTouchStartPosition] = useState<number | null>(null);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        setTouchStartPosition(e.touches[0].clientY);
    }, []);

    const handleTouchEnd = useCallback(
        (e: React.TouchEvent) => {
            if (touchStartPosition === null) return;

            const touchEndPosition = e.changedTouches[0].clientY;
            const swipeDistance = touchEndPosition - touchStartPosition;

            if (Math.abs(swipeDistance) < threshold) return;

            const isDownDirection = swipeDistance > 0;

            if (isDownDirection && onSwipeDown) {
                onSwipeDown();
            } else if (!isDownDirection && onSwipeUp) {
                onSwipeUp();
            }

            setTouchStartPosition(null);
        },
        [touchStartPosition, threshold, onSwipeUp, onSwipeDown],
    );

    return {
        handleTouchStart,
        handleTouchEnd,
    };
};
