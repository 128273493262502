import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';

import { BadgeProps } from './badge.props';

export const Badge = ({
    icon,
    url,
    children,
    noWrap = true,
    rightElement,
    rounded = 'full',
    dangerouslySetInnerHTML,
    ...props
}: BadgeProps): JSX.Element => {
    const childrenProp = {
        ...(dangerouslySetInnerHTML && {
            dangerouslySetInnerHTML,
        }),
        ...(children && {
            children,
        }),
    };

    return (
        <Tag rounded={rounded} as={url ? 'a' : undefined} href={url} {...props}>
            {icon && <TagLeftIcon as={icon} />}
            <TagLabel noOfLines={noWrap ? 1 : 0} {...childrenProp} />
            {rightElement}
        </Tag>
    );
};
