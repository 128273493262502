import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useStateWithDependency<S>(
    initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>] {
    const [value, setValue] = useState(initialState);

    useEffect(() => setValue(initialState), [initialState]);

    return [value, setValue];
}
