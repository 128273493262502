import Head from 'next/head';

import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Link, Text } from '@chakra-ui/react';

import { isNil } from 'lodash';
import { urlJoin } from 'url-join-ts';

import { commonHelpers } from '@nocowanie/core';

import { BreadcrumbsProps } from './breadcrumbs.props';
import { useHorizontalScroll } from './useHorizontalScroll';

import { ListItem } from 'schema-dts';

export const BreadCrumbs = ({ breadcrumbs, name }: BreadcrumbsProps) => {
    const { ref: breadcrumbsRef, canScrollLeft, canScrollRight } = useHorizontalScroll();

    if (!breadcrumbs?.length || isNil(name)) return null;

    const breadcrumbsShadowCommonStyles = {
        position: 'absolute' as const,
        top: 0,
        width: 8,
        height: '100%',
    };

    const itemListElements: ListItem[] = breadcrumbs?.map(crumb => {
        return {
            '@type': 'ListItem',
            position: crumb.position,
            item: urlJoin('https://www.nocowanie.pl', crumb.item, crumb.position !== 1 && '/'),
            name: crumb.name,
        };
    });

    itemListElements?.push({
        '@type': 'ListItem',
        position: itemListElements.length + 1,
        name: name ?? '',
    });

    const schemaObj = {
        '@context': 'https://schema.org/',
        '@type': 'BreadcrumbList',
        itemListElement: itemListElements,
    };

    return (
        <>
            <Head>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaObj) }}
                />
            </Head>
            <Box position="relative" mt={4}>
                {canScrollLeft && (
                    <Box
                        {...breadcrumbsShadowCommonStyles}
                        left={0}
                        backgroundImage={
                            'linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255))'
                        }
                    />
                )}
                <Flex
                    ref={breadcrumbsRef}
                    align="center"
                    gap={1}
                    sx={{
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': { display: 'none' },
                    }}
                >
                    {commonHelpers.intersperse(
                        breadcrumbs.map(crumb => (
                            <Link
                                display={'flex'}
                                key={crumb.position}
                                color="gray.500"
                                fontSize="sm"
                                href={crumb.item}
                            >
                                {crumb.name}
                            </Link>
                        )),
                        n => (
                            <ChevronRightIcon key={`chevron-${n}`} />
                        ),
                    )}
                    <ChevronRightIcon />
                    <Text as={'strong'} color="gray.500" fontSize="sm" fontWeight={'normal'}>
                        {name}
                    </Text>
                </Flex>
                {canScrollRight && (
                    <Box
                        {...breadcrumbsShadowCommonStyles}
                        right={0}
                        backgroundImage={
                            'linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255))'
                        }
                    />
                )}
            </Box>
        </>
    );
};
