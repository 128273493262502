import isNil from 'lodash/isNil';

import { FeatureToggleEnum } from '../enums';

const stringToBooleanConverter = (value: string): boolean => {
    let result = false;

    if (['1', 'true'].includes(value.trim())) {
        result = Boolean(value);
    }

    return result;
};

const stringToNumberConverter = (value: string): number | undefined => {
    let result = undefined;

    if (!isNil(value) && value.length > 0 && !isNaN(+value)) {
        result = Number(value);
    }

    return result;
};

export const FeatureToggleValueParserMap: Record<FeatureToggleEnum, (value: string) => unknown> = {
    [FeatureToggleEnum.USE_AUTOCOMPLETE_ES_VERSION]: stringToNumberConverter,
    [FeatureToggleEnum.USE_HOME_PAGE_ES_VERSION]: stringToNumberConverter,
    [FeatureToggleEnum.USE_NEW_CALENDAR]: stringToBooleanConverter,
    [FeatureToggleEnum.USE_V7_THEME]: stringToBooleanConverter,
};
