/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheapestRate_jsonld } from './CheapestRate_jsonld';
import type { Image_jsonld } from './Image_jsonld';
import type { InternalAmenityGroup_jsonld } from './InternalAmenityGroup_jsonld';
import type { InternalLabel_jsonld } from './InternalLabel_jsonld';
import type { Listing_jsonld } from './Listing_jsonld';
export type Accommodation_jsonld = {
    readonly '@id'?: string;
    readonly '@type'?: string;
    id?: string;
    name?: string;
    path?: string;
    settlementName?: string;
    longitude?: number | null;
    latitude?: number | null;
    ratingsAverage?: number | null;
    ratingsCount?: number;
    streetNumber?: string | null;
    address?: string;
    internalLabel?: (InternalLabel_jsonld | null);
    internalAmenityGroups?: Array<InternalAmenityGroup_jsonld>;
    images?: Array<Image_jsonld>;
    type?: string;
    currency?: string;
    listing?: (Listing_jsonld | null);
    aboOta?: boolean;
    source?: string;
    cheapestRate?: (CheapestRate_jsonld | null);
    active?: boolean;
    guaranteedPosition?: boolean;
    foreignPartnerId?: number | null;
    distance?: number | null;
    ratingDescription?: Accommodation_jsonld.ratingDescription;
    placement?: Accommodation_jsonld.placement;
    caption?: string | null;
    distanceFromCenter?: number | null;
};
export namespace Accommodation_jsonld {
    export enum ratingDescription {
        AVERAGE = 'average',
        DECENT = 'decent',
        GOOD = 'good',
        VERY_GOOD = 'veryGood',
        SENSATIONAL = 'sensational',
    }
    export enum placement {
        GUARANTEE = 'Guarantee',
        SORT = 'Sort',
        PLACEHOLDER = 'Placeholder',
        NEARBY = 'Nearby',
    }
}

