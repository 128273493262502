export const wordPluralisation = (
    quantity: number,
    singular: string,
    plural: string,
    genitivePlural: string,
): string => {
    const value = Math.abs(quantity);

    if (value === 1) {
        return singular;
    }

    const restOf10 = value % 10;
    const restOf100 = value % 100;

    if (restOf10 > 4 || restOf10 < 2 || (restOf100 < 15 && restOf100 > 11)) {
        return genitivePlural;
    }

    return plural;
};
