import { Badge, Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { SeoLinksProps } from './seo-links.props';

export const SeoLinks = ({ title, linkSections, classNames, ...props }: SeoLinksProps) => {
    const styles = useMultiStyleConfig('SeoLinks', {});
    const badgeColorScheme = styles.tagBadge?.colorScheme as string;

    if (!linkSections?.length) {
        return;
    }
    return (
        <Box {...props}>
            {title ? (
                <Heading as={'h2'} size="lg" sx={styles.heading}>
                    {title}
                </Heading>
            ) : null}
            {linkSections.map((section, index) => {
                return (
                    <Box
                        key={index}
                        sx={styles.seoLinksSection}
                        data-testid={`seo-links-section-${index}`}
                        className={classNames?.section}
                    >
                        <Text
                            sx={styles.seoLinksSectionTitle}
                            data-testid={`seo-links-section-title-${index}`}
                            className={classNames?.sectionTitle}
                        >
                            {section.title}
                        </Text>
                        <Flex sx={styles.tagsWrapper} className={classNames?.list}>
                            {section.tags?.map((tag, tagIndex) => {
                                return (
                                    <Badge
                                        as="a"
                                        key={tagIndex}
                                        href={tag.url}
                                        sx={styles.tagBadge}
                                        colorScheme={badgeColorScheme}
                                        size={'md'}
                                        data-testid={`seo-link-${index}-${tagIndex}`}
                                        className={classNames?.link}
                                    >
                                        {tag.name}
                                    </Badge>
                                );
                            })}
                        </Flex>
                    </Box>
                );
            })}
        </Box>
    );
};
