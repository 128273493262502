export enum DataLayerEventName {
    Checkout = 'begin_checkout',
    Purchase = 'purchase',
    Offer = 'offer',
    PhoneRevealed = 'phone-revealed',
    PageView = 'pageview',
    SearchResults = 'search-results',
    SearchEngine = 'searchEngine',
    Em = 'em',
}

export enum DataLayerPageTypeEnum {
    Home = 'home',
    Search = 'search',
    Offer = 'offer',
    Checkout = 'checkout',
    Register = 'register',
    Other = 'other',
}

export enum DataLayerBookingTypeEnum {
    Ota = 'ota',
    OtaAbo = 'ota-abo',
}

export enum DataLayerOfferTypeEnum {
    Booking = 'booking',
    OtaAbo = 'ota-abo',
    Advertising = 'advertising',
}

export enum DataLayerOperationTypeEnum {
    ConfirmationNeeded = 'confirmation-needed',
    AlreadyConfirmed = 'already-confirmed',
}

export enum DataLayerOriginEnum {
    Home = 'home',
    SearchRefine = 'search-refine',
    SearchResults = 'search-results',
    SearchRedirect = 'search-redirect',
    Other = 'other',
    Entrance = 'entrance',
    OfferImportantInfo = 'offer-important-info',
}

export enum DataLayerUserTypeEnum {
    Anonymous = 'anonymous',
    LoggedInB2BActive = 'logged-in-b2b-active',
    LoggedInB2BInactive = 'logged-in-b2b-inactive',
    LoggedInB2C = 'logged-in-b2c',
    LoggedInInternal = 'logged-in-internal',
}

export enum DataLayerReservationTypeEnum {
    RealTime = 'real-time',
    OnRequest = 'on-request',
}

export type DataLayerEcommerceItem = {
    item_name?: string;
    item_id?: string;
    price?: number;
    priceShare?: number;
    booking_type?: DataLayerBookingTypeEnum;
    item_variant?: string;
    item_brand?: 'nocowanie.pl';
    item_category?: string;
    destinationCountry?: string;
    destinationCity?: string;
    destinationCityID?: string;
    provisionType?: string;
    operationType?: DataLayerOperationTypeEnum;
    offerProviderName?: string;
    offerProviderID?: string;
    offerVerificationStatus?: string;
    offerFacilities?: string;
    offerRank?: string;
    offerReviews?: number;
    offerPhoto?: number;
    offerMovies?: number;
    quantity?: number;
};

export type DataLayerEcommerceType = {
    transaction_id?: number;
    value?: number;
    tax?: number;
    shipping?: number;
    coupon?: string;
    currency?: string;
    items?: DataLayerEcommerceItem[];
};

export type DataLayerStorageDataType = {
    keyword: string;
    origin: DataLayerOriginEnum.Home | DataLayerOriginEnum.SearchRefine;
};

export interface DataLayerEcommerceEvent extends DataLayerEvent {
    checkIn?: string;
    checkOut?: string;
    numberOfNights?: number;
    numberOfRooms?: number;
    numberOfChildren?: number;
    numberOfAdults?: number;
    currency?: string;
    ecommerce?: DataLayerEcommerceType;
}

export interface DataLayerAccommodationEvent extends DataLayerEvent {
    origin?: DataLayerOriginEnum;
    offerType?: DataLayerOfferTypeEnum;
    offerName?: string;
    offerId?: string;
    offerProviderName?: string;
    offerProviderId?: string;
    offerLocation?: string;
    offerLocationID?: string;
    offerDestType?: string;
    offerVerificationStatus?: string;
    offerFacilities?: string;
    offerRank?: string;
    offerReviews?: number;
    offerPhotos?: number;
    offerMovies?: number;
    offerReservationModel?: DataLayerReservationTypeEnum;
}

export interface DataLayerSearchResultsEvent extends DataLayerEvent {
    pageDomain?: string;
    origin?: DataLayerOriginEnum;
    searchOutputType?: string;
    searchOutputCount?: number;
    searchKeyword?: string;
    searchLocation?: string;
    searchLocationID?: string;
    searchHowLongBefore?: string;
    searchDateFrom?: string;
    searchDateTo?: string;
    searchNights?: number;
    searchAdults?: number;
    searchKids?: number;
    searchDestType?: string;
    searchFacilities?: string;
    searchRank?: string;
}

export interface DataLayerPageViewEvent extends DataLayerEvent {
    pageDomain?: string;
    pagePath?: string;
    pageType?: string;
    userLoggedIn?: 'yes' | 'no';
    userType?: DataLayerUserTypeEnum;
    userId?: string;
}

export interface DataLayerEmEvent extends DataLayerEvent {
    em_id?: string;
}
export interface DataLayersSarchEngineEvent extends DataLayerEvent {
    query: string;
    arrival?: string;
    departure?: string;
    adults: number;
    children: number;
    child_age: {
        child: {
            age: number;
        };
    }[];
    room_nights?: number;
}

export interface DataLayerEvent {
    event: DataLayerEventName;
}
