import { IconProps, NocIconGenerator } from '../common';

export const DoubleBed = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'DoubleBed',
            path: (
                <>
                    <path
                        d="M12.11 15.4C11.83 15.4 11.61 15.16 11.61 14.87V12.88C11.61 12.12 11.06 11.42 10.45 11.42H4.69999C4.08999 11.42 3.53999 12.11 3.53999 12.88V14.87C3.53999 15.16 3.31999 15.4 3.03999 15.4C2.75999 15.4 2.53999 15.16 2.53999 14.87V12.88C2.53999 11.52 3.52999 10.37 4.69999 10.37H10.45C11.62 10.37 12.61 11.52 12.61 12.88V14.87C12.61 15.16 12.39 15.4 12.11 15.4Z"
                        fill="black"
                    />
                    <path
                        d="M20.96 15.4C20.68 15.4 20.46 15.16 20.46 14.87V12.88C20.46 12.12 19.91 11.42 19.3 11.42H13.77C13.16 11.42 12.61 12.11 12.61 12.88V14.87C12.61 15.16 12.39 15.4 12.11 15.4C11.83 15.4 11.61 15.16 11.61 14.87V12.88C11.61 11.52 12.6 10.37 13.77 10.37H19.3C20.47 10.37 21.46 11.52 21.46 12.88V14.87C21.46 15.16 21.24 15.4 20.96 15.4Z"
                        fill="black"
                    />
                    <path
                        d="M23.5 22.01H0.5C0.22 22.01 0 21.77 0 21.48V17.51C0 15.76 1.22 14.33 2.71 14.33H21.29C22.79 14.33 24 15.75 24 17.51V21.48C24 21.77 23.78 22.01 23.5 22.01ZM1 20.95H23V17.51C23 16.34 22.23 15.39 21.29 15.39H2.71C1.77 15.39 1 16.34 1 17.51V20.95Z"
                        fill="black"
                    />
                    <path
                        d="M0.5 24C0.22 24 0 23.76 0 23.47V20.82C0 20.53 0.22 20.29 0.5 20.29C0.78 20.29 1 20.53 1 20.82V23.47C1 23.76 0.78 24 0.5 24Z"
                        fill="black"
                    />
                    <path
                        d="M23.5 24C23.22 24 23 23.76 23 23.47V20.82C23 20.53 23.22 20.29 23.5 20.29C23.78 20.29 24 20.53 24 20.82V23.47C24 23.76 23.78 24 23.5 24Z"
                        fill="black"
                    />
                    <path
                        d="M20.96 15.4C20.68 15.4 20.46 15.16 20.46 14.87V8.51999C20.46 7.75999 19.91 7.05999 19.3 7.05999H4.69999C4.08999 7.05999 3.53999 7.74999 3.53999 8.51999V14.87C3.53999 15.16 3.31999 15.4 3.03999 15.4C2.75999 15.4 2.53999 15.16 2.53999 14.87V8.51999C2.53999 7.15999 3.52999 6.00999 4.69999 6.00999H19.3C20.47 6.00999 21.46 7.15999 21.46 8.51999V14.87C21.46 15.16 21.24 15.4 20.96 15.4Z"
                        fill="black"
                    />
                </>
            ),
        }}
    />
);
