export const Popover = {
    baseStyle: {
        content: {
            border: 'none',
            boxShadow: 'lg',
        },
        arrow: {
            display: 'none',
        },
    },
};
