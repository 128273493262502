import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
    'container',
    'subHeader',
    'constactInfoBox',
    'userTypesWrapper',
    'userTypeBox',
    'emailLink',
    'hotlineAvailability',
    'checkOurOfferBox',
    'bgWrapper',
    'buttonColorScheme',
]);

export const Support = helpers.defineMultiStyleConfig({
    baseStyle: {
        bgWrapper: {
            bg: 'pistachioGreen.50',
        },
        container: {
            maxW: 'containerWidth',
            m: '0 auto',
            px: { base: 'gutterWidth', md: 'gutterWidthDesktop' },
            py: { base: 8, md: 16 },
            flexDirection: { base: 'column', lg: 'row' },
        },
        subHeader: {
            mb: 8,
            color: 'gray.400',
            fontSize: 'md',
            strong: {
                color: 'secondary.500',
            },
        },
        contactInfoBox: {
            flexDirection: 'column',
            flex: '2',
            px: 0,
        },
        userTypesWrapper: {
            flexDirection: { base: 'column', lg: 'row' },
            gap: 2,
        },
        userTypeBox: {
            alignItems: 'start',
            flexBasis: { base: '100%', lg: '50%' },
            mb: { base: 10, md: 0 },
        },
        emailLink: {
            fontSize: 'md',
            color: 'brand.500',
        },
        hotlineAvailability: {
            ml: 7,
            fontSize: 'xs',
            color: 'gray.400',
        },
        checkOurOfferBox: {
            bg: 'pistachioGreen.200',
            flex: '1',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
            minH: '280px',
            position: 'relative',
            p: 6,
            borderRadius: 'lg',
        },
        image: {
            position: 'absolute',
            width: '75%',
            height: '90%',
            right: '2rem',
        },
        buttonColorScheme: 'brand',
    },
});
