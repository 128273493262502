import { useCallback, useEffect, useRef } from 'react';

export const useDebouncedCallback = <T extends unknown[]>(
    callback: (...args: T) => void,
    delay: number,
) => {
    const handlerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const debouncedCallback = useCallback(
        (...args: T) => {
            if (handlerRef.current) {
                clearTimeout(handlerRef.current);
            }

            handlerRef.current = setTimeout(() => {
                callback(...args);
            }, delay);
        },
        [callback, delay],
    );

    useEffect(() => {
        return () => {
            if (handlerRef.current) {
                clearTimeout(handlerRef.current);
            }
        };
    }, []);

    return debouncedCallback;
};
