
import { useCallback } from 'react';

import { wordPluralisation } from '../helpers';

export function usePluralisationTranslation(namespace: string, keyPrefix?: string) {
    const useTranslation = typeof window !== 'undefined' ? require('react-i18next').useTranslation : require('next-i18next').useTranslation;

    const { t } = useTranslation(namespace, {
        keyPrefix,
    });

    return useCallback(
        (value: number, translationKey: string, displayedValue?: number | string) => {
            const translationValue: any = t(translationKey, {
                returnObjects: true,
            });

            return `${displayedValue ?? value} ${wordPluralisation(
                value,
                translationValue.singular,
                translationValue.plural,
                translationValue.genitivePlural,
            )}`;
        },
        [t],
    );
}
