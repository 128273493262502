export const FONT_SIZES = {
    header: {
        base: 22,
        md: 32,
    },
    sectionHeader: {
        base: '1.375rem',
    },
    checkout: {
        header: {
            base: 'xl',
            lg: '2xl',
        },
        subheader: {
            base: 'sm',
            lg: 'md',
        },
    },
};
