import { CustomImageSize, ImageRatio, ImageSize } from '../enums';

// TODO :: choosing the right sizes for the images

export const IMAGE_SIZES = {
    [ImageRatio['16/9']]: {
        [ImageSize.Xs]: {
            width: 160,
            height: 90,
        },
        [ImageSize.Sm]: {
            width: 480,
            height: 270,
        },
        [ImageSize.Md]: {
            width: 800,
            height: 450,
        },
        [ImageSize.Lg]: {
            width: 992,
            height: 558,
        },
        [ImageSize.Xl]: {
            width: 1200,
            height: 675,
        },
    },
    [ImageRatio['4/3']]: {
        [ImageSize.Xs]: {
            width: 200,
            height: 150,
        },
        [ImageSize.Sm]: {
            width: 480,
            height: 360,
        },
        [ImageSize.Md]: {
            width: 800,
            height: 600,
        },
        [ImageSize.Lg]: {
            width: 992,
            height: 744,
        },
        [ImageSize.Xl]: {
            width: 1200,
            height: 900,
        },
    },
    [ImageRatio['3/2']]: {
        [ImageSize.Xs]: {
            width: 180,
            height: 120,
        },
        [ImageSize.Sm]: {
            width: 480,
            height: 320,
        },
        [ImageSize.Md]: {
            width: 768,
            height: 512,
        },
        [ImageSize.Lg]: {
            width: 996,
            height: 664,
        },
        [ImageSize.Xl]: {
            width: 1200,
            height: 800,
        },
    },
    [ImageRatio['1/1']]: {
        [ImageSize.Xs]: {
            width: 140,
            height: 140,
        },
        [ImageSize.Sm]: {
            width: 320,
            height: 320,
        },
        [ImageSize.Md]: {
            width: 512,
            height: 512,
        },
        [ImageSize.Lg]: {
            width: 664,
            height: 664,
        },
        [ImageSize.Xl]: {
            width: 800,
            height: 800,
        },
    },
};

export const CUSTOM_IMAGE_SIZES = {
    [CustomImageSize.AccommodationMain]: {
        width: 528,
        height: 297,
    },
};
