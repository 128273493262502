import { IconProps, NocIconGenerator } from '../common';

export const Sauna = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Sauna',
            path: (
                <>
                    <g
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M5 7a2 2 0 0 0 2 2c1.11 0 2-.89 2-2a2 2 0 1 0-4 0ZM17.34 20l-2.26-4.18a2.652 2.652 0 0 0-2.33-1.38h-2.59L8.6 10.81a1.325 1.325 0 0 0-2.25-.3l-3.52 4.43c-.49.55-.44 1.39.1 1.88s1.38.44 1.87-.1c.03-.04.06-.07.09-.11l2.15-2.66 1.04 2.4c.26.57.87.9 1.49.8.49 0 .88.4.88.89v2.66c0 .73.59 1.33 1.32 1.33.73 0 1.32-.59 1.32-1.33v-2.95l1.93 3.54c.35.62 1.13.86 1.76.53.64-.33.9-1.13.57-1.78 0 0 0-.01-.01-.02V20ZM2 19h6M13.36 2c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M13.36 7.84c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47M20.64 2c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M20.64 7.84c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47M16.96 2.16c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M16.96 8c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
