import { forwardRef, useMemo } from 'react';

import { Box } from '@chakra-ui/layout';

import isNil from 'lodash/isNil';

import { wordPluralisation } from './../../../helpers';
import { AutocompleteConfig, defaultAutocompleteConfig } from './autocomplete.config';
import { AutocompleteBaseItem } from './autocomplete-base-item';
import { AutocompleteItemProps } from './autocomplete-item.props';

export const AutocompleteLocationItem = forwardRef<HTMLLIElement, any>(
    ({ itemCount, type, translationData, ...rest }: AutocompleteItemProps, ref): JSX.Element => {
        const translations: AutocompleteConfig['translationData'] = {
            ...defaultAutocompleteConfig.translationData,
            ...translationData,
        };

        const countLabel = useMemo(() => {
            return !isNil(itemCount)
                ? wordPluralisation(
                      itemCount,
                      translations.accommodationCount.singular,
                      translations.accommodationCount.plural,
                      translations.accommodationCount.genitivePlural,
                  )
                : null;
        }, [itemCount]);

        return (
            <AutocompleteBaseItem ref={ref} {...rest}>
                {!isNil(itemCount) ? <Box>{`${itemCount} ${countLabel}`}</Box> : null}
            </AutocompleteBaseItem>
        );
    },
);

AutocompleteLocationItem.displayName = 'AutocompleteLocationItem';
