/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AccommodationService } from './services/AccommodationService';
import { AccommodationStatsService } from './services/AccommodationStatsService';
import { BreadcrumbsService } from './services/BreadcrumbsService';
import { CategoryService } from './services/CategoryService';
import { ConnectionCheckerService } from './services/ConnectionCheckerService';
import { HandleService } from './services/HandleService';
import { LinkspaceService } from './services/LinkspaceService';
import { ListingSettingService } from './services/ListingSettingService';
import { SynchronizeService } from './services/SynchronizeService';
import { TestService } from './services/TestService';
import { UserService } from './services/UserService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class NocApiClient {
    public readonly accommodation: AccommodationService;
    public readonly accommodationStats: AccommodationStatsService;
    public readonly breadcrumbs: BreadcrumbsService;
    public readonly category: CategoryService;
    public readonly connectionChecker: ConnectionCheckerService;
    public readonly handle: HandleService;
    public readonly linkspace: LinkspaceService;
    public readonly listingSetting: ListingSettingService;
    public readonly synchronize: SynchronizeService;
    public readonly test: TestService;
    public readonly user: UserService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.accommodation = new AccommodationService(this.request);
        this.accommodationStats = new AccommodationStatsService(this.request);
        this.breadcrumbs = new BreadcrumbsService(this.request);
        this.category = new CategoryService(this.request);
        this.connectionChecker = new ConnectionCheckerService(this.request);
        this.handle = new HandleService(this.request);
        this.linkspace = new LinkspaceService(this.request);
        this.listingSetting = new ListingSettingService(this.request);
        this.synchronize = new SynchronizeService(this.request);
        this.test = new TestService(this.request);
        this.user = new UserService(this.request);
    }
}

