import { IncomingHttpHeaders } from 'http';
import { isNil } from 'lodash';

import { TRACE_HEADER_NAME } from '@app/consts';

type HEADERS = Record<string, any>;

export const createRequestWithTraceIdHeaders = (
    incomingHeaders: IncomingHttpHeaders,
    extraHeaders?: HEADERS,
): HEADERS => {
    const resultHeaders: HEADERS = {
        ...(extraHeaders ?? {}),
    };

    const traceId = incomingHeaders[TRACE_HEADER_NAME];

    if (!isNil(traceId)) {
        resultHeaders[TRACE_HEADER_NAME] = `${traceId}`;
    }

    return resultHeaders;
};
