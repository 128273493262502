import { Button } from '@chakra-ui/button';
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/layout';

import { IconsLinear } from '@nocowanie/icons';

import { Badge } from './../../../components';

const Section = ({ header, children }: { header: string; children?: React.ReactNode }) => (
    <Box mx={5} my={8}>
        <Text mb={4}>{header}</Text>
        {children}
    </Box>
);

const ButtonPreview = ({ colorScheme }: { colorScheme: string }) => {
    return (
        <Box mb={8}>
            <Grid gap={5} templateColumns={'repeat(auto-fill, minmax(160px, auto))'} mb={5}>
                <Button colorScheme={colorScheme}>{colorScheme}</Button>
                <Button colorScheme={colorScheme} isActive>
                    {colorScheme} active
                </Button>
                <Button colorScheme={colorScheme} isDisabled>
                    {colorScheme} disabled
                </Button>
                <Button colorScheme={colorScheme} isLoading>
                    {colorScheme} loading
                </Button>
            </Grid>
            <Grid gap={5} templateColumns={'repeat(auto-fill, minmax(160px, auto))'} mb={5}>
                <Button colorScheme={colorScheme} variant={'solid'}>
                    {colorScheme} solid
                </Button>
                <Button colorScheme={colorScheme} variant={'solid'} isActive>
                    {colorScheme} solid active
                </Button>
                <Button colorScheme={colorScheme} variant={'solid'} isDisabled>
                    {colorScheme} solid disabled
                </Button>
                <Button colorScheme={colorScheme} variant={'solid'} isLoading>
                    {colorScheme} solid loading
                </Button>
            </Grid>
            <Grid gap={5} templateColumns={'repeat(auto-fill, minmax(160px, auto))'} mb={5}>
                <Button colorScheme={colorScheme} variant={'link'}>
                    {colorScheme} link
                </Button>
                <Button colorScheme={colorScheme} variant={'link'} isActive>
                    {colorScheme} link active
                </Button>
                <Button colorScheme={colorScheme} variant={'link'} isDisabled>
                    {colorScheme} link disabled
                </Button>
                <Button colorScheme={colorScheme} variant={'link'} isLoading>
                    {colorScheme} link loading
                </Button>
            </Grid>
        </Box>
    );
};

const BadgePreview = ({ colorScheme }: { colorScheme: string }) => {
    return (
        <Box mb={8}>
            <Grid gap={5} templateColumns={'repeat(auto-fill, minmax(160px, auto))'} mb={5}>
                <Badge colorScheme={colorScheme}>{colorScheme}</Badge>
                <Badge colorScheme={colorScheme} variant={'solid'}>
                    {colorScheme} solid
                </Badge>
                <Badge colorScheme={colorScheme} variant={'subtle'}>
                    {colorScheme} subtle
                </Badge>
            </Grid>
            <Grid gap={5} templateColumns={'repeat(auto-fill, minmax(160px, auto))'} mb={5}>
                <Badge size={'sm'} colorScheme={'pink'}>
                    bagde sm
                </Badge>
            </Grid>
        </Box>
    );
};

export const ThemeSandbox = () => {
    return (
        <>
            <Section header={'Buttons'}>
                <ButtonPreview colorScheme={'brand'} />
                <ButtonPreview colorScheme={'secondary'} />
                <ButtonPreview colorScheme={'tertiary'} />
                <ButtonPreview colorScheme={'quaternary'} />
                <Flex gap={5} mb={5}>
                    <Button colorScheme={'brand'} size={'lg'}>
                        lg button
                    </Button>
                    <Button colorScheme={'brand'} size={'md'}>
                        md button
                    </Button>
                    <Button colorScheme={'brand'} size={'sm'}>
                        sm button
                    </Button>
                    <Button colorScheme={'brand'} size={'xs'}>
                        xs button
                    </Button>
                </Flex>
                <Flex gap={5} mb={5}>
                    <Button colorScheme={'brand'} size={'lg'} leftIcon={<IconsLinear.Location />}>
                        lg button
                    </Button>
                    <Button colorScheme={'brand'} size={'md'} leftIcon={<IconsLinear.Location />}>
                        md button
                    </Button>
                    <Button colorScheme={'brand'} size={'sm'} leftIcon={<IconsLinear.Location />}>
                        sm button
                    </Button>
                    <Button colorScheme={'brand'} size={'xs'} leftIcon={<IconsLinear.Location />}>
                        xs button
                    </Button>
                </Flex>
            </Section>

            <Section header={'Badges'}>
                <BadgePreview colorScheme={'brand'} />
                <BadgePreview colorScheme={'secondary'} />
                <BadgePreview colorScheme={'tertiary'} />
                <BadgePreview colorScheme={'quaternary'} />
            </Section>

            <Section header={'Headers'}>
                <Flex gap={3} direction={'column'}>
                    <Heading size={'4xl'}>Heading 4xl</Heading>
                    <Heading size={'3xl'}>Heading 3xl</Heading>
                    <Heading size={'2xl'}>Heading 2xl</Heading>
                    <Heading size={'xl'}>Heading xl</Heading>
                    <Heading size={'lg'}>Heading lg</Heading>
                    <Heading size={'md'}>Heading md</Heading>
                    <Heading size={'sm'}>Heading sm</Heading>
                    <Heading size={'xs'}>Heading xs</Heading>
                </Flex>
            </Section>

            <Section header={'Block of text'}>
                <Flex gap={3} direction={'column'}>
                    <Text>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo a deleniti,
                        minus, quasi ratione amet, impedit quae cum distinctio totam nihil quo quos
                        voluptatibus? Numquam nihil esse illo dicta expedita!Lorem ipsum dolor, sit
                        amet consectetur adipisicing elit. Illo a deleniti, minus, quasi ratione
                        amet, impedit quae cum distinctio totam nihil quo quos voluptatibus? Numquam
                        nihil esse illo dicta expedita!Lorem ipsum dolor, sit amet consectetur
                        adipisicing elit. Illo a deleniti, minus, quasi ratione amet, impedit quae
                        cum distinctio totam nihil quo quos voluptatibus? Numquam nihil esse illo
                        dicta expedita!Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                        Illo a deleniti, minus, quasi ratione amet, impedit quae cum distinctio
                        totam nihil quo quos voluptatibus? Numquam nihil esse illo dicta
                        expedita!Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo a
                        deleniti, minus, quasi ratione amet, impedit quae cum distinctio totam nihil
                        quo quos voluptatibus? Numquam nihil esse illo dicta expedita!
                    </Text>
                    <Text>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo a deleniti,
                        minus, quasi ratione amet, impedit quae cum distinctio totam nihil quo quos
                        voluptatibus? Numquam nihil esse illo dicta expedita!
                    </Text>
                    <Text>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo a deleniti,
                        minus, quasi ratione amet, impedit quae cum distinctio totam nihil quo quos
                        voluptatibus? Numquam nihil esse illo dicta expedita!
                    </Text>
                </Flex>
            </Section>
        </>
    );
};
