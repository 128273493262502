import { useEffect } from 'react';

import { LoadingState } from '@nocowanie/core';

import { commonUiStateSlice, useAppDispatch, useAppSelector, userProfileSlice } from '@app/state';

export const useUserProfile = () => {
    const dispatch = useAppDispatch();
    const isSession = useAppSelector(commonUiStateSlice.selectors.selectIsSession);
    const user = useAppSelector(userProfileSlice.selectors.selectUser);
    const loadingState = useAppSelector(userProfileSlice.selectors.selectLoading);
    const isRequestDone = user !== undefined;

    useEffect(() => {
        if (isRequestDone || isSession === undefined || loadingState !== LoadingState.IDLE) {
            return;
        }

        dispatch(
            userProfileSlice.actions.getUserProfile({
                skipRequest: isSession === false,
            }),
        );
    }, [isRequestDone, isSession, loadingState]);

    return {
        user,
    };
};
