import { IconProps, NocIconGenerator } from '../common';

export const Tick = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Tick',
            path: (
                <path
                    d="M7.75 12.6659L11.2459 16.1618L18.25 9.17"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            ),
        }}
    />
);
