export enum PageTypeEnum {
    City = 'city',
    CityCategory = 'cityCategory',
    CityPictogram = 'cityPictogram',
    CitySpecialOffer = 'citySpecialOffer',
    CityService = 'cityService',
    CityCenter = 'cityCenter',
    CityCenterCategory = 'cityCenterCategory',
    CityCheapAccommodation = 'cityCheapAccommodation',
    CityCategoryCheapAccommodation = 'cityCategoryCheapAccommodation',
    CityOpinions = 'cityOpinions',
    Region = 'region',
    RegionCategory = 'regionCategory',
    RegionPictogram = 'regionPictogram',
    RegionCheapAccommodation = 'regionCheapAccommodation',
    RegionSpecialOfferPage = 'regionSpecialOfferPage',
    RegionSpecialOffer = 'regionSpecialOffer',
    Voivodeship = 'voivodeship',
    VoivodeshipCategory = 'voivodeshipCategory',
    VoivodeshipCenterCategory = 'voivodeshipCenterCategory',
    VoivodeshipSpecialOffer = 'voivodeshipSpecialOffer',
    VoivodeshipSpecialOfferPage = 'voivodeshipSpecialOfferPage',
    VoivodeshipService = 'voivodeshipService',
    VoivodeshipOpinion = 'voivodeshipOpinion',
    VoivodeshipCenter = 'voivodeshipCenter',
    VoivodeshipCheapAccommodation = 'voivodeshipCheapAccommodation',
    VoivodeshipCategoryPage = 'voivodeshipCategoryPage',
    VoivodeshipPictogram = 'voivodeshipPictogram',
    Country = 'country',
    CountryPage = 'countryPage',
    CountryCategory = 'countryCategory',
    CountrySpecialOffer = 'countrySpecialOffer',
    CountryCheapAccommodation = 'countryCheapAccommodation',
    CountryCategoryCheapAccommodation = 'countryCategoryCheapAccommodation',
    CountryPictogram = 'countryPictogram',
    CountryService = 'countryService',
    CountrySpecialOfferPage = 'countrySpecialOfferPage',
}
