import { useTranslation } from 'next-i18next';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
    HStack,
    IconButton,
    Link,
    useBreakpointValue,
    VStack,
} from '@chakra-ui/react';

import { User } from '@nocowanie/noc-api';

import { urlHelpers } from '@nocowanie/core';
import { IconsLinear } from '@nocowanie/icons';

import { AccordionMenuItem } from '@app/components/molecules/accordion-menu-item';
import { getDashboardLink, logOutUser, modifyLink } from '@app/helpers/navigation.helpers';
import { NavGroupModel, NavLinkModel } from '@app/models';

import navLinks from './navigation-links.json';

import staticData from '../../../assets/data/static-data.json';

const MenuFooter = (): JSX.Element => {
    const { t } = useTranslation('common', {
        keyPrefix: 'appHeader',
    });

    return (
        <Box p={0}>
            <Box color={'gray.500'} textTransform={'uppercase'} py={4} fontWeight={'bold'}>
                {t('footerGroupHeader')}
            </Box>
            <Box as="a" href={`mailto:${staticData.navigation.customerServiceEmail}`}>
                <IconsLinear.Sms mr={2} fontSize={'sm'} />
                {staticData.navigation.customerServiceEmail}
            </Box>
            <HStack gap={4} mt={4}>
                <Box
                    as="a"
                    href={urlHelpers.getPhoneNoHref(staticData.navigation.hotline)}
                    fontWeight={'bold'}
                >
                    <IconsLinear.Call mr={2} fontSize={'sm'} />
                    {staticData.navigation.hotline}
                </Box>
                <Box as={'span'} fontSize={'xs'}>
                    {staticData.navigation.openingDays}, {staticData.navigation.openingHours}
                </Box>
            </HStack>
        </Box>
    );
};

const MenuGroup = ({ navGroup }: { navGroup: NavGroupModel }): JSX.Element => {
    const [expandedMenuIndex, setExpandedMenuIndex] = useState<string | null>(null);
    const handleToggleMenu = (index: string) => {
        setExpandedMenuIndex(prevIndex => (prevIndex === index ? null : index));
    };
    const handleLogout = useCallback((isLogoutLink: boolean) => {
        if (isLogoutLink) {
            logOutUser();
        }
    }, []);

    return (
        <>
            <Box color={'gray.500'} textTransform={'uppercase'} pt={4} pb={2} fontWeight={'bold'}>
                {navGroup.navGroupTitle}
            </Box>
            <VStack gap={0}>
                {navGroup.navLinks.map((navLink: NavLinkModel) => {
                    const isLogoutLink = navLink.navLinkId === '401';
                    return navLink.isExpandable ? (
                        <AccordionMenuItem
                            key={navLink.navLinkId}
                            index={navLink.navLinkId}
                            currentItemIndex={expandedMenuIndex}
                            navLink={navLink}
                            isOpen={expandedMenuIndex === navLink.navLinkId}
                            onMenuClick={() => handleToggleMenu(navLink.navLinkId)}
                        />
                    ) : (
                        <Link
                            key={navLink.navLinkId}
                            as={isLogoutLink ? Button : undefined}
                            href={!isLogoutLink ? modifyLink(navLink) : undefined}
                            variant={'menuLinkBase'}
                            mb={'3px'}
                            sx={{
                                display: 'block',
                                bg: 'white',
                                fontSize: 'md',
                                py: 2.5,
                                fontWeight: navLink.navLinkId === '000' ? 'bold' : 'unset',
                                textAlign: 'start',
                                borderRadius: 'none',
                                maxH: isLogoutLink ? '2.75rem' : undefined,
                            }}
                            onClick={() => handleLogout(isLogoutLink)}
                        >
                            {navLink.navLinkLabel}
                        </Link>
                    );
                })}
            </VStack>
        </>
    );
};

export const NavigationMobile = ({
    menuLabel,
    closeLabel,
    user,
    isGhostVariant = false,
}: {
    menuLabel: string;
    closeLabel: string;
    user: User | null | undefined;
    isGhostVariant?: boolean;
}) => {
    const { t } = useTranslation('common', {
        keyPrefix: 'appHeader',
    });

    const [navOpen, setNavOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const showFull = useBreakpointValue({
        base: true,
        md: false,
    });

    useEffect(() => {
        if (navOpen && buttonRef.current) {
            buttonRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [navOpen]);

    const profileLinks = {
        navGroupId: 'userProfile',
        navGroupTitle: t('userMenu.groupHeader'),
        navLinks: [
            {
                navLinkId: '400',
                navLinkLabel: t('userMenu.goToPanel'),
                isExpandable: false,
                hasContentGroups: false,
                url: user ? getDashboardLink(user) : '',
            },
            {
                navLinkId: '401',
                navLinkLabel: t('userMenu.logOut'),
                isExpandable: false,
                hasContentGroups: false,
                url: '',
            },
        ],
    };

    if (user?.type === User.type.HOTELIER) {
        const addAccommodationLink = {
            navLinkId: '402',
            navLinkLabel: t('cta.addAccommodation'),
            isExpandable: false,
            hasContentGroups: false,
            url: (navLinks as NavGroupModel[])[0].navLinks[1].url ?? '/',
        };
        profileLinks.navLinks.push(addAccommodationLink);
    }

    const userNavLinks = navLinks.filter(
        (navGroup: NavGroupModel) => navGroup.navGroupId !== 'profile',
    );
    userNavLinks.push(profileLinks);

    const renderUserNavLinks = useMemo(
        () =>
            userNavLinks.map((navGroup: NavGroupModel) => (
                <MenuGroup navGroup={navGroup} key={navGroup.navGroupId} />
            )),
        [userNavLinks],
    );

    return (
        <>
            <Button
                ref={buttonRef}
                as={IconButton}
                aria-label={navOpen ? closeLabel : menuLabel}
                icon={
                    navOpen ? (
                        <CloseIcon color={'white'} fontSize={'lg'} />
                    ) : (
                        <HamburgerIcon
                            color={isGhostVariant ? 'white' : 'secondary.500'}
                            fontSize={'2xl'}
                        />
                    )
                }
                border={'none'}
                px={navOpen ? '19px' : 4}
                ml={-4}
                minW={undefined}
                zIndex={navOpen ? 'popover' : 1}
                alignItems={'center'}
                onClick={() => setNavOpen(!navOpen)}
                _hover={{
                    bg: 'transparent',
                }}
                _active={{
                    bg: 'transparent',
                }}
            />
            <Drawer
                isOpen={navOpen}
                onClose={() => setNavOpen(false)}
                placement={'left'}
                size={showFull ? 'full' : 'sm'}
                variant={'navigation'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerBody as={'nav'} pb={28} px={2.5} minH={'100%'}>
                        {user
                            ? renderUserNavLinks
                            : navLinks.map((navGroup: NavGroupModel) => (
                                  <MenuGroup navGroup={navGroup} key={navGroup.navGroupId} />
                              ))}
                        <MenuFooter />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};
