export enum SliceNameEnum {
    Accommodation = 'mfeAccommodation',
    AccommodationRatings = 'mfeAccommodationRatings',
    AccommodationStatic = 'mfeAccommodationStatic',
    CommonUiState = 'mfeCommonUiState',
    CheckoutDetails = 'mfeCheckoutDetails',
    CheckoutReservation = 'mfeCheckoutReservation',
    CreatePayment = 'mfeCreatePayment',
    ReservationDetails = 'mfeReservationDetails',
    Autocomplete = 'autocomplete',
    Homepage = 'homepage',
}
