/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheapestRate } from './CheapestRate';
import type { Image } from './Image';
import type { InternalAmenityGroup } from './InternalAmenityGroup';
import type { InternalLabel } from './InternalLabel';
import type { Listing } from './Listing';
export type Accommodation = {
    id?: string;
    name?: string;
    path?: string;
    settlementName?: string;
    longitude?: number | null;
    latitude?: number | null;
    ratingsAverage?: number | null;
    ratingsCount?: number;
    streetNumber?: string | null;
    address?: string;
    internalLabel?: (InternalLabel | null);
    internalAmenityGroups?: Array<InternalAmenityGroup>;
    images?: Array<Image>;
    type?: string;
    currency?: string;
    listing?: (Listing | null);
    aboOta?: boolean;
    source?: string;
    cheapestRate?: (CheapestRate | null);
    active?: boolean;
    guaranteedPosition?: boolean;
    foreignPartnerId?: number | null;
    distance?: number | null;
    ratingDescription?: Accommodation.ratingDescription;
    placement?: Accommodation.placement;
    caption?: string | null;
    distanceFromCenter?: number | null;
};
export namespace Accommodation {
    export enum ratingDescription {
        AVERAGE = 'average',
        DECENT = 'decent',
        GOOD = 'good',
        VERY_GOOD = 'veryGood',
        SENSATIONAL = 'sensational',
    }
    export enum placement {
        GUARANTEE = 'Guarantee',
        SORT = 'Sort',
        PLACEHOLDER = 'Placeholder',
        NEARBY = 'Nearby',
    }
}

