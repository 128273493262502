import React from 'react';

import { Input as ChakraInput, InputLeftElement } from '@chakra-ui/input';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { IconsLinear } from '@nocowanie/icons';

import { SearchLocationProps } from './search-location.props';

import { defaultSearchLocationTranslation } from '../../../models';
import { Autocomplete } from '../autocomplete';
import { AutocompleteBaseItemModel } from '../autocomplete/autocomplete-base-item.model';

export const SearchLocation = <T extends AutocompleteBaseItemModel>({
    ref,
    autocompleteData,
    inputProps = {},
    translationData = defaultSearchLocationTranslation,
    ...rest
}: SearchLocationProps<T> & {
    ref?: React.ForwardedRef<typeof ChakraInput>;
}): React.ReactNode => {
    const themeStyles = useMultiStyleConfig('SearchLocation', {});
    const { ref: inputRef, inputProps: innerInputProps, ...props } = inputProps;

    return (
        <Autocomplete
            {...autocompleteData}
            inputProps={{
                leftIcon: (
                    <InputLeftElement
                        sx={themeStyles.icon}
                        children={<IconsLinear.Location fontSize={'xl'} />}
                    />
                ),
                ref: inputRef,
                ...props,
                inputProps: {
                    placeholder: translationData.locationPlaceholder,
                    ...innerInputProps,
                },
            }}
            {...rest}
        />
    );
};
