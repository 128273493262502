/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListingSetting_ListingSettingsInput_write } from '../models/ListingSetting_ListingSettingsInput_write';
import type { ListingSetting_read } from '../models/ListingSetting_read';
import type { ListingSetting_write } from '../models/ListingSetting_write';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ListingSettingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Retrieves the collection of ListingSetting resources.
     * Retrieves the collection of ListingSetting resources.
     * @param page The collection page number
     * @param limit The number of items per page
     * @param pagination Enable or disable pagination
     * @param sortAlgorithm
     * @param sortAlgorithmArray
     * @param orderId
     * @param orderCreatedAt
     * @param orderSortAlgorithm
     * @returns ListingSetting_read ListingSetting collection
     * @throws ApiError
     */
    public apiListingSettingsGetCollection(
        page: number = 1,
        limit: number = 10,
        pagination?: boolean,
        sortAlgorithm?: string,
        sortAlgorithmArray?: Array<string>,
        orderId?: string,
        orderCreatedAt?: string,
        orderSortAlgorithm?: string,
    ): CancelablePromise<Array<ListingSetting_read>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/listing_settings',
            query: {
                'page': page,
                'limit': limit,
                'pagination': pagination,
                'sortAlgorithm': sortAlgorithm,
                'sortAlgorithm[]': sortAlgorithmArray,
                'order[id]': orderId,
                'order[createdAt]': orderCreatedAt,
                'order[sortAlgorithm]': orderSortAlgorithm,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Creates a ListingSetting resource.
     * Creates a ListingSetting resource.
     * @param requestBody The new ListingSetting resource
     * @returns ListingSetting_read ListingSetting resource created
     * @throws ApiError
     */
    public apiListingSettingsPost(
        requestBody: ListingSetting_write,
    ): CancelablePromise<ListingSetting_read> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/listing_settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Retrieves a ListingSetting resource.
     * Retrieves a ListingSetting resource.
     * @param id ListingSetting identifier
     * @returns ListingSetting_read ListingSetting resource
     * @throws ApiError
     */
    public apiListingSettingsIdGet(
        id: string,
    ): CancelablePromise<ListingSetting_read> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/listing_settings/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Removes the ListingSetting resource.
     * Removes the ListingSetting resource.
     * @param id ListingSetting identifier
     * @returns void
     * @throws ApiError
     */
    public apiListingSettingsIdDelete(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/listing_settings/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Updates the ListingSetting resource.
     * Updates the ListingSetting resource.
     * @param id ListingSetting identifier
     * @param requestBody The updated ListingSetting resource
     * @returns ListingSetting_read ListingSetting resource updated
     * @throws ApiError
     */
    public apiListingSettingsIdPatch(
        id: string,
        requestBody: ListingSetting_ListingSettingsInput_write,
    ): CancelablePromise<ListingSetting_read> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/listing_settings/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
}
