/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Context_jsonld_write } from './Context_jsonld_write';
export type ListingSetting_jsonld_write = {
    sortAlgorithm?: ListingSetting_jsonld_write.sortAlgorithm;
    context?: Array<Context_jsonld_write>;
};
export namespace ListingSetting_jsonld_write {
    export enum sortAlgorithm {
        MIX = 'mix',
        NORMAL = 'normal',
    }
}

