import { isNil, merge, pickBy } from 'lodash';

import { SearchEngineModel } from '../../../models';
import { defaultGuestsPickerConfig, SearchFormDataType } from '../../';

export const defaultSearchFormData = (): SearchEngineModel => ({
    location: '',
    dates: {
        checkInDate: null,
        checkOutDate: null,
    },
    guests: {
        adultsCount: defaultGuestsPickerConfig?.adultsConfig.default as number,
        childrenCount: defaultGuestsPickerConfig?.childrenConfig.default as number,
        childrenAges: [],
    },
    instantReservation: false,
});

export const assignDefaultValuesIfNeeded = (source: SearchFormDataType): SearchEngineModel => {
    return merge(
        {},
        defaultSearchFormData(),
        pickBy(source, value => !isNil(value)),
    );
};
