import { IconProps, NocIconGenerator } from '../common';

export const People = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'People',
            path: (
                <>
                    <path
                        d="M9 4.94C9 6.6 10.34 7.94 12 7.94C13.66 7.94 15 6.6 15 4.94C15 3.28 13.66 1.94 12 1.94C10.34 1.94 9 3.28 9 4.94Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M16.5 13.6C16.5 11.58 14.48 9.95001 12 9.95001C9.52 9.95001 7.5 11.59 7.5 13.6V16.73H9.54L10.08 21.95H13.94L14.48 16.73H16.52V13.6H16.5Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            ),
        }}
    />
);
