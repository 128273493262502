export const Input = {
    baseStyle: {
        field: {
            borderRadius: 'xl',
        },
    },
    sizes: {
        md: {
            field: {
                h: '2.875rem',
            },
        },
    },
};
