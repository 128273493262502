import { urlJoinP } from 'url-join-ts';

import { EsBaseItemModel, EsResponseModel } from '../models/elastic-search';

export class EsSlugMiddlewareService {
    readonly #accommodationBaseEsIndexName = 'noc-accommodation-base';
    readonly #accommodationBaseEsIndexVersion = 1;

    private static _instance: EsSlugMiddlewareService;

    public static get instance(): EsSlugMiddlewareService {
        if (!this._instance) {
            this._instance = new EsSlugMiddlewareService();
        }

        return this._instance;
    }

    public async loadSlugData(id: string): Promise<EsResponseModel<EsBaseItemModel<string>>> {
        const { ELASTICSEARCH_USERNAME, ELASTICSEARCH_PASSWORD, ELASTICSEARCH_HOST } = process.env;

        const url = urlJoinP(
            `${ELASTICSEARCH_HOST}`,
            [
                `${this.#accommodationBaseEsIndexName}-${this.#accommodationBaseEsIndexVersion}`,
                '_doc',
                id,
            ],
            {
                _source_includes: ['id', 'name'].join(','),
            },
        );

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${Buffer.from(
                    ELASTICSEARCH_USERNAME + ':' + ELASTICSEARCH_PASSWORD,
                ).toString('base64')}`,
            },
        });

        return response.json();
    }
}
