import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BehaviorSubject } from 'rxjs';

import { AvailabilityReasonCode, dateHelpers, LoadingState, RegexConst } from '@nocowanie/core';

import { DATE_FORMAT } from '@app/consts';
import { SliceNameEnum } from '@app/enums';
import { apiTypes, CheckoutDetailsStateModel, CheckoutInputDataModel } from '@app/models';
import { CheckoutService } from '@app/services';

const initialState: CheckoutDetailsStateModel = {
    loading: LoadingState.PENDING,
    inputData: null,
};

const checkoutInputData$: BehaviorSubject<Partial<CheckoutInputDataModel> | undefined> =
    new BehaviorSubject<Partial<CheckoutInputDataModel> | undefined>(undefined);

const fetchCheckoutDetailsThunk = createAsyncThunk(
    `${SliceNameEnum.CheckoutDetails}/fetch-checkoutDetails`,
    async (args: CheckoutInputDataModel) => {
        const {
            accommodationId,
            ratePlanId,
            provisionType,
            roomTypeId,
            guests: guestCount,
            checkIn,
            checkOut,
            childrenAges: childAges,
        } = args;

        return await CheckoutService.instance.getFinalRate({
            checkIn,
            checkOut,
            accommodationID: accommodationId,
            bookingRooms: [
                {
                    ratePlanId: Number.parseInt(`${ratePlanId}`),
                    roomTypeId: Number.parseInt(`${roomTypeId}`),
                    provisionType,
                    guestCount,
                    childAges,
                },
            ],
        });
    },
);

const slice = createSlice({
    name: SliceNameEnum.CheckoutDetails,
    initialState,
    reducers: {
        setInputData: (state, action: PayloadAction<CheckoutInputDataModel>) => {
            const { payload } = action;
            const { accommodationId } = payload;

            if (!accommodationId || isNaN(parseInt(accommodationId))) {
                state.errors = [
                    {
                        extensions: {
                            exception: {
                                errorCode: AvailabilityReasonCode.INVALID_INPUT_DATA,
                            },
                        },
                    },
                ];
                state.loading = LoadingState.IDLE;

                checkoutInputData$.next(undefined);
                return;
            }

            const isValidDateFormat =
                RegexConst.DATE_FORMAT.test(payload.checkIn) &&
                RegexConst.DATE_FORMAT.test(payload.checkOut);
            const checkIn = new Date(payload.checkIn);
            const checkOut = new Date(payload.checkOut);

            if (!isValidDateFormat || !dateHelpers.isValidRange(checkIn, checkOut)) {
                state.errors = [
                    {
                        extensions: {
                            exception: {
                                errorCode: AvailabilityReasonCode.INVALID_DATES,
                            },
                        },
                    },
                ];
                state.loading = LoadingState.IDLE;

                checkoutInputData$.next(undefined);
                return;
            }

            state.errors = null;
            state.inputData = {
                ...payload,
                accommodationId: `/api/accommodations/${accommodationId}`,
            };
            checkoutInputData$.next(payload);
        },
        setError: (state, action) => {
            state.errors = [
                {
                    extensions: {
                        exception: {
                            errorCode: action?.payload ?? AvailabilityReasonCode.INTERNAL_ERROR,
                        },
                    },
                },
            ];
            state.loading = LoadingState.IDLE;
        },
        clearErrors: state => {
            return {
                ...state,
                errors: null,
            };
        },
        setEmptyCartState: state => {
            state.loading = LoadingState.IDLE;
            state.errors = [];
            state.accommodationDetails = null;
            state.checkoutSummary = null;
        },
        clearInputData: state => {
            state.inputData = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCheckoutDetailsThunk.pending, state => {
            state.loading = LoadingState.PENDING;
            state.errors = null;
        });
        builder.addCase(fetchCheckoutDetailsThunk.fulfilled, (state, { payload }) => {
            if (!payload) {
                return;
            }

            const finalRateResult = payload;

            state.checkoutSummary = { ...finalRateResult.data };
            const { prepaymentSummary, acceptedCreditCards } = state.checkoutSummary;
            const additionalErrors: Array<any> = [];

            if (
                prepaymentSummary?.paymentMethod === apiTypes.PaymentMethod.CreditCard &&
                !acceptedCreditCards?.length
            ) {
                // Payment method specified as `CreditCard`, but none is accepted (`acceptedCreditCards`)
                additionalErrors.push({
                    extensions: {
                        exception: {
                            errorCode: AvailabilityReasonCode.ACCEPTED_PAYMENT_CARDS_INVALID,
                        },
                    },
                });
            }

            state.loading = LoadingState.IDLE;
            state.errors = Array.from([
                ...(additionalErrors ?? []),
                ...(finalRateResult.errors ?? []),
            ]);
        });
        builder.addCase(fetchCheckoutDetailsThunk.rejected, (state, action) => {
            state.errors = [action.error].flat();
            state.loading = LoadingState.IDLE;
        });
    },
});

const { actions: sliceActions } = slice;

const actions = {
    ...sliceActions,
    fetchCheckoutDetailsThunk,
};

const selectSelfState = (state: { [SliceNameEnum.CheckoutDetails]: CheckoutDetailsStateModel }) => {
    return state[slice.name] ?? initialState;
};

const selectCheckoutSummary = createSelector(selectSelfState, state => state.checkoutSummary);

const selectors = {
    selectInputData: createSelector(selectSelfState, state => state.inputData),
    selectStateIsLoading: createSelector(
        selectSelfState,
        state => state?.loading === LoadingState.PENDING,
    ),
    selectErrors: createSelector(selectSelfState, state => state.errors),
    selectCheckoutSummary: createSelector(selectSelfState, state => state.checkoutSummary),
    selectCheckoutDiscounts: createSelector(selectCheckoutSummary, summary => summary?.discounts),
    selectReservationWithConfirmation: createSelector(
        selectCheckoutSummary,
        state => !(state?.realtimeReservation ?? true),
    ),
    selectReservationWithPrepayment: createSelector(
        selectCheckoutSummary,
        state => !!state?.prepayment?.amount,
    ),
    selectReservationPrepaymentDeadline: createSelector(selectCheckoutSummary, state => {
        return state?.prepaymentSummary?.deadline
            ? dateHelpers.format(
                  new Date(state?.prepaymentSummary?.deadline),
                  DATE_FORMAT.displayedDateYearFormatWithTime,
              )
            : undefined;
    }),

    selectReservationWithOnlinePayment: createSelector(
        selectCheckoutSummary,
        state => !!state?.hasOnlinePayment,
    ),
    selectReservationWithRemainingConvertedPrice: createSelector(
        selectCheckoutSummary,
        state =>
            !!(
                state?.prepayment?.convertedAmount &&
                state?.convertedTotalPrice &&
                state?.convertedCurrency &&
                state?.convertedCurrency !== state?.currency
            ),
    ),
};

const observers = {
    checkoutInputDataChangedObserver: checkoutInputData$.asObservable(),
};

export { slice, actions, selectors, observers };
