import { IconProps, NocIconGenerator } from '../common';

export const Playground = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Playground',
            path: (
                <>
                    <g clipPath="url(#a)">
                        <path
                            stroke="#000001"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 16.5V22M16 14.78V22M19 7.5l-6.67-4.84c-.25-.23-.67-.22-.93.03L5 7.49h14v.01ZM8 13l-4 6H2v3h3l4-5.5M16 13l6 9"
                        />
                        <path
                            stroke="#000"
                            strokeMiterlimit={10}
                            d="M8 7.5v8.04c0 .53.41.96.92.96h6.16c.51 0 .92-.43.92-.96V7.5H8Z"
                        />
                        <path
                            stroke="#000001"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.16 10h1.69c.64 0 1.16.4 1.16.88v2.23c0 .49-.52.88-1.16.88h-1.69c-.64 0-1.16-.4-1.16-.88v-2.23c0-.49.52-.88 1.16-.88Z"
                        />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
