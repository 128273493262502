import { environment } from '@app/environments/environment';
import { AutocompleteItemSCModel, EsResponseManyModel } from '@app/models/elastic-search';
import { EsServiceBase } from '@app/services/abstracts/es-service.base';

export class EsAutocompleteService extends EsServiceBase {
    readonly #autocompleteEsIndexName = 'noc-autocomplete';
    readonly #autocompleteEsIndexVersion = environment.AUTOCOMPLETE_ES_VERSION;

    private static _instance: EsAutocompleteService;

    protected indexName(version?: number): string {
        return `${this.#autocompleteEsIndexName}-${version ?? this.#autocompleteEsIndexVersion}`;
    }

    public static get instance(): EsAutocompleteService {
        if (!this._instance) {
            this._instance = new EsAutocompleteService();
        }

        return this._instance;
    }

    public async loadResults(
        query: string,
        size = 5,
        indexVersion?: number,
    ): Promise<EsResponseManyModel<AutocompleteItemSCModel>> {
        return await this.getMany(this.indexName(indexVersion), {
            size,
            _source: ['id', 'name', 'caption', 'path', 'type', 'details'],
            query: {
                function_score: {
                    query: {
                        multi_match: {
                            query,
                            type: 'bool_prefix',
                            fields: [
                                'name.autocomplete',
                                'name.autocomplete._2gram',
                                'name.autocomplete._3gram',
                            ],
                            operator: 'and',
                        },
                    },
                    functions: [
                        {
                            filter: {
                                terms: {
                                    type: ['city', 'country', 'region', 'voivodeship'],
                                },
                            },
                            script_score: {
                                script: '(!doc["details.popularity"].empty ? doc["details.popularity"].value : 1) + (doc["details.accommodations_count"].value * 0.01)',
                            },
                            weight: 10,
                        },
                        {
                            filter: {
                                term: {
                                    type: 'accommodation',
                                },
                            },
                            weight: 1,
                            script_score: {
                                script: '(doc["details.ota"].value ? 20 : 0) + (doc["details.rating"].value) + (doc["details.rating_count"].value * 0.01)',
                            },
                        },
                    ],
                    boost_mode: 'sum',
                },
            },
        });
    }
}
