import { GoogleTagManager } from '@next/third-parties/google';
import Script from 'next/script';

import { wpjslibData } from '@nocowanie/core';

import { ThirdPartyScriptsProps } from './third-party-scripts.props';

export const ThirdPartyScripts = ({ gtm, wphPixelId }: ThirdPartyScriptsProps) => {
    return (
        <>
            <Script
                id="wpjslib6"
                defer
                rel="modulepreload"
                src={wpjslibData.wpjslib6Url}
                crossOrigin="anonymous"
            />
            <Script
                id="wpjslib_data"
                dangerouslySetInnerHTML={{
                    __html: `
                    var WP = [];
                    var wp_pp_gemius_identifier = "${wpjslibData.wp_pp_gemius_identifier}";
                    var wp_sn = "${wpjslibData.wp_sn}";
                    var wp_cookie_info = false;
                    `,
                }}
            />
            <Script
                strategy="afterInteractive"
                id="wpjslib6"
                src={wpjslibData.wpjslib6Url}
                crossOrigin={'anonymous'}
            />

            <GoogleTagManager gtmId={gtm.tagId} auth={gtm.auth} preview={gtm.preview} />

            <Script
                id="wphpixel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                          !function(d,m,e,v,n,t,s){d['WphTrackObject'] = n;
                          d[n] = window[n] || function() {(d[n].queue=d[n].queue||[]).push(arguments)},
                          d[n].l = 1 * new Date(), t=m.createElement(e), s=m.getElementsByTagName(e)[0],
                          t.defer=1;t.src=v;s.parentNode.insertBefore(t,s)}(window,document,'script',
                          'https://pixel.wp.pl/w/${wphPixelId}/tr.js', 'wph');
                          wph('init', '${wphPixelId}', {});
                    `,
                }}
            />
        </>
    );
};
