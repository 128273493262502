import { IconProps, NocIconGenerator } from '../common';

export const Meals = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Meals',
            path: (
                <>
                    <g
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M14.5 15.04H17c.28 0 .5-.19.5-.43C17.43 7.83 15.85 2 14.5 2v20M5 2v4.35c0 1.44 1.34 2.61 3 2.61s3-1.17 3-2.61V2M8 2v20" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
