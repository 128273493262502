import React, { forwardRef, useEffect, useRef } from 'react';

import { Box } from '@chakra-ui/react';

import { register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';

import { SliderProps } from './slider.props';

/* eslint-disable @typescript-eslint/no-namespace */
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'swiper-container': React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement> & SwiperOptions,
                HTMLElement
            >;
            'swiper-slide': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}
/* eslint-enable @typescript-eslint/no-namespace */

register();

export const Slider = forwardRef<HTMLElement, SliderProps>(
    (
        {
            slideHeight = 'auto',
            sliderOverflow,
            slideMinHeight,
            sliderParams,
            slides,
            ...props
        }: SliderProps,
        ref,
    ) => {
        const internalSwiperElRef = useRef<HTMLElement>(null);

        const swiperElRef = (ref as React.RefObject<HTMLElement>) || internalSwiperElRef;

        useEffect(() => {
            if (!swiperElRef || !swiperElRef.current) {
                return;
            }

            const swiperEl = swiperElRef?.current as any;

            let swiperParams: SwiperOptions = {
                touchMoveStopPropagation: true,
                touchReleaseOnEdges: true,
                injectStyles: [
                    `
                    :host {
                        --swiper-navigation-size: 2rem;
                        --swiper-navigation-color: #ffffff;
                    }
                    :host .swiper-pagination {
                        text-align: right;
                        color: #ffffff;
                        font-weight: bold;
                        width: fit-content;
                    }
                    :host .swiper-pagination-fraction {
                        left: unset;
                        right: 1rem;
                        bottom: 1rem;
                        background-color: rgba(0, 0, 0, 0.3);
                        padding: 0.125rem 0.5rem;
                        border-radius: 0.5rem;
                    }
                `,
                ],
            };

            if (sliderParams) {
                swiperParams = { ...swiperParams, ...sliderParams };
            }

            Object.assign(swiperEl, swiperParams);
            swiperEl.initialize();
        }, [sliderParams, swiperElRef]);

        return (
            <Box minH={slideMinHeight} h={slideHeight} overflow={sliderOverflow}>
                <swiper-container
                    init={false}
                    ref={swiperElRef}
                    style={{ height: '100%' }}
                    {...props}
                >
                    {slides?.map(slide => (
                        <swiper-slide key={slide.id}>{slide.body}</swiper-slide>
                    ))}
                </swiper-container>
            </Box>
        );
    },
);
