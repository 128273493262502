import { useEffect } from 'react';

/**
 * Adds class to root element
 * @param element
 * @param className
 */
export function useRootClass(element?: HTMLElement, className?: string): void {
    useEffect(() => {
        (element ?? document.documentElement).classList.add(className ?? 'nocowanie-root');
    }, [className]);
}
