import { IconProps, NocIconGenerator } from '../common';

export const Jacuzzi = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Jacuzzi',
            path: (
                <>
                    <g clipPath="url(#a)">
                        <path
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M22 11H2v2h20v-2ZM3 13v9h18v-9M3 16h18M3 19h18"
                        />
                        <path
                            stroke="#000001"
                            strokeMiterlimit={10}
                            d="M7 16v-3M10 16v-3M14 16v-3M17 16v-3"
                        />
                        <path
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.18 2.44c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M8.18 8.29c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47M15.45 2.44c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M15.45 8.29c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47M11.77 2.6c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M11.77 8.44c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47"
                        />
                        <path
                            stroke="#000001"
                            strokeMiterlimit={10}
                            d="M7 22v-3M10 22v-3M14 22v-3M17 22v-3"
                        />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
