import axios from 'axios';
import { urlJoinP } from 'url-join-ts';

import { environment } from '@app/environments/environment';

type QueryStringObject = Record<string, any>;

export class ApiServiceBase {
    protected get ApiHost(): string {
        return environment.SSR_API_HOST || '';
    }

    protected async query<TModel>(url: string, queryParams?: QueryStringObject) {
        const newUrl = urlJoinP(this.ApiHost, [url, '/'], queryParams);

        const response = await axios.get<TModel>(newUrl, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data;
    }
}
