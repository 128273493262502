import { Container, Grid } from '@chakra-ui/react';

import { GridWrapperProps } from './grid-wrapper.props';

export const GridWrapper = ({ children, ...rest }: GridWrapperProps): JSX.Element => {
    return (
        <Container
            as={Grid}
            templateColumns={{
                base: 'repeat(4, 1fr)',
                md: 'repeat(16, 1fr)',
            }}
            maxWidth={'containerWidth'}
            px={{
                base: 'gutterWidth',
                md: 'gutterWidthDesktop',
            }}
            rowGap={'gutterHeight'}
            columnGap={{ base: 'gutterWidth', md: 'gutterWidthDesktop' }}
            {...rest}
        >
            {children}
        </Container>
    );
};
