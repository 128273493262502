import { IconProps, NocIconGenerator } from '../common';

export const Kids = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Kids',
            path: (
                <>
                    <path
                        d="M9.5 8C9.5 9.38 10.62 10.5 12 10.5C13.38 10.5 14.5 9.38 14.5 8C14.5 6.62 13.38 5.5 12 5.5C10.62 5.5 9.5 6.62 9.5 8Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12 12C10.07 12 8.5 13.34 8.5 15V17.5H10.25L10.83 22H13.16L13.74 17.5H15.49V15C15.49 13.34 13.92 12 11.99 12H12Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            ),
        }}
    />
);
