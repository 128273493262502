import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { clone } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { LoadingState } from '@nocowanie/core';

import { SliceNameEnum } from '@app/enums';
import {
    apiTypes,
    CheckoutReservationStateModel,
    RegistrationFormState,
    ReservationModel,
} from '@app/models';
import { CheckoutService } from '@app/services';

const initialState: CheckoutReservationStateModel = {
    loading: LoadingState.IDLE,
    reservationForm: {
        isValid: {
            isConsentValid: false,
            isUserDataValid: false,
            isCreditCardValid: false,
        },
    },
    reservationDetails: null,
    isSubmitLocked: false,
};

const makeReservationThunk = createAsyncThunk(
    `${SliceNameEnum.CheckoutReservation}/make-reservation`,
    async (input: apiTypes.CreateReservationInput) => {
        return await CheckoutService.instance.makeReservation(input);
    },
);

const makeReservationFinished$: BehaviorSubject<ReservationModel | undefined> = new BehaviorSubject<
    ReservationModel | undefined
>(undefined);

const slice = createSlice({
    name: SliceNameEnum.CheckoutReservation,
    initialState,
    reducers: {
        setIsSubmitLocked: (
            state,
            action: PayloadAction<CheckoutReservationStateModel['isSubmitLocked']>,
        ) => {
            state.isSubmitLocked = action.payload;
        },
        setFormData: (
            state,
            action: PayloadAction<
                RegistrationFormState & {
                    isValid: {
                        isUserDataValid: boolean;
                        isConsentValid: boolean;
                        isCreditCardValid: boolean;
                    };
                }
            >,
        ) => {
            state.reservationForm = {
                ...action.payload,
                isValid: clone(action.payload.isValid),
                consentCheckbox: clone(action.payload.consentCheckbox),
            };
        },
        clearErrors: state => {
            return {
                ...state,
                errors: undefined,
            };
        },
        clearReservationDetails: state => {
            state.reservationDetails = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(makeReservationThunk.pending, (state, action) => {
            state.loading = LoadingState.PENDING;
        });
        builder.addCase(makeReservationThunk.fulfilled, (state, { payload }) => {
            if (!payload) {
                state.isSubmitLocked = false;
                return;
            }

            const { data, errors = [] } = payload;
            const reservationDetails = data?.reservation ?? undefined;
            state.errors = Array.from(errors);
            state.loading = LoadingState.IDLE;
            state.reservationDetails = reservationDetails;

            const responseData = reservationDetails
                ? {
                      reservationId: reservationDetails.reservationId,
                      identifierCode: reservationDetails.identifierCode,
                  }
                : undefined;

            if (errors?.length) {
                // If reservation is made we want to keep the submit locked until new page (thyp) is loaded
                state.isSubmitLocked = false;
            }

            makeReservationFinished$.next(errors?.length ? undefined : responseData);
        });
        builder.addCase(makeReservationThunk.rejected, (state, action) => {
            state.errors = [action.error].flat();
            state.isSubmitLocked = false;
            state.loading = LoadingState.IDLE;
        });
    },
});

const { actions: sliceActions } = slice;

const actions = {
    ...sliceActions,
    makeReservationThunk,
};

const selectSelfState = (state: {
    [SliceNameEnum.CheckoutReservation]: CheckoutReservationStateModel;
}) => {
    return state[slice.name] ?? initialState;
};

const selectFormDataState = createSelector(selectSelfState, state => state?.reservationForm);

const selectors = {
    selectStateIsLoading: createSelector(
        selectSelfState,
        state => state?.loading === LoadingState.PENDING,
    ),
    selectErrors: createSelector(selectSelfState, state => state?.errors),
    selectReservationForm: selectFormDataState,
    selectReservationFormIsValid: createSelector(selectFormDataState, state => state?.isValid),
    selectReservationDetails: createSelector(selectSelfState, state => state.reservationDetails),
    selectIsSubmitLocked: createSelector(selectSelfState, state => state.isSubmitLocked),
};

const observers = {
    makeReservationFinishedObserver: makeReservationFinished$.asObservable(),
};

export { slice, actions, selectors, observers };
