import { IconProps, NocIconGenerator } from '../common';

export const Timer1 = (props: IconProps) => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Timer1',
            path: (
                <>
                    <path
                        d="M20.75 13.25c0 4.83-3.92 8.75-8.75 8.75s-8.75-3.92-8.75-8.75S7.17 4.5 12 4.5s8.75 3.92 8.75 8.75ZM12 8v5"
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                    />
                    <path
                        d="M9 2h6"
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        strokeWidth={1.5}
                    />
                </>
            ),
        }}
    />
);
