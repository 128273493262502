export const RANGE_PICKER = {
    DATE_FORMAT: 'yyyy-MM-dd',
    POPPER_CLOSE_DELAY: 5000,
    DAY_CLASS_NAME_CONFIG: {
        selected: 'react-datepicker__day--selection-start', // We apply custom class name to selected startDate (when endDate is not yet selected)
        minCutOff: 'react-datepicker__day--min-cut-off',
        noArrival: 'react-datepicker__day--no-arrival',
        noDeparture: 'react-datepicker__day--no-departure',
        danger: 'react-datepicker__day--danger',
        unavailable: 'react-datepicker__day--unavailable',
    },
    RULES: {
        MAX_STAY: 31,
        MIN_STAY: 1,
        MIN_CUT_OFF: 0,
    },
};
