import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker, {
    CalendarContainer,
    DatePickerProps as ReactDatepickerProps,
    registerLocale,
    setDefaultLocale,
} from 'react-datepicker';

import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

import { dateHelpers } from '@nocowanie/core';
import { IconsLinear } from '@nocowanie/icons';

import './date-picker.scss';
import { DatePickerProps } from './date-picker.props';

import { useIsMobile } from '../../../helpers';

if (typeof window !== 'undefined') {
    // Client-side-only code
    const globalLocale = dateHelpers.getGlobalLocale();
    registerLocale(globalLocale, dateHelpers.dateFnsLocales[globalLocale]);
    setDefaultLocale(globalLocale);
}

const DatePickerContainer = ({
    className,
    children,
    showPopperArrow,
    arrowProps = {},
}: ReactDatepickerProps & { arrowProps: Record<string, any> }): JSX.Element => {
    return (
        <div>
            <CalendarContainer className={className}>
                {showPopperArrow && <div className="react-datepicker__triangle" {...arrowProps} />}
                <div style={{ position: 'relative' }} className="clearfix">
                    {children}
                </div>
            </CalendarContainer>
        </div>
    );
};

export const DatePicker = ({
    displayedDateFormat,
    onChange,
    pickerProps,
    selectedDate,
    inputProps = {},
    showIcon = false,
}: DatePickerProps): JSX.Element => {
    const isMobileBrowser = useIsMobile();
    const [displayedValue, setDisplayedValue] = useState<string>('');
    const [startDate, setStartDate] = useState(selectedDate);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (startDate) {
            onChanged(startDate);
        }
    }, [startDate]);

    const updatePicker = (date: Date | null): void => {
        setDisplayedValue(date ? `${dateHelpers.format(date, displayedDateFormat)}` : '');

        date && onChange && onChange(date, displayedValue);
    };

    const onChanged = (
        date: Date | null,
        event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | undefined,
    ): void => {
        setStartDate(date);
        updatePicker(date);
    };

    // fixes react-datepicker click through overlay without picking dates error
    const preventEventPropagation = useCallback((e: TouchEvent) => {
        document.removeEventListener('touchstart', preventEventPropagation, true);
        e.stopPropagation();
    }, []);

    useEffect(() => {
        document.addEventListener('touchstart', preventEventPropagation, true);

        return () => document.removeEventListener('touchstart', preventEventPropagation, true);
    }, [isOpen]);

    // There is weird type issue after picker's update - it needs types (onChange, selectsRange, selectsMultiple) explicitly set
    // https://github.com/Hacker0x01/react-datepicker/issues/4924
    // https://github.com/Hacker0x01/react-datepicker/issues/4999
    const props: Omit<
        DatePickerProps,
        'onChange' | 'excludeDates' | 'selectsRange' | 'selectsMultiple' | 'selectedDate'
    > & {
        onChange: (
            date: Date | null,
            event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | undefined,
        ) => void;
        selectsRange?: never;
        selectsMultiple?: never;
    } = {
        selectsMultiple: undefined,
        selectsRange: undefined,
        onChange: onChanged,
        ...pickerProps,
    };

    return (
        <ReactDatePicker
            open={isOpen}
            selected={startDate}
            onCalendarClose={() => setIsOpen(false)}
            onCalendarOpen={() => setIsOpen(true)}
            calendarContainer={DatePickerContainer}
            withPortal={isMobileBrowser}
            todayButton={null}
            closeOnScroll={!isMobileBrowser}
            shouldCloseOnSelect={isMobileBrowser}
            popperPlacement="top"
            portalId={'datepickerPortal'}
            customInput={
                <InputGroup>
                    {showIcon && (
                        <InputLeftElement
                            pointerEvents="none"
                            children={
                                <IconsLinear.Calendar
                                    fontSize={'xl'}
                                    color={'secondary.500'}
                                    width={'10'}
                                />
                            }
                        />
                    )}
                    {/* textTransform - polish month names are lowercase - we want them capitalized */}
                    <Input
                        {...inputProps}
                        pl={'10'}
                        readOnly
                        defaultValue={displayedValue}
                        textTransform={'capitalize'}
                    />
                </InputGroup>
            }
            ref={(picker: any) => {
                // https://github.com/Hacker0x01/react-datepicker/issues/1480
                // React-datepicker overrides input's readonly prop, so we need to override it >again<
                if (picker && picker.input) {
                    picker.input.readOnly = true;

                    // Some other props are not passed too
                    for (const [propName, propValue] of Object.entries(inputProps as object)) {
                        picker.input[propName] = propValue;
                    }
                }
            }}
            {...props}
        />
    );
};
