import { createAsyncThunk } from '@reduxjs/toolkit';

import { SectionNameEnum, SliceNameEnum } from '@app/enums';
import { EsHomepageService } from '@app/services';

/**
 * Get data for specific section by {@link SectionNameEnum}
 * @param section {@link SectionNameEnum}
 */
export const getSectionData = createAsyncThunk<
    { data: unknown; section: SectionNameEnum },
    { section: SectionNameEnum; indexVersion?: number }
>(
    `${SliceNameEnum.Homepage}/fetch-data`,
    async (
        { section, indexVersion },
        { rejectWithValue },
    ): Promise<
        { data: unknown; section: SectionNameEnum } | ReturnType<typeof rejectWithValue>
    > => {
        try {
            const { found, _source } = await EsHomepageService.instance.loadSectionData<
                Record<SectionNameEnum, unknown>
            >(section, indexVersion);

            return {
                data: found ? _source[section] : null,
                section,
            };
        } catch (err) {
            return rejectWithValue({ errors: [err].flat(), section });
        }
    },
);
