import React from 'react';
import { Store } from 'redux';
export interface WebComponentWrapperProps {
    children?: React.ReactElement | React.ReactElement[];
    [key: string]: any;
}

export interface WebComponentWrapperWithStoreProps extends WebComponentWrapperProps {
    store: Store;
}
