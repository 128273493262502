/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccommodationStats } from '../models/AccommodationStats';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AccommodationStatsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Creates a AccommodationStats resource.
     * Creates a AccommodationStats resource.
     * @param requestBody The new AccommodationStats resource
     * @returns any AccommodationStats resource created
     * @throws ApiError
     */
    public apiAccommodationStatsPost(
        requestBody: AccommodationStats,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/accommodation_stats',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
}
