import { Grid, GridItem, SystemProps } from '@chakra-ui/react';

import { MediaProps } from './media.props';

export const Media = ({
    image,
    imageWidth = 'auto',
    imageVerticalAlign = 'start',
    bodyVerticalAlign = 'start',
    bodyTextAlign = 'inherit',
    reversed = false,
    gap = 4,
    breakOn,
    children,
    ...rest
}: MediaProps): JSX.Element => {
    const wrapperClassName = ['wc-media', reversed ? 'wc-media--reversed' : ''].join(' ').trim();

    const templateColumns = (): SystemProps['gridTemplateColumns'] => {
        const orderedColumns = image
            ? reversed
                ? `1fr ${imageWidth}`
                : `${imageWidth} 1fr`
            : '1fr';

        if (breakOn) {
            return {
                base: 'auto',
                [breakOn]: orderedColumns,
            };
        }

        return orderedColumns;
    };

    const mediaImage = image ? (
        <GridItem className="wc-media__img" alignSelf={imageVerticalAlign} key="img" flexGrow={0}>
            {image}
        </GridItem>
    ) : null;

    const mediaBody = (
        <GridItem
            className="wc-media__body"
            alignSelf={bodyVerticalAlign}
            textAlign={bodyTextAlign}
            flexGrow={1}
            key="body"
        >
            {children}
        </GridItem>
    );

    const mediaElements = [mediaImage, mediaBody];

    return (
        <Grid className={wrapperClassName} gap={gap} templateColumns={templateColumns()} {...rest}>
            {reversed ? mediaElements.reverse() : mediaElements}
        </Grid>
    );
};
