import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['select', 'iconColor']);

export const GuestsPickerSelect = helpers.defineMultiStyleConfig({
    baseStyle: (props: StyleFunctionProps) => {
        const { isTouched } = props;

        return {
            select: {
                color: isTouched ? undefined : 'var(--chakra-colors-chakra-placeholder-color)',
            },
            iconColor: 'secondary.500',
        };
    },
});
