import { AppProps } from 'next/app';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';

import { Provider } from 'react-redux';

import { extendTheme } from '@chakra-ui/theme-utils';

import { ssrTheme, ThemeVersionEnum, ThirdPartyScripts, v7SSRtheme } from '@nocowanie/common-ui';

import { AppHeader } from '@app/components/organisms/app-header';
import { AppHeaderOld } from '@app/components/organisms/app-header-old';
import { environment } from '@app/environments/environment';
import { hindFont } from '@app/fonts/hind';
import { setMobile } from '@app/helpers/ssr.helpers';
import { useRemoveFragmentsOnMount } from '@app/hooks';
import { PageProps } from '@app/models';
import nextI18nextConfig from '@app/next-i18next.config';
import { nocowanieAppStateWrapper } from '@app/state';
import { setMobileVersion } from '@app/state/slices/common-ui-state.slice';
import { ThemeProvider } from '@app/ui-providers';

import speculationRules from '../public/speculation-rules.json';

function NocowanieApp({ Component, ...rest }: AppProps<PageProps>) {
    const store = nocowanieAppStateWrapper.useStore();
    const { pageProps: props } = rest;
    const isV7Theme = props?.themeVersion === ThemeVersionEnum.V7;
    const font = isV7Theme ? hindFont : undefined;
    const theme = isV7Theme ? v7SSRtheme : ssrTheme;

    useRemoveFragmentsOnMount();

    setMobile({
        userAgent: props.userAgent,
        store,
        action: setMobileVersion,
    });

    const extendedTheme = isV7Theme
        ? extendTheme({
              ...theme,
              styles: {
                  ...theme?.styles,
                  global: {
                      ...theme?.styles?.global,
                      html: {
                          ...theme?.styles?.global?.html,
                          fontFamily: font?.style.fontFamily,
                      },
                  },
              },
          })
        : theme;

    return (
        <>
            <ThirdPartyScripts
                gtm={{
                    tagId: environment.GTM.GTM_TAG_ID,
                    auth: environment.GTM.GTM_AUTH,
                    preview: environment.GTM.GTM_PREVIEW,
                }}
                wphPixelId={environment.WPH_PIXEL_ID}
            />
            <ThemeProvider theme={extendedTheme}>
                <Provider store={store}>
                    {props.hideAppHeader ? null : isV7Theme || props.appHeaderProps?.isHomePage ? (
                        <AppHeader isV7Theme={isV7Theme} {...props.appHeaderProps} />
                    ) : (
                        <AppHeaderOld {...props.appHeaderProps} />
                    )}
                    <Component {...props} />
                </Provider>
            </ThemeProvider>
            <Script
                id="speculation-rules"
                type="speculationrules"
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(speculationRules),
                }}
            />
        </>
    );
}

// We don't define `getInitialProps` due to https://github.com/vercel/next.js/discussions/47501#discussioncomment-5420368.
// If it will be needed, we will most propably have to 'duplicate' 500 page in `_error` page.

// https://github.com/i18next/next-i18next/issues/990
export default appWithTranslation(NocowanieApp, nextI18nextConfig);
