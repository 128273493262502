import { useId } from 'react';

import { Button } from '@chakra-ui/button';
import { Box, Flex, Grid } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import isEqual from 'lodash/isEqual';

import { IconsLinear } from '@nocowanie/icons';

import { SearchEngineDesktopV7Props } from './search-engine-desktop-v7.props';

import {
    GuestsPicker,
    RangePicker,
    SearchEngineInstantReservationCheckbox,
    SearchLocation,
} from '../../../components';
import { defaultSearchEngineTranslation, GuestsPickerInputDataModel } from '../../../models';
import { AutocompleteBaseItemModel } from '../../molecules/autocomplete/autocomplete-base-item.model';

export const SearchEngineDesktopV7 = <T extends AutocompleteBaseItemModel>({
    setValue,
    getValues,
    register,
    watch,
    isSubmitted,
    onInputValueUpdated,
    translationData = defaultSearchEngineTranslation,
    showInstantReservationInput = true,
    autocompleteData,
    isWebComponentVersion = false,
    ...props
}: SearchEngineDesktopV7Props<T>) => {
    const themeStyles = useMultiStyleConfig('SearchEngineDesktop', {});
    const locationId = useId();

    return (
        <Flex flexDirection={'column'}>
            <Grid className="search-engine-container" sx={themeStyles.wrapper} {...props}>
                <SearchLocation
                    inputProps={{
                        id: locationId,
                        inputProps: {
                            ...register('location'),
                            sx: themeStyles.locationInput,
                            'data-testid': 'search-engine-location-input',
                        },
                    }}
                    autocompleteData={autocompleteData}
                    flexGrow={4}
                />

                <Box sx={themeStyles.divider} />

                <Box flexGrow={3}>
                    <RangePicker
                        selectedDates={[
                            getValues('dates.checkInDate'),
                            getValues('dates.checkOutDate'),
                        ]}
                        onRangeChange={(dates: [Date, Date]) => {
                            if (
                                dates[0] !== getValues('dates.checkInDate') ||
                                dates[1] !== getValues('dates.checkOutDate')
                            ) {
                                setValue('dates.checkInDate', dates[0]);
                                setValue('dates.checkOutDate', dates[1]);

                                onInputValueUpdated();
                            }
                        }}
                        inputProps={{
                            inputProps: {
                                sx: themeStyles.rangePickerTrigger,
                            },
                            'data-testid': 'search-engine-range-picker',
                        }}
                        isMobileBrowser={false}
                        isWebComponent={isWebComponentVersion}
                    />
                </Box>

                <Box sx={themeStyles.divider} />

                <Box flexGrow={3}>
                    <GuestsPicker
                        inputData={getValues('guests')}
                        noArrow={true}
                        onInputDataChanged={(data: GuestsPickerInputDataModel) => {
                            const newGuests = {
                                adultsCount: data.adultsCount || 1,
                                childrenCount: data.childrenCount || 0,
                                childrenAges: data.childrenAges
                                    ? data.childrenAges.filter(
                                          (age): age is number => age !== undefined,
                                      )
                                    : [],
                            };

                            if (!isEqual(newGuests, getValues('guests'))) {
                                setValue('guests', newGuests);
                                onInputValueUpdated();
                            }
                        }}
                        triggerProps={{
                            'data-testid': 'search-engine-guests-picker',
                            sx: themeStyles.guestPickerTrigger,
                        }}
                        isMobileBrowser={false}
                    />
                </Box>

                <Button
                    type="submit"
                    isDisabled={isSubmitted}
                    leftIcon={<IconsLinear.Search fontSize={'xl'} />}
                    variant={'solid'}
                    colorScheme={'brand'}
                    data-testid="search-engine-submit-button"
                    sx={themeStyles.submit}
                >
                    {translationData.buttonSubmitLabel}
                </Button>
            </Grid>
            {showInstantReservationInput ? (
                <SearchEngineInstantReservationCheckbox
                    onInputValueUpdated={onInputValueUpdated}
                    setValue={setValue}
                    watch={watch}
                    getValues={getValues}
                    withBackground={true}
                    sx={themeStyles.instantReservationCheckboxWrapper}
                />
            ) : null}
        </Flex>
    );
};
