import React, { ChangeEvent, useState } from 'react';

import { Box, Select, Text } from '@chakra-ui/react';

import { AppPreset, AppPresetSelectProps } from './app-preset-select.props';

export const AppPresetSelect = ({
    onPresetChange,
    preset,
    presetList,
}: AppPresetSelectProps): React.ReactElement => {
    const [currentPreset, setCurrentPreset] = useState<AppPreset>(preset);
    const onPresetChanged = (event: ChangeEvent<HTMLSelectElement>): void => {
        const current = presetList.find(preset => preset.value === event.currentTarget.value);
        setCurrentPreset(current as AppPreset);
        onPresetChange && onPresetChange(current as AppPreset);
    };

    return (
        <Box>
            <Text>Preset: </Text>
            <Select w="auto" value={currentPreset.value} onChange={onPresetChanged}>
                <option value={undefined} disabled>
                    Select Preset
                </option>
                {presetList.map((preset: AppPreset) => (
                    <option value={preset.value} key={preset.value}>
                        {preset.label}
                    </option>
                ))}
            </Select>
        </Box>
    );
};
