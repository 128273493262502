export enum AvailabilityReasonCode {
    PROVISION_TYPE_INVALID = 'ProvisionTypeInvalid',
    PACKAGE_ID_INVALID = 'PackageIdInvalid',
    INVALID_ROOM_TYPE_ID = 'InvalidRoomTypeId',
    MISSING_ROOM_TYPES = 'MissingRoomTypes',
    INVALID_RATE_PLAN_ID = 'InvalidRatePlanId',
    INVALID_GUEST_COUNT = 'InvalidGuestCount',
    INVALID_TOTAL_GUEST_COUNT = 'InvalidTotalGuestCount',
    INVALID_CHILD_AGES = 'InvalidChildAges',
    RATE_NOT_AVAILABLE = 'RateNotAvailable',
    INVALID_GUEST_NAMES = 'InvalidGuestNames',
    INVALID_INTERVAL = 'InvalidInterval',
    RESERVATION_TOO_LONG = 'ReservationTooLong',
    INVALID_RESERVATION_PRICE = 'InvalidReservationPrice',
    INACTIVE_HOTEL = 'InactiveHotel',
    HOTEL_NOT_RESERVABLE = 'HotelNotReservable',
    PAYMENT_CARD_REQUIRED = 'PaymentCardRequired',
    INVALID_PAYMENT_CARD_EXPIRATION_DATE = 'InvalidPaymentCardExpirationDate',
    INVALID_PAYMENT_CARD_NUMBER = 'InvalidPaymentCardNumber',
    PET_COUNT_INVALID = 'PetCountInvalid',
    PET_ID_INVALID = 'PetIdInvalid',
    PET_DISALLOWED = 'PetDisallowed',
    HOTEL_UNAVAILABLE = 'HotelUnavailable',
    NO_ARRIVAL_CHECK_IN_DATE = 'NoArrivalCheckInDate',
    NO_DEPARTURE_CHECK_OUT_DATE = 'NoDepartureCheckOutDate',
    MINIMAL_NIGHTS_NOT_REACHED = 'MinimalNightsNotReached',
    MAXIMAL_NIGHTS_EXCEEDED = 'MaximalNightsExceeded',
    CUT_OFF_DAYS_NOT_REACHED = 'CutOffDaysNotReached',
    ROOM_FULL = 'RoomFull',
    INVALID_GUEST_COUNTRY = 'InvalidGuestCountry',
    PAYMENT_METHOD_REQUIRED = 'PaymentMethodRequired',
    PAYMENT_METHOD_NOT_REQUIRED = 'PaymentMethodNotRequired',
    PAYMENT_METHOD_NOT_ALLOWED = 'PaymentMethodNotAllowed',
    INVALID_PAYMENT_METHOD = 'InvalidPaymentMethod',
    BAD_REQUEST = 'BadRequest',
    INTERNAL_ERROR = 'InternalError',
    INVALID_INPUT_DATA = 'InvalidInputData', // Custom error
    INVALID_DATES = 'InvalidDates', // Custom error
    ACCEPTED_PAYMENT_CARDS_INVALID = 'AcceptedPaymentCardsInvalid', // Custom error - Host requires card guarantee, but does not accept any type of credit cards
    UNKNOWN = 'Unknown',
}
