const namedColors = {
    sparkusPurple: '#977bff',
    nightPurple: '#1a0c3f',
};

// Palettes are still WiP
// some were generated via https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
// we should get proper ones soon

const colorPalettes = {
    red: {
        50: '#ffcbd4',
        100: '#f2959d',
        200: '#e96a77',
        300: '#f54256',
        400: '#ec173b',
        500: '#da0235',
        600: '#cd002d',
        700: '#bf0020',
        800: '#9f001b',
        900: '#7f0015',
    },

    green: {
        50: '#e4f6ed',
        100: '#bde9d2',
        200: '#92dbb6',
        300: '#78d5a8',
        400: '#5ece99',
        500: '#29c383',
        600: '#00b86d',
        700: '#009655',
        800: '#008448',
        900: '#006533',
    },

    gray: {
        25: '#fafafa',
        50: '#f2f2f2',
        100: '#cccccc',
        200: '#b3b3b3',
        300: '#999999',
        400: '#808080',
        500: '#666666',
        600: '#4d4d4d',
        700: '#333333',
        800: '#1a1a1a',
        900: '#000000',
    },
};

export const colors = {
    sparkusPurple: namedColors.sparkusPurple,

    brand: {
        50: '#f1eeff',
        100: '#e2dcff', // light purple from palette
        200: '#bdacff',
        300: namedColors.sparkusPurple,
        400: '#8155f7',
        500: '#6b2eef',
        600: '#5f29e8',
        700: '#4c20e0',
        800: '#3719da',
        900: '#0002d3',
    },

    // todo - modify the palette and customize styles for `black`
    secondary: {
        50: '#f5f5f5',
        100: '#e9e9e9',
        200: '#c4c4c4',
        300: '#7b7b7b',
        400: '#434343',
        500: '#000000',
        600: '#000000',
        700: '#000000',
        800: '#000000',
        900: '#000000',
    },
    tertiary: {
        50: '#e5e5ed',
        100: '#bfbed4',
        200: '#9595b6',
        300: '#6f6d9a',
        400: '#544f86',
        500: '#3b3373',
        600: '#362d6b',
        700: '#2e2461',
        800: '#261b55',
        900: namedColors.nightPurple,
    },

    quaternary: {
        50: '#fde5ed',
        100: '#fabdd2',
        200: '#f793b4',
        300: '#f56896',
        400: '#f1497e',
        500: '#ef2e68', // pink from the palette
        600: '#de2a65',
        700: '#c72660',
        800: '#b2225b',
        900: '#8d1b53',
    },

    gray: colorPalettes.gray,

    red: colorPalettes.red,
    green: colorPalettes.green,

    success: colorPalettes.green,
    danger: colorPalettes.red,
    selectiveYellow: '#ffb400',

    gelattoPink: {
        50: '#feefff',
        200: '#fccaff',
    },

    pistachioGreen: {
        50: '#eefcf0',
        200: '#cbefd2',
    },

    sandYellow: {
        50: '#fdfbf1',
        200: '#fff4d9',
    },
};
