import { createAsyncThunk } from '@reduxjs/toolkit';

import { NocApiClient, User } from '@nocowanie/noc-api';

import { ApiResponseModel } from '@nocowanie/gql-core';

import { UserSliceName } from '@app/enums';
import { environment } from '@app/environments/environment';

const apiClient = new NocApiClient({ BASE: environment.NOC_API_END_POINT });

export const getUserProfile = createAsyncThunk<
    ApiResponseModel<User> | { data: null },
    { skipRequest?: boolean }
>(
    `${UserSliceName.UserProfile}/fetch-data`,
    async ({ skipRequest = false } = {}, { rejectWithValue }) => {
        if (skipRequest) {
            return {
                data: null,
            };
        }

        try {
            const userProfileResponse = await apiClient.user.me();
            return { data: userProfileResponse };
        } catch (err) {
            if (err instanceof Error) {
                return rejectWithValue(err.message);
            } else {
                return rejectWithValue('Unknown error occurred');
            }
        }
    },
);
