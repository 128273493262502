import { extendTheme, StyleFunctionProps } from '@chakra-ui/react';

import { themeBase } from './theme-base';

/**
 * Overrides for App styles (SSR only)
 */

const ssrTheme = extendTheme({
    ...themeBase,
    styles: {
        global: {
            ...themeBase.styles.global,
            html: {
                fontFamily: 'Proxima Nova, Arial, sans-serif',
            },
        },
    },
    components: {
        ...themeBase.components,
        Badge: {
            baseStyle: {
                textTransform: 'none',
                textAlign: 'center',
                rounded: 'md',
                py: 1,
                px: 2,
                fontSize: {
                    base: 'xs',
                    md: 'sm',
                },
            },
            variants: {
                'solid-light': (props: StyleFunctionProps) => {
                    const { colorScheme } = props;
                    return {
                        bg: `${colorScheme}.25`,
                        color: 'black',
                        fontSize: {
                            base: '2xs',
                            md: 'sm',
                        },
                    };
                },
                solid: (props: StyleFunctionProps) => {
                    const { colorScheme } = props;
                    return {
                        color: colorScheme === 'yellow' ? 'black' : undefined,
                    };
                },
                subtle: (props: StyleFunctionProps) => {
                    const { colorScheme } = props;
                    return {
                        bg: `${colorScheme}.200`,
                        color: `${colorScheme}.700`,
                        fontWeight: '400',
                        fontSize: 'xs',
                    };
                },
            },
        },
    },
});

export { ssrTheme };
