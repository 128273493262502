import * as yup from 'yup';

import * as apiTypes from './api/api-types';

export interface CheckoutInputDataModel {
    accommodationId: string;
    checkIn: string;
    checkOut: string;
    guests: number;
    childrenAges?: number[];
    provisionType: apiTypes.ProvisionType;
    ratePlanId: number;
    roomTypeId: number;
}

export const CheckoutInputDataModelValidationSchema = yup.object({
    accommodationId: yup.string().required(),
    checkIn: yup.string().required(),
    checkOut: yup.string().required(),
    guests: yup.number().required(),
    // FIXME
    // Even though types define this field as optional,
    // graphql api expects has this field defined as `any` (not undefined/null).
    childrenAges: yup.array().of(yup.number().required()).default([]),
    provisionType: yup
        .mixed<apiTypes.ProvisionType>()
        .oneOf(Object.values(apiTypes.ProvisionType))
        .required(),
    ratePlanId: yup.number().required(),
    roomTypeId: yup.number().required(),
});
