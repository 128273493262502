import { extendTheme } from '@chakra-ui/react';

import './../fonts/proximanova.css';
import { themeBase } from './theme-base';

/**
 * Additional styles for APP (not included in web component styles)
 */

const appPreviewTheme = extendTheme({
    ...themeBase,
    styles: {
        global: {
            ...themeBase.styles.global,

            body: {
                color: 'gray.900',
            },

            '.nocowanie-root, .chakra-portal': {
                ...themeBase.styles.global['.nocowanie-root, .chakra-portal'],
                fontFamily: 'Proxima Nova, Arial, sans-serif',
            },
        },
    },
});

export { appPreviewTheme };
