import { DocumentNode, gql, TypedDocumentNode } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import { ApiResponseModel, BaseModel, createGQLClient, GraphQLClient } from '@nocowanie/gql-core';

import { SkeletonOptions } from './core-skeleton.options';

export abstract class CoreSkeletonService<TKey, TModel extends BaseModel<TKey>> {
    private readonly gqlClient: GraphQLClient;

    protected constructor({ gqlOptions }: SkeletonOptions) {
        this.gqlClient = createGQLClient(gqlOptions);
    }

    public executeMutation(
        mutationName: string,
        resource: Partial<TModel>,
        clientMutationId: string = uuid(),
    ): Promise<TModel> {
        return this.gqlClient.execMutation<TKey, TModel>(mutationName, resource, clientMutationId);
    }

    public executeQuery<TReturnType = TModel>(
        queryName: string,
        query: DocumentNode | TypedDocumentNode,
        variables?: Record<string, unknown>,
        headers?: Record<string, any>,
    ): Promise<ApiResponseModel<TReturnType>> {
        return this.gqlClient.execQuery<TReturnType>(queryName, query, variables, headers);
    }

    public executeQueryByType<TQueryObject = TModel, TReturnType = TModel>(
        queryName: string,
        ...fields: (keyof TQueryObject)[]
    ): Promise<ApiResponseModel<TReturnType>> {
        const query = gql`query{
      ${queryName}{
        paginationInfo{
          itemsPerPage,
          lastPage,
          totalCount,
          __typename
        }
        collection{
          ${fields.join(', ')}
        }
      }
    }`;

        return this.gqlClient.execQuery<TReturnType>(queryName, query);
    }
}
