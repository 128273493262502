import { IconProps, NocIconGenerator } from '../common';

export const BagCross = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'BagCross',
            path: (
                <>
                    <path
                        d="m13.39 17.36-2.75-2.75M13.36 14.64l-2.75 2.75M8.81 2 5.19 5.63M15.19 2l3.62 3.63"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M2 7.85c0-1.85.99-2 2.22-2h15.56c1.23 0 2.22.15 2.22 2 0 2.15-.99 2-2.22 2H4.22C2.99 9.85 2 10 2 7.85Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                    />
                    <path
                        d="m3.5 10 1.41 8.64C5.23 20.58 6 22 8.86 22h6.03c3.11 0 3.57-1.36 3.93-3.24L20.5 10"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    />
                </>
            ),
        }}
    />
);
