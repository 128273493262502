import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
    'heading',
    'seoLinksSection',
    'seoLinksSectionTitle',
    'tagsWrapper',
    'tagBadge',
]);

export const SeoLinks = helpers.defineMultiStyleConfig({
    baseStyle: {
        seoLinksSection: {
            mt: 6,
        },
        seoLinksSectionTitle: {
            mb: 4,
            fontSize: 'lg',
            fontWeight: 'bold',
        },
        tagsWrapper: {
            flexWrap: 'wrap',
            gap: 2,
        },
        tagBadge: {
            borderRadius: 'xl',
            px: 3,
        },
    },
});
