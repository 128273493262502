import { IconProps, NocIconGenerator } from '../common';

export const Tv = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Tv',
            path: (
                <>
                    <g
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M2.43 4h19.13s.43 0 .43.52v12.96s0 .52-.43.52H2.43S2 18 2 17.48V4.52S2 4 2.43 4ZM12 18.5v3M7.5 22h9" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
