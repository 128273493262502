import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['caption', 'icon', 'label', 'wrapper']);

export const AutocompleteItem = helpers.defineMultiStyleConfig({
    baseStyle: (props: StyleFunctionProps) => {
        const { isHighlighted } = props;

        return {
            wrapper: {
                display: 'flex',
                cursor: 'pointer',
                position: 'relative',
                gap: 4,
                alignItems: 'center',
                p: 3,
                bgColor: isHighlighted ? 'secondary.50' : undefined,
                _after: {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 'gutterWidth',
                    right: 'gutterWidth',
                    height: '1px',
                    bgColor: 'gray.50',
                },
                _hover: {
                    bgColor: 'secondary.50',
                },
            },
            label: {
                color: 'gray.600',
                fontSize: 'sm',
                lineHeight: 1.125,
                strong: {
                    textDecoration: 'underline',
                    fontWeight: 'semibold',
                    color: 'black',
                },
            },
            caption: {
                fontSize: 'xs',
                lineHeight: 1.25,
            },
            icon: {
                color: 'secondary.500',
            },
        };
    },
});
