import { Button } from '@chakra-ui/button';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, HStack, Link, Text, VStack } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { modifyLink } from '@app/helpers/navigation.helpers';
import { ContentLinkGroupModel, ContentLinkModel } from '@app/models';

import { ExpandableMenuItemProps } from './expandable-menu-item.props';

const InnerMenuLink = ({ subLink }: { subLink: ContentLinkModel }): JSX.Element => (
    <Link href={modifyLink(subLink)} variant={'menuLinkInner'} width={'17.5rem'}>
        {subLink.label}
    </Link>
);

export const ExpandableMenuItem = ({ navLink, isOpen, toggleMenu }: ExpandableMenuItemProps) => {
    const styles = useMultiStyleConfig('ExpandableMenuItem', {});
    const MAX_ITEMS_PER_COL = 13;

    return (
        <>
            <Button variant={'expandableMenuItem'} onClick={() => toggleMenu()}>
                <VStack align={'flex-start'} gap={0}>
                    <Text as={'span'} sx={styles.label}>{navLink.navLinkLabel}</Text>
                    {navLink.excerpt ? (
                        <Text as={'span'} color={'gray.500'} fontSize={'xs'} noOfLines={1}>
                            {navLink.excerpt}
                        </Text>
                    ) : null}
                </VStack>
                <ChevronRightIcon sx={styles.icon} />
            </Button>
            {isOpen ? (
                <Box sx={styles.dropdown}>
                    <Text fontSize={'sm'} fontWeight={'bold'} px={5} py={2.5}>
                        {navLink.navLinkLabel}
                    </Text>
                    {navLink.hasContentGroups ? (
                        <HStack
                            align={'flex-start'}
                            gap={0}
                            maxW={{ md: 'calc(100vw - 480px)', xl: 'calc(100vw - 640px)' }}
                        >
                            {navLink.navLinkContent?.map(
                                (
                                    contentGroup: ContentLinkGroupModel | ContentLinkModel,
                                    contentIndex: number,
                                ) => {
                                    return (
                                        <VStack key={contentIndex} align={'flex-start'} gap={0}>
                                            <Text
                                                fontSize={'sm'}
                                                px={'1.25rem'}
                                                py={2.5}
                                                sx={styles.title}
                                            >
                                                {'contentLinksTitle' in contentGroup
                                                    ? contentGroup.contentLinksTitle
                                                    : null}
                                            </Text>
                                            {'contentLinks' in contentGroup
                                                ? contentGroup.contentLinks.map(
                                                      (subLink: ContentLinkModel) => (
                                                          <Link
                                                              key={subLink.url}
                                                              href={subLink.url}
                                                              variant={'menuLinkInner'}
                                                              minW={'182px'}
                                                          >
                                                              {subLink.label}
                                                          </Link>
                                                      ),
                                                  )
                                                : null}
                                        </VStack>
                                    );
                                },
                            )}
                        </HStack>
                    ) : (
                        <HStack gap={0} align={'start'}>
                            <VStack gap={0}>
                                {navLink.navLinkContent?.map(
                                    (
                                        subLink: ContentLinkModel | ContentLinkGroupModel,
                                        i: number,
                                    ) =>
                                        'url' in subLink ? (
                                            i < MAX_ITEMS_PER_COL ? (
                                                <InnerMenuLink
                                                    key={subLink.url}
                                                    subLink={subLink}
                                                />
                                            ) : null
                                        ) : null,
                                )}
                            </VStack>
                            {navLink.navLinkContent && navLink.navLinkContent?.length > 13 ? (
                                <VStack gap={0}>
                                    {navLink.navLinkContent?.map(
                                        (
                                            subLink: ContentLinkModel | ContentLinkGroupModel,
                                            i: number,
                                        ) =>
                                            'url' in subLink ? (
                                                i >= MAX_ITEMS_PER_COL ? (
                                                    <InnerMenuLink
                                                        key={subLink.url}
                                                        subLink={subLink}
                                                    />
                                                ) : null
                                            ) : null,
                                    )}
                                </VStack>
                            ) : null}
                        </HStack>
                    )}
                </Box>
            ) : null}
        </>
    );
};
