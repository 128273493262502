import { AvailabilityReasonCode } from '@nocowanie/core';

import { GraphQLError } from 'graphql';

export const getAvailabilityReasonCodeFromGraphQLError = (
    error: GraphQLError,
): AvailabilityReasonCode => {
    return (
        (error?.extensions?.exception as any)?.errorCode ||
        ((error as any).statusCode === 400 && AvailabilityReasonCode.BAD_REQUEST) ||
        AvailabilityReasonCode.UNKNOWN
    );
};
