import { createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '@nocowanie/core';

import { SliceNameEnum } from '@app/enums';
import { registerHomepageReducers } from '@app/state/slices/homepage/homepage.reducers';
import { HomepageState } from '@app/state/slices/homepage/homepage.types';

const homepageInitialState: HomepageState = {
    loading: LoadingState.PENDING,
    data: null,
};

const homepageSlice = createSlice({
    name: SliceNameEnum.Homepage,
    initialState: homepageInitialState,
    reducers: {},
    extraReducers: builder => registerHomepageReducers(builder),
});

const homepageReducer = homepageSlice.reducer;

export { homepageSlice, homepageInitialState, homepageReducer };
