import { urlJoin } from 'url-join-ts';

import { ApiResponseModel } from '@nocowanie/gql-core';

import { environment } from '@app/environments/environment';

import { apiTypes } from '../models';

export class AccommodationMiddlewareService {
    readonly #apiUrl: string = urlJoin(environment.ACCOMMODATION_API_ENDPOINT, 'api', 'graphql');
    private static _instance?: AccommodationMiddlewareService;

    public static get instance(): AccommodationMiddlewareService {
        if (!this._instance) {
            this._instance = new AccommodationMiddlewareService();
        }

        return this._instance;
    }

    public async loadName(
        args: apiTypes.QueryAccommodationArgs,
        headers?: Record<string, string>,
    ): Promise<ApiResponseModel<apiTypes.Accommodation>> {
        const requestBody = {
            query: 'query AccommodationQuery($id: ID!) { accommodation(id: $id) { id name } }',
            variables: {
                ...args,
            },
        };

        const response = await fetch(this.#apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        return response.json().then(x => ({
            data: x.data.accommodation,
        }));
    }
}
