import { sendGTMEvent } from '@next/third-parties/google';

import { differenceInCalendarDays, format } from 'date-fns';

import { DataLayerEventName, DataLayersSarchEngineEvent } from '@nocowanie/core';
import { SearchEngineModel } from '@nocowanie/common-ui';

export function useSearchEngineDataLayerEvents() {
    const sendSearchEngineDataLayerEvent = (data: SearchEngineModel): void => {
        const structuredChildrenAgeArray = data?.guests?.childrenAges
            ? data.guests.childrenAges.map(age => ({
                  child: {
                      age,
                  },
              }))
            : [];
        const searchEnginEventData: DataLayersSarchEngineEvent = {
            event: DataLayerEventName.SearchEngine,
            query: data.location,
            arrival: data.dates.checkInDate
                ? format(new Date(data.dates.checkInDate), 'yyyy-MM-dd')
                : undefined,
            departure: data.dates.checkOutDate
                ? format(new Date(data.dates.checkOutDate), 'yyyy-MM-dd')
                : undefined,
            adults: data.guests.adultsCount,
            children: data.guests.childrenCount,
            child_age: structuredChildrenAgeArray,
            room_nights:
                data.dates.checkOutDate && data.dates.checkInDate
                    ? differenceInCalendarDays(data.dates.checkOutDate, data.dates.checkInDate)
                    : undefined,
        };

        sendGTMEvent(searchEnginEventData);
    };

    return {
        sendSearchEngineDataLayerEvent,
    };
}
