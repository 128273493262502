import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['trigger', 'label', 'item', 'icon', 'title']);

export const ExpandableMenuItem = helpers.defineMultiStyleConfig({
    baseStyle: {
        label: {
            color: 'black',
        },
        title: {
            fontWeight: '500',
        },
        icon: {
            color: 'brand.500',
        },
        dropdown: {
            position: 'absolute',
            top: 0,
            left: '100%',
            height: '100%',
            width: 'max-content',
            overflowX: 'auto',
            bgColor: 'sandYellow.50',
        },
    },
});
