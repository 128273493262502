const modalZIndexStyles = {
    dialog: {
        zIndex: 'modal',
    },
    overlay: {
        zIndex: 'modal',
    },
};
const drawerDialogStyle = {
    maxH: 'calc(100% - 4rem)',
    borderTopRadius: '12px',
};

export const Drawer = {
    baseStyle: {
        ...modalZIndexStyles,
        dialog: {
            maxH: 'var(--chakra-vh)',
            height: 'var(--chakra-vh)',
        },
        body: {
            p: '1rem',
        },
    },

    variants: {
        navigation: {
            dialog: {
                height: 'calc(100vh - var(--chakra-space-headerOffset))',
                maxH: 'calc(100vh - var(--chakra-space-headerOffset))',
                mt: 'headerOffset',
            },
            body: {
                background: 'sandYellow.50',
            },
        },
        withMargin: {
            dialog: {
                ...drawerDialogStyle,
                height: '100vh',
            },
        },
    },
};
