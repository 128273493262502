import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
    'bgColors',
    'button',
    'container',
    'formWrapper',
    'headingLg',
    'headingSm',
    'form',
    'formInput',
    'formDivider',
    'emailErrorMsg',
    'privacyPolicyTxt',
    'image',
]);

export const Newsletter = helpers.defineMultiStyleConfig({
    baseStyle: {
        bgColors: {
            success: 'green.50',
            error: 'red.50',
            initial: 'secondary.25',
        },
        button: {
            colorScheme: 'secondary',
        },
        container: {
            p: { base: 'gutterWidth', md: 6 },
            mx: { base: '-gutterWidth', md: 0 },
            gap: 4,
            borderRadius: { base: 'none', md: 'xl' },
            position: 'relative',
            justifyContent: 'center',
        },
        formWrapper: {
            minH: '270px',
            alignItems: { base: 'flex-start', md: 'center' },
            textAlign: { base: 'left', md: 'center' },
            width: { base: '100%', md: '75%' },
            zIndex: 1,
        },
        headingLg: {
            my: 2,
        },
        headingSm: {
            my: 2,
            span: {
                fontSize: 'xl',
            },
        },
        form: {
            background: 'white',
            p: 4,
            borderRadius: 'xl',
            w: 'full',
            mt: { base: 4, md: 6 },
            mb: 2,
        },
        formInput: {
            flex: '1',
            border: 'none',
            _focusVisible: {
                border: 'none',
            },
            '&[aria-invalid=true]': {
                border: 'none',
                boxShadow: 'none',
            },
        },
        formDivider: {
            borderColor: 'inherit',
            opacity: 1,
            h: 'auto',
            w: 'auto',
            alignSelf: 'stretch',
            my: 0,
            mx: 0,
        },
        emailErrorMsg: {
            color: 'danger.500',
            fontSize: 'xs',
            pos: 'absolute',
            left: 4,
            bottom: -4,
            textAlign: 'left',
            minW: 48,
        },
        privacyPolicyTxt: {
            color: 'gray.500',
            fontSize: 'sm',
            mb: { base: 2, md: 1 },
        },
        image: {
            width: { base: '35%', md: '25%' },
            position: { base: 'absolute', md: 'static' },
            top: -28,
            right: 4,
            zIndex: 0,
        },
    },
});
