import { Box } from '@chakra-ui/layout';
import { Checkbox } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { IconsLinear } from '@nocowanie/icons';

import { SearchEngineInstantReservationCheckboxProps } from './search-engine-instant-reservation-checkbox.props';

import { defaultSearchEngineTranslation } from '../../../../lib/models';

export const SearchEngineInstantReservationCheckbox = ({
    setValue,
    getValues,
    onInputValueUpdated,
    translationData = defaultSearchEngineTranslation,
    watch,
    withBackground = false,
    ...rest
}: SearchEngineInstantReservationCheckboxProps) => {
    const themeStyles = useMultiStyleConfig('SearchEngineInstantReservationCheckbox', {});

    return (
        <Checkbox
            isChecked={watch('instantReservation')}
            onChange={e => {
                setValue('instantReservation', e.target.checked);
                onInputValueUpdated();
            }}
            data-testid="search-engine-instant-reservation-checkbox"
            alignItems={'flex-start'}
            spacing={2}
            size={'lg'}
            sx={{
                ...(withBackground ? themeStyles.withBackground : {}),
                ...rest.sx,
            }}
        >
            <Box
                fontSize={{
                    base: 'xs',
                    md: 'md',
                }}
            >
                <Box fontWeight={'700'}>
                    {translationData.instantReservationLabel}
                    <IconsLinear.Flash
                        color={'selectiveYellow'}
                        fill={'selectiveYellow'}
                        ml={{
                            base: 1,
                            md: 2,
                        }}
                    />
                </Box>
                <Box color={'green.800'}>{translationData.instantReservationDescription}</Box>
            </Box>
        </Checkbox>
    );
};
