/**
 * Returns formatted price with currency
 */
export const price = (
    value: number,
    currency?: string | null | undefined,
    showEmptyFractions = false,
    rounding = false,
): string => {
    const hasFraction = value - Math.floor(value) > 0;

    return new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: currency ?? 'PLN',
        maximumFractionDigits: rounding ? 0 : !hasFraction && !showEmptyFractions ? 0 : 2,
    })
        .format(value)
        .replace(',', '.');
};

export const lowerCaseString = (service?: string) =>
    service && service.charAt(0).toLowerCase() + service.slice(1);
