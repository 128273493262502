import { useEffect, useRef, useState } from 'react';

export const useHorizontalScroll = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const { scrollLeft, scrollWidth, clientWidth } = ref.current;
                setCanScrollLeft(scrollLeft > 0);
                // We have to use Math.round because on some browsers, the scroll values properties can return fractional pixel values instead of rounded integers. This is because browsers use sub-pixel rendering to provide smoother animations and scrolling.
                setCanScrollRight(Math.round(scrollLeft) < scrollWidth - clientWidth);
            }
        };

        if (ref.current) {
            ref.current.addEventListener('scroll', handleScroll);
            ref.current.scrollLeft = ref.current.scrollWidth;
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return { ref, canScrollLeft, canScrollRight };
};
