import { Provider } from 'react-redux';

import { ChakraProvider } from '@chakra-ui/react';

import {
    WebComponentWrapperProps,
    WebComponentWrapperWithStoreProps,
} from './web-component-wrapper.props';

import { wcTheme as theme } from '../../../theme';

export const WebComponentWrapper = ({
    children,
    ...props
}: WebComponentWrapperProps): JSX.Element => {
    return (
        <ChakraProvider
            theme={theme}
            resetScope={':where(.nocowanie-root, .chakra-portal)'}
            {...props}
        >
            {children}
        </ChakraProvider>
    );
};

export const WebComponentWrapperWithStore = ({
    store,
    ...props
}: WebComponentWrapperWithStoreProps): JSX.Element => {
    return (
        <Provider store={store}>
            <WebComponentWrapper {...props} />
        </Provider>
    );
};
