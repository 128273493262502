// Icon Library: https://iconsax-react.pages.dev/

import { Box, Icon as ChakraIcon, useMultiStyleConfig } from '@chakra-ui/react';

import { IconProps } from './icon.props';

export const Icon = ({
    icon,
    isRound = false,
    variant = 'default',
    size = 'sm',
    duoTone = false,
    duoToneColor = 'current',
    ...props
}: IconProps): JSX.Element => {
    console.warn('`Icon` is deprecated! Use `NocIcon` instead.');
    const { icon: iconStyles, box: boxStyles } = useMultiStyleConfig('Icon', { variant, size });
    const innerContent = (
        <ChakraIcon
            as={icon as any}
            __css={iconStyles}
            variant={duoTone ? 'TwoTone' : 'Linear'}
            sx={{
                path: {
                    vectorEffect: 'non-scaling-stroke',
                    strokeWidth: 'var(--icon-stroke, 1.75)',
                },
                '[opacity]': {
                    // Hacky override of second color
                    opacity: 1,
                    color: duoToneColor,
                },
            }}
            {...props}
        />
    );

    return isRound ? <Box sx={boxStyles}>{innerContent}</Box> : innerContent;
};
