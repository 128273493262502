import { useCallback } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import { GuestsPickerItemProps } from './guests-picker-item.props';

import { MobileNumberInput } from '../../mobile-number-input';
import { GuestPickerSelect } from '../guest-picker-select';

export const GuestsPickerItem = ({
    label,
    value,
    config,
    isRequired,
    placeholder,
    onValueChanged,
    itemKey,
    name,
    disableIncrementValue,
    autoFocus = false,
}: GuestsPickerItemProps): JSX.Element => {
    const handleInputChange = useCallback(
        (strValue: string, newValue: number) => {
            if (value === newValue || !onValueChanged) {
                return;
            }

            const triggeredValue = isNaN(newValue)
                ? config?.default
                : Math.min(newValue, config?.max as number);
            onValueChanged(itemKey, triggeredValue);
        },
        [config?.default, config?.max, itemKey, value, onValueChanged],
    );

    const onChange = useCallback(
        (strValue: string, value: number) => {
            handleInputChange(strValue, value);
        },
        [handleInputChange],
    );

    return itemKey === 'childrenAges' ? (
        <GuestPickerSelect
            label={label}
            placeholder={placeholder}
            max={config?.max}
            value={value}
            onChange={onChange}
        />
    ) : (
        <Flex py="1" alignItems={'center'}>
            <Text mr={'auto'}>{label}</Text>
            <MobileNumberInput
                value={value}
                placeholder={placeholder}
                defaultValue={config?.default}
                min={config?.min}
                max={disableIncrementValue ? value : config?.max}
                name={name}
                step={1}
                isRequired={isRequired}
                onChange={onChange}
                disableIncrement={disableIncrementValue}
                inputClassName={'wc-guest-picker__input'}
                autoFocus={autoFocus}
            />
        </Flex>
    );
};
