import { Flex, Spinner, Text } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { dateHelpers } from '@nocowanie/core';
import { IconsLinear } from '@nocowanie/icons';

import { SearchEngineMobileProps } from './search-engine-mobile.props';

import { usePluralisationTranslation } from '../../../hooks';
import { defaultSearchEngineTranslation } from '../../../models';
import { SearchEngineInstantReservationCheckbox } from '../../molecules';

export const SearchEngineMobile = ({
    setValue,
    getValues,
    watch,
    onInputValueUpdated,
    translationData = defaultSearchEngineTranslation,
    handleDrawerOpen,
    isDrawerPending,
    ...props
}: SearchEngineMobileProps) => {
    const styles = useMultiStyleConfig('SearchEngineMobile', {});

    const inputFontSize = 'sm';
    const iconColor = styles.icon.color as string;

    const openDrawer = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleDrawerOpen();
    };

    const displayedDateFormat = 'dd MMM.';
    const pluralizeTranslation = usePluralisationTranslation('accommodation', 'guestsNightsLabel');
    const guestsLabel = getValues('guests.adultsCount')
        ? pluralizeTranslation(
              getValues('guests.adultsCount') + getValues('guests.childrenCount'),
              'person',
          )
        : translationData.guestsPickerLabel;
    const locationValue = getValues('location');
    const checkInDate = getValues('dates.checkInDate');
    const checkOutDate = getValues('dates.checkOutDate');

    return (
        <Flex flexDirection={'column'}>
            <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                bg={'white'}
                boxShadow={'search-bar-shadow'}
                borderTopRadius={'xl'}
                p={4}
                w={'full'}
                as="button"
                onClick={openDrawer}
                data-testid={'search-engine-mobile-trigger'}
                className={'wc-search-engine__mobile-trigger'}
                {...props}
            >
                <Flex direction={'column'} fontSize={inputFontSize} gap={2}>
                    <Text as={'span'} textAlign={'left'} fontWeight={'semibold'}>
                        {locationValue?.length
                            ? locationValue
                            : translationData.locationPlaceholder}
                    </Text>
                    <Flex gap={4}>
                        <Flex gap={2} alignItems={'center'}>
                            <IconsLinear.Calendar color={iconColor} />
                            <Text as="span" color={'gray.600'}>
                                {checkInDate
                                    ? dateHelpers.format(checkInDate, displayedDateFormat)
                                    : translationData.checkInPlaceholder}
                            </Text>
                            <IconsLinear.ArrowRight color={iconColor} />
                            <Text as="span" color={'gray.600'}>
                                {checkOutDate
                                    ? dateHelpers.format(checkOutDate, displayedDateFormat)
                                    : translationData.checkOutPlaceholder}
                            </Text>
                        </Flex>
                        <Flex gap={2} alignItems={'center'}>
                            <IconsLinear.Profile2User fontSize={'md'} color={iconColor} />
                            <Text as="span">{guestsLabel}</Text>
                        </Flex>
                    </Flex>
                </Flex>
                {isDrawerPending ? (
                    <Spinner color={iconColor} size="md" />
                ) : (
                    <IconsLinear.Search fontSize={'2xl'} color={iconColor} />
                )}
            </Flex>
            <SearchEngineInstantReservationCheckbox
                onInputValueUpdated={onInputValueUpdated}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                withBackground={true}
            />
        </Flex>
    );
};
