import axios from 'axios';
import { urlJoinP } from 'url-join-ts';

import { environment } from '@app/environments/environment';
import { NewsletterPayloadModel } from '@app/models';
import { ApiServiceBase } from '@app/services/abstracts/api-service.base';

export class ApiSalesmanagoService extends ApiServiceBase {
    private static _instance: ApiSalesmanagoService;

    public static get instance(): ApiSalesmanagoService {
        if (!this._instance) {
            this._instance = new ApiSalesmanagoService();
        }

        return this._instance;
    }

    private get apiUrl(): string {
        return process.env.SM_ENDPOINT || '';
    }

    /**
     * Sends contact data to Salesmanago
     */
    public async subscribeToNewsletter(payload: NewsletterPayloadModel) {
        const url = urlJoinP(this.apiUrl, ['contact', 'upsert']);
        return await this.post(url, payload);
    }

    /**
     * Generic POST method for Salesmanago API requests
     */
    private async post<T>(url: string, payload: Record<string, any>): Promise<T> {
        const requestData = {
            clientId: environment.SM_CLIENT_ID,
            apiKey: environment.SM_API_KEY,
            requestTime: Math.floor(Date.now() / 1000),
            sha: environment.SM_SHA,
            owner: environment.SM_OWNER_EMAIL,
            ...payload,
        };
        try {
            const response = await axios.post<T>(url, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        } catch (error: any) {
            throw new Error(`Salesmanago API Error: ${error.message}`);
        }
    }
}
