import { extendTheme } from '@chakra-ui/theme-utils';

import { v7themeBase } from './v7-theme-base';

export const v7SSRtheme = extendTheme({
    ...v7themeBase,

    // Copied from old ssr-theme
    styles: {
        global: {
            ...v7themeBase.styles.global,
            body: {
                color: 'black',
            },
        },
    },
});
