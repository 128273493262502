import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { IconsLinear } from '@nocowanie/icons';

import { SearchEngineMobileDrawerProps } from './search-engine-mobile-drawer.props';

import { defaultSearchEngineTranslation, GuestsPickerInputDataModel } from '../../../models';
import {
    GuestsPicker,
    RangePicker,
    SearchEngineInstantReservationCheckbox,
    SearchLocation,
} from '../../molecules';
import { AutocompleteBaseItemModel } from '../../molecules/autocomplete/autocomplete-base-item.model';

export const SearchEngineMobileDrawer = <T extends AutocompleteBaseItemModel>({
    setValue,
    getValues,
    register,
    onSubmit,
    isSubmitted,
    onInputValueUpdated,
    translationData = defaultSearchEngineTranslation,
    isOpen,
    onClose,
    watch,
    isV7SearchEngineVersion,
    autocompleteData,
    isWebComponentVersion = false,
}: SearchEngineMobileDrawerProps<T>) => {
    const themeStyles = useMultiStyleConfig('SearchEngineMobileDrawer', {});
    const buttonColorScheme = themeStyles.button?.colorScheme as string;

    return (
        <Drawer placement={'top'} onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent height="auto" className="search-engine-container">
                <DrawerCloseButton />
                <DrawerHeader sx={themeStyles.drawerHeader}>
                    {translationData.drawerHeader}
                </DrawerHeader>
                <DrawerBody py={4}>
                    <form onSubmit={onSubmit}>
                        <Flex direction={'column'} gap={4}>
                            {isV7SearchEngineVersion ? (
                                <SearchLocation
                                    autocompleteData={autocompleteData}
                                    inputProps={{
                                        inputProps: {
                                            ...register('location'),
                                            'data-testid': 'search-engine-location-input',
                                        },
                                        label: translationData.searchLocationLabel,
                                        labelProps: {
                                            sx: themeStyles.label,
                                        },
                                    }}
                                    sx={{
                                        w: '100%',
                                    }}
                                />
                            ) : (
                                <FormControl>
                                    <FormLabel sx={themeStyles.label}>
                                        {translationData.searchLocationLabel}
                                    </FormLabel>
                                    <SearchLocation
                                        autocompleteData={autocompleteData}
                                        inputProps={{
                                            inputProps: {
                                                ...register('location'),
                                                'data-testid': 'search-engine-location-input',
                                            },
                                        }}
                                    />
                                </FormControl>
                            )}
                            <FormControl>
                                <FormLabel sx={themeStyles.label}>
                                    {translationData.rangePickerLabel}
                                </FormLabel>
                                <RangePicker
                                    isWebComponent={isWebComponentVersion}
                                    selectedDates={[
                                        getValues('dates.checkInDate'),
                                        getValues('dates.checkOutDate'),
                                    ]}
                                    onRangeChange={(dates: [Date, Date]) => {
                                        setValue('dates.checkInDate', dates[0]);
                                        setValue('dates.checkOutDate', dates[1]);
                                        onInputValueUpdated();
                                    }}
                                    inputProps={{
                                        'data-testid': 'search-engine-range-picker',
                                        inputProps: {
                                            sx: themeStyles.rangePicker,
                                        },
                                    }}
                                    isMobileBrowser={true}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel sx={themeStyles.label}>
                                    {translationData.guestsPickerLabel}
                                </FormLabel>
                                <GuestsPicker
                                    inputData={getValues('guests')}
                                    noArrow={true}
                                    onInputDataChanged={(data: GuestsPickerInputDataModel) => {
                                        setValue('guests', {
                                            adultsCount: data.adultsCount || 1,
                                            childrenCount: data.childrenCount || 0,
                                            childrenAges: data.childrenAges
                                                ? data.childrenAges.filter(
                                                      (age): age is number => age !== undefined,
                                                  )
                                                : [],
                                        });
                                        onInputValueUpdated();
                                    }}
                                    triggerProps={{
                                        pl: 3,
                                        width: 'full',
                                        'data-testid': 'search-engine-guests-picker',
                                    }}
                                    isMobileBrowser={true}
                                />
                            </FormControl>
                            <SearchEngineInstantReservationCheckbox
                                onInputValueUpdated={onInputValueUpdated}
                                setValue={setValue}
                                getValues={getValues}
                                watch={watch}
                            />
                            <Button
                                type="submit"
                                onClick={onSubmit}
                                isDisabled={isSubmitted}
                                leftIcon={<IconsLinear.Search fontSize={'xl'} />}
                                variant={'solid'}
                                colorScheme={buttonColorScheme}
                                mt={4}
                                data-testid="search-engine-submit-button"
                            >
                                {translationData.buttonSubmitLabel}
                            </Button>
                        </Flex>
                    </form>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
