import { BoxProps } from '@chakra-ui/react';

export enum ExpandCount {
    All = 'all',
}

export interface ListExpandableProps<T> extends BoxProps {
    items: T[];
    renderItem: (item: T) => React.ReactNode;
    show: number | ExpandCount;
}
