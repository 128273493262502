import React from 'react';

import { FormLabelProps, SwitchProps } from '@chakra-ui/react';

export interface SwitchComponentProps extends SwitchProps {
    id?: string;
    labelLeft?: string;
    labelRight: string;
    labelProps?: FormLabelProps;
    name?: string;
    checked?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
