import { IconProps, NocIconGenerator } from '../common';

export const Mail = (props: IconProps) => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Mail',
            path: (
                <>
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        d="M3.201 8.083c0-3.25 1.875-4.75 4.75-4.75h9.5c2.875 0 4.75 1.5 4.75 4.75v6.625c0 3.25-1.875 4.75-4.75 4.75H8.025"
                    />
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        d="m17.313 8.467-2.958 2.334c-.972 .773-2.558 .773-3.53 0L8.025 8.467M3.201 15.75h5.75M3.201 12h2.875"
                    />
                </>
            ),
        }}
    />
);
