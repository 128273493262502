import React from 'react';

import { GridProps, LayoutProps, TypographyProps } from '@chakra-ui/react';

export interface MediaProps extends GridProps {
    breakOn?: 'md' | 'lg' | 'xl' | undefined;
    image?: React.ReactNode;
    imageWidth?: LayoutProps['width'];
    imageVerticalAlign?: GridProps['alignSelf'];
    bodyVerticalAlign?: GridProps['alignSelf'];
    bodyTextAlign?: TypographyProps['textAlign'];
    reversed?: boolean;
    children?: React.ReactNode;
}
