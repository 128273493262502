import { GUEST_PICKER } from '../../../consts/guest-picker.const';
import {
    commonModel,
    defaultGuestsPickerTranslation,
    GuestsPickerTranslationModel,
} from '../../../models';

interface GuestsPickerConfig {
    totalGuestsCountConfig: commonModel.ValueConfig<number>;
    roomsConfig: commonModel.ValueConfig<number>;
    adultsConfig: commonModel.ValueConfig<number>;
    childrenConfig: commonModel.ValueConfig<number>;
    childAgeItemConfig: commonModel.ValueConfig<number>;
    translationData: GuestsPickerTranslationModel;
}

const defaultGuestsPickerConfig: GuestsPickerConfig = {
    totalGuestsCountConfig: {
        min: GUEST_PICKER.CONFIG.TOTAL_GUESTS.MIN,
        max: GUEST_PICKER.CONFIG.TOTAL_GUESTS.MAX,
    },
    roomsConfig: { min: 1, max: 15, default: 1 },
    adultsConfig: {
        min: GUEST_PICKER.CONFIG.ADULTS.MIN,
        max: GUEST_PICKER.CONFIG.ADULTS.MAX,
        default: 2,
    },
    childrenConfig: {
        min: GUEST_PICKER.CONFIG.CHILDREN.MIN,
        max: GUEST_PICKER.CONFIG.CHILDREN.MAX,
        default: 0,
    },
    childAgeItemConfig: {
        min: GUEST_PICKER.CONFIG.CHILD_AGE.MIN,
        max: GUEST_PICKER.CONFIG.CHILD_AGE.MAX,
    },
    translationData: defaultGuestsPickerTranslation,
};

export { type GuestsPickerConfig, defaultGuestsPickerConfig };
