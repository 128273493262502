import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { SEARCH_ENGINE } from '../../../consts';

const helpers = createMultiStyleConfigHelpers([
    'divider',
    'guestPickerTrigger',
    'locationInput',
    'rangePickerInput',
    'submit',
    'wrapper',
    'instantReservationCheckboxWrapper',
]);

export const SearchEngineDesktop = helpers.defineMultiStyleConfig({
    baseStyle: ({ theme }) => {
        const gutterWidth = theme.space.gutterWidth;

        return {
            wrapper: {
                gridTemplateColumns: `${SEARCH_ENGINE.DESKTOP_FIRST_COLUMN_WIDTH} auto 3fr auto 3fr auto`,
                justifyContent: 'space-between',
                bg: 'white',
                boxShadow: 'search-bar-shadow',
                borderRadius: 'xl',
                borderBottomLeftRadius: 0,
                gap: 'gutterWidth',
                px: 'gutterWidth',
            },
            guestPickerTrigger: {
                borderRadius: 0,
                w: '100%',
                pr: 0,
                pl: 2,
                border: 0,
                justifyContent: 'flex-start',
                fontSize: 'md',
                _hover: {
                    bg: 'none',
                },
                h: '4.875rem',
            },
            rangePickerTrigger: {
                border: 'none',
                borderRadius: 0,
                h: '4.875rem',
            },
            locationInput: {
                border: 'none',
                borderRadius: 0,
                _focus: {
                    boxShadow: 'none',
                },
                h: '4.875rem',
            },
            divider: {
                bgColor: 'gray.400',
                w: '1px',
                my: 'gutterWidth',
            },
            submit: {
                my: 'auto',
            },
            instantReservationCheckboxWrapper: {
                w: `calc(${SEARCH_ENGINE.DESKTOP_FIRST_COLUMN_WIDTH} + 2 * ${gutterWidth})`,
            },
        };
    },
});
