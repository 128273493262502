export const INPUT_EXCLUDED_KEYS = [
    'Escape',
    'Control',
    'Alt',
    'AltGraph',
    'ArrowUp',
    'ArrowLeft',
    'ArrowDown',
    'ArrowRight',
    'End',
    'Home',
    'PageDown',
    'PageUp',
    'CapsLock',
    'Enter',
];
