import React, { forwardRef } from 'react'

import { RangePickerInput } from './range-picker-input';
import { RangePickerTriggerProps } from './range-picker-trigger.props';


export const RangePickerTrigger = forwardRef<HTMLInputElement, any>(
    ({
        startDate,
        endDate,
        inputProps,
        onClick,
        isLoading,
        withDrawer,
        children,
    }: RangePickerTriggerProps, inputRef) => {
        const drawerTriggerProps = {
            ref: withDrawer ? inputRef : undefined,
            onClick,
        };

        const customTriggerElement = children
            ? React.cloneElement(children, {
                    ...drawerTriggerProps,
                    isLoading,
                })
            : undefined;

        if (customTriggerElement) {
            return customTriggerElement;
        }

        if (withDrawer) {
            return (
                <RangePickerInput
                    displayedValue={{ startDate, endDate }}
                    {...drawerTriggerProps}
                    {...{
                        ...inputProps,
                        inputProps: {
                            ...inputProps?.inputProps,
                            pointerEvents: 'none',
                        },
                    }}
                    isDrawerPending={isLoading}
                />
            );
        }

        return (
            <RangePickerInput
                displayedValue={{ startDate, endDate }}
                onClick={onClick}
                isDrawerPending={isLoading}
                {...inputProps}
            />
        );
    }
);