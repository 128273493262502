import { urlJoin } from 'url-join-ts';

import { ApiResponseModel } from '@nocowanie/gql-core';
import { CoreSkeletonService } from '@nocowanie/core';

import { environment } from '@app/environments/environment';
import { withOTLPSpan } from '@app/instrumentation/instrumentation.helpers';

import accommodationsQuery from './accommodation.query.graphql';

import { apiTypes } from '../models';

export class AccommodationService extends CoreSkeletonService<string, apiTypes.Accommodation> {
    private constructor() {
        super({
            gqlOptions: {
                apiUrl: urlJoin(environment.ACCOMMODATION_API_ENDPOINT, 'api', 'graphql'),
            },
        });
    }

    private static _instance?: AccommodationService;

    public static get instance(): AccommodationService {
        if (!this._instance) {
            this._instance = new AccommodationService();
        }

        return this._instance;
    }

    public async loadEntity(
        args: apiTypes.QueryAccommodationArgs,
        headers?: Record<string, string>,
    ): Promise<ApiResponseModel<apiTypes.Accommodation>> {
        return await withOTLPSpan('GQL - fetch accommodation data', span =>
            this.executeQuery<apiTypes.Accommodation>(
                'accommodation',
                accommodationsQuery,
                args,
                headers,
            ).finally(() => span.end()),
        );
    }
}
