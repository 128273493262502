import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
    'container',
    'image',
    'contentWrapper',
    'heading',
    'cheapestRateAndRattingWrapper',
    'settlementNameContainer',
    'cheapestRateWrapper',
    'discountBadge',
    'discountBadgeWrapper',
    'link',
    'badge',
]);

export const AccommodationItem = helpers.defineMultiStyleConfig({
    baseStyle: {
        container: {
            flexDirection: 'column',
            gap: 4,
            borderRadius: 'md',
            position: 'relative',
        },
        image: {
            width: '100%',
            height: '195px',
            rounded: 'xl',
            objectFit: 'cover',
        },
        contentWrapper: {
            flexDirection: 'column',
            height: 'full',
            gap: 1,
        },
        accommodationInfoWrapper: {
            minH: '65px',
            gap: 2,
        },
        heading: {
            fontSize: 'md',
            noOfLines: 2,
        },
        settlementNameContainer: {
            fontSize: 'xs',
            alignItems: 'center',
            gap: 2,
        },
        cheapestRateAndRattingWrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 'md',
            marginTop: 'auto',
        },
        cheapestRateWrapper: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginLeft: 'auto',
        },
        discountBadge: {
            colorScheme: 'brand',
        },
        discountBadgeWrapper: {
            minH: '30px',
            marginTop: '-34px',
        },
        link: {
            _before: {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            },
        },
    },
});
