import { IconProps, NocIconGenerator } from '../common';

export const PlayCircle = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'PlayCircle',
            path: (
                <>
                    <path
                        fill="#000"
                        fillRule="evenodd"
                        d="M21.97 12c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10ZM8.74 10.56v3.36c0 2.08 1.47 2.93 3.27 1.89l1.45-.84 1.45-.84c1.8-1.04 1.8-2.74 0-3.78l-1.45-.84-1.45-.84c-1.8-1.04-3.27-.19-3.27 1.89Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill="#000"
                        d="m12.01 15.81.25.433-.25-.433Zm2.9-1.68-.25-.433.25.433Zm0-3.78-.25.433.25-.433Zm-2.9-1.68.25-.433-.25.433Zm-.04 13.83c5.799 0 10.5-4.701 10.5-10.5h-1a9.5 9.5 0 0 1-9.5 9.5v1ZM1.47 12c0 5.799 4.701 10.5 10.5 10.5v-1a9.5 9.5 0 0 1-9.5-9.5h-1Zm10.5-10.5C6.171 1.5 1.47 6.201 1.47 12h1a9.5 9.5 0 0 1 9.5-9.5v-1ZM22.47 12c0-5.799-4.701-10.5-10.5-10.5v1a9.5 9.5 0 0 1 9.5 9.5h1Zm-13.23.23v-1.67h-1v1.67h1Zm0 1.69v-1.69h-1v1.69h1Zm2.52 1.457c-.808.467-1.426.447-1.81.225-.384-.222-.71-.748-.71-1.682h-1c0 1.146.41 2.085 1.21 2.548.8.463 1.818.348 2.81-.225l-.5-.866Zm1.45-.84-1.45.84.5.866 1.45-.84-.5-.866Zm1.45-.84-1.45.84.5.866 1.45-.84-.5-.866Zm0-2.914c.807.466 1.1 1.012 1.1 1.457 0 .445-.293.99-1.1 1.457l.5.866c.993-.574 1.6-1.398 1.6-2.323s-.607-1.75-1.6-2.323l-.5.866Zm-1.45-.84 1.45.84.5-.866-1.45-.84-.5.866Zm-1.45-.84 1.45.84.5-.866-1.45-.84-.5.866ZM9.24 10.56c0-.933.326-1.46.71-1.682.384-.222 1.002-.242 1.81.225l.5-.866c-.992-.573-2.01-.688-2.81-.225-.8.463-1.21 1.401-1.21 2.548h1Z"
                    />
                </>
            ),
        }}
    />
);
