import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, UseFormGetValues, UseFormReset } from 'react-hook-form';

import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/hooks';
import { useTheme } from '@chakra-ui/system';

import { format } from 'date-fns/format';
import { urlJoinP } from 'url-join-ts';

import { urlMap } from '@nocowanie/core';

import { SearchEngineModel } from '../../../models/search-engine.model';

interface UseSearchEngineActions {
    getValues: UseFormGetValues<SearchEngineModel>;
    reset: UseFormReset<SearchEngineModel>;
    onSearchEngineSubmit?: ({
        queryParams,
        searchEngineData,
    }: {
        queryParams: string;
        searchEngineData: SearchEngineModel;
    }) => void;
    baseUrl?: string;
    defaultBaseUrl?: string;
    mobileDrawerDisclosure?: UseDisclosureReturn;
    addFragment?: () => void;
}

export const useSearchEngineActions = ({
    getValues,
    reset,
    addFragment,
    mobileDrawerDisclosure,
    onSearchEngineSubmit,
    baseUrl = urlMap.search,
    defaultBaseUrl = urlMap.search,
}: UseSearchEngineActions) => {
    const theme = useTheme();
    const defaultMobileDisclosure = useDisclosure();
    const { isOpen, onOpen, onClose } = mobileDrawerDisclosure ?? defaultMobileDisclosure;

    const onSubmit: SubmitHandler<SearchEngineModel> = useCallback(
        async data => {
            const { location, dates, guests, instantReservation } = data;
            const transitionDuration = parseInt(theme.transition.duration.normal);
            const queryParams = new URLSearchParams({
                ...(baseUrl === defaultBaseUrl
                    ? {
                          command: 'search_location',
                      }
                    : undefined),
                q: location,
                'data[od]': dates.checkInDate
                    ? format(new Date(dates.checkInDate), 'yyyy-MM-dd')
                    : '',
                'data[do]': dates.checkOutDate
                    ? format(new Date(dates.checkOutDate), 'yyyy-MM-dd')
                    : '',
                miejsca_dorosli: guests.adultsCount.toString(),
                miejsca_dzieci: guests.childrenCount ? guests.childrenCount.toString() : '0',
                wiek_dzieci: guests.childrenAges ? guests.childrenAges.join(',') : '',
                miejsca: (guests.adultsCount + guests.childrenCount).toString(),
                rezerwacja_online: instantReservation ? '1' : '0',
            }).toString();

            onSearchEngineSubmit?.({
                queryParams: queryParams,
                searchEngineData: data,
            });

            onClose();

            setTimeout(
                () => {
                    window.location.href = urlJoinP(window.location.origin + baseUrl, [
                        `?${queryParams}`,
                    ]);

                    reset(getValues());
                },
                isNaN(transitionDuration) ? 200 : transitionDuration,
            ); // we retrieve default value of transition from chakra
        },
        [
            baseUrl,
            defaultBaseUrl,
            getValues,
            onClose,
            onSearchEngineSubmit,
            reset,
            theme.transition.duration.normal,
        ],
    );

    const [isMobileDrawerPending, setIsMobileDrawerPending] = useState<boolean>(false);

    const handleDrawerOpen = useCallback(() => {
        setIsMobileDrawerPending(true);
        requestAnimationFrame(() => {
            addFragment?.();
            onOpen();
        });
    }, [addFragment, onOpen]);

    useEffect(() => {
        if (isOpen) {
            setIsMobileDrawerPending(false);
        }
    }, [isOpen]);

    return {
        handleDrawerOpen,
        isMobileDrawerPending,
        isOpen,
        onClose,
        onOpen,
        onSubmit,
    };
};
