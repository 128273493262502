export * from './add.icon';
export * from './arrow-down2.icon';
export * from './arrow-left.icon';
export * from './arrow-right.icon';
export * from './arrow-up.icon';
export * from './arrow-up2.icon';
export * from './bag-cross.icon';
export * from './building3.icon';
export * from './calendar.icon';
export * from './calendar-remove.icon';
export * from './calendar-tick.icon';
export * from './call.icon';
export * from './call2.icon';
export * from './clock.icon';
export * from './close-circle.icon';
export * from './coffee.icon';
export * from './double-bed.icon';
export * from './emoji-happy.icon';
export * from './empty-wallet.icon';
export * from './flash.icon';
export * from './image.icon';
export * from './info-circle.icon';
export * from './kids.icon';
export * from './lifebuoy.icon';
export * from './like.icon';
export * from './like-1.icon';
export * from './location.icon';
export * from './mail.icon';
export * from './medal.icon';
export * from './menu.icon';
export * from './minus.icon';
export * from './people.icon';
export * from './play-circle.icon';
export * from './profile-2user.icon';
export * from './rocket.icon';
export * from './room-size.icon';
export * from './search.icon';
export * from './send.icon';
export * from './single-bed.icon';
export * from './sms.icon';
export * from './sms-tracking.icon';
export * from './tick.icon';
export * from './tick-circle.icon';
export * from './timer1.icon';
export * from './user.icon';
export * from './user-tick.icon';
