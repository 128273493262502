export interface AutocompleteTranslationModel {
    accommodationCount: {
        singular: string;
        plural: string;
        genitivePlural: string;
    };
    noResults: string;
    placeholder: string;
}

export const defaultAutocompleteTranslation: Required<AutocompleteTranslationModel> = {
    accommodationCount: {
        singular: 'obiekt',
        plural: 'obiekty',
        genitivePlural: 'obiektów',
    },
    noResults: 'Nie udało się nic znaleźć',
    placeholder: 'Wpisz miasto lub region',
};
