import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import isEqual from 'lodash/isEqual';

import { assignDefaultValuesIfNeeded } from './search-engine-container.defaults';
import { SearchFormDataType } from './search-engine-container.props';

import { SearchEngineModel, UseAutocompleteDataReturnModel } from '../../../models';
import { AutocompleteBaseItemModel } from '../../molecules/autocomplete/autocomplete-base-item.model';

interface UseSearchEngineDataProps<T> {
    autocompleteData: UseAutocompleteDataReturnModel<T>;
    initialFormData?: SearchFormDataType;
    onSearchEngineDataChanged?: (data: SearchEngineModel) => void;
    isWebComponentVersion?: boolean;
}

export const useSearchEngineData = <T extends AutocompleteBaseItemModel>({
    autocompleteData,
    initialFormData,
    onSearchEngineDataChanged,
    isWebComponentVersion = false,
}: UseSearchEngineDataProps<T>) => {
    const initialFormDataWithDefaults = useMemo(
        () => assignDefaultValuesIfNeeded(initialFormData || {}),
        [initialFormData],
    );
    const [formData, setFormData] = useState({
        ...initialFormDataWithDefaults,
    });

    const {
        formState: { isSubmitted },
        getValues,
        handleSubmit,
        setValue,
        register,
        reset,
        watch,
    } = useForm<SearchEngineModel>({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {
            ...formData,
        },
        resetOptions: {
            keepDirtyValues: false,
        },
    });

    const onInputValueUpdated = useCallback(() => {
        const values = getValues();
        if (isEqual(values, formData)) {
            return;
        }

        onSearchEngineDataChanged?.({
            dates: getValues('dates'),
            guests: getValues('guests'),
            instantReservation: getValues('instantReservation'),
            location: getValues('location'),
        });
    }, [getValues, formData, onSearchEngineDataChanged]);

    const onAutocompleteChange = useCallback(
        (value: string) => {
            const oldValue = getValues('location');
            if (oldValue === value) {
                return;
            }

            setValue('location', value);
            onInputValueUpdated();
        },
        [getValues, onInputValueUpdated, setValue],
    );

    const seAutocompleteData = {
        ...autocompleteData,
        onChange: onAutocompleteChange,
        onBlur: useCallback(() => {
            if (formData.location !== getValues('location')) {
                onInputValueUpdated();
            }
        }, [formData.location, getValues, onInputValueUpdated]),
        initialQuery: initialFormData?.location,
    };

    useEffect(() => {
        if (isEqual(formData, initialFormDataWithDefaults)) {
            return;
        }

        const { dates, guests, instantReservation, location } = initialFormDataWithDefaults;
        setValue('dates', dates);
        setValue('guests', guests);
        setValue('instantReservation', instantReservation);
        setValue('location', location);

        setFormData({
            ...initialFormDataWithDefaults,
        });
    }, [formData, initialFormDataWithDefaults, setValue]);

    const seCommonProps = {
        setValue,
        getValues,
        onInputValueUpdated,
    };

    return {
        formData,
        getValues,
        handleSubmit,
        reset,
        isSubmitted,
        onInputValueUpdated,
        register,
        seAutocompleteData,
        seDesktopProps: {
            ...seCommonProps,
            autocompleteData: seAutocompleteData,
            register,
            isSubmitted,
            isWebComponentVersion,
        },
        seMobileProps: {
            ...seCommonProps,
            watch,
        },
        setFormData,
        watch,
    };
};
