import { Box } from '@chakra-ui/react';

import { StickyBarDirection, StickyBarProps } from './sticky-bar.props';

export const StickyBar = ({
    children,
    direction = StickyBarDirection['bottom'],
    withShadow = true,
    ...rest
}: StickyBarProps): JSX.Element => {
    const { top, bottom, sx, ...props } = rest;

    return (
        <Box
            px={{
                base: 3,
                md: 6,
            }}
            py={{
                base: 3,
                md: 6,
            }}
            boxShadow={withShadow ? 'eq-md' : undefined}
            bgColor={'white'}
            zIndex={'sticky'}
            position={'sticky'}
            top={direction === StickyBarDirection['top'] ? top ?? 0 : 'auto'}
            bottom={direction === StickyBarDirection['bottom'] ? bottom ?? 0 : 'auto'}
            sx={{
                ...sx,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};
