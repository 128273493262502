import { Middleware } from '@reduxjs/toolkit';

import { AppModalsEnum } from '@app/enums';
import {
    resetModalDataAction,
    setModalDataAction,
} from '@app/state/slices/common-ui-state.actions';

const modalPriority: AppModalsEnum[] = [
    AppModalsEnum.ApartmentsList,
    AppModalsEnum.ApartmentDetails,
    AppModalsEnum.CancellationDetails,
];

function addFragment(modalName: AppModalsEnum, modalData?: any) {
    if (typeof window === 'undefined') return;

    const currentHash = window.location.hash.slice(1);
    const modals = currentHash ? currentHash.split(',') : [];

    let fragmentString = modalName;

    const apartmentId = modalData?.apartmentId;
    const ratePlanId = modalData?.variant?.ratePlan?.id;

    if (apartmentId && ratePlanId) {
        fragmentString = `${modalName}:${apartmentId}:${ratePlanId}` as AppModalsEnum;
    } else if (modalData?.id) {
        fragmentString = `${modalName}:${modalData?.id}` as AppModalsEnum;
    }

    const filtered = modals.filter(m => !m.startsWith(modalName));

    filtered.push(fragmentString);

    filtered.sort((a, b) => {
        const nameA = a.split(':')[0] as AppModalsEnum;
        const nameB = b.split(':')[0] as AppModalsEnum;
        const indexA = modalPriority.indexOf(nameA);
        const indexB = modalPriority.indexOf(nameB);
        return indexA - indexB;
    });

    const newHash = filtered.length > 0 ? `#${filtered.join(',')}` : '';

    if (window.location.hash !== newHash) {
        const Router = require('next/router').default;

        Router.push(`${window.location.pathname}${window.location.search}${newHash}`, undefined, {
            shallow: true,
            scroll: false,
        });
        // save to session to controll if modal was opened programmatically
        sessionStorage.setItem(modalName, 'true');
    }
}

function removeFragment(modalName: string) {
    if (typeof window === 'undefined') return;

    const currentHash = window.location.hash.slice(1);
    const modals = currentHash ? currentHash.split(',') : [];

    if (!modals.some(m => m.startsWith(modalName))) return;

    const criteriaSessionStorageKey = 'criteriaChanged';
    const Router = require('next/router').default;
    const baseUrl = `${window.location.pathname}${window.location.search}`;
    const remaining = modals.filter(m => !m.startsWith(modalName));
    const newUrl = remaining.length > 0 ? `${baseUrl}#${remaining.join(',')}` : baseUrl;

    const safeReplace = () => {
        Router.replace(newUrl, undefined, { shallow: true, scroll: false }).catch((err: any) => {
            if (err.message && err.message.includes('Cancel rendering route')) return;
            console.error(err);
        });
    };

    // check if modal was opened programmatically
    if (sessionStorage.getItem(modalName)) {
        sessionStorage.removeItem(modalName);
        if (sessionStorage.getItem(criteriaSessionStorageKey)) {
            // fallback in case if query params changed
            safeReplace();
            remaining.length === 0 && sessionStorage.removeItem(criteriaSessionStorageKey);
        } else {
            Router.back();
        }
    } else {
        // fallback in case if modal was opened after direct navigation from URL
        safeReplace();
    }
}

export const modalsMiddleware: Middleware = store => next => action => {
    const result = next(action);

    if (typeof window !== 'undefined') {
        if (setModalDataAction.match(action)) {
            addFragment(action.payload.modalName, action.payload.modalData);
        }
        if (resetModalDataAction.match(action)) {
            removeFragment(action.payload);
        }
    }

    return result;
};
