import { Dispatch, SetStateAction, useState } from 'react';

import { SystemStyleObject } from '@chakra-ui/react';

export function useVisible(
    isVisible: boolean,
    visibleStyles: SystemStyleObject = {},
    invisibleStyles: SystemStyleObject = {},
): {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    visibleStyles?: SystemStyleObject;
} {
    const [isVisibleState, setIsVisibleState] = useState<boolean>(isVisible);
    const styles = {
        visible: {
            opacity: 1,
            visibility: 'visible',
            ...visibleStyles,
        },
        invisible: {
            opacity: 0,
            visibility: 'hidden',
            ...invisibleStyles,
        },
    };

    return {
        isVisible: isVisibleState,
        setIsVisible: setIsVisibleState,
        visibleStyles: styles[isVisibleState ? 'visible' : 'invisible'],
    };
}
