import { useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = debounce(() => {
            const position = window.scrollY;
            setScrollPosition(position);
        }, 250);

        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll, { passive: true });

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return scrollPosition;
};
