import { Flex, Link, Text, VStack } from '@chakra-ui/react';

import { IconsLinear } from '@nocowanie/icons';

import { InfoBoxProps } from './info-box.props';

const InfoBox = ({ items }: InfoBoxProps): JSX.Element => {
    return (
        <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            justifyContent={'space-between'}
            gap={8}
            mt={5}
            p={{ base: '4', sm: '6' }}
            bg={'secondary.50'}
            borderRadius="2xl"
        >
            {items.map(({ uuid, phoneNumber, openingDays, openingHours }) => (
                <VStack key={uuid} spacing={4} align={'flex-start'} flexBasis={{ lg: '50%' }}>
                    <Flex flexDirection={'column'} gap={4} fontSize={{ base: 'sm', sm: 'md' }}>
                        <Flex gap={3}>
                            <IconsLinear.Call size={'sm'} />
                            <Flex flexDirection={'column'}>
                                <Link
                                    href={`tel:${phoneNumber.replace(/ /g, '')}`}
                                    fontWeight="bold"
                                    lineHeight={1}
                                >
                                    {phoneNumber}
                                </Link>
                                <Text color={'gray.700'} fontSize={{ base: 'xs', sm: 'sm' }}>
                                    {openingDays}: {openingHours}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </VStack>
            ))}
        </Flex>
    );
};

export { InfoBox };
