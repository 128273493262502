import { IconButton } from '@chakra-ui/button';
import { Box, Divider, Flex } from '@chakra-ui/layout';

import { isEqual } from 'lodash';

import { IconsLinear } from '@nocowanie/icons';

import { SearchEngineDesktopProps } from './search-engine-desktop.props';

import { GuestsPicker, RangePicker, SearchLocation } from '../../../components';
import { defaultSearchEngineTranslation, GuestsPickerInputDataModel } from '../../../models';
import { AutocompleteBaseItemModel } from '../../molecules/autocomplete/autocomplete-base-item.model';

export const SearchEngineDesktop = <T extends AutocompleteBaseItemModel>({
    setValue,
    getValues,
    register,
    isSubmitted,
    onInputValueUpdated,
    translationData = defaultSearchEngineTranslation,
    autocompleteData,
    isWebComponentVersion,
    ...props
}: SearchEngineDesktopProps<T>) => {
    const dividerStyles = {
        orientation: 'vertical',
        borderColor: 'inherit',
        opacity: 1,
        h: 'auto',
        w: 'auto',
        alignSelf: 'stretch',
        my: 2,
        mx: 0,
    } as const;

    return (
        <Flex
            className="search-engine-container"
            borderWidth={'1px'}
            borderStyle={'solid'}
            borderColor={'gray.200'}
            borderRadius={'md'}
            {...props}
        >
            <SearchLocation
                autocompleteData={autocompleteData}
                w="215px"
                inputProps={{
                    inputProps: {
                        ...register('location'),
                        border: 'none',
                        px: 1,
                        _focus: {
                            boxShadow: 'none',
                        },
                        'data-testid': 'search-engine-location-input',
                    },
                }}
            />
            <Divider {...dividerStyles} />
            <Box w="190px">
                <RangePicker
                    selectedDates={[
                        getValues('dates.checkInDate'),
                        getValues('dates.checkOutDate'),
                    ]}
                    onRangeChange={(dates: [Date, Date]) => {
                        if (
                            dates[0] !== getValues('dates.checkInDate') ||
                            dates[1] !== getValues('dates.checkOutDate')
                        ) {
                            setValue('dates.checkInDate', dates[0]);
                            setValue('dates.checkOutDate', dates[1]);

                            onInputValueUpdated();
                        }
                    }}
                    inputProps={{
                        inputProps: {
                            border: 'none',
                            cursor: 'pointer',
                            px: 0,
                        },
                        'data-testid': 'search-engine-range-picker',
                    }}
                    isMobileBrowser={false}
                    isWebComponent={isWebComponentVersion}
                />
            </Box>
            <Divider {...dividerStyles} />
            <GuestsPicker
                inputData={getValues('guests')}
                noArrow={true}
                onInputDataChanged={(data: GuestsPickerInputDataModel) => {
                    const newGuests = {
                        adultsCount: data.adultsCount || 1,
                        childrenCount: data.childrenCount || 0,
                        childrenAges: data.childrenAges
                            ? data.childrenAges.filter((age): age is number => age !== undefined)
                            : [],
                    };

                    if (!isEqual(newGuests, getValues('guests'))) {
                        setValue('guests', newGuests);
                        onInputValueUpdated();
                    }
                }}
                triggerProps={{
                    pr: 0,
                    pl: 2,
                    border: 0,
                    justifyContent: 'flex-start',
                    'data-testid': 'search-engine-guests-picker',
                }}
                isMobileBrowser={false}
            />
            <IconButton
                type="submit"
                aria-label={translationData.buttonSubmitAriaLabel}
                pl={1}
                pr={3}
                border={'none'}
                _hover={{
                    bg: 'none',
                }}
                icon={<IconsLinear.Search size="md" />}
                data-testid="search-engine-submit-button"
                isDisabled={isSubmitted}
            />
        </Flex>
    );
};
