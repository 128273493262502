/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Context_write = {
    id?: string;
    type?: Context_write.type;
    name?: string;
};
export namespace Context_write {
    export enum type {
        CITY = 'city',
        REGION = 'region',
        COUNTRY = 'country',
        VOIVODESHIP = 'voivodeship',
        CATEGORY = 'category',
    }
}

