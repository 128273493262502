import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { BehaviorSubject } from 'rxjs';

import { LoadingState } from '@nocowanie/core';

import { SliceNameEnum } from '@app/enums';
import { apiTypes, CreatePaymentStateModel } from '@app/models';
import { SummaryService } from '@app/services';

const initialState: CreatePaymentStateModel = {
    loading: LoadingState.IDLE,
};

const createPaymentFinished$: BehaviorSubject<
    Partial<apiTypes.CreateCardPaymentInitiationPayload> | undefined
> = new BehaviorSubject<Partial<apiTypes.CreateCardPaymentInitiationPayload> | undefined>(
    undefined,
);

const createPaymentThunk = createAsyncThunk(
    `${SliceNameEnum.CreatePayment}/create-payment`,
    async (input: apiTypes.CreateCardPaymentInitiationInput) => {
        return await SummaryService.instance.createPayment(input);
    },
);

const slice = createSlice({
    name: SliceNameEnum.CreatePayment,
    initialState,
    reducers: {
        clearErrors: state => {
            return {
                ...state,
                errors: undefined,
            };
        },
    },
    extraReducers: builder => {
        builder.addCase(createPaymentThunk.pending, state => {
            state.errors = undefined;
            state.loading = LoadingState.PENDING;
        });

        builder.addCase(createPaymentThunk.fulfilled, (state, { payload }) => {
            if (!payload) {
                return;
            }

            const { data, errors = [] } = payload;

            state.loading = LoadingState.IDLE;
            state.paymentData = {
                ...data?.cardPaymentInitiation,
            } as apiTypes.CardPaymentInitiation;
            state.errors = Array.from(errors);

            createPaymentFinished$.next(errors?.length ? undefined : data);
        });

        builder.addCase(createPaymentThunk.rejected, (state, action) => {
            state.errors = [action.error].flat();
            state.loading = LoadingState.IDLE;
        });
    },
});

const { actions: sliceActions } = slice;

const actions = {
    ...sliceActions,
    createPaymentThunk,
};

const selectSelfState = (state: { [SliceNameEnum.CreatePayment]: CreatePaymentStateModel }) => {
    return state[slice.name] ?? initialState;
};

const selectors = {
    selectPaymentDetails: createSelector(selectSelfState, state => state?.paymentData),
    selectStateIsLoading: createSelector(
        selectSelfState,
        state => state.loading === LoadingState.PENDING,
    ),
    selectErrors: createSelector(selectSelfState, state => state.errors),
};

const observers = {
    createPaymentFinishedObserver: createPaymentFinished$.asObservable(),
};

export { slice, actions, selectors, observers };
