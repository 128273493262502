/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Category } from '../models/Category';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CategoryService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Retrieves the collection of Category resources.
     * Retrieves the collection of Category resources.
     * @param nopCityId Category identifier
     * @param limit
     * @returns Category Category collection
     * @throws ApiError
     */
    public accommodationCategoriesForCity(
        nopCityId: string,
        limit?: number,
    ): CancelablePromise<Array<Category>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/accommodations/listing/cities/{nopCityId}/categories',
            path: {
                'nopCityId': nopCityId,
            },
            query: {
                'limit': limit,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
}
