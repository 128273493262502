export enum AdministratorData {
    SzallasGroup = 'szallasGroup',
    Nocowanie = 'nocowanie',
}
interface FormConsentsTranslationModel {
    expand: string;
    collapse: string;
    selectAll: string;
    administratorInfo: string;
    administratorInfoExpanded: string;
}

const getDefaultFormConsentsTranslation = ({
    administratorData,
}: {
    administratorData: 'szallasGroup' | 'nocowanie';
}): Required<FormConsentsTranslationModel> => {
    return {
        expand: 'rozwiń',
        collapse: 'zwiń',
        selectAll: 'Zaznacz wszystkie',
        administratorInfo:
            administratorData === AdministratorData.SzallasGroup
                ? 'Administratorem Twoich danych, które wpisujesz będzie Szallas Group Zrt. (siedziba: 3525 Miskolc, Régiposta utca 9., e-mail: privacy@szallas.hu, tel.: +36 30 344 2000, numer rejestracyjny firmy: 05-10-000701, numer podatkowy: 32362903-2-05). Twoje dane będą przetwarzane w celu obsługi rezerwacji.'
                : 'Administratorem Twoich danych, które wpisujesz będzie Nocowanie.pl sp. z o. o. z siedzibą przy ul. Nałęczowskiej 14, 20-701 Lublin.',
        administratorInfoExpanded:
            administratorData === AdministratorData.SzallasGroup
                ? 'Masz m.in. prawo do żądania dostępu do danych, wycofania zgody, sprostowania, usunięcia danych, jak również skorzystania z innych praw opisanych szczegółowo w Polityce Prywatności. <br>W przypadku wyrażenia przez Ciebie zgody na przedstawienie usług oferowanych przez Nocowanie, Administratorem Twoich danych osobowych będzie również Nocowanie.pl sp. z o.o. z siedzibą przy ul. Nałęczowskiej 14, 20-701 Lublin. Masz m.in. prawo do wycofania zgody, żądania dostępu do danych, sprostowania, usunięcia danych, jak również skorzystania z innych praw opisanych szczegółowo w Polityce Prywatności Nocowania.pl.'
                : 'Twoje dane będą przetwarzane w celu obsługi zapytania, utworzenia i obsługi konta użytkownika, a na podstawie Twojej dobrowolnej zgody, w celu przedstawienia ofert obiektów noclegowych znajdujących się na naszym portalu, usług oferowanych przez Nocowanie.pl oraz partnerów, informacji z zakresu turystyki, a także badań satysfakcji klientów. Masz m.in. prawo do żądania dostępu do danych, wycofania zgody, sprostowania, usunięcia danych, jak również skorzystania z innych praw opisanych szczegółowo w Polityce Prywatności.',
    };
};

export { type FormConsentsTranslationModel, getDefaultFormConsentsTranslation };
