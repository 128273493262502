import { sendGTMEvent } from '@next/third-parties/google';
import { useRouter } from 'next/router';

import { useCallback, useEffect, useRef } from 'react';

import { User } from '@nocowanie/noc-api';

import {
    DataLayerEmEvent,
    DataLayerEventName,
    DataLayerPageTypeEnum,
    DataLayerPageViewEvent,
    DataLayerUserTypeEnum,
} from '@nocowanie/core';

import { sha256 } from '@app/helpers';
import { commonUiStateSlice, useAppDispatch, useAppSelector } from '@app/state';
import { setIsPageDataLayerSent } from '@app/state/slices/common-ui-state.slice';

import { useUserProfile } from './useUserProfile';

export const usePageDataLayerEvents = ({
    pageType = DataLayerPageTypeEnum.Other,
}: {
    pageType: DataLayerPageTypeEnum;
}) => {
    const dispatch = useAppDispatch();
    const isPageDataLayerEventSent = useAppSelector(
        commonUiStateSlice.selectors.selectIsPageDataLayerSent,
    );
    const isPageDataLayerEventSending = useRef(false);
    const { asPath } = useRouter();
    const { user } = useUserProfile();

    const getUserType = useCallback((user: User) => {
        switch (user.type) {
            case User.type.TOURIST:
                return DataLayerUserTypeEnum.LoggedInB2C;
            case User.type.HOTELIER:
            case User.type.SUBACCOUNT:
                return user?.activeAccommodationsCount && user.activeAccommodationsCount > 0
                    ? DataLayerUserTypeEnum.LoggedInB2BActive
                    : DataLayerUserTypeEnum.LoggedInB2BInactive;
            case User.type.BOK:
            case User.type.ADMIN:
            case User.type.REDACTOR:
            case User.type.SALESMAN:
                return DataLayerUserTypeEnum.LoggedInInternal;
        }
    }, []);

    const userType = user?.type ? getUserType(user) : DataLayerUserTypeEnum.Anonymous;

    const sendPageViewEvent = useCallback(() => {
        const pageViewEventData: DataLayerPageViewEvent = {
            event: DataLayerEventName.PageView,
            pageDomain: 'nocowanie.pl',
            pagePath: asPath,
            pageType: pageType,
            userLoggedIn: user?.type ? 'yes' : 'no',
            userType: userType,
            userId: user?.id?.toString() ?? undefined,
        };

        sendGTMEvent(pageViewEventData);
    }, [asPath, pageType, user?.id, user?.type, userType]);

    const sendEmEvent = useCallback(async () => {
        const emEventData: DataLayerEmEvent = {
            event: DataLayerEventName.Em,
            em_id: user?.email ? await sha256(user.email) : undefined,
        };

        sendGTMEvent(emEventData);
    }, [user?.email]);

    useEffect(() => {
        if (isPageDataLayerEventSent || user === undefined || isPageDataLayerEventSending.current) {
            return;
        }

        isPageDataLayerEventSending.current = true;

        sendPageViewEvent();
        sendEmEvent().then(() => {
            dispatch(setIsPageDataLayerSent(true));
            isPageDataLayerEventSending.current = false;
        });
    }, [user, asPath, isPageDataLayerEventSent, sendPageViewEvent, sendEmEvent]);
};
