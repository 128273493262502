export const GUEST_PICKER = {
    CONFIG: {
        TOTAL_GUESTS: {
            MIN: 1,
            MAX: 40,
        },
        ADULTS: {
            MIN: 1,
            MAX: 40,
        },
        CHILDREN: {
            MIN: 0,
            MAX: 40,
        },
        CHILD_AGE: {
            MIN: 0,
            MAX: 17,
        },
    },
};
