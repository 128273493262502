interface RangePickerTranslationModel {
    mobileLabel: string;
    submitAction: string;
    datePlaceholder: string;
    dateFromPlaceholder: string;
    dateToPlaceholder: string;
    dateFromInputPlaceholder: string;
    dateToInputPlaceholder: string;
    days: { singular: string; plural: string; genitivePlural: string };
    errorMessages: {
        minCutOff: string;
        minStay: string;
        maxStay: string;
    };
    nights: { singular: string; plural: string; genitivePlural: string };
    stayDurationLabels: {
        minStay: string;
        maxStay: string;
    };
    tooltipClose: string;
    tooltipLabels: {
        minStay: string;
        maxStay: string;
        noArrival: string;
        noArrivalMinCutOff: string;
        noDeparture: string;
        unavailable: string;
    };
    unavailableLabels: {
        title: string;
        description: string;
        datePrefix: string;
        rangePrefix: string;
        secondRangePrefix: string;
        secondDatePrefix: string;
        pickNewDates: string;
    };
    weekDayNames: [string, string, string, string, string, string, string];
    ariaLabel: {
        nextMonth: string;
        prevMonth: string;
    };
}

const defaultRangePickerTranslation: Required<RangePickerTranslationModel> = {
    mobileLabel: 'Wybierz termin',
    submitAction: 'Wybierz',
    days: {
        singular: 'dzień',
        plural: 'dni',
        genitivePlural: 'dni',
    },
    datePlaceholder: 'Wybierz datę',
    dateFromPlaceholder: 'przyjazdu',
    dateToPlaceholder: 'wyjazdu',
    dateFromInputPlaceholder: 'Przyjazd',
    dateToInputPlaceholder: 'Wyjazd',
    errorMessages: {
        minCutOff:
            'Obiekt przyjmuje rezerwację <strong>minimum {{value}}</strong> przed przyjazdem.',
        minStay:
            'W wybranym terminie obiekt przyjmuje gości <strong>na minimum {{value}}</strong>.',
        maxStay:
            'W wybranym terminie obiekt przyjmuje gości <strong>na maksimum {{value}}</strong>.',
    },
    nights: {
        singular: 'noc',
        plural: 'noce',
        genitivePlural: 'nocy',
    },

    stayDurationLabels: {
        minStay: 'Obiekt rezerwuje pobyty <strong>na minimum {{value}}</strong>.',
        maxStay: 'Obiekt rezerwuje pobyty <strong>na maksymalnie {{value}}</strong>.',
    },

    tooltipLabels: {
        minStay: 'Pobyt musi trwać minimum {{value}}',
        maxStay: 'Pobyt może trwać maksymalnie {{value}}',
        noArrival: 'Przyjazd niemożliwy w wybranym dniu',
        noArrivalMinCutOff: 'Przyjazd niemożliwy w wybranym dniu',
        noDeparture: 'Wyjazd niemożliwy w wybranym dniu',
        unavailable: 'Obiekt nie jest dostępny w tym terminie',
    },
    tooltipClose: 'Zamknij',

    unavailableLabels: {
        title: 'Termin niedostępny.',
        description: 'obiekt nie jest dostępny.',
        datePrefix: '{{value}} ',
        rangePrefix: 'Od {{value}} do {{value2}} ',
        secondRangePrefix: 'oraz od {{value}} do {{value2}} ',
        secondDatePrefix: 'oraz {{value}} ',
        pickNewDates: 'Wybierz nową datę.',
    },

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
    // Week starts with Sunday
    weekDayNames: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb'],

    ariaLabel: {
        nextMonth: 'Następny miesiąc',
        prevMonth: 'Poprzedni miesiąc',
    },
};

export { type RangePickerTranslationModel, defaultRangePickerTranslation };
