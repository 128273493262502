import { TFunction } from 'next-i18next';

import { useMemo } from 'react';

import { formatHelpers } from '@nocowanie/core';

import { apiTypes } from '@app/models';

export const usePrepaymentLabel = (
    cancellationRules: apiTypes.Maybe<apiTypes.CancellationRules> | undefined,
    t: TFunction,
    currency: apiTypes.Maybe<apiTypes.Currency> | undefined,
) => {
    return useMemo(() => {
        if (!cancellationRules) return;

        if (cancellationRules.ratePercentage === 0) {
            return t('prepaymentRules.noPrepayment');
        }
        if (cancellationRules.ratePercentage === 100) {
            return t('prepaymentRules.fullPrepayment');
        }
        if (cancellationRules.ratePercentage && cancellationRules.prepaidRate) {
            return t('prepaymentRules.partialPrepayment', {
                value: formatHelpers.price(Math.round(cancellationRules.prepaidRate), currency),
                percent: cancellationRules.ratePercentage,
            });
        }
        return;
    }, [cancellationRules, t, currency]);
};
