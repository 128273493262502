import { memo } from 'react';

import { Box } from '@chakra-ui/layout';

import { addDays } from 'date-fns/addDays';
import { differenceInDays } from 'date-fns/differenceInDays';

import { dateHelpers } from '@nocowanie/core';

import { rangePickerHelpers, wordPluralisation } from './../../../helpers';
import { RangePickerFooterContentProps } from './range-picker-footer-content.props';

export const RangePickerFooterContent = memo(
    ({
        startDate,
        endDate,
        isInvalid = false,
        translationData,
        daysConfig,
        displayedDateFormat = 'dd MMM.',
    }: RangePickerFooterContentProps) => {
        const isOnlyStartDateSelected = startDate && !endDate;
        const startDayConfig = rangePickerHelpers.getDayConfig(startDate, daysConfig);

        if (startDate && endDate && isInvalid) {
            const unavailableDateGroup: Date[][] = [];
            const daysCount = differenceInDays(endDate, startDate);
            let isPrevDayUnavailable = false;

            let description = rangePickerHelpers.getRangeErrorMessage(
                startDate,
                endDate,
                daysConfig,
                translationData,
            );
            const withRangeConfigError = !!description.length;

            if (!withRangeConfigError) {
                for (let index = 0; index <= daysCount; index++) {
                    const day = addDays(startDate, index);
                    const dayConfig = rangePickerHelpers.getDayConfig(day, daysConfig);
                    const nextDayConfig = rangePickerHelpers.getDayConfig(
                        addDays(day, 1),
                        daysConfig,
                    );
                    const isDayTo = index === daysCount;
                    const isNextDayUnavailable = !!nextDayConfig?.full;

                    if (dayConfig?.full) {
                        if (!isNextDayUnavailable && !isPrevDayUnavailable && !isDayTo) {
                            // one-day unavailability
                            unavailableDateGroup.push([day]);
                        } else if (!isPrevDayUnavailable && isNextDayUnavailable && !isDayTo) {
                            // start date of unavailability range
                            unavailableDateGroup.push([day]);
                        } else if (isPrevDayUnavailable && (!isNextDayUnavailable || isDayTo)) {
                            // end date of unavailability range
                            // we only want to check dateTo availability for displaying correct unavailability range end
                            unavailableDateGroup[unavailableDateGroup.length - 1].push(day);
                        }
                    }

                    isPrevDayUnavailable = !!dayConfig?.full;
                }

                if ([1, 2].includes(unavailableDateGroup.length)) {
                    // We only show date ranges if there are 1 or 2 groups of unavailable dates, in other cases we show generic info
                    description =
                        unavailableDateGroup[0].length === 1
                            ? translationData.unavailableLabels.datePrefix.replace(
                                  '{{value}}',
                                  dateHelpers.format(
                                      unavailableDateGroup[0][0],
                                      displayedDateFormat,
                                  ),
                              )
                            : translationData.unavailableLabels.rangePrefix
                                  .replace(
                                      '{{value}}',
                                      dateHelpers.format(
                                          unavailableDateGroup[0][0],
                                          displayedDateFormat,
                                      ),
                                  )
                                  .replace(
                                      '{{value2}}',
                                      dateHelpers.format(
                                          unavailableDateGroup[0][1],
                                          displayedDateFormat,
                                      ),
                                  );

                    if (unavailableDateGroup.length === 2) {
                        description +=
                            unavailableDateGroup[1].length === 1
                                ? translationData.unavailableLabels.secondDatePrefix.replace(
                                      '{{value}}',
                                      dateHelpers.format(
                                          unavailableDateGroup[1][0],
                                          displayedDateFormat,
                                      ),
                                  )
                                : translationData.unavailableLabels.secondRangePrefix
                                      .replace(
                                          '{{value}}',
                                          dateHelpers.format(
                                              unavailableDateGroup[1][0],
                                              displayedDateFormat,
                                          ),
                                      )
                                      .replace(
                                          '{{value2}}',
                                          dateHelpers.format(
                                              unavailableDateGroup[1][1],
                                              displayedDateFormat,
                                          ),
                                      );
                    }
                }
            }

            const errorMessage = withRangeConfigError
                ? `${description} `
                : (description
                      ? `${description} ${translationData.unavailableLabels.description} `
                      : '') + translationData.unavailableLabels.pickNewDates;

            return (
                <>
                    <Box
                        color={'danger.500'}
                        fontWeight={'bold'}
                        fontSize={'xs'}
                        textAlign={'left'}
                    >
                        {translationData.unavailableLabels.title}
                    </Box>
                    <Box
                        fontSize={'xs'}
                        textAlign={'left'}
                        dangerouslySetInnerHTML={{
                            __html: errorMessage,
                        }}
                    />
                </>
            );
        } else if (
            isOnlyStartDateSelected &&
            (startDayConfig?.minStay || startDayConfig?.maxStay)
        ) {
            const nights = wordPluralisation(
                startDayConfig.minStay ?? (startDayConfig.maxStay as number),
                translationData.nights.singular,
                translationData.nights.plural,
                translationData.nights.genitivePlural,
            );

            return (
                <Box
                    textAlign={'left'}
                    fontSize={'xs'}
                    dangerouslySetInnerHTML={{
                        __html: translationData.stayDurationLabels[
                            `${startDayConfig.minStay ? 'minStay' : 'maxStay'}`
                        ].replace(
                            '{{value}}',
                            `${startDayConfig.minStay ?? startDayConfig.maxStay} ${nights}`,
                        ),
                    }}
                />
            );
        } else {
            return null;
        }
    },
);
