// Careful when changing this file - it's used in the middleware which has restrictions for some imports (tree-shaking)
export const getIdFromSlug = (slug: string | string[] | undefined): string | null => {
    const stringSlug = !Array.isArray(slug) ? slug : slug[0];
    const id = stringSlug?.match(/^\d+/);

    return id ? id[0].toString() : null;
};

export const getSlug = (input: string): string => {
    return input
        .toLowerCase() // Convert to lowercase
        .normalize('NFD') // Normalize to decompose accented characters
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/ł/g, 'l') // Manually replace 'ł' with 'l'. The custom replacement is necessary because normalize('NFD') doesn't handle it correctly. This step ensures proper conversion of this character.
        .trim() // Trim whitespace from both ends
        .replace(/[^a-z0-9\s-]/g, '-') // Replace non-alphanumeric characters except (spaces and hyphens)
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
        .replace(/^-|-$/g, ''); // Remove first and last hyphen
};
