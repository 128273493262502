import { FeatureToggleEnum, featureToggleHelpers } from '@nocowanie/core';

export const ftInitialState: Partial<Record<FeatureToggleEnum, unknown>> = {
    [FeatureToggleEnum.USE_NEW_CALENDAR]: true,
    [FeatureToggleEnum.USE_V7_THEME]: false,
};

type CookieObject = { [key: string]: string };

const parseCookies = (cookieString: string): CookieObject => {
    return cookieString.split(';').reduce((acc, cookie) => {
        const [key, value] = cookie.trim().split('=');
        return { ...acc, [key.trim()]: value };
    }, {});
};

// In common UI components and web components, we don't have access to the FeatureToggle state, so we have to retrieve by this helper.
export const getFeatureToggleValue = <T = unknown>(featureToggle: FeatureToggleEnum): T => {
    if (typeof window === 'undefined') {
        return false as T;
    }

    const cookies = parseCookies(document.cookie);
    return (cookies[featureToggle]
        ? featureToggleHelpers.FeatureToggleValueParserMap[featureToggle](cookies[featureToggle])
        : ftInitialState[featureToggle] ) as T;
};
