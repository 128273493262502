import { createSelector } from '@reduxjs/toolkit';

import { SliceNameEnum } from '@app/enums';
import { HomepageDataModel } from '@app/models/elastic-search';
import { homepageInitialState } from '@app/state/slices/homepage/homepage.slice';
import { HomepageState } from '@app/state/slices/homepage/homepage.types';

const selectSelfState = (state: { [SliceNameEnum.Homepage]: HomepageState }) => {
    return state[SliceNameEnum.Homepage] ?? homepageInitialState;
};

const selectStateData = createSelector(selectSelfState, (state: HomepageState) => state.data);

export const homepageSelectors = {
    selectLoading: createSelector(selectSelfState, state => state.loading),
    selectErrors: createSelector(selectSelfState, state => state.errors),
    selectIsDataLoaded: createSelector(selectStateData, state => !!state),
    selectSectionData: <T>(section: keyof HomepageDataModel) =>
        createSelector(selectStateData, state => state?.[section] as T),
};
