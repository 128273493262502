import { AspectRatio } from '@chakra-ui/react';

import { IMAGE_SIZES, ImageRatio, ImageSize } from '@nocowanie/core';

import { Image } from './../../../../components';
import { RoomImageProps } from './room-image.props';

export const RoomImage = ({
    ratio = ImageRatio['16/9'],
    imageProps,
    rounded = 'md',
    alt,
    ...rest
}: RoomImageProps) => {
    const defaultImageProps = {
        htmlWidth: IMAGE_SIZES[ImageRatio['16/9']][ImageSize.Xs].width,
        htmlHeight: IMAGE_SIZES[ImageRatio['16/9']][ImageSize.Xs].height,
    };

    const mergedImageProps = { ...defaultImageProps, ...imageProps };

    return (
        <AspectRatio
            ratio={ratio}
            maxW={{
                base: 'none',
                md: 160,
            }}
            w="100%"
            rounded={rounded}
            overflow={'hidden'}
            {...rest}
        >
            <Image
                objectFit="cover"
                loading="lazy"
                width={'100%'}
                height={'100%'}
                {...mergedImageProps}
                data-testid="room-image"
                alt={alt}
            />
        </AspectRatio>
    );
};
