export const semanticTokens = {
    colors: {
        'chakra-border-color': {
            _light: 'gray.200',
        },
    },
    shadows: {
        'search-bar-shadow': '0px 0px 32px 0px rgba(0, 0, 0, 0.15)',
    },
};
