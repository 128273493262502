import { useEffect } from 'react';

import { CommonModalsEnum } from '@nocowanie/common-ui';

// According to industry standards we don't want
// guests or date picker modals to open by default
// even if the URL contains fragment with calendar
// or guests part inside.
// The hook cleans the URL of the unnecessary fragment parts.

export const useRemoveFragmentsOnMount = () => {
    useEffect(() => {
        if (typeof window === 'undefined') return;

        const currentHash = window.location.hash.slice(1);
        if (!currentHash) return;

        const fragments = currentHash.split(',');

        const filtered = fragments.filter(
            fragment =>
                !(
                    fragment.startsWith(CommonModalsEnum.Calendar) ||
                    fragment.startsWith(CommonModalsEnum.GuestPicker)
                ),
        );

        if (filtered.length !== fragments.length) {
            window.location.hash = filtered.length > 0 ? `#${filtered.join(',')}` : '';
        }
    }, []);
};
