import { EsServiceBase } from '@app/services/abstracts/es-service.base';

import {
    AccommodationBaseModel,
    EsResponseModel,
    EsSearchArgsModel,
} from '../models/elastic-search';

export class EsAccommodationService extends EsServiceBase {
    readonly #accommodationBaseEsIndexName = 'noc-accommodation-base';
    readonly #accommodationBaseEsIndexVersion = 1;

    private static _instance: EsAccommodationService;

    protected get indexName(): string {
        return `${this.#accommodationBaseEsIndexName}-${this.#accommodationBaseEsIndexVersion}`;
    }

    public static get instance(): EsAccommodationService {
        if (!this._instance) {
            this._instance = new EsAccommodationService();
        }

        return this._instance;
    }

    public async loadAccommodationData(
        id: string | number,
    ): Promise<EsResponseModel<AccommodationBaseModel>> {
        return await this.get<AccommodationBaseModel>(this.indexName, id, '_doc', {
            _source_excludes: 'links.facilities',
        });
    }

    public async getLegacyCityIdByAccommodationId(
        id: AccommodationBaseModel['id'],
    ): Promise<string> {
        const searchArgs: EsSearchArgsModel = {
            query: {
                ids: {
                    values: [id],
                },
            },
            script_fields: {
                legacyCityId: {
                    script: {
                        lang: 'painless',
                        source: `
                            for (item in params._source.listings) {
                                if (item.type == 'legacy_city') {
                                    return item.object.legacyCityId;
                                }
                            }
                            return null;
                        `,
                    },
                },
            },
        };

        const res = await this.getMany<Partial<AccommodationBaseModel>>(this.indexName, searchArgs);

        if (!res.total.value || !res.hits[0].fields || !res.hits[0].fields.legacyCityId) {
            throw new Error('Geo ID not found');
        }

        return res.hits[0].fields.legacyCityId[0];
    }
}
