import { IconProps, NocIconGenerator } from '../common';

export const Grill = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Grill',
            path: (
                <>
                    <g
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M16.75 9c.24 0 .43.19.43.43v.02a5.23 5.23 0 0 1-5.68 4.72 5.228 5.228 0 0 1-4.72-4.72c-.01-.24.17-.44.41-.46H16.74l.01.01ZM5.99 22l4-8M17.99 22l-4-8M16.49 19h-9M15.99 2.5s.02 0 .02.01c1.3.59 1.3 1.89 0 2.48 0 0-.02 0-.03.01M15.99 7.5s-.02 0-.02-.01c-1.3-.59-1.3-1.89 0-2.48 0 0 .02 0 .03-.01M11.99 2.5s.02 0 .02.01c1.3.56 1.3 1.92 0 2.49 0 0-.02 0-.03.01" />
                        <path d="M11.99 7.5s-.02 0-.02-.01c-1.3-.56-1.3-1.92 0-2.49 0 0 .02 0 .03-.01M7.99 2.5s.02 0 .02.01c1.3.56 1.3 1.92 0 2.49 0 0-.02 0-.03.01" />
                        <path d="M7.99 7.5s-.02 0-.02-.01c-1.3-.56-1.3-1.92 0-2.49 0 0 .02 0 .03-.01" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
