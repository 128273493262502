import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['label', 'drawerHeader', 'rangePicker', 'button']);

export const SearchEngineMobileDrawer = helpers.defineMultiStyleConfig({
    baseStyle: {
        label: {
            fontSize: 'xs',
            color: 'gray.500',
        },
        drawerHeader: {
            fontSize: 'md',
            fontWeight: 'normal',
            borderBottomWidth: '1px',
        },
        rangePicker: {
            borderRadius: 'md',
        },
        button: {
            colorScheme: 'secondary',
        },
    },
});
