import { objectToCamel } from 'ts-case-convert';

import { SectionNameEnum } from '@app/enums';
import { HomepageDataESModel, HomepageDataModel } from '@app/models/elastic-search';
import { HomepagePayloadMutators } from '@app/state/slices/homepage/homepage.types';

const sectionPayloadMutator =
    <T extends SectionNameEnum>(section: T) =>
    (
        currentState: Partial<HomepageDataModel>,
        input: HomepageDataESModel[T],
    ): Partial<HomepageDataModel> => ({
        ...currentState,
        [section]: objectToCamel(input),
    });

// for each page section a dedicated mutator should be added here
const destinationSuggestionsSectionPayloadMutator = sectionPayloadMutator(
    SectionNameEnum.DestinationSuggestionsSection,
);
const featuredAccommodationsSectionPayloadMutator = sectionPayloadMutator(
    SectionNameEnum.FeaturedAccommodationsSection,
);
const featuredCountriesSectionPayloadMutator = sectionPayloadMutator(
    SectionNameEnum.FeaturedCountriesSection,
);
const heroSectionPayloadMutator = sectionPayloadMutator(SectionNameEnum.HeroSection);
const popularTravelDestinationsSectionPayloadMutator = sectionPayloadMutator(
    SectionNameEnum.PopularTravelDestinations,
);

export const homepagePayloadMutators: HomepagePayloadMutators = {
    [SectionNameEnum.DestinationSuggestionsSection]: destinationSuggestionsSectionPayloadMutator,
    [SectionNameEnum.FeaturedCountriesSection]: featuredCountriesSectionPayloadMutator,
    [SectionNameEnum.FeaturedAccommodationsSection]: featuredAccommodationsSectionPayloadMutator,
    [SectionNameEnum.HeroSection]: heroSectionPayloadMutator,
    [SectionNameEnum.PopularTravelDestinations]: popularTravelDestinationsSectionPayloadMutator,
};
