interface SearchEngineTranslationModel {
    buttonSubmitLabel: string;
    buttonSubmitAriaLabel: string;
    drawerHeader: string;
    searchLocationLabel: string;
    rangePickerLabel: string;
    guestsPickerLabel: string;
    locationPlaceholder: string;
    checkInPlaceholder: string;
    checkOutPlaceholder: string;
    instantReservationLabel: string;
    instantReservationDescription: string;
}

const defaultSearchEngineTranslation: Required<SearchEngineTranslationModel> = {
    buttonSubmitLabel: 'Wyszukaj',
    buttonSubmitAriaLabel: 'Wyszukaj',
    drawerHeader: 'Wyszukiwarka',
    searchLocationLabel: 'Gdzie podróżujesz?',
    rangePickerLabel: 'Data przyjazdu - wyjazdu',
    guestsPickerLabel: 'Liczba osób',
    locationPlaceholder: 'Wpisz miasto lub region',
    checkInPlaceholder: 'Przyjazd',
    checkOutPlaceholder: 'Wyjazd',
    instantReservationLabel: 'Pokaż dostępne obiekty z rezerwacją online',
    instantReservationDescription: 'Gwarantowana cena, szybka rezerwacja',
};

export { type SearchEngineTranslationModel, defaultSearchEngineTranslation };
