import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Box, Flex } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';
import { As, ComponentWithAs, forwardRef, useMultiStyleConfig } from '@chakra-ui/system';

import { dateHelpers } from '@nocowanie/core';
import { IconsLinear } from '@nocowanie/icons';

import { defaultRangePickerConfig, RangePickerConfig } from './range-picker.config';
import { RangePickerInputProps } from './range-picker-input.props';

import { RANGE_PICKER } from '../../../consts/range-picker.const';

export const RangePickerInput: ComponentWithAs<As, RangePickerInputProps> = forwardRef(
    (
        {
            config,
            displayedValue,
            isDrawerPending,
            onClick,
            displayedDateFormat = 'dd MMM.',
            inputProps,
            ...rest
        }: RangePickerInputProps,
        ref,
    ) => {
        const { translationData }: Required<RangePickerConfig> = {
            ...defaultRangePickerConfig,
            ...config,
        };
        const isInvalid = inputProps?.isInvalid || false;

        const { sx: inputPropsSx, ...restInputProps } = inputProps || {};
        // Input and fake label needs common styling
        const inputStyles = {
            border: inputProps?.border ?? '1px solid',
            borderRadius: inputProps?.borderRadius ?? 'md',
            backgroundColor: inputProps?.backgroundColor ?? 'white',
            color: isInvalid ? 'danger.500' : (inputProps?.backgroundColor ?? undefined),
            ...inputPropsSx,
        };

        const leftIconSize = 10;
        const startDate = displayedValue?.startDate
            ? dateHelpers.format(displayedValue?.startDate, displayedDateFormat)
            : translationData.dateFromInputPlaceholder;
        const endDate = displayedValue?.endDate
            ? dateHelpers.format(displayedValue?.endDate, displayedDateFormat)
            : translationData.dateToInputPlaceholder;
        const startDateFormatted = displayedValue?.startDate
            ? dateHelpers.format(displayedValue?.startDate, RANGE_PICKER.DATE_FORMAT)
            : '';
        const endDateFormatted = displayedValue?.endDate
            ? dateHelpers.format(displayedValue?.endDate, RANGE_PICKER.DATE_FORMAT)
            : '';

        const themeStyles = useMultiStyleConfig('RangePicker', {});

        return (
            <InputGroup
                fontSize={'md'}
                onClick={onClick}
                value={`${startDateFormatted} - ${endDateFormatted}`}
                data-testid={'range-picker-input'}
                {...rest}
            >
                <InputLeftElement
                    pointerEvents="none"
                    height={'100%'}
                    sx={themeStyles.icon}
                    children={
                        <IconsLinear.Calendar
                            fontSize={'xl'}
                            color={isInvalid ? 'danger.500' : undefined}
                            width={leftIconSize}
                        />
                    }
                />

                <Input
                    pl={leftIconSize}
                    value={`${startDate} - ${endDate}`}
                    textTransform={'capitalize'} // polish month names are lowercase - we want them capitalized
                    readOnly
                    ref={ref}
                    sx={{
                        ...inputStyles,
                        borderColor: 'inherit',
                        cursor: 'pointer',
                        _focusVisible: {}, // Focus styles override
                        _readOnly: {
                            userSelect: 'none', // Fix for iOS safari issue with not clickable parts of input (mainly text inside)
                            boxShadow: 'none',
                        },
                    }}
                    aria-label={translationData.mobileLabel}
                    {...restInputProps}
                    disabled={isDrawerPending}
                />

                {/* Fake label - covers the input */}
                <Flex
                    gap={2}
                    position={'absolute'}
                    textTransform={'capitalize'}
                    alignItems={'center'}
                    left={leftIconSize}
                    right={0}
                    top={0}
                    bottom={0}
                    pointerEvents={'none'}
                    backgroundClip={'padding-box'}
                    whiteSpace={'nowrap'}
                    aria-hidden="true"
                    sx={{
                        ...inputStyles,
                        borderLeft: 0,
                        borderColor: 'transparent',
                    }}
                >
                    {isDrawerPending ? (
                        <Skeleton height={'20px'} width={'90%'} />
                    ) : (
                        <>
                            {startDate}
                            <Box sx={themeStyles.icon}>
                                <IconsLinear.ArrowRight
                                    color={isInvalid ? 'danger.500' : undefined}
                                />
                            </Box>
                            {endDate}
                        </>
                    )}
                </Flex>
            </InputGroup>
        );
    },
);
