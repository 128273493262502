import { ReactNode, useEffect, useRef, useState } from 'react';

import {
    Button,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
} from '@chakra-ui/react';

import isNil from 'lodash/isNil';

import { IconsLinear } from '@nocowanie/icons';

import { TooltipProps } from './tooltip.props';

import { useIsMobile } from '../../../helpers';

export const Tooltip = ({
    title,
    children,
    autoCloseDelay,
    buttonProps = {},
    buttonIconProps = {},
    bodyProps = {},
    extendedTriggerArea,
    width,
    maxW,
    showTriggerIcon = true,
    ...rest
}: TooltipProps): JSX.Element => {
    const isMobileBrowser = useIsMobile();

    const [isOpen, setIsOpen] = useState(false);
    const [autoCloseTimeout, setAutoCloseTimeout] = useState<ReturnType<typeof setTimeout> | null>(
        null,
    );
    const popoverRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleTouchMove = (event: TouchEvent) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            window.addEventListener('touchmove', handleTouchMove);
        }

        return () => {
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [isOpen]);

    const tooltipTrigger = (
        <Button variant={'link-invisible'} alignItems={'center'} ml={2} {...buttonProps}>
            <IconsLinear.InfoCircle {...buttonIconProps} />
        </Button>
    );

    const onClose = () => {
        setIsOpen(false);

        if (autoCloseDelay && !isNil(autoCloseTimeout)) {
            clearTimeout(autoCloseTimeout);
            setAutoCloseTimeout(null);
        }
    };

    const onOpen = () => {
        setIsOpen(true);

        if (autoCloseDelay) {
            if (!isNil(autoCloseTimeout)) {
                clearTimeout(autoCloseTimeout);
            }

            const timeout = setTimeout(() => {
                onClose();
            }, autoCloseDelay);
            setAutoCloseTimeout(timeout);
        }
    };

    return (
        <Popover
            trigger={isMobileBrowser ? 'click' : 'hover'}
            isLazy={true}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            {...rest}
        >
            <PopoverTrigger>
                {extendedTriggerArea ? (
                    <HStack {...extendedTriggerArea.wrapperProps}>
                        {extendedTriggerArea.additionalTrigger}
                        {showTriggerIcon && tooltipTrigger}
                    </HStack>
                ) : (
                    tooltipTrigger
                )}
            </PopoverTrigger>
            <Portal>
                <PopoverContent
                    sx={{
                        '--popper-bg': 'var(--chakra-colors-gray-900)',
                        color: 'white',
                        borderColor: 'transparent',
                    }}
                    pr={isMobileBrowser ? 5 : undefined}
                    width={width}
                    maxW={maxW}
                    ref={popoverRef}
                >
                    {title && (
                        <PopoverHeader fontWeight={700} borderBottom={0} fontSize={'sm'} pb={0}>
                            {title}
                        </PopoverHeader>
                    )}
                    <PopoverArrow />
                    {isMobileBrowser && <PopoverCloseButton />}
                    <PopoverBody fontSize={'sm'} {...bodyProps}>
                        {children as ReactNode}
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};
