import { useCallback, useEffect, useState } from 'react';

import { useDisclosure } from '@chakra-ui/hooks';

import { isEqual } from 'lodash';

import { dateHelpers, LocalStorageKey, urlMap } from '@nocowanie/core';
import {
    assignDefaultValuesIfNeeded,
    SearchEngineContainer,
    SearchEngineModel,
} from '@nocowanie/common-ui';

import { environment } from '@app/environments/environment';
import { accommodationHelpers } from '@app/helpers';
import { useCrossStorage } from '@app/hooks';
import { useSearchEngineDataLayerEvents } from '@app/hooks/useSearchEngineDataLayerEvents';
import { QueryAccommodationArgs } from '@app/models/api/api-types';
import { accommodationSlice, commonUiStateSlice, useAppDispatch, useAppSelector } from '@app/state';

import { SearchEngineWrapperProps } from './search-engine-wrapper.props';

import { useAutocompleteData } from '../autocomplete-wrapper/use-autocomplete-data.hook';

export const SearchEngineWrapper = ({
    isMobileCompactVersion = true,
    isV7SearchEngineVersion,
    defaultBaseUrl = urlMap.search,
    initialSearchData,
    ...rest
}: SearchEngineWrapperProps) => {
    const mobileDrawerDisclosure = useDisclosure();
    const dispatch = useAppDispatch();
    const filters = useAppSelector(accommodationSlice.selectors.selectFilters);
    const accommodation = useAppSelector(accommodationSlice.selectors.selectCurrentAccommodation);
    const [accommodationLocation, setAccommodationLocation] = useState(
        accommodation?.settlement?.name || '',
    );
    const [location, setLocation] = useState(accommodationLocation);
    const [accommodationId, setAccommodationId] = useState(accommodation?.id);
    const [instantReservation, setinstantReservation] = useState(false);
    const [isInitialSearchFormDataSet, setIsInitialSearchFormDataSet] = useState(false);
    const [initialSearchFormData, setInitialSearchFormData] = useState({
        ...assignDefaultValuesIfNeeded({
            ...initialSearchData,
        }),
    });
    const isMobileBrowser = useAppSelector(commonUiStateSlice.selectors.selectIsMobileVersion);
    const [baseUrl, setBaseUrl] = useState<string | null>(null);

    const autocompleteData = useAutocompleteData({
        setBaseUrl,
        onAccommodationSelected: useCallback(() => {
            mobileDrawerDisclosure.onClose();
        }, [mobileDrawerDisclosure]),
        initialQuery: location,
    });

    const { sendSearchEngineDataLayerEvent } = useSearchEngineDataLayerEvents();

    useEffect(() => {
        if (
            !accommodation?.settlement?.name ||
            (accommodationLocation === accommodation?.settlement?.name &&
                accommodationId === accommodation?.id)
        ) {
            return;
        }

        setAccommodationLocation(accommodation?.settlement?.name);
        setAccommodationId(accommodation?.id);
        setIsInitialSearchFormDataSet(false);
    }, [
        accommodation?.id,
        accommodation?.settlement?.name,
        accommodationId,
        accommodationLocation,
    ]);

    useEffect(() => {
        if (accommodationLocation && !isInitialSearchFormDataSet) {
            setTimeout(() => {
                setIsInitialSearchFormDataSet(true);
                setLocation(accommodationLocation);
                autocompleteData.setQuery(accommodationLocation);
            }, 0);
        }
    }, [accommodationLocation, autocompleteData, isInitialSearchFormDataSet]);

    useEffect(() => {
        const newFormData = {
            location: location || initialSearchFormData.location || '',
            dates: {
                checkInDate: filters?.checkIn
                    ? new Date(filters.checkIn)
                    : initialSearchFormData.dates.checkInDate,
                checkOutDate: filters?.checkOut
                    ? new Date(filters.checkOut)
                    : initialSearchFormData.dates.checkOutDate,
            },
            guests: {
                adultsCount:
                    typeof filters?.guestsCount === 'number'
                        ? filters.guestsCount
                        : initialSearchFormData.guests.adultsCount,
                childrenCount:
                    typeof filters?.childrenCount === 'number'
                        ? filters.childrenCount
                        : initialSearchFormData.guests.childrenCount,
                childrenAges: filters?.childrenAges
                    ? filters.childrenAges.filter((age): age is number => age !== null)
                    : initialSearchFormData.guests.childrenAges,
            },
            instantReservation:
                instantReservation || initialSearchFormData.instantReservation || false,
        };

        if (!isEqual(newFormData, initialSearchFormData)) {
            setInitialSearchFormData({ ...newFormData });
        }
    }, [filters, initialSearchFormData, instantReservation, isInitialSearchFormDataSet, location]);

    const onSearchEngineDataChanged = (data: SearchEngineModel) => {
        if (isEqual(initialSearchFormData, data)) {
            return;
        }

        const currenFilters = {
            checkIn: initialSearchFormData.dates.checkInDate,
            checkOut: initialSearchFormData.dates.checkOutDate,
            guestsCount: initialSearchFormData.guests.adultsCount,
            childrenCount: initialSearchFormData.guests.childrenCount,
            childrenAges: initialSearchFormData.guests.childrenAges,
        };

        const { dates, guests } = data;
        const newFilters = {
            checkIn: dates.checkInDate,
            checkOut: dates.checkOutDate,
            guestsCount: guests.adultsCount,
            childrenCount: guests.childrenCount,
            childrenAges: guests.childrenAges,
        };

        if (location !== data.location) {
            setLocation(data.location);
        }

        if (
            data.instantReservation !== undefined &&
            instantReservation !== data.instantReservation
        ) {
            setinstantReservation(data.instantReservation);
        }

        if (!isEqual(currenFilters, newFilters)) {
            dispatch(
                accommodationSlice.actions.updateFilters({
                    checkIn: dates.checkInDate
                        ? dateHelpers.format(dates.checkInDate, environment.API_DATE_FORMAT)
                        : null,
                    checkOut: dates.checkOutDate
                        ? dateHelpers.format(dates.checkOutDate, environment.API_DATE_FORMAT)
                        : null,
                    guestsCount: guests.adultsCount,
                    childrenCount: guests.childrenCount,
                    childrenAges: guests.childrenAges,
                }),
            );
        }
    };

    const crossStorageClient = useCrossStorage();
    const saveFiltersToCrossStorage = useCallback(
        (params: Partial<QueryAccommodationArgs>) => {
            if (!crossStorageClient) {
                return;
            }
            const mappedParams = accommodationHelpers.generateLegacySearchCriteriaParams(params);
            crossStorageClient
                .setItem(LocalStorageKey.SearchCriteria, mappedParams)
                .catch(error => {
                    console.warn(error);
                });
        },
        [crossStorageClient],
    );

    const onSearchEngineSubmit = useCallback(
        ({ searchEngineData }: { searchEngineData: SearchEngineModel }) => {
            sendSearchEngineDataLayerEvent(searchEngineData);
            saveFiltersToCrossStorage({
                checkIn: searchEngineData.dates.checkInDate
                    ? dateHelpers.format(
                          searchEngineData.dates.checkInDate,
                          environment.API_DATE_FORMAT,
                      )
                    : null,
                checkOut: searchEngineData.dates.checkOutDate
                    ? dateHelpers.format(
                          searchEngineData.dates.checkOutDate,
                          environment.API_DATE_FORMAT,
                      )
                    : null,
                guestsCount: searchEngineData.guests.adultsCount,
                childrenCount: searchEngineData.guests.childrenCount,
                childrenAges: searchEngineData.guests.childrenAges,
            });
        },
        [saveFiltersToCrossStorage, sendSearchEngineDataLayerEvent],
    );

    return (
        <SearchEngineContainer
            initialFormData={initialSearchFormData}
            onSearchEngineDataChanged={onSearchEngineDataChanged}
            onSearchEngineSubmit={onSearchEngineSubmit}
            isV7SearchEngineVersion={isV7SearchEngineVersion}
            isMobileCompactVersion={isMobileCompactVersion}
            isMobileBrowser={isMobileBrowser}
            autocompleteData={autocompleteData}
            baseUrl={baseUrl || defaultBaseUrl}
            mobileDrawerDisclosure={mobileDrawerDisclosure}
            {...rest}
        />
    );
};
