import { IconButton } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';

import { urlMap } from '@nocowanie/core';
import { IconsLinear } from '@nocowanie/icons';

import { SearchEngineContainerProps } from './search-engine-container.props';
import { useSearchEngineActions } from './use-search-engine-actions.hook';
import { useSearchEngineData } from './use-search-engine-data.hook';

import { CommonModalsEnum } from '../../../enums';
import { useFragmentActions, useHasMounted, useModalSync } from '../../../hooks';
import { defaultSearchEngineTranslation } from '../../../models';
import { AutocompleteBaseItemModel } from '../../molecules/autocomplete/autocomplete-base-item.model';
import { SearchEngineDesktop } from '../search-engine-desktop';
import { SearchEngineDesktopV7 } from '../search-engine-desktop-v7';
import { SearchEngineMobile } from '../search-engine-mobile';
import { SearchEngineMobileDrawer } from '../search-engine-mobile-drawer';

export const searchEngineIsMobileSsrBreakpoint = '1250px';
const legacyBreakpoint = '1500px';

export const SearchEngineContainer = <T extends AutocompleteBaseItemModel>({
    autocompleteData,
    isWebComponentVersion = false,
    initialFormData = {},
    onSearchEngineDataChanged,
    onSearchEngineSubmit,
    mobileDrawerDisclosure,
    isV7SearchEngineVersion,
    isMobileCompactVersion = true,
    isMobileBrowser = false,
    baseUrl = urlMap.search,
    defaultBaseUrl = urlMap.search,
    ...rest
}: SearchEngineContainerProps<T>) => {
    const {
        seDesktopProps,
        seMobileProps,
        watch,
        getValues,
        handleSubmit,
        register,
        reset,
        isSubmitted,
        seAutocompleteData,
    } = useSearchEngineData({
        isWebComponentVersion,
        initialFormData,
        autocompleteData,
        onSearchEngineDataChanged,
    });

    const { addFragment, removeFragment } = useFragmentActions(CommonModalsEnum.SearchEngine);

    const { onSubmit, isOpen, onClose, onOpen, isMobileDrawerPending, handleDrawerOpen } =
        useSearchEngineActions({
            getValues,
            reset,
            baseUrl,
            defaultBaseUrl,
            mobileDrawerDisclosure,
            onSearchEngineSubmit,
            addFragment,
        });

    const [isMobile] = useMediaQuery(
        `(max-width: ${
            isWebComponentVersion ? legacyBreakpoint : searchEngineIsMobileSsrBreakpoint
        })`,
        {
            ssr: true,
            fallback: isMobileBrowser,
        },
    );

    useModalSync({
        modalName: CommonModalsEnum.SearchEngine,
        onOpen,
        onClose,
        isOpen,
    });

    const hasMounted = useHasMounted();

    // condition required only for webcomponent version - where desktop variant is initially rendered, which causes layout issues
    if (!hasMounted && isWebComponentVersion) {
        return null;
    }

    const searchEngineDesktopContent = isV7SearchEngineVersion ? (
        <SearchEngineDesktopV7 {...seDesktopProps} watch={watch} />
    ) : (
        <SearchEngineDesktop {...seDesktopProps} />
    );

    return (
        <Box {...rest}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isMobile ? (
                    <>
                        {isMobileCompactVersion ? (
                            <IconButton
                                onClick={handleDrawerOpen}
                                aria-label={defaultSearchEngineTranslation.buttonSubmitAriaLabel}
                                border={'none'}
                                icon={<IconsLinear.Search size="md" />}
                                isLoading={isMobileDrawerPending}
                                data-testid="search-engine-open-button"
                            />
                        ) : (
                            <SearchEngineMobile
                                handleDrawerOpen={handleDrawerOpen}
                                isDrawerPending={isMobileDrawerPending}
                                {...seMobileProps}
                            />
                        )}
                        <SearchEngineMobileDrawer
                            onSubmit={handleSubmit(onSubmit)}
                            register={register}
                            isSubmitted={isSubmitted}
                            isOpen={isOpen}
                            onClose={() => {
                                onClose();
                                removeFragment();
                            }}
                            isWebComponentVersion={isWebComponentVersion}
                            isV7SearchEngineVersion={isV7SearchEngineVersion}
                            autocompleteData={seAutocompleteData}
                            {...seMobileProps}
                        />
                    </>
                ) : (
                    searchEngineDesktopContent
                )}
            </form>
        </Box>
    );
};
