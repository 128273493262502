import { IconProps, NocIconGenerator } from '../common';

export const Youtube = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Youtube',
            path: (
                <>
                    <title>Youtube logo</title>
                    <g clipPath="url(#a)">
                        <path
                            fill="#fff"
                            d="M23.986 9.991a26.213 26.213 0 0 0-.113-1.828 16.616 16.616 0 0 0-.288-1.976 3.115 3.115 0 0 0-.931-1.647 2.854 2.854 0 0 0-1.668-.777C19.005 3.54 16.01 3.428 12 3.428s-7.004.112-8.987.335a2.824 2.824 0 0 0-1.66.777 3.132 3.132 0 0 0-.925 1.647c-.133.58-.234 1.24-.3 1.976a26.28 26.28 0 0 0-.115 1.828C.004 10.473 0 11.143 0 12s.004 1.527.014 2.009c.008.482.046 1.091.113 1.828.067.737.163 1.395.288 1.976.143.651.453 1.2.931 1.647a2.856 2.856 0 0 0 1.667.776c1.983.224 4.978.335 8.987.335 4.01 0 7.004-.111 8.986-.335a2.823 2.823 0 0 0 1.661-.776c.474-.447.782-.996.924-1.648.134-.58.235-1.238.302-1.975.067-.736.104-1.346.113-1.828.01-.482.014-1.152.014-2.01 0-.856-.005-1.526-.014-2.008Zm-7.245 2.732L9.884 17.01a.764.764 0 0 1-.455.134.927.927 0 0 1-.415-.108.797.797 0 0 1-.442-.75v-8.57c0-.34.147-.59.442-.75.303-.161.593-.152.87.026l6.857 4.286c.268.151.402.393.402.723 0 .33-.134.572-.402.723Z"
                        />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
