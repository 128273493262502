import { useCallback, useRef } from 'react';

export const useThrottledCallback = <T extends unknown[]>(
    callback: (...args: T) => void,
    limit: number,
) => {
    const lastCallRef = useRef(0);

    return useCallback(
        (...args: T) => {
            const now = Date.now();
            if (now - lastCallRef.current >= limit) {
                lastCallRef.current = now;
                callback(...args);
            }
        },
        [callback, limit],
    );
};
