import { IconProps, NocIconGenerator } from '../common';

export const Instagram = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Instagram',
            path: (
                <>
                    <title>Instagram logo</title>
                    <g clipPath="url(#a)">
                        <path
                            fill="#fff"
                            d="M21.099.627H2.9A2.281 2.281 0 0 0 .627 2.902v18.196a2.281 2.281 0 0 0 2.274 2.275h18.197a2.281 2.281 0 0 0 2.275-2.275V2.902A2.28 2.28 0 0 0 21.099.627ZM12 7.451A4.563 4.563 0 0 1 16.55 12 4.562 4.562 0 0 1 12 16.55 4.563 4.563 0 0 1 7.45 12 4.563 4.563 0 0 1 12 7.45ZM3.47 21.098c-.34 0-.569-.227-.569-.568v-9.667H5.29c-.113.34-.113.796-.113 1.137 0 3.753 3.07 6.824 6.824 6.824 3.753 0 6.823-3.071 6.823-6.824 0-.34 0-.796-.114-1.137h2.389v9.667c0 .341-.228.568-.569.568H3.47ZM21.1 5.745c0 .34-.228.568-.57.568h-2.274c-.341 0-.569-.227-.569-.568V3.47c0-.341.227-.568.57-.568h2.274c.34 0 .569.227.569.568v2.275Z"
                        />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
