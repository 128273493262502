import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['links']);

export const FeaturedCountryItem = helpers.defineMultiStyleConfig({
    baseStyle: {
        links: {
            zIndex: 2,
            flexWrap: 'wrap',
            alignItems: 'center',
            mt: 3,
            columnGap: 3,
            rowGap: 2,
            fontSize: 'sm',
            maxWidth: '272px',
        },
    },
});
