import { EsServiceBase } from '@app/services/abstracts/es-service.base';

import { AccommodationBaseModel, BestsellerModel, EsResponseModel } from '../models/elastic-search';

export class EsAccommodationBestsellerService extends EsServiceBase {
    readonly #BestsellerEsIndexName = 'noc-bestseller-ota-objects';

    private static _instance: EsAccommodationBestsellerService;

    public static get instance(): EsAccommodationBestsellerService {
        if (!this._instance) {
            this._instance = new EsAccommodationBestsellerService();
        }

        return this._instance;
    }

    public async loadBestseller(
        id: AccommodationBaseModel['id'],
    ): Promise<EsResponseModel<BestsellerModel>> {
        return await this.get(this.#BestsellerEsIndexName, id, '_doc', {});
    }
}
