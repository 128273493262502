import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['icon', 'button']);

export const AccordionMenuItem = helpers.defineMultiStyleConfig({
    baseStyle: {
        icon: {
            fontSize: '2xl',
        },
        button: {
            py: '0',
            pl: '7',
            justifyContent: 'space-between',
            color: 'secondary.500',
            borderLeft: '0.25rem solid',
            borderLeftColor: 'transparent',
            _hover: { bg: 'white' },
            _expanded: {
                bg: 'blue.50',
                borderLeftColor: 'blue.300',
                fontWeight: 'bold',
            },
        },
    },
});
