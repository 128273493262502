/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Breadcrumbs_RunCommandInputBreadcrumbs } from '../models/Breadcrumbs_RunCommandInputBreadcrumbs';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class BreadcrumbsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Run breadcrumbs migration command to es
     * Executes a console command asynchronously.
     * @param requestBody The new Breadcrumbs resource
     * @returns void
     * @throws ApiError
     */
    public apiBreadcrumbsrunCommandEsMigrationPost(
        requestBody: Breadcrumbs_RunCommandInputBreadcrumbs,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/breadcrumbs/run-command-es-migration',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
}
