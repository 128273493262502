import { IconProps, NocIconGenerator } from '../common';

export const RoomSize = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'RoomSize',
            path: (
                <>
                    <path
                        d="M6.30657 14.7951C6.1874 14.7951 6.0774 14.7493 5.98574 14.6576L2.8049 11.3026C2.6399 11.1285 2.6399 10.8626 2.8049 10.6976L5.98574 7.34262C6.15074 7.16845 6.4349 7.15928 6.60907 7.32428C6.78324 7.48928 6.7924 7.77345 6.6274 7.94762L3.73074 11.0001L6.6274 14.0526C6.7924 14.2268 6.78324 14.511 6.60907 14.676C6.52657 14.7585 6.41657 14.7951 6.30657 14.7951Z"
                        fill="black"
                    />
                    <path
                        d="M15.7118 14.7951C15.6018 14.7951 15.4918 14.7585 15.4093 14.676C15.2351 14.511 15.2259 14.2268 15.3909 14.0526L18.2876 11.0001L15.3909 7.94762C15.2259 7.77345 15.2351 7.48928 15.4093 7.32428C15.5834 7.15928 15.8676 7.16845 16.0326 7.34262L19.2134 10.6976C19.3784 10.8718 19.3784 11.1376 19.2134 11.3026L16.0326 14.6576C15.9501 14.7493 15.8309 14.7951 15.7118 14.7951Z"
                        fill="black"
                    />
                    <path
                        d="M18.8925 11.4401H3.10748C2.85998 11.4401 2.66748 11.2476 2.66748 11.0001C2.66748 10.7526 2.85998 10.5601 3.10748 10.5601H18.8925C19.14 10.5601 19.3325 10.7526 19.3325 11.0001C19.3325 11.2476 19.14 11.4401 18.8925 11.4401Z"
                        fill="black"
                    />
                    <path
                        d="M0.44 22C0.1925 22 0 21.8075 0 21.56V0.44C0 0.1925 0.1925 0 0.44 0C0.6875 0 0.88 0.1925 0.88 0.44V21.56C0.88 21.8075 0.6875 22 0.44 22Z"
                        fill="black"
                    />
                    <path
                        d="M21.5601 22C21.3126 22 21.1201 21.8075 21.1201 21.56V0.44C21.1201 0.1925 21.3126 0 21.5601 0C21.8076 0 22.0001 0.1925 22.0001 0.44V21.56C22.0001 21.8075 21.8076 22 21.5601 22Z"
                        fill="black"
                    />
                </>
            ),
        }}
    />
);
