import { forwardRef, useMemo } from 'react';

import { Box, ListItem } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

import escapeRegExp from 'lodash/escapeRegExp';

import { IconsLinear } from '@nocowanie/icons';

import { AutocompleteBaseItemProps } from './autocomplete-item.props';

export const AutocompleteBaseItem = forwardRef<HTMLLIElement, any>(
    (
        {
            caption,
            children,
            query,
            name,
            url,
            LeftIcon = IconsLinear.Location,
            isHighlighted = false,
            translationData,
            ...rest
        }: AutocompleteBaseItemProps,
        ref,
    ): JSX.Element => {
        const styles = useMultiStyleConfig('AutocompleteItem', {
            isHighlighted,
        });

        const text = useMemo(() => {
            if (!query) {
                return name;
            }

            return name.replace(
                new RegExp(escapeRegExp(query), 'ig'),
                part => `<strong>${part}</strong>`,
            );
        }, [name, query]);

        const iconProps = {
            size: 'md',
            color: styles.icon.color,
        };

        return (
            <ListItem
                ref={ref}
                sx={styles.wrapper}
                data-testid={'autocomplete-item'}
                className={'wc-autocomplete-item'}
                {...rest}
            >
                <LeftIcon {...iconProps} />
                <Box>
                    <Box
                        sx={styles.label}
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                    <Box fontSize={'xs'}>{caption}</Box>
                </Box>
                <Box ml={'auto'} fontSize={'sm'} flex={'0 0 auto'}>
                    {children}
                </Box>
            </ListItem>
        );
    },
);

AutocompleteBaseItem.displayName = 'AutocompleteBaseItem';
