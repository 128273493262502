import { IconProps, NocIconGenerator } from '../common';

export const Wifi = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Wifi',
            path: (
                <>
                    <g
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M9 19c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3ZM16.5 14c-2.49-2.67-6.51-2.67-9 0M19.5 11c-4.14-5.33-10.86-5.33-15 0" />
                        <path d="M22 7.5C16.37.83 7.63.83 2 7.5" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
