import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['button']);

export const Navigation = helpers.defineMultiStyleConfig({
    baseStyle: {
        button: {
            mr: '2',
            fontWeight: 'semibold',
        },
    },
});
