import {
    addDays,
    addMonths,
    differenceInDays,
    endOfMonth,
    format as dateFnsFormat,
    isValid,
    Locale as DateFnsLocale,
    min,
} from 'date-fns';
import { enGB, pl } from 'date-fns/locale';

import { Locale } from '../models';

declare global {
    interface Window {
        __localeId__: Locale;
    }
}

export const defaultLocale = Locale.Pl;
export const dateFnsLocales: Record<string, DateFnsLocale> = { enGB, pl };

export const daysDateDiff = (date1: Date, date2: Date): number =>
    Math.abs(differenceInDays(date1, date2));

/**
 * Returns default checkIn date (3 days from today)
 */
export const defaultCheckInDate = (): Date => addDays(new Date().setHours(0, 0, 0, 0), 3);

/**
 * Returns default checkOut date -  if checkIn is defined - next day or if checkIn is not defined 7 days from today
 */
export const defaultCheckOutDate = (checkIn?: Date | string): Date =>
    checkIn ? addDays(new Date(checkIn), 1) : addDays(new Date().setHours(0, 0, 0, 0), 7);

export const isValidRange = (
    checkIn: any,
    checkOut: any,
    minimalDayDiff = 1,
    maxDayDiff = 31,
): boolean => {
    if (!isValid(checkIn) || !isValid(checkOut)) {
        return false;
    }

    const today = new Date();
    const isCheckInValid = differenceInDays(checkIn, today) >= 0;
    const dayDiff = differenceInDays(checkOut, checkIn);
    const isDaysDiffValid = dayDiff >= minimalDayDiff && dayDiff <= maxDayDiff;
    return isDaysDiffValid && isCheckInValid;
};

export const format = (date: Date, formatStr = 'PP'): string => {
    return dateFnsFormat(date, formatStr, {
        locale: dateFnsLocales[getGlobalLocale()],
    });
};

/**
 * Set global Locale
 */
export const setGlobalLocale = (locale = Locale.Pl): void => {
    if (typeof window !== 'undefined') {
        // Client-side-only code
        window.__localeId__ = locale;
    }
};

/**
 * Get global Locale
 */
export const getGlobalLocale = (): Locale => {
    if (typeof window !== 'undefined') {
        return window.__localeId__ ?? defaultLocale;
    }
    return Locale.Pl;
};

export const getMinRangeDate = (startDate: Date | null, endDate: Date | null, minStay = 1) => {
    if (startDate && !endDate) {
        return addDays(startDate, minStay);
    }

    return new Date();
};

export const getMaxRangeDate = (startDate: Date | null, endDate: Date | null, maxStay?: number) => {
    maxStay = maxStay ?? 31;
    const today = new Date();
    const maxDate = endOfMonth(addMonths(today, 11));

    if (startDate && !endDate) {
        const maxRangeDate = addDays(startDate, maxStay);
        return min([maxRangeDate, maxDate]);
    }

    return maxDate;
};
