import { RANGE_PICKER } from '../../../consts';
import { defaultRangePickerTranslation, RangePickerTranslationModel } from '../../../models';

interface RangePickerConfig {
    translationData: RangePickerTranslationModel;
    defaultRules: {
        minCutOff: number;
        minStay: number;
        maxStay: number;
    };
}

const defaultRangePickerConfig: RangePickerConfig = {
    translationData: defaultRangePickerTranslation,
    defaultRules: {
        minCutOff: RANGE_PICKER.RULES.MIN_CUT_OFF,
        minStay: RANGE_PICKER.RULES.MIN_STAY,
        maxStay: RANGE_PICKER.RULES.MAX_STAY,
    },
};

export { type RangePickerConfig, defaultRangePickerConfig };
