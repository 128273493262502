import { urlJoin } from 'url-join-ts';

import { isBrowser } from 'next-runtime-env/build/utils/is-browser';

import { env } from 'next-runtime-env';

/**
 * Get runtime environment as string or undefined if not exist
 * @param clientSideKey key value available on client side (should start with NEXT_PUBLIC_)
 * @param serverSideKey key value available on server side
 * @return string | undefined
 */
export const getEnvStringValue = (
    clientSideKey: string,
    serverSideKey: string = clientSideKey,
): string => (isBrowser() ? env(clientSideKey) : env(serverSideKey)) ?? '';

/**
 * Get runtime environment as T or undefined if not exist
 * @param clientSideKey key value available on client side (should start with NEXT_PUBLIC_)
 * @param serverSideKey key value available on server side
 * @return T | undefined
 */
export const getEnv = <T>(
    clientSideKey: string,
    serverSideKey: string = clientSideKey,
): T | undefined => {
    const val = getEnvStringValue(clientSideKey, serverSideKey);

    if (val === null || val === undefined) {
        return undefined;
    }

    return val.trim().length > 0 ? (JSON.parse(val) as T) : undefined;
};

/**
 * Get absolute url if url path is relative. If Given url is absolute return original value
 * @param url URL Value
 */
export const getAbsoluteUrl = (url: string): string => {
    if (!isBrowser() || !url?.trim()?.length) {
        return url;
    }

    try {
        const absoluteURL = new URL(url);

        return absoluteURL.toString();
    } catch (_) {
        return urlJoin(`${window.location.protocol}//${window.location.hostname}`, url);
    }
};
