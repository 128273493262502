import { IconProps, NocIconGenerator } from '../common';

export const Kitchen = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Kitchen',
            path: (
                <>
                    <g clipPath="url(#a)">
                        <path
                            fill="#000001"
                            d="M7.03 19.5c-.26 0-.47.22-.47.5s.21.5.47.5.47-.22.47-.5-.21-.5-.47-.5Z"
                        />
                        <path
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M22 18.5H2v3h20v-3Z"
                        />
                        <path
                            stroke="#000001"
                            strokeLinecap="round"
                            strokeMiterlimit={10}
                            d="M21 11H5v5.19c0 .45.45.81 1.01.81h8.76c.56 0 1.01-.36 1.01-.81V11"
                        />
                        <path
                            fill="#000001"
                            d="M4.97 19.5c-.26 0-.47.22-.47.5s.21.5.47.5.47-.22.47-.5-.21-.5-.47-.5Z"
                        />
                        <path
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.36 3c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M6.36 8.84c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47M13.63 3c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M13.63 8.84c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47M9.96 3.16c-.47.4-.77.91-.86 1.47.09.55.4 1.07.86 1.47M9.96 9c.47-.4.77-.91.86-1.47-.09-.55-.4-1.07-.86-1.47"
                        />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
