import { createIcon } from '@chakra-ui/icons';
import { Box, useMultiStyleConfig } from '@chakra-ui/react';

import { IconDefaultProps } from './icon.default.props';
import { IconGeneratorProps } from './icon.props';

export const NocIconGenerator = ({
    createIconProps,
    isRound = false,
    withBorder = false,
    variant = 'default',
    size = 'sm',
    duoToneColor = 'current',
    accentFillColor,
    boxProps,
    ...props
}: IconGeneratorProps): JSX.Element => {
    const { icon: iconStyles, box: boxStyles } = useMultiStyleConfig('Icon', { variant, size });
    const GeneratedIcon = createIcon({
        ...createIconProps,
        defaultProps: {
            ...IconDefaultProps,
            ...createIconProps?.defaultProps,
        },
    });

    const strokeWidth = 1.75;

    const icon = (
        <GeneratedIcon
            __css={iconStyles}
            sx={{
                path: {
                    vectorEffect: 'non-scaling-stroke',
                    strokeWidth: `var(--icon-stroke, ${strokeWidth})`,
                },
                '[opacity]': {
                    // Hacky override of second color
                    opacity: 1,
                    color: duoToneColor,
                },
                '[stroke]': {
                    stroke: 'current',
                },
                '[fill]': {
                    fill: 'current',
                },
                '[data-fill="true"]': {
                    fill: accentFillColor,
                },
            }}
            {...props}
        />
    );

    return isRound || withBorder ? (
        <Box
            sx={{
                ...boxStyles,
                p: withBorder ? 2 : boxStyles?.p,
                border: withBorder ? '1px solid' : undefined,
                borderRadius: isRound ? boxStyles?.borderRadius : undefined,
                ...boxProps,
            }}
        >
            {icon}
        </Box>
    ) : (
        icon
    );
};
