import { urlJoin } from 'url-join-ts';

import { EsResponseManyModel } from '@app/models/elastic-search';
import { RatingModel } from '@app/models/elastic-search/rating.model';
import { CalendarAvailabilityModel } from '@app/models/nop-api/calendar-availability.model';
import { ApiServiceBase } from '@app/services/abstracts/api-service.base';

export class ApiAccommodationService extends ApiServiceBase {
    private static _instance: ApiAccommodationService;

    public static get instance(): ApiAccommodationService {
        if (!this._instance) {
            this._instance = new ApiAccommodationService();
        }

        return this._instance;
    }

    public async loadAccommodationRatings(
        id: string | number,
        offset = 0,
        limit = 2,
    ): Promise<EsResponseManyModel<RatingModel>> {
        return await this.query<EsResponseManyModel<RatingModel>>(
            urlJoin('accommodation', 'ratings'),
            {
                id,
                limit,
                offset,
            },
        );
    }

    public async getAccommodationAvailability(accommodationId: string | number): Promise<any> {
        return await this.query<CalendarAvailabilityModel>(
            urlJoin('accommodation', 'availability-calendar'),
            {
                accommodationId,
            },
        );
    }
}
