import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { LoadingState } from '@nocowanie/core';

import { SectionNameEnum } from '@app/enums';
import { homepagePayloadMutators } from '@app/state/slices/homepage/homepage.payload-mutators';
import { getSectionData } from '@app/state/slices/homepage/homepage.thunks';
import { HomepageState } from '@app/state/slices/homepage/homepage.types';

export const registerHomepageReducers = (builder: ActionReducerMapBuilder<HomepageState>): void => {
    builder.addCase(getSectionData.pending, state => {
        state.loading = LoadingState.PENDING;
    });

    builder.addCase(getSectionData.rejected, (state, { payload, error }) => {
        const { errors, section } = payload as { errors: Array<unknown>; section: SectionNameEnum };
        state.errors = [error, errors].flat();
        state.loading = LoadingState.IDLE;
        state.data = {
            ...(state.data ?? {}),
            ...homepagePayloadMutators[section](state.data ?? {}, null),
        } as HomepageState['data'];
    });

    builder.addCase(getSectionData.fulfilled, (state, { payload }) => {
        state.errors = null;
        state.loading = LoadingState.IDLE;

        const { section, data } = payload;

        state.data = {
            ...(state.data ?? {}),
            ...homepagePayloadMutators[section](state.data ?? {}, data),
        } as HomepageState['data'];
    });
};
