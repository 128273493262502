import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/layout';

import { addMonths } from 'date-fns/addMonths';
import { startOfMonth } from 'date-fns/startOfMonth';

import { dateHelpers, FeatureToggleEnum } from '@nocowanie/core';

import { defaultRangePickerConfig, RangePickerConfig } from './range-picker.config';
import { RangePickerHeaderProps } from './range-picker-header.props';

import { getFeatureToggleValue } from '../../../helpers';

export const RangePickerHeader = ({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    isMobile,
}: RangePickerHeaderProps) => {
    const FT_USE_NEW_CALENDAR = getFeatureToggleValue<boolean>(FeatureToggleEnum.USE_NEW_CALENDAR);
    const {
        translationData: { ariaLabel },
    }: Required<RangePickerConfig> = { ...defaultRangePickerConfig };
    const currentMonth = new Date();
    const minMonth = startOfMonth(addMonths(currentMonth, 1));
    const maxMonth = startOfMonth(addMonths(currentMonth, 10));

    const isPrevDisabled = monthDate < minMonth;
    const isNextDisabled = monthDate >= maxMonth;
    const monthName = dateHelpers.format(monthDate, 'LLLL yyyy');

    const prevBtnDisplay = customHeaderCount === 1 || isPrevDisabled ? 'none' : 'flex';
    const nextBtnDisplay =
        (isMobile && !FT_USE_NEW_CALENDAR ? customHeaderCount === 1 : customHeaderCount === 0) ||
        isNextDisabled
            ? 'none'
            : 'flex';

    return (
        <Box
            className="react-datepicker__header"
            p={isMobile && FT_USE_NEW_CALENDAR ? 0 : '8px 0'}
            textAlign={isMobile && FT_USE_NEW_CALENDAR ? 'left' : 'center'}
        >
            {!isMobile || (isMobile && !FT_USE_NEW_CALENDAR) ? (
                <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                    style={{
                        display: prevBtnDisplay,
                        top: '4px',
                    }}
                    onClick={() => {
                        requestAnimationFrame(() => {
                            decreaseMonth();
                        });
                    }}
                    aria-label={ariaLabel.prevMonth}
                >
                    <ChevronLeftIcon fontSize={'2xl'} />
                </button>
            ) : null}
            <Text as={'span'} className="react-datepicker__current-month">
                {monthName}
            </Text>
            {!isMobile || (isMobile && !FT_USE_NEW_CALENDAR) ? (
                <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--next"
                    style={{
                        display: nextBtnDisplay,
                        top: '2px',
                    }}
                    onClick={() => {
                        requestAnimationFrame(() => {
                            increaseMonth();
                        });
                    }}
                    aria-label={ariaLabel.nextMonth}
                >
                    <ChevronRightIcon fontSize={'2xl'} />
                </button>
            ) : null}
        </Box>
    );
};
