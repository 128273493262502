export const Menu = {
    parts: ['button', 'list', 'groupTitle'],
    baseStyle: {
        button: {
            color: 'secondary.500',
            borderColor: 'transparent',
            px: 4,
            py: 3,
            _hover: {
                bg: 'transparent',
            },
            _active: {
                bg: 'transparent',
            },
            _focusVisible: {
                outline: '2px solid black',
            },
        },
        list: {
            borderRadius: '0',
        },
        groupTitle: {
            fontSize: 'md',
            pl: 1,
            py: 1,
            color: 'black',
        },
        item: {
            color: 'black',
        },
    },
};
