import { ReactNode } from 'react';

import { AutocompleteAccommodationItem } from './autocomplete-accommodation-item';
import { AutocompleteItemProps, AutocompleteItemType } from './autocomplete-item.props';
import { AutocompleteLocationItem } from './autocomplete-location-item';

const autocompleteItemComponentsMap: Record<
    AutocompleteItemType,
    (data: AutocompleteItemProps) => ReactNode
> = {
    accommodation: (data: AutocompleteItemProps) => <AutocompleteAccommodationItem {...data} />,
    city: (data: AutocompleteItemProps) => <AutocompleteLocationItem {...data} />,
    country: (data: AutocompleteItemProps) => <AutocompleteLocationItem {...data} />,
    region: (data: AutocompleteItemProps) => <AutocompleteLocationItem {...data} />,
    voivodeship: (data: AutocompleteItemProps) => <AutocompleteLocationItem {...data} />,
};

export const autocompleteItemFactory = (type: AutocompleteItemType, props: AutocompleteItemProps) =>
    autocompleteItemComponentsMap[type](props);
