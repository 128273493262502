import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['button']);

export const MobileNumberInput = helpers.defineMultiStyleConfig({
    baseStyle: {
        button: {
            h: '8',
            colorScheme: 'brand',
        },
    },
});
