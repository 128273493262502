export const Icon = {
    parts: ['box', 'icon'],
    baseStyle: {
        box: {
            display: 'inline-flex',
            p: '1.125em',
        },
    },
    sizes: {
        xxs: {
            box: {
                p: '0.5em',
            },
            icon: {
                fontSize: '0.75rem',
                '--icon-stroke': 1.25,
            },
        },
        xs: {
            icon: {
                fontSize: 'md',
                '--icon-stroke': 1.25,
            },
        },
        sm: {
            icon: {
                fontSize: 'lg',
                '--icon-stroke': 1.5,
            },
        },
        md: {
            icon: {
                fontSize: '2xl',
                '--icon-stroke': 1.75,
            },
        },
        lg: {
            icon: {
                fontSize: '3xl',
                '--icon-stroke': 1.75,
            },
        },
        xl: {
            icon: {
                fontSize: '4xl',
                '--icon-stroke': 1.75,
            },
        },
        '2xl': {
            icon: {
                fontSize: '6xl',
                '--icon-stroke': 3.5,
            },
        },
        '4xl': {
            icon: {
                fontSize: '8xl',
                '--icon-stroke': 4.5,
            },
        },
        '8xl': {
            icon: {
                fontSize: '9xl',
                '--icon-stroke': 1.75,
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
};
