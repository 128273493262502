import { useRouter } from 'next/router';

import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { UseComboboxSelectedItemChange } from 'downshift';
import { objectToCamel } from 'ts-case-convert';

import { GeographyTypeEnum, useDebouncedCallback } from '@nocowanie/common-ui';

import { AutocompleteItemModel, AutocompleteItemSCModel } from '@app/models/elastic-search';
import { ApiAutocompleteService } from '@app/services';

export interface UseAutocompleteDataProps {
    itemCount?: number;
    setBaseUrl?: Dispatch<SetStateAction<string | null>>;
    onAccommodationSelected?: () => void;
    initialQuery?: string;
}

export const useAutocompleteData = ({
    setBaseUrl,
    itemCount = 5,
    initialQuery,
    onAccommodationSelected,
}: UseAutocompleteDataProps = {}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [innerQuery, setInnerQuery] = useState(initialQuery || '');
    const [items, setItems] = useState<AutocompleteItemModel[] | null>(null);
    const router = useRouter();

    const onQueryChange = useDebouncedCallback(async (query: string) => {
        setBaseUrl?.(null);

        if (!query.trim().length) {
            return;
        }

        setIsLoading(true);

        try {
            const result = await ApiAutocompleteService.instance.getAutocompleteItems(
                query,
                itemCount,
            );

            const items =
                result?.hits?.map(({ _source }: { _source: AutocompleteItemSCModel }) =>
                    objectToCamel(_source),
                ) || [];

            setItems(items);
        } catch (error) {
            throw new Error(`[useAutocomplete] Request failed: ${error}`);
        } finally {
            setIsLoading(false);
        }
    }, 300);

    const onSelectedItemChange = useCallback(
        (item: UseComboboxSelectedItemChange<AutocompleteItemModel>) => {
            const { path, type } = item.selectedItem;
            const isAccommodation = type === GeographyTypeEnum.accommodation;

            setBaseUrl?.(isAccommodation ? null : path);

            if (isAccommodation) {
                onAccommodationSelected?.();

                requestAnimationFrame(() => {
                    router.push(path);
                });
            }
        },
        [onAccommodationSelected, router, setBaseUrl],
    );

    return {
        initialQuery,
        query: innerQuery,
        setQuery: setInnerQuery,
        items,
        isLoading,
        onQueryChange,
        onSelectedItemChange,
    };
};
