import { StyleFunctionProps, theme as chakraTheme } from '@chakra-ui/react';

import { menuLinkStyle } from './Link';

export const Button = {
    baseStyle: {
        fontWeight: 600,
        borderRadius: '0.75rem',
    },

    defaultProps: {
        colorScheme: 'secondary',
        variant: 'outline',
        size: 'md',
    },

    sizes: {
        xs: {
            fontSize: 'sm',
            py: '0.25rem',
            px: '0.75rem',
            borderRadius: '0.625rem',
            h: '1.875rem',
        },
        sm: {
            fontSize: 'sm',
            py: '0.625rem',
            px: '1.5rem',
            borderRadius: '0.625rem',
            h: '2.5rem',
        },
        md: {
            fontSize: 'md',
            py: '0.75rem',
            px: '1.5rem',
            h: '2.875rem',
        },
        lg: {
            fontSize: 'md',
            py: '0.875rem',
            px: '1.5rem',
            h: '3.25rem',
        },
    },

    variants: {
        expandableMenuItem: {
            ...menuLinkStyle,
            fontWeight: 'normal',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 4,
            h: 'max-content',
            borderRadius: 'none',
            color: 'black',
            bg: 'white',
            _hover: {
                ...menuLinkStyle._hover,
                bg: 'sandYellow.50',
            },
            _focus: {
                bg: 'white',
            },
        },
        filterItem: (props: StyleFunctionProps) => {
            const buttonStyles = chakraTheme?.components?.Button?.variants?.outline(props);

            return {
                ...buttonStyles,
                borderRadius: 'xl',
                color: 'gray.600',
                bg: 'white',
                _hover: {
                    bg: 'gray.50',
                },
            };
        },
        filterItemActive: (props: StyleFunctionProps) => {
            const buttonStyles = chakraTheme?.components?.Button?.variants?.outline(props);

            return {
                ...buttonStyles,
                borderRadius: 'xl',
                color: 'white',
                bg: 'tertiary.900',
                _hover: {
                    bg: 'tertiary.900',
                },
                _active: {
                    bg: 'tertiary.900',
                },
            };
        },
    },
};
