import { addDays } from 'date-fns/addDays';
import { differenceInDays } from 'date-fns/differenceInDays';
import { isEqual as isEqualDate } from 'date-fns/isEqual';
import { startOfDay } from 'date-fns/startOfDay';
import { startOfToday } from 'date-fns/startOfToday';
import { isEmpty, isNil } from 'lodash';

import { dateHelpers } from '@nocowanie/core';

import { RangePickerConfig, RangePickerProps } from './../components';
import { RANGE_PICKER } from './../consts';
import { wordPluralisation } from './../helpers';
import { DayConfigModel } from './../models';

/**
 * Get excluded dates
 * @param excludedDateStringArray array of date-strings in 'yyyy-MM-dd' format
 * @param daysConfig days config
 * @param startDate
 * @param endDate
 */
export const getExcludedDates = (
    excludedDateStringArray: string[],
    daysConfig: Record<string, DayConfigModel> | null,
    startDate: Date | null,
    endDate: Date | null,
) => {
    const dates = new Set<string>(excludedDateStringArray);
    if (daysConfig && !isNil(daysConfig)) {
        const isOnlyStartDateSelected = startDate && !endDate;
        const today = startOfToday();

        Object.entries(daysConfig).forEach(([dayString, config]) => {
            if (
                (config?.noArrival && !isOnlyStartDateSelected) ||
                (config?.noDeparture && isOnlyStartDateSelected)
            ) {
                dates.add(dayString);
            }

            if (!isOnlyStartDateSelected) {
                const date = startOfDay(new Date(dayString));
                const todayDiff = differenceInDays(date, today);

                if (config?.minCutOff && config.minCutOff > todayDiff) {
                    dates.add(dayString);
                }
            }
        });

        if (isOnlyStartDateSelected) {
            const startDayConfig = getDayConfig(startDate, daysConfig);
            const maxStay = startDayConfig?.maxStay || RANGE_PICKER.RULES.MAX_STAY;

            let isFullDateFound = false;
            for (let dayDiff = 1; dayDiff <= maxStay; dayDiff++) {
                const date = addDays(startDate, dayDiff);

                const rangeConfig = getRangeConfig(startDate, date, daysConfig);
                const dayConfig = getDayConfig(date, daysConfig);

                if (rangeConfig?.minStay && rangeConfig.minStay > dayDiff) {
                    const dateString = dateHelpers.format(date, RANGE_PICKER.DATE_FORMAT);
                    dates.add(dateString);
                }

                if (rangeConfig?.maxStay && rangeConfig.maxStay < dayDiff) {
                    const dateString = dateHelpers.format(date, RANGE_PICKER.DATE_FORMAT);
                    dates.add(dateString);
                }

                if (dayConfig?.full || isFullDateFound) {
                    if (isFullDateFound) {
                        const dateString = dateHelpers.format(date, RANGE_PICKER.DATE_FORMAT);
                        dates.add(dateString);
                    }

                    isFullDateFound = true;
                }
            }
        }
    }

    return [...dates];
};

/**
 * Get config for a date
 * @param date
 * @param daysConfig
 */
export const getDayConfig = (
    date: Date | null,
    daysConfig: Record<string, DayConfigModel> | null,
) => {
    if (!date) {
        return null;
    }

    const dateString = dateHelpers.format(date, RANGE_PICKER.DATE_FORMAT);
    return daysConfig?.[dateString];
};

/**
 * get dayClassName for range picker
 * @param date
 * @param startDate
 * @param endDate
 * @param daysConfig
 */
export const getDayClassName = (
    date: Date,
    startDate: Date | null,
    endDate: Date | null,
    daysConfig: Record<string, DayConfigModel> | null,
) => {
    const classNames: string[] = [];
    const isOnlyStartDateSelected = !!(startDate && !endDate);
    const dayConfig = getDayConfig(date, daysConfig);
    const isStartDate = startDate ? isEqualDate(startDate, date) : false;

    if (isStartDate) {
        classNames.push(RANGE_PICKER.DAY_CLASS_NAME_CONFIG.selected);
    }

    if (isOnlyStartDateSelected) {
        const diffFromStart = startDate ? differenceInDays(date, startDate) : 0;
        if (diffFromStart > 0) {
            const firstFullDate = getFirstFullDate(startDate, endDate, daysConfig);
            const dayAfterFullDate = firstFullDate ? addDays(firstFullDate, 1) : null;

            if (dayAfterFullDate && isEqualDate(date, dayAfterFullDate)) {
                // First day after full date within range should be displayed as noDeparture
                classNames.push(RANGE_PICKER.DAY_CLASS_NAME_CONFIG.noDeparture);
            } else if (dayConfig?.noDeparture) {
                classNames.push(RANGE_PICKER.DAY_CLASS_NAME_CONFIG.noDeparture);
            } else if (diffFromStart <= (dayConfig?.maxStay ?? RANGE_PICKER.RULES.MAX_STAY)) {
                const rules = getRangeConfig(startDate, date, daysConfig);

                if (
                    (rules?.minStay && rules.minStay > diffFromStart) ||
                    (rules?.maxStay && rules.maxStay < diffFromStart)
                ) {
                    classNames.push(RANGE_PICKER.DAY_CLASS_NAME_CONFIG.unavailable);
                }
            }
        }
    } else {
        // no date selected or both dates
        if (dayConfig?.noArrival) {
            classNames.push(RANGE_PICKER.DAY_CLASS_NAME_CONFIG.noArrival);
        }

        if (dayConfig?.minCutOff) {
            const today = startOfToday();
            const diffFromToday = differenceInDays(date, today);

            if (diffFromToday >= 0 && dayConfig.minCutOff > diffFromToday) {
                classNames.push(RANGE_PICKER.DAY_CLASS_NAME_CONFIG.minCutOff);
            }
        }

        if (startDate && endDate && date >= startDate && date <= endDate && dayConfig) {
            const isRangeStart = isEqualDate(date, startDate);
            const isRangeEnd = isEqualDate(date, endDate);

            if (
                (isRangeStart && dayConfig.noArrival) ||
                (isRangeEnd && dayConfig.noDeparture) ||
                (dayConfig.full && ((isRangeEnd && !dayConfig.noArrival) || !isRangeEnd))
            ) {
                classNames.push(RANGE_PICKER.DAY_CLASS_NAME_CONFIG.danger);
            }
        }
    }

    return classNames.join(' ');
};

/**
 * Get availability config for date range
 * @param startDate
 * @param endDate
 * @param daysConfig
 * @param defaultMinCutOff
 * @param defaultMinStay
 * @param defaultMaxStay
 */
export const getRangeConfig = (
    startDate: Date | null,
    endDate: Date | null,
    daysConfig: Record<string, DayConfigModel> | null,
    defaultMinCutOff = RANGE_PICKER.RULES.MIN_CUT_OFF,
    defaultMinStay = RANGE_PICKER.RULES.MIN_STAY,
    defaultMaxStay = RANGE_PICKER.RULES.MAX_STAY,
) => {
    if (!startDate || !endDate || isNil(daysConfig)) {
        return;
    }

    const dayDiff = differenceInDays(endDate, startDate);
    let minStay = defaultMinStay;
    let maxStay = defaultMaxStay;
    let minCutOff = defaultMinCutOff;
    const todayDiff = differenceInDays(startDate, startOfToday());

    for (let day = 0; day < dayDiff; day++) {
        const dayConfig = getDayConfig(addDays(startDate, day), daysConfig);

        minStay = Math.max(dayConfig?.minStay || defaultMinStay, minStay);
        maxStay = Math.min(dayConfig?.maxStay || defaultMaxStay, maxStay);
        minCutOff = Math.max(dayConfig?.minCutOff || defaultMinCutOff, minCutOff);
    }

    return {
        minCutOff: minCutOff > todayDiff && minCutOff !== defaultMinCutOff ? minCutOff : undefined,
        minStay: minStay > dayDiff && minStay !== defaultMinStay ? minStay : undefined,
        maxStay: maxStay < dayDiff && maxStay !== defaultMaxStay ? maxStay : undefined,
    };
};

/**
 * get first day of unavailability
 * @param startDate
 * @param endDate
 * @param daysConfig
 */
export const getFirstFullDate = (
    startDate: Date | null,
    endDate: Date | null,
    daysConfig: RangePickerProps['daysConfig'],
): null | Date => {
    const isOnlyStartDateSelected = !!(startDate && !endDate);

    if (!isOnlyStartDateSelected || isNil(daysConfig)) {
        return null;
    }

    for (let i = 0; i <= RANGE_PICKER.RULES.MAX_STAY; i++) {
        const date = addDays(startDate, i);
        const config = getDayConfig(date, daysConfig);

        if (config?.full) {
            return date;
        }
    }

    return null;
};

/**
 * Get error messages for availability calendar
 * @param startDate
 * @param endDate
 * @param daysConfig
 * @param translationData
 */
export const getRangeErrorMessage = (
    startDate: Date | null,
    endDate: Date | null,
    daysConfig: RangePickerProps['daysConfig'],
    translationData: RangePickerConfig['translationData'],
) => {
    if (!startDate || !endDate || isNil(daysConfig)) {
        return '';
    }

    const messages: string[] = [];
    const rangeConfig = getRangeConfig(startDate, endDate, daysConfig);
    if (!isEmpty(rangeConfig)) {
        ['minStay', 'maxStay', 'minCutOff'].forEach((rule: string) => {
            const dayRule = rangeConfig?.[rule as keyof typeof rangeConfig];
            if (dayRule) {
                const unit =
                    rule === 'minCutOff'
                        ? wordPluralisation(
                              dayRule,
                              translationData.days.singular,
                              translationData.days.plural,
                              translationData.days.genitivePlural,
                          )
                        : wordPluralisation(
                              dayRule,
                              translationData.nights.singular,
                              translationData.nights.plural,
                              translationData.nights.genitivePlural,
                          );

                messages.push(
                    translationData.errorMessages[
                        rule as keyof typeof translationData.errorMessages
                    ].replace('{{value}}', `${dayRule} ${unit}`),
                );
            }
        });
    }

    return messages.join('<br />');
};

/**
 * Get tooltip message for specified date
 * @param dateString
 * @param startDate
 * @param endDate
 * @param daysConfig
 * @param translationData
 */
export const getTooltipText = (
    dateString: string,
    startDate: Date | null,
    endDate: Date | null,
    daysConfig: RangePickerProps['daysConfig'],
    translationData: RangePickerConfig['translationData'],
) => {
    if (!dateString || isNil(daysConfig)) {
        return undefined;
    }
    const date = startOfDay(new Date(dateString));
    const today = startOfToday();
    const diffFromToday = differenceInDays(date, today);

    if (diffFromToday < 0) {
        return undefined;
    }

    const dayConfig = daysConfig[dateString];
    const isOnlyStartDateSelected = startDate && !endDate;

    if (isOnlyStartDateSelected) {
        const firstFullDate = getFirstFullDate(startDate, endDate, daysConfig);

        if (firstFullDate) {
            // First day after full date within range should be displayed as noDeparture
            const dayAfterFullDate = addDays(firstFullDate, 1);
            if (isEqualDate(date, dayAfterFullDate)) {
                return translationData.tooltipLabels['noDeparture'];
            }
        }
    }

    if (dayConfig?.full && !dayConfig?.noDeparture && !dayConfig?.noArrival) {
        return translationData.tooltipLabels['unavailable'];
    } else {
        if (!isOnlyStartDateSelected) {
            if (dayConfig?.noArrival) {
                return translationData.tooltipLabels['noArrival'];
            } else if (dayConfig?.minCutOff && dayConfig.minCutOff > diffFromToday) {
                return translationData.tooltipLabels['noArrivalMinCutOff'];
            }
        } else if (startDate && date > startDate) {
            if (dayConfig?.noDeparture) {
                return translationData.tooltipLabels['noDeparture'];
            } else {
                const diffFromStart = differenceInDays(date, startDate);
                const rangeConfig = getRangeConfig(startDate, date, daysConfig);
                const maxStay = rangeConfig?.maxStay || RANGE_PICKER.RULES.MAX_STAY;

                if (
                    diffFromStart > 0 &&
                    rangeConfig?.minStay &&
                    rangeConfig.minStay > diffFromStart
                ) {
                    const nights = wordPluralisation(
                        rangeConfig.minStay,
                        translationData.nights.singular,
                        translationData.nights.plural,
                        translationData.nights.genitivePlural,
                    );

                    return translationData.tooltipLabels['minStay'].replace(
                        '{{value}}',
                        `${rangeConfig.minStay} ${nights}`,
                    );
                } else if (diffFromStart > 0 && maxStay < diffFromStart) {
                    const nights = wordPluralisation(
                        maxStay,
                        translationData.nights.singular,
                        translationData.nights.plural,
                        translationData.nights.genitivePlural,
                    );

                    return translationData.tooltipLabels['maxStay'].replace(
                        '{{value}}',
                        `${maxStay} ${nights}`,
                    );
                }
            }
        }
    }

    return undefined;
};

/**
 * Map with numbered day names (polish)
 */
const rangePickerWeekDaysMap = [
    'niedziela',
    'poniedziałek',
    'wtorek',
    'środa',
    'czwartek',
    'piątek',
    'sobota',
];

/**
 * get numeric value of days of week (unfortunately react-datepicker passes only localized day name and we need numbers)
 * @param polishDay
 */
export const getNumericDayOfWeek = (polishDay: string) => rangePickerWeekDaysMap.indexOf(polishDay);
