import { IconProps, IconsNocowanie } from '@nocowanie/icons';

import { IconTypeEnum } from '@app/enums';

export const getFeaturedSubsectionIcon = (type: IconTypeEnum, iconConfig?: IconProps) => {
    const featuredSubsectionIconMap = {
        [IconTypeEnum.Mountains]: <IconsNocowanie.Mountains {...iconConfig} />,
        [IconTypeEnum.Sea]: <IconsNocowanie.Sea {...iconConfig} />,
        [IconTypeEnum.CityBreak]: <IconsNocowanie.CityBreak {...iconConfig} />,
        [IconTypeEnum.CrossCountry]: <IconsNocowanie.CrossCountry {...iconConfig} />,
    };
    return featuredSubsectionIconMap[type];
};
