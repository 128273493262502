import { useEffect, useState } from 'react';

import { CrossStorage } from '@nocowanie/core';

import { environment } from '@app/environments/environment';

import { CrossStorageClientOptions } from 'cross-storage';

export function useCrossStorage(config: CrossStorageClientOptions = {}) {
    const [client, setClient] = useState<CrossStorage | undefined>(undefined);

    useEffect(() => {
        if (!client) {
            setClient(CrossStorage.getInstance(environment.CROSSSTORAGE_ENDPOINT, config));
        }
    }, []);

    return client;
}
