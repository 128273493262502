import { extendTheme } from '@chakra-ui/react';

import { themeBase } from './theme-base';

/**
 * Additional styles for web components
 */
const wcTheme = extendTheme({
    ...themeBase,
    styles: {
        global: {
            ...themeBase.styles.global,
            html: {
                fontFamily: 'Proxima Nova, Arial, sans-serif',
            },
        },
    },
});

export { wcTheme };
