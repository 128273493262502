import { IconProps, NocIconGenerator } from '../common';

export const SwimmingPool = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'SwimmingPool',
            path: (
                <>
                    <g
                        stroke="#000001"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M3.25 5.61C3.25 3.73 4.82 2 6.75 2s3.5 1.73 3.5 3.61M10.25 16V5.61M14 2c2.21 0 4 1.57 4 3.5V16M10.5 6H18M10.5 12H18M10.5 9H18M10.25 15h7.5M8.66 17c0 1.1-1.49 2-3.33 2S2 18.1 2 17M15.33 17c0 1.1-1.49 2-3.33 2s-3.33-.9-3.33-2M22 17c0 1.1-1.49 2-3.33 2s-3.33-.9-3.33-2M8.66 20c0 1.1-1.49 2-3.33 2S2 21.1 2 20M15.33 20c0 1.1-1.49 2-3.33 2s-3.33-.9-3.33-2M22 20c0 1.1-1.49 2-3.33 2s-3.33-.9-3.33-2" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
