export enum ConsentNameType {
    NocowanieTouristGDPR = 'nocowanieTouristGDPR',
    SzallasTouristGDPR = 'szallasTouristGDPR',
    Mailing = 'mailing',
    Profiling = 'profiling',
}

export interface ConsentDataModel {
    id: ConsentNameType;
    isRequired: boolean;
    excerptTranslationKey: string;
    expandedTextTranslationKey?: string;
    validationMessage?: string;
}
