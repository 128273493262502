export enum WpPageType {
    Home = 'StronaGlowna',
    Product = 'ViewProduct',
    List = 'ProductList',
    Other = 'Other',
}

export interface WpEventParams {
    em?: string;
    id?: number;
    content_category_id?: number;
    content_category?: string;
    uid?: number | string;
}

export enum PartnerType {
    otherTrustedPartners = 'TP',
    wpHolding = 'GWP',
}

export interface GdprQueueParams {
    consents: PartnerType[];
    callback: () => void;
}
