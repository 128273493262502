import { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Link, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { IconsLinear } from '@nocowanie/icons';

import { ExpandableMenuItem } from '@app/components/molecules/expandable-menu-item';
import { modifyLink } from '@app/helpers/navigation.helpers';
import { NavGroupModel, NavLinkModel } from '@app/models';

import navLinks from './navigation-links.json';

export const Navigation = ({ triggerText }: { triggerText: string }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openMenuIndex, setOpenMenuIndex] = useState<string | null>(null);

    const styles = useMultiStyleConfig('Navigation', {});

    const handleToggleMenu = (index: string) => {
        setOpenMenuIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const menuTriggerRef = useRef(null);

    const handleCloseNav = () => {
        setOpenMenuIndex(null);
        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (!isMenuOpen) {
            return;
        }

        window.addEventListener('scroll', handleCloseNav);

        return () => {
            window.removeEventListener('scroll', handleCloseNav);
        };
    }, [isMenuOpen]);

    const filteredNavLinks = useMemo(
        () => navLinks.filter((navGroup: NavGroupModel) => !navGroup.mobileOnly),
        [],
    );

    const renderNavLinks = useMemo(
        () =>
            filteredNavLinks.map((navGroup: NavGroupModel) => (
                <MenuGroup key={navGroup.navGroupId} title={navGroup.navGroupTitle.toUpperCase()}>
                    {navGroup.navLinks.map((navLink: NavLinkModel) =>
                        navLink.isExpandable ? (
                            <ExpandableMenuItem
                                key={navLink.navLinkId}
                                navLink={navLink}
                                isOpen={openMenuIndex === navLink.navLinkId}
                                toggleMenu={() => handleToggleMenu(navLink.navLinkId)}
                            />
                        ) : (
                            <MenuItem
                                key={navLink.navLinkId}
                                as={Link}
                                href={modifyLink(navLink)}
                                variant={'menuLinkBase'}
                                py={2}
                                px={4}
                            >
                                {navLink.navLinkLabel}
                            </MenuItem>
                        ),
                    )}
                </MenuGroup>
            )),
        [filteredNavLinks, openMenuIndex],
    );

    return (
        <Menu
            isOpen={isMenuOpen}
            onOpen={() => setIsMenuOpen(true)}
            onClose={handleCloseNav}
            initialFocusRef={menuTriggerRef}
        >
            <MenuButton ref={menuTriggerRef}>
                <Box as={'span'} sx={styles.button}>
                    {triggerText}
                </Box>
                <IconsLinear.ArrowDown2 fontSize={'sm'} />
            </MenuButton>
            <MenuList position={'relative'} zIndex={5}>
                {renderNavLinks}
            </MenuList>
        </Menu>
    );
};
