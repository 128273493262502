import { EsServiceBase } from '@app/services/abstracts/es-service.base';

import { EsResponseManyModel } from '../models/elastic-search';
import { RatingModel } from '../models/elastic-search/rating.model';

export class EsAccommodationRatingService extends EsServiceBase {
    readonly #accommodationRatingEsIndexName = 'noc-accommodation-rating';
    readonly #accommodationRatingEsIndexVersion = 2;

    private static _instance: EsAccommodationRatingService;

    protected get indexName(): string {
        return `${this.#accommodationRatingEsIndexName}-${this.#accommodationRatingEsIndexVersion}`;
    }

    public static get instance(): EsAccommodationRatingService {
        if (!this._instance) {
            this._instance = new EsAccommodationRatingService();
        }

        return this._instance;
    }

    public async loadAccommodationRatings(
        id: string | number,
        from = 0,
        size = 5,
    ): Promise<EsResponseManyModel<RatingModel>> {
        return await this.getMany(this.indexName, {
            query: {
                bool: {
                    must: [{ match: { accommodationId: id } }],
                },
            },
            // Sorting:
            // - polish reviews first
            // - with named guest first
            // - by text length ranges
            // - by date
            sort: [
                {
                    _script: {
                        type: 'number',
                        script: {
                            lang: 'painless',
                            source: 'int sortOrder = 0; if (doc["language.code.keyword"].value == "pl") {sortOrder = 1} sortOrder;',
                        },
                        order: 'desc',
                    },
                },
                {
                    _script: {
                        type: 'number',
                        script: {
                            lang: 'painless',
                            source: 'doc["guest.name.keyword"].size() > 0 ? 0 : 1',
                        },
                        order: 'asc',
                    },
                },
                {
                    _script: {
                        type: 'number',
                        script: {
                            lang: 'painless',
                            source: `
                                int textLength = 0;
                                if (doc['text.keyword'].size() > 0) {
                                    textLength += doc['text.keyword'].value.length();
                                }
                                if (doc['positive.keyword'].size() > 0) {
                                    textLength += doc['positive.keyword'].value.length();
                                }
                                if (doc['negative.keyword'].size() > 0) {
                                    textLength += doc['negative.keyword'].value.length();
                                }
                                if (textLength >= 200) {
                                    return 3;
                                } else if (textLength >= 100) {
                                    return 2;
                                } else if (textLength > 0) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            `,
                        },
                        order: 'desc',
                    },
                },
                { date: { order: 'desc' } },
            ],
            from,
            size,
        });
    }
}
