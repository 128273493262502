import { extendTheme } from '@chakra-ui/theme-utils';

import { Avatar } from './components/Avatar';
import { Badge } from './components/Badge';
import { Button } from './components/Button';
import { Checkbox } from './components/Checkbox';
import { Drawer } from './components/Drawer';
import { Heading } from './components/Heading';
import { Icon } from './components/Icon';
import { Input } from './components/Input';
import { Link } from './components/Link';
import { Menu } from './components/Menu';
import { Popover } from './components/Popover';
import { Tag } from './components/Tag';
import { AccommodationItem } from './custom-components/AccommodationItem';
import { AccordionMenuItem } from './custom-components/AccordionMenuItem';
import { Autocomplete } from './custom-components/Autocomplete';
import { AutocompleteItem } from './custom-components/AutocompleteItem';
import { AverageRating } from './custom-components/AverageRating';
import { ExpandableMenuItem } from './custom-components/ExpandableMenuItem';
import { FeaturedCountryItem } from './custom-components/FeaturedCountryItem';
import { FeaturedImageWrapper } from './custom-components/FeaturedImageWrapper';
import { Footer } from './custom-components/Footer';
import { GuestsPickerSelect } from './custom-components/GuestPickerSelect';
import { GuestsPicker } from './custom-components/GuestsPicker';
import { Hero } from './custom-components/Hero';
import { MobileNumberInput } from './custom-components/MobileNumberInput';
import { Navigation } from './custom-components/Navigation';
import { Newsletter } from './custom-components/Newsletter';
import { RangePicker } from './custom-components/RangePicker';
import { RangePickerDrawer } from './custom-components/RangePickerDrawer';
import { SearchEngineDesktop } from './custom-components/SearchEngineDesktop';
import { SearchEngineInstantReservationCheckbox } from './custom-components/SearchEngineInstantReservationCheckbox';
import { SearchEngineMobile } from './custom-components/SearchEngineMobile';
import { SearchEngineMobileDrawer } from './custom-components/SearchEngineMobileDrawer';
import { SearchLocation } from './custom-components/SearchLocation';
import { SeoLinks } from './custom-components/SeoLinks';
import { Support } from './custom-components/Support';
import { colors } from './tokens/colors';
import { fontsWeights } from './tokens/font-weights';
import { fonts } from './tokens/fonts';
import { semanticTokens } from './tokens/semantic-tokens';
import { sizes } from './tokens/sizes';
import { space } from './tokens/space';

export const v7themeBase = extendTheme({
    // tokens
    colors,
    fonts,
    fontsWeights,
    sizes,
    space,
    semanticTokens,

    components: {
        AccommodationItem,
        Avatar,
        AverageRating,
        Badge,
        Button,
        Checkbox,
        Drawer,
        ExpandableMenuItem,
        FeaturedCountryItem,
        FeaturedImageWrapper,
        Footer,
        Heading,
        Hero,
        Icon,
        Input,
        Link,
        Menu,
        AccordionMenuItem,
        Autocomplete,
        AutocompleteItem,
        Newsletter,
        Support,
        GuestsPicker,
        GuestsPickerSelect,
        MobileNumberInput,
        Navigation,
        RangePicker,
        RangePickerDrawer,
        SearchEngineDesktop,
        SearchEngineMobile,
        SearchEngineMobileDrawer,
        SearchEngineInstantReservationCheckbox,
        SearchLocation,
        SeoLinks,
        Popover,
        Tag,
    },
});
