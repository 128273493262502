import { IconProps, NocIconGenerator } from '../common';

export const Bathroom = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Bathroom',
            path: (
                <>
                    <g
                        stroke="#000001"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M13 6c0-1.79-1.07-3.67-3-3.67S6 4.22 6 6M6 14V6M13 8.5v1M2 14h20v2c0 3.17-2.79 6-6 6H8c-3.25 0-6-2.79-6-6v-2Z" />
                        <path d="M14 14h4v3c0 .63-.37 1-1 1h-2c-.63 0-1-.37-1-1v-3Z" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
