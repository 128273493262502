import { IconProps, NocIconGenerator } from '../common';

export const SingleBed = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'SingleBed',
            path: (
                <>
                    <path
                        d="M16.31 15.4C16.03 15.4 15.81 15.16 15.81 14.87V12.88C15.81 12.12 15.28 11.42 14.69 11.42H9.3C8.72 11.42 8.18 12.11 8.18 12.88V14.87C8.18 15.16 7.96 15.4 7.68 15.4C7.4 15.4 7.18 15.16 7.18 14.87V12.88C7.18 11.52 8.15 10.37 9.3 10.37H14.69C15.84 10.37 16.81 11.52 16.81 12.88V14.87C16.81 15.16 16.59 15.4 16.31 15.4Z"
                        fill="black"
                    />
                    <path
                        d="M23.5 22.01H0.5C0.22 22.01 0 21.77 0 21.48V17.51C0 15.76 1.22 14.33 2.71 14.33H21.29C22.79 14.33 24 15.75 24 17.51V21.48C24 21.77 23.78 22.01 23.5 22.01ZM1 20.95H23V17.51C23 16.34 22.23 15.39 21.29 15.39H2.71C1.77 15.39 1 16.34 1 17.51V20.95Z"
                        fill="black"
                    />
                    <path
                        d="M0.5 24C0.22 24 0 23.76 0 23.47V20.82C0 20.53 0.22 20.29 0.5 20.29C0.78 20.29 1 20.53 1 20.82V23.47C1 23.76 0.78 24 0.5 24Z"
                        fill="black"
                    />
                    <path
                        d="M23.5 24C23.22 24 23 23.76 23 23.47V20.82C23 20.53 23.22 20.29 23.5 20.29C23.78 20.29 24 20.53 24 20.82V23.47C24 23.76 23.78 24 23.5 24Z"
                        fill="black"
                    />
                    <path
                        d="M20.96 15.4C20.68 15.4 20.46 15.16 20.46 14.87V8.51999C20.46 7.75999 19.91 7.05999 19.3 7.05999H4.7C4.09 7.05999 3.54 7.74999 3.54 8.51999V14.87C3.54 15.16 3.32 15.4 3.04 15.4C2.76 15.4 2.54 15.16 2.54 14.87V8.51999C2.54 7.15999 3.53 6.00999 4.7 6.00999H19.3C20.47 6.00999 21.46 7.15999 21.46 8.51999V14.87C21.46 15.16 21.24 15.4 20.96 15.4Z"
                        fill="black"
                    />
                </>
            ),
        }}
    />
);
