import React, { memo, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import DatePicker, {
    CalendarContainer,
    DatePickerProps,
    registerLocale,
    setDefaultLocale,
} from 'react-datepicker';
import ReactDOM from 'react-dom';

import { Box, Divider, VStack } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { addMonths } from 'date-fns/addMonths';
import { differenceInDays } from 'date-fns/differenceInDays';
import { isEqual as isEqualDate } from 'date-fns/isEqual';
import { startOfMonth } from 'date-fns/startOfMonth';
import isEqual from 'lodash/isEqual';
import { flip, shift } from '@floating-ui/react';

import { dateHelpers, FeatureToggleEnum } from '@nocowanie/core';

import './../date-picker/date-picker.scss';
import { RANGE_PICKER } from './../../../consts';
import { rangePickerHelpers } from './../../../helpers';
import { defaultRangePickerConfig, RangePickerConfig } from './range-picker.config';
import { RangePickerDates, RangePickerProps } from './range-picker.props';
import { RangePickerDrawer } from './range-picker-drawer';
import { RangePickerFooterContent } from './range-picker-footer-content';
import { RangePickerHeader } from './range-picker-header';
import { RangePickerPopper } from './range-picker-popper';
import { RangePickerTrigger } from './range-picker-trigger';

import { CommonModalsEnum, SessionStorageKeysEnum } from '../../../enums';
import { getFeatureToggleValue } from '../../../helpers/feature-toggle-helpers';
import { useFragmentActions, useModalSync, useSwipeGesture } from '../../../hooks';

const globalLocale = dateHelpers.getGlobalLocale();
registerLocale(globalLocale, dateHelpers.dateFnsLocales[globalLocale]);
setDefaultLocale(globalLocale);

export const RangePicker = ({
    selectedDates,
    pickerProps,
    onRangeChange,
    config,
    daysConfig,
    children,
    onError,
    isMobileBrowser = true,
    inputProps = {},
    displayedDateFormat = 'dd MMM.',
    excludeDateStrings = [],
    isWebComponent,
    saveToSession,
}: RangePickerProps) => {
    const FT_USE_NEW_CALENDAR = getFeatureToggleValue<boolean>(FeatureToggleEnum.USE_NEW_CALENDAR);
    const themeStyles = useMultiStyleConfig('RangePicker', {});
    const drawerContentRef = useRef<HTMLDivElement>(null);
    const isValidRange = useCallback((dates: RangePickerDates): boolean => {
        const [start, end] = dates;
        return !(start === null || end === null || differenceInDays(end, start) < 1);
    }, []);

    const initialState: { startDate: null | Date; endDate: null | Date } = {
        startDate: null,
        endDate: null,
    };

    if (selectedDates && isValidRange([selectedDates[0], selectedDates[1]])) {
        initialState.startDate = selectedDates[0];
        initialState.endDate = selectedDates[1];
    }

    const [componentState, setComponentState] = useState<{
        startDate: Date | null;
        endDate: Date | null;
    }>({
        ...initialState,
    });
    const [displayedRange, setDisplayedRange] = useState<{
        startDate: Date | null;
        endDate: Date | null;
    }>({
        ...initialState,
    });
    const mobileInputRef = useRef<HTMLInputElement>(null);
    const datePickerRef = useRef<DatePicker>(null);
    const withDrawer = isMobileBrowser;
    const defaultMonthsShown = 2;
    const [focusSelectedMonth, setFocusSelectedMonth] = useState<boolean>(false);
    const [monthsShown, setMonthsShown] = useState<number>(defaultMonthsShown);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isDrawerRendered, setIsDrawerRendered] = useState<boolean>(false);
    const [daysConfigCached, setDaysConfigCached] = useState(daysConfig ? { ...daysConfig } : null);
    const [isCalendarPending, setIsCalendarPending] = useState<boolean>(false);
    const [isInitialConfigLoaded, setIsInitialConfigLoaded] = useState(false);

    const { translationData }: Required<RangePickerConfig> = {
        ...defaultRangePickerConfig,
        ...config,
    };

    const locationId = useId();

    const { addFragment, removeFragment } = useFragmentActions(
        CommonModalsEnum.Calendar,
        locationId,
    );

    useEffect(() => {
        if (isEqual(daysConfig, daysConfigCached)) {
            return;
        }
        setDaysConfigCached(daysConfig ? { ...daysConfig } : null);
    }, [daysConfig]);

    useEffect(() => {
        if (!daysConfigCached || isInitialConfigLoaded) {
            return;
        }

        setIsInitialConfigLoaded(true);

        if (onError) {
            const errorMessage = rangePickerHelpers.getRangeErrorMessage(
                displayedRange?.startDate,
                displayedRange?.endDate,
                daysConfigCached,
                translationData,
            );
            onError(errorMessage);
        }
    }, [
        daysConfigCached,
        displayedRange?.startDate,
        displayedRange?.endDate,
        isInitialConfigLoaded,
    ]);

    const startDayConfig = rangePickerHelpers.getDayConfig(
        displayedRange?.startDate ?? null,
        daysConfigCached,
    );
    const isOnlyStartDateSelected = !!displayedRange?.startDate && !displayedRange?.endDate;
    const showFooterContent = !!(
        (isOnlyStartDateSelected &&
            (startDayConfig?.minStay || startDayConfig?.maxStay || startDayConfig?.minCutOff)) ||
        // We want to show the info only if currently selected dates match the dates from api response
        (inputProps?.inputProps?.isInvalid &&
            selectedDates?.[0] &&
            selectedDates?.[1] &&
            displayedRange?.startDate &&
            displayedRange?.endDate &&
            isEqualDate(selectedDates[0], displayedRange.startDate) &&
            isEqualDate(selectedDates[1], displayedRange.endDate))
    );

    const onCancel = useCallback(() => {
        if (isEqual(componentState, displayedRange)) {
            return;
        }

        setDisplayedRange({ ...componentState });
    }, [componentState, displayedRange]);

    const {
        isOpen: isDrawerOpen,
        onOpen: onDrawerOpen,
        onClose: onDrawerClose,
    } = useDisclosure({
        onOpen: () => {
            setIsCalendarPending(false);
            setTimeout(() => setIsDrawerRendered(true), 0);
        },
        onClose: () => {
            setIsDrawerRendered(false);
            setIsCalendarPending(false);
            removeFragment();
        },
    });

    const onDrawerCancel = useCallback(() => {
        onCancel();
        onDrawerClose();
    }, [onCancel, onDrawerClose]);

    const onDrawerSubmit = useCallback(() => {
        if (!emitRangeChanged([displayedRange?.startDate, displayedRange?.endDate])) {
            return;
        }
        setComponentState({
            startDate: displayedRange?.startDate,
            endDate: displayedRange?.endDate,
        });
        onDrawerClose();
        !sessionStorage.getItem(SessionStorageKeysEnum.CriteriaChanged) &&
            sessionStorage.setItem(SessionStorageKeysEnum.CriteriaChanged, 'true');
    }, [onDrawerClose, displayedRange?.startDate, displayedRange?.endDate]);

    useEffect(() => {
        if (initialState && !isEqual(componentState, initialState)) {
            setComponentState({
                ...initialState,
            });
            onRangeChanged([initialState.startDate, initialState.endDate]);
        }
    }, [selectedDates]);

    useModalSync({
        modalName: CommonModalsEnum.Calendar,
        onOpen: onDrawerOpen,
        onClose: () => {
            onCancel();
            onDrawerClose();
        },
        isOpen: isDrawerOpen,
        fragmentId: locationId,
    });

    const emitRangeChanged = useCallback(
        (dates: RangePickerDates): boolean => {
            const [start, end] = dates;

            if (!isValidRange(dates)) {
                return false;
            }

            setComponentState({
                startDate: start,
                endDate: end,
            });

            onRangeChange && onRangeChange(dates);

            if (onError) {
                const errorMessage = rangePickerHelpers.getRangeErrorMessage(
                    start,
                    end,
                    daysConfigCached,
                    translationData,
                );
                onError(errorMessage);
            }

            return true;
        },
        [onRangeChange, isValidRange, daysConfigCached],
    );

    const updatePicker = useCallback(
        (dates: RangePickerDates): void => {
            const [start, end] = dates;

            setDisplayedRange({
                startDate: start,
                endDate: end,
            });

            if (!withDrawer) {
                if (isWebComponent) {
                    emitRangeChanged(dates);
                } else {
                    setIsCalendarPending(true);
                    setTimeout(() => {
                        emitRangeChanged(dates);
                        setIsCalendarPending(false);
                        saveToSession &&
                            !sessionStorage.getItem(SessionStorageKeysEnum.CriteriaChanged) &&
                            sessionStorage.setItem(SessionStorageKeysEnum.CriteriaChanged, 'true');
                    }, 0);
                }
            }
        },
        [withDrawer, emitRangeChanged],
    );

    const onRangeChanged = (
        dates: [Date | null, Date | null],
        event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | undefined,
    ): void => {
        updatePicker(dates);
    };

    const renderDayContents = useCallback((dayOfMonth: number, date: Date) => {
        return (
            <span className={'react-datepicker__day-inner'} data-date={date.getTime()}>
                {dayOfMonth}
            </span>
        );
    }, []);

    const dayClassName = useCallback(
        (date: Date) => {
            return rangePickerHelpers.getDayClassName(
                date,
                displayedRange?.startDate ?? null,
                displayedRange?.endDate ?? null,
                daysConfigCached,
            );
        },
        [displayedRange?.startDate, displayedRange?.endDate, daysConfigCached],
    );

    const excludeDates = useMemo(() => {
        return rangePickerHelpers.getExcludedDates(
            excludeDateStrings ?? [],
            daysConfigCached,
            displayedRange?.startDate ?? null,
            displayedRange?.endDate ?? null,
        );
    }, [daysConfigCached, displayedRange?.startDate, displayedRange?.endDate, excludeDateStrings]);

    const filterDate = useCallback(
        (date: Date) => {
            const dateString = dateHelpers.format(date, RANGE_PICKER.DATE_FORMAT);

            return !excludeDates?.includes(dateString);
        },
        [excludeDates],
    );

    const CalendarFooterContent = () =>
        showFooterContent ? (
            <RangePickerFooterContent
                startDate={displayedRange?.startDate}
                endDate={displayedRange?.endDate}
                isInvalid={inputProps?.inputProps?.isInvalid}
                translationData={translationData}
                displayedDateFormat={displayedDateFormat}
                daysConfig={daysConfigCached}
            />
        ) : null;

    const RangePickerContainer = ({
        className,
        children,
        showPopperArrow,
        arrowProps = {},
        datePickerRef,
    }: DatePickerProps & {
        arrowProps: Record<string, any>;
        datePickerRef: React.RefObject<DatePicker>;
    }) => {
        const calendarInstance = datePickerRef.current?.calendar;
        const monthNavigationState = useMemo(() => {
            const currentMonth = new Date();
            const minMonth = startOfMonth(currentMonth);
            const maxMonth = startOfMonth(addMonths(currentMonth, 11));
            const displayedMonth = calendarInstance?.state;
            if (!displayedMonth) return { isPrevMonthDisabled: false, isNextMonthDisabled: false };

            return {
                isPrevMonthDisabled: startOfMonth(displayedMonth.date) <= minMonth,
                isNextMonthDisabled: startOfMonth(displayedMonth.date) >= maxMonth,
            };
        }, [calendarInstance?.state]);

        const handleSwipeDown = useCallback(() => {
            if (!monthNavigationState.isPrevMonthDisabled) {
                requestAnimationFrame(() => calendarInstance?.decreaseMonth());
            }
        }, [calendarInstance, monthNavigationState.isPrevMonthDisabled]);

        const handleSwipeUp = useCallback(() => {
            if (!monthNavigationState.isNextMonthDisabled) {
                requestAnimationFrame(() => calendarInstance?.increaseMonth());
            }
        }, [calendarInstance, monthNavigationState.isNextMonthDisabled]);

        const { handleTouchStart, handleTouchEnd } = useSwipeGesture({
            onSwipeDown: handleSwipeDown,
            onSwipeUp: handleSwipeUp,
        });

        return (
            <CalendarContainer className={className}>
                <Box
                    sx={themeStyles.cssVars}
                    {...(isMobileBrowser &&
                        FT_USE_NEW_CALENDAR && {
                            onTouchStart: handleTouchStart,
                            onTouchEnd: handleTouchEnd,
                        })}
                >
                    {showPopperArrow ? (
                        <div className="react-datepicker__triangle" {...arrowProps} />
                    ) : null}
                    <div style={{ position: 'relative' }} className="clearfix">
                        {children}
                    </div>
                    {showFooterContent && !isMobileBrowser ? (
                        <VStack px={3.5} pb={3} align={'start'} gap={0} width={'100%'}>
                            <Divider mb={3} />
                            <CalendarFooterContent />
                        </VStack>
                    ) : null}
                </Box>
            </CalendarContainer>
        );
    };

    const formatWeekDay = useCallback((polishDay: string) => {
        const dayOfWeek = rangePickerHelpers.getNumericDayOfWeek(polishDay);
        return translationData.weekDayNames[dayOfWeek];
    }, []);

    // There is weird type issue after picker's update - it needs types (onChange, selectsRange, selectsMultiple) explicitly set
    // https://github.com/Hacker0x01/react-datepicker/issues/4924
    // https://github.com/Hacker0x01/react-datepicker/issues/4999
    const commonPickerProps: Omit<
        DatePickerProps,
        'onChange' | 'excludeDates' | 'selectsRange' | 'selectsMultiple' | 'showMonthYearDropdown'
    > & {
        onChange: (
            dates: [Date | null, Date | null],
            event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | undefined,
        ) => void;
        selectsRange: true;
        ref: (picker?: any) => void;
        selectsMultiple?: never;
    } = {
        ...pickerProps,
        startDate: displayedRange?.startDate ?? undefined,
        endDate: displayedRange?.endDate ?? undefined,
        onChange: onRangeChanged,
        minDate: dateHelpers.getMinRangeDate(
            displayedRange?.startDate ?? null,
            displayedRange?.endDate ?? null,
            startDayConfig?.minStay,
        ),
        maxDate: dateHelpers.getMaxRangeDate(
            displayedRange?.startDate ?? null,
            displayedRange?.endDate ?? null,
            startDayConfig?.maxStay,
        ),
        selectsRange: true,
        selectsMultiple: undefined,
        todayButton: null,
        calendarContainer: calendarProps => (
            <RangePickerContainer {...calendarProps} datePickerRef={datePickerRef} />
        ),
        dayClassName,
        renderDayContents,
        focusSelectedMonth,
        monthsShown,
        filterDate: isOpen || isDrawerOpen ? filterDate : undefined, // Do not use excludeDates! it's poorly optimized
        ref: (picker: any) => {
            // https://github.com/Hacker0x01/react-datepicker/issues/1480
            // React-datepicker overrides input's readonly prop, so we need to override it >again<
            if (picker && picker.input) {
                picker.input.readOnly = true;

                // Some other props are not passed too
                for (const [propName, propValue] of Object.entries(inputProps as object)) {
                    picker.input[propName] = propValue;
                }
            }
            (datePickerRef as React.MutableRefObject<DatePicker>).current = picker;
        },
    };

    const handleTriggerClick = () => {
        if (withDrawer) {
            setIsCalendarPending(true);
            setTimeout(() => {
                onDrawerOpen();
            }, 0);
            addFragment();
        } else {
            setIsOpen(true);
        }
    };

    const commonTriggerProps = {
        startDate: displayedRange.startDate,
        endDate: displayedRange.endDate,
        onClick: handleTriggerClick,
        inputProps,
        isLoading: isCalendarPending,
        children,
    };

    const DatePickerMobile = memo(() => (
        <DatePicker
            inline={true}
            calendarClassName={'react-datepicker--drawer'}
            renderCustomHeader={({ ...props }) => <RangePickerHeader isMobile {...props} />}
            {...commonPickerProps}
        />
    ));

    const bodyRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        bodyRef.current = document.body;
    }, []);

    return (
        <>
            {withDrawer ? (
                <>
                    <RangePickerTrigger
                        inputRef={mobileInputRef}
                        withDrawer
                        {...commonTriggerProps}
                    />
                    <RangePickerDrawer
                        isOpen={isDrawerOpen}
                        translationData={translationData}
                        onClose={onDrawerCancel}
                        onSubmit={onDrawerSubmit}
                        startDate={displayedRange?.startDate}
                        endDate={displayedRange?.endDate}
                        inputRef={mobileInputRef}
                        contentRef={drawerContentRef}
                        footerContent={showFooterContent ? <CalendarFooterContent /> : null}
                        isLoading={isCalendarPending}
                        setIsLoading={setIsCalendarPending}
                    >
                        <DatePickerMobile />
                    </RangePickerDrawer>
                </>
            ) : (
                <DatePicker
                    onInputClick={() => setIsCalendarPending(true)}
                    open={isOpen}
                    onCalendarOpen={() => {
                        requestAnimationFrame(() => {
                            setIsOpen(true);
                            // focusSelectedMonth + monthsShown change - fix for shifting months issue
                            // https://github.com/Hacker0x01/react-datepicker/issues/3942
                            setFocusSelectedMonth(false);
                            setIsCalendarPending(false);
                        });
                    }}
                    onCalendarClose={() => {
                        setIsOpen(false);
                        setFocusSelectedMonth(true);
                        setMonthsShown(1);
                        setTimeout(() => {
                            setMonthsShown(defaultMonthsShown);
                        }, 1);
                        onCancel();
                    }}
                    closeOnScroll={true}
                    shouldCloseOnSelect={true}
                    popperPlacement={'bottom'}
                    customInput={<RangePickerTrigger {...commonTriggerProps} />}
                    popperContainer={({ children }) =>
                        bodyRef.current &&
                        ReactDOM.createPortal(
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>{children}</>,
                            bodyRef.current,
                        )
                    }
                    popperModifiers={[shift(), flip()]}
                    formatWeekDay={formatWeekDay}
                    renderCustomHeader={({ ...props }) => <RangePickerHeader {...props} />}
                    {...commonPickerProps}
                />
            )}
            {daysConfigCached && (isOpen || isDrawerOpen) ? (
                <RangePickerPopper
                    isMobile={isMobileBrowser}
                    translationData={translationData}
                    daysConfig={daysConfigCached}
                    dateRange={displayedRange}
                    isDrawerRendered={isDrawerRendered}
                    drawerContentRef={drawerContentRef}
                />
            ) : null}
        </>
    );
};
