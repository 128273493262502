import {
    Alert as ChakraAlert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    ButtonGroup,
} from '@chakra-ui/react';

import { AlertProps } from './alert.props';

export const Alert = ({
    status = 'info',
    title,
    children,
    customIcon,
    buttons = [],
    titleProps = {},
    dangerouslySetInnerHTML,
    hideIcon = false,
    ...rest
}: AlertProps): JSX.Element => {
    return (
        <ChakraAlert
            status={status}
            bgColor={status === 'error' ? 'danger.50' : undefined}
            {...rest}
        >
            <>
                {!hideIcon && <AlertIcon />}
                <Box flexGrow={1}>
                    {title && (
                        <AlertTitle mb={'2'} {...titleProps}>
                            {title}
                        </AlertTitle>
                    )}
                    <AlertDescription
                        display={'block'}
                        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                        children={!dangerouslySetInnerHTML ? children : undefined}
                    />
                    {buttons && buttons.length ? (
                        <ButtonGroup mt={4} spacing={4} justifyContent={'end'} display={'flex'}>
                            {buttons.map(button => button)}
                        </ButtonGroup>
                    ) : null}
                </Box>
            </>
        </ChakraAlert>
    );
};
