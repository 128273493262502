import { useMemo, useState } from 'react';

import { Select } from '@chakra-ui/select';
import { useMultiStyleConfig } from '@chakra-ui/system';

import isNil from 'lodash/isNil';

import {
    defaultGuestsPickerConfig,
    defaultGuestsPickerTranslation,
    wordPluralisation,
} from '@nocowanie/common-ui';

import { GuestPickerSelectProps } from './guest-picker-select.props';

export const GuestPickerSelect = ({
    value,
    max = defaultGuestsPickerConfig.childAgeItemConfig.max as number,
    label,
    placeholder,
    isRequired = true,
    onChange,
}: GuestPickerSelectProps) => {
    const [age, setAge] = useState(value);
    const [isTouched, setIsTouched] = useState(!isNil(age));
    const themeStyles = useMultiStyleConfig('GuestsPickerSelect', { isTouched });
    const iconColor = themeStyles.iconColor as string;

    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newAge = event.target.value;
        if (newAge === age || newAge === '') {
            return;
        }

        setAge(newAge);
        onChange && onChange(newAge, parseInt(newAge));
    };

    const ageOptions = useMemo(() => {
        const { singular, plural, genitivePlural } = defaultGuestsPickerTranslation.ageLabel;

        const getGuestLabel = (count: number) =>
            wordPluralisation(count, singular, plural, genitivePlural);

        return [...Array(max + 1).keys()].map(age => (
            <option key={age} value={age}>
                {age} {getGuestLabel(age)}
            </option>
        ));
    }, [max]);

    return (
        <Select
            className="wc-guestpicker-select"
            isRequired={isRequired}
            value={isNil(age) ? '' : age}
            onFocus={() => setIsTouched(true)}
            onChange={handleOnChange}
            sx={themeStyles.select}
            iconColor={iconColor}
        >
            <option hidden disabled value="">{`${placeholder} ${label}`}</option>
            {ageOptions}
        </Select>
    );
};
