import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['wrapper']);

export const Hero = helpers.defineMultiStyleConfig({
    baseStyle: {
        wrapper: {
            position: 'relative',
            overflow: 'hidden',
            height: { base: '100vw', sm: '50vw', md: '53vw', lg: '27.5vw' },
            minHeight: { sm: '340px', lg: '380px' },
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background:
                    'linear-gradient(251deg, rgba(217, 217, 217, 0) 25%, rgba(0, 0, 0, 0.80) 100%)',
            },
        },
    },
});
