import { Logo } from '@nocowanie/common-ui';

import { useAppHeaderHook } from '../app-header/app-header.hook'

export const AppHeaderLogo = () => {
    const { isMobileRes } = useAppHeaderHook();

    return (
        <Logo
            logoProps={{
                width: isMobileRes ? 140 : 177,
                height: isMobileRes ? 22 : 28,
            }}
            showWpLabel
            wpAsPartnerProps={{
                width: isMobileRes ? 104 : 132,
                height: isMobileRes ? 15 : 18,
            }}
            url="/"
            direction={'column'}
        />
    );
};
