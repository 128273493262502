import { createSelector, createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '@nocowanie/core';

import { UserSliceName } from '@app/enums';
import { UserStateType } from '@app/models';

import { getUserProfile } from '../api-thunks';

const initialState: UserStateType = {
    loading: LoadingState.IDLE,
    userProfile: undefined,
};

const slice = createSlice({
    name: UserSliceName.UserProfile,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getUserProfile.pending, state => {
            state.loading = LoadingState.PENDING;
        });
        builder.addCase(getUserProfile.rejected, (state, action) => {
            state.errors = [action.payload as string];
            state.loading = LoadingState.ERROR;
            state.userProfile = null;
        });
        builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
            state.errors = null;
            state.loading = LoadingState.SUCCESS;
            state.userProfile = payload.data;
        });
    },
});

const { actions: sliceActions } = slice;
const actions = {
    ...sliceActions,
    getUserProfile,
};

const selectSelfState = (state: { [UserSliceName.UserProfile]: UserStateType }) => {
    return state[slice.name] ?? initialState;
};

const selectors = {
    selectLoading: createSelector(selectSelfState, state => state.loading),
    selectUser: createSelector(selectSelfState, state => state.userProfile),
};

export { slice, actions, selectors };
