import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { dateHelpers, LoadingState } from '@nocowanie/core';

import { SliceNameEnum } from '@app/enums';
import { SummaryInputDataModel, SummaryStateModel } from '@app/models';
import { Apartment, GuestCancelReason, ReservationStatus } from '@app/models/api/api-types';
import { SummaryService } from '@app/services';

const initialState: SummaryStateModel = {
    loading: LoadingState.PENDING,
    isReservationRequestDone: false,
    inputData: null,
};

const fetchReservationDetailsThunk = createAsyncThunk(
    `${SliceNameEnum.ReservationDetails}/fetch-reservationDetails`,
    async (args: SummaryInputDataModel) => {
        const reservationDetailsResult = await SummaryService.instance.loadReservationDetails(args);

        const hotelId = reservationDetailsResult.data?.hotelId;
        const hasRooms = !!reservationDetailsResult.data?.rooms?.length;

        // We assume that there is only one room
        // Multiple rooms would mean multiple requests
        // We'd need to adjust backend
        const firstRoomData = reservationDetailsResult.data?.rooms?.[0];

        if (reservationDetailsResult.errors?.length || !hasRooms || !firstRoomData || !hotelId) {
            return Promise.resolve({
                reservationDetails: reservationDetailsResult,
                accommodationDetails: null,
            });
        }

        const { adultCount, childAges, roomTypeId } = firstRoomData;

        const { provisionTypeId } = reservationDetailsResult.data;

        const accommodationDetailsArgs = {
            // We omit checkIn and checkOut on purpose, we only want aprartment details (not the availability)
            id: `/api/accommodations/${hotelId}`,
            guestsCount: adultCount,
            childrenAges: childAges,
            childrenCount: childAges.length,
            provisionType: provisionTypeId,
        };

        const accommodationResult =
            await SummaryService.instance.getAccommodationDetails(accommodationDetailsArgs);

        const { apartments, ...rest } = accommodationResult.data;
        const accommodationDetailsWithFilteredRooms = {
            ...accommodationResult,
            data: {
                ...rest,
                apartments: apartments?.filter(
                    apartment => apartment?.id === `${roomTypeId}`,
                ) as Apartment[],
            },
        };

        return Promise.resolve({
            reservationDetails: reservationDetailsResult,
            accommodationDetails: accommodationDetailsWithFilteredRooms,
        });
    },
);

const slice = createSlice({
    name: SliceNameEnum.ReservationDetails,
    initialState,
    reducers: {
        setInputData: (state, action: PayloadAction<SummaryInputDataModel>) => {
            const { payload } = action;
            const { transactionId, ...rest } = payload;

            state.inputData = {
                // Do not pass empty string
                transactionId: transactionId?.trim().length ? transactionId : undefined,
                ...rest,
            };

            state.reservationDetails = null;
            state.accommodationDetails = null;
            state.errors = null;
            state.isReservationRequestDone = false;
        },
        setError: (state, action: PayloadAction<Error>) => {
            const { payload } = action;

            state.loading = LoadingState.IDLE;
            state.inputData = null;

            state.errors = [payload];
        },
        clearErrors: state => {
            return {
                ...state,
                errors: null,
            };
        },
        clearInputData: state => {
            state.inputData = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchReservationDetailsThunk.pending, state => {
            state.loading = LoadingState.PENDING;
            state.errors = null;
        });
        builder.addCase(fetchReservationDetailsThunk.fulfilled, (state, { payload }) => {
            if (!payload) {
                return;
            }

            const { reservationDetails, accommodationDetails } = payload;

            if (reservationDetails?.data) {
                state.reservationDetails = { ...reservationDetails.data };

                if (accommodationDetails?.data) {
                    state.accommodationDetails = { ...accommodationDetails.data };
                }
            }

            state.errors = Array.from([
                ...(accommodationDetails?.errors ?? []),
                ...(reservationDetails?.errors ?? []),
            ]);

            state.isReservationRequestDone = true;
            state.loading = LoadingState.IDLE;
        });
        builder.addCase(fetchReservationDetailsThunk.rejected, (state, action) => {
            state.errors = [action.error].flat();
            state.loading = LoadingState.IDLE;
            state.isReservationRequestDone = true;
        });
    },
});

const { actions: sliceActions } = slice;

const actions = {
    ...sliceActions,
    fetchReservationDetailsThunk,
};

const selectSelfState = (state: { [SliceNameEnum.ReservationDetails]: SummaryStateModel }) => {
    return state[slice.name] ?? initialState;
};

const selectReservationDetails = createSelector(
    selectSelfState,
    state => state?.reservationDetails,
);
const selectors = {
    selectInputData: createSelector(selectSelfState, state => state.inputData),
    selectIsDataLoaded: createSelector(
        selectSelfState,
        state => state.reservationDetails && state.accommodationDetails,
    ),
    selectStateIsLoading: createSelector(
        selectSelfState,
        state => state.loading === LoadingState.PENDING,
    ),
    selectErrors: createSelector(selectSelfState, state => state.errors),
    selectHasErrors: createSelector(selectSelfState, state => !!state.errors?.length),
    selectAccommodationDetails: createSelector(
        selectSelfState,
        state => state?.accommodationDetails,
    ),
    selectReservationDetails: createSelector(selectSelfState, state => state?.reservationDetails),
    selectReservationWithPrepayment: createSelector(
        selectReservationDetails,
        state => !!state?.prepayment?.price,
    ),
    selectReservationWithCardGuarantee: createSelector(
        selectReservationDetails,
        state => state?.hasGuarantee,
    ),
    selectReservationWithConfirmation: createSelector(
        selectReservationDetails,
        state => !state?.isRealtime,
    ),
    selectReservationIsPaid: createSelector(
        selectReservationDetails,
        state => state?.onlinePaymentDetails?.isPaid,
    ),
    selectReservationWithOnlinePayment: createSelector(
        selectReservationDetails,
        state => state?.hasOnlinePayment,
    ),
    selectReservationIsCancelled: createSelector(selectReservationDetails, state =>
        [
            ReservationStatus.GuestCanceled,
            ReservationStatus.HotelRefused,
            ReservationStatus.Failed,
        ].includes(state?.status as ReservationStatus),
    ),
    selectReservationWithDeadlineExceeded: createSelector(
        selectReservationDetails,
        state =>
            !state?.onlinePaymentDetails?.isPaid &&
            state?.status === ReservationStatus.GuestCanceled &&
            state?.guestCancelReason === GuestCancelReason.OnlinePaymentDeadlineExceeded,
    ),
    selectReservationPrepaymentDeadline: createSelector(selectReservationDetails, state => {
        return state?.prepayment?.deadline
            ? dateHelpers.format(new Date(state?.prepayment?.deadline), 'dd.MM.yyyy')
            : undefined;
    }),
    selectIsReservationRequestDone: createSelector(
        selectSelfState,
        state => state.isReservationRequestDone,
    ),
};

export { slice, actions, selectors };
