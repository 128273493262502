export const baseSentryConfig = (sentryConfig) => ({
    dsn: sentryConfig.SENTRY_DNS,
    tracesSampleRate: 1,
    debug: false,
    environment: sentryConfig.SENTRY_ENVIRONMENT
        ? sentryConfig.SENTRY_ENVIRONMENT[0] +
          sentryConfig.SENTRY_ENVIRONMENT.slice(1)
        : 'Development-local',
    enabled: !!sentryConfig.SENTRY_ENABLED,
});

export const baseClientSentryConfig = (sentryConfig) => ({
    ...baseSentryConfig(sentryConfig),
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
});