import { StyleFunctionProps } from '@chakra-ui/styled-system';

export const Badge = {
    baseStyle: {
        textTransform: 'none',
        textAlign: 'center',
        rounded: 'md',
        fontWeight: 'bold',
    },

    sizes: {
        sm: {
            fontSize: 'xs',
            py: 1,
            px: 1.5,
        },
        md: {
            fontSize: 'xs',
            py: 1.5,
            px: 3,
            fontWeight: 'normal',
            rounded: 'lg',
        },

        variants: {
            solid: (props: StyleFunctionProps) => {
                const { colorScheme } = props;
                return {
                    color: colorScheme === 'yellow' ? 'black' : undefined,
                };
            },
            subtle: (props: StyleFunctionProps) => {
                const { colorScheme } = props;
                return {
                    bg: `${colorScheme}.200`,
                    color: `${colorScheme}.700`,
                };
            },
        },
    },
};
