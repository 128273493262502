import { ChangeEvent, useState } from 'react';

import { Box, Select, Text } from '@chakra-ui/react';

import { AppLang, AppLangSelectProps } from './app-lang-select.props';

export const AppLangSelect = ({
    onLangChange,
    lang = AppLang.PL,
}: AppLangSelectProps): JSX.Element => {
    const [currentLang, setCurrentLang] = useState<AppLang>(lang);
    const onLangChanged = (event: ChangeEvent<HTMLSelectElement>): void => {
        setCurrentLang(event.currentTarget.value as AppLang);
        onLangChange && onLangChange(event.currentTarget.value as AppLang);
    };

    return (
        <Box>
            <Text>Language: </Text>
            <Select w="auto" value={currentLang} onChange={onLangChanged}>
                <option value={undefined} disabled>
                    Select langugage
                </option>
                <option value={AppLang.EN}>EN</option>
                <option value={AppLang.PL}>PL</option>
            </Select>
        </Box>
    );
};
