import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { IconButton } from '@chakra-ui/button';
import { CloseIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/layout';
import { Portal } from '@chakra-ui/portal';
import { useTheme } from '@chakra-ui/system';

import isNil from 'lodash/isNil';
import { VirtualElement } from '@popperjs/core/lib/types';

import { dateHelpers } from '@nocowanie/core';

import './../date-picker/date-picker.scss';
import { RangePickerPopperProps } from './range-picker-popper.props';

import { RANGE_PICKER } from '../../../consts';
import { rangePickerHelpers } from '../../../helpers';
import { DEFAULT_SWIPE_THRESHOLD } from '../../../hooks';

export const RangePickerPopper = memo(
    ({
        dateRange,
        daysConfig,
        drawerContentRef,
        translationData,
        isDrawerRendered = false, // Update the component after drawer is rendered (on mobile)
        isMobile = false,
    }: RangePickerPopperProps) => {
        const [tooltipText, setTooltipText] = useState<string | undefined>(undefined);
        const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
        const currentTargetRef = useRef<HTMLElement | null>(null);
        const [referenceElement, setReferenceElement] = useState<HTMLElement | VirtualElement>({
            getBoundingClientRect: () =>
                ({
                    top: 0,
                    left: 0,
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    bottom: 0,
                    right: 0,
                }) as DOMRect,
        });
        const [isPopoverOpen, setIsPopoverOpen] = useState(false);
        const arrowRef = useRef<HTMLDivElement | null>(null);
        const { styles, attributes } = usePopper(referenceElement, popperElement, {
            placement: 'top',
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        padding: 8,
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [8, 8],
                    },
                },
                {
                    name: 'arrow',
                    options: {
                        padding: 10,
                        element: arrowRef?.current,
                    },
                },
            ],
            // onFirstUpdate: (state) => {} // we can't use it - see https://github.com/floating-ui/react-popper/issues/448
        });

        const theme = useTheme();
        const {
            popper: popoverStyles,
            content: popoverContentStyles,
            body: popoverBodyStyles,
        } = theme?.components?.Popover?.baseStyle || {};
        const [autoCloseTimeout, setAutoCloseTimeout] = useState<ReturnType<
            typeof setTimeout
        > | null>(null);

        const touchStartY = useRef<number | null>(null);
        const TOUCH_THRESHOLD = DEFAULT_SWIPE_THRESHOLD;

        const onClose = useCallback(() => {
            if (!isPopoverOpen) {
                return;
            }

            setIsPopoverOpen(false);

            currentTargetRef.current = null;
            setTooltipText(undefined);

            if (isMobile && !isNil(autoCloseTimeout)) {
                clearTimeout(autoCloseTimeout);
                setAutoCloseTimeout(null);
            }
        }, [autoCloseTimeout, isMobile, isPopoverOpen]);

        const getTooltipText = useCallback(
            (dateString?: string) => {
                if (!dateString || isNil(daysConfig)) {
                    return undefined;
                }

                return rangePickerHelpers.getTooltipText(
                    dateString,
                    dateRange?.startDate ?? null,
                    dateRange?.endDate ?? null,
                    daysConfig,
                    translationData,
                );
            },
            [dateRange?.startDate, dateRange?.endDate, daysConfig, translationData],
        );

        const onDatepickerEvent = useCallback(
            (event: React.MouseEvent | TouchEvent) => {
                const { target, type } = event;
                const isClickEvent = type === 'click';
                const isMouseMoveEvent = ['mousemove', 'mouseleave'].includes(type);
                const isTouchEvent = type === 'touchmove';
                const el = (target as HTMLElement)?.closest('.react-datepicker__day');

                if (type === 'touchstart') {
                    touchStartY.current = (event as TouchEvent).touches[0].clientY;
                    return;
                }

                if (isTouchEvent) {
                    const touchY = (event as TouchEvent).touches[0].clientY;
                    const touchDiff = Math.abs(touchY - (touchStartY.current || 0));

                    if (touchDiff > TOUCH_THRESHOLD) {
                        onClose();
                        return;
                    }
                }

                if ((!isMobile && isClickEvent) || (isMobile && isMouseMoveEvent)) {
                    return;
                }

                if (!el || type === 'mouseleave' || (isTouchEvent && !el)) {
                    onClose();
                    return;
                }

                if (el === currentTargetRef?.current) {
                    return;
                }

                const dateEl = el.querySelector('.react-datepicker__day-inner');
                const timestamp = dateEl?.getAttribute('data-date');
                const date = timestamp ? new Date(+timestamp) : null;
                const dateString = date ? dateHelpers.format(date, RANGE_PICKER.DATE_FORMAT) : null;

                const tooltipText = dateString
                    ? rangePickerHelpers.getTooltipText(
                          dateString,
                          dateRange?.startDate ?? null,
                          dateRange?.endDate ?? null,
                          daysConfig,
                          translationData,
                      )
                    : undefined;

                setTooltipText(tooltipText);

                if (!tooltipText) {
                    currentTargetRef.current = null;
                    setIsPopoverOpen(false);

                    return;
                }

                currentTargetRef.current = el as HTMLElement;
                setReferenceElement(el);
                if (!isTouchEvent) {
                    setIsPopoverOpen(true);
                }
            },
            [isMobile, onClose, getTooltipText],
        );
        const onScroll = useCallback(() => {
            onClose();
        }, [onClose]);

        useEffect(() => {
            // Closing popper on scroll on mobile
            if (!(isMobile && isPopoverOpen)) {
                return;
            }

            drawerContentRef?.current?.addEventListener('scroll', onScroll);

            return () => {
                drawerContentRef?.current?.removeEventListener('scroll', onScroll);
            };
        }, [drawerContentRef?.current, isMobile, isPopoverOpen]);

        useEffect(() => {
            const el = document.querySelector('.react-datepicker') as HTMLDivElement;

            if (!el) {
                return;
            }

            const events = ['mousemove', 'mouseleave', 'click', 'touchmove', 'touchstart'];
            events.forEach(event => el.addEventListener(event as any, onDatepickerEvent));

            return () => {
                events.forEach(event => el.removeEventListener(event as any, onDatepickerEvent));
            };
        }, [isDrawerRendered, onDatepickerEvent]);

        useEffect(() => {
            if (!isPopoverOpen || !isMobile) {
                autoCloseTimeout && clearTimeout(autoCloseTimeout);
                return;
            }

            if (!isNil(autoCloseTimeout)) {
                return;
            }

            const timeout = setTimeout(() => {
                onClose();
            }, RANGE_PICKER.POPPER_CLOSE_DELAY);

            setAutoCloseTimeout(timeout);
        }, [autoCloseTimeout, isMobile, isPopoverOpen, onClose]);

        return isPopoverOpen && tooltipText ? (
            <Portal>
                <Box
                    ref={setPopperElement}
                    sx={{ ...styles.popper, ...popoverStyles }}
                    {...attributes.popper}
                >
                    <Box
                        sx={{
                            ...popoverContentStyles,
                            '--popper-bg': 'var(--chakra-colors-gray-900)',
                            color: 'white',
                            borderColor: 'transparent',
                            maxW: '240px',
                            w: 'auto',
                            display: 'flex',
                            gap: 2,
                        }}
                    >
                        <Box
                            ref={arrowRef}
                            sx={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: 'inherit',
                                position: 'absolute',
                                bottom: '-5px',
                                transform: `${styles.arrow.transform} rotate(45deg)`,
                            }}
                            {...attributes.arrow}
                        />
                        {isMobile ? (
                            <IconButton
                                aria-label={translationData.tooltipClose}
                                icon={<CloseIcon fontSize={'xs'} />}
                                size={'sm'}
                                border={'none'}
                                colorScheme={'white'}
                                sx={{
                                    order: 1,
                                }}
                                onClick={onClose}
                            />
                        ) : null}
                        <Box
                            sx={{
                                ...popoverBodyStyles,
                                fontSize: 'xs',
                            }}
                        >
                            {tooltipText}
                        </Box>
                    </Box>
                </Box>
            </Portal>
        ) : null;
    },
);
