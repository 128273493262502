/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ListingSetting_ListingSettingsInput_write = {
    sortAlgorithm?: ListingSetting_ListingSettingsInput_write.sortAlgorithm;
};
export namespace ListingSetting_ListingSettingsInput_write {
    export enum sortAlgorithm {
        MIX = 'mix',
        NORMAL = 'normal',
    }
}

