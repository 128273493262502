import axios from 'axios';

import { User } from '@nocowanie/noc-api';

import { ContentLinkModel, NavLinkModel } from '@app/models';

export const modifyLink = (navLink: NavLinkModel | ContentLinkModel) => {
    const currentHostname = typeof window !== 'undefined' ? window.location.hostname : '';

    if (navLink.isSubdomain && navLink.url) {
        if (currentHostname.includes('testing')) {
            // replace needed for subdomains once on testing env
            // so at such url https://www.nocowanie.pl.testing.nocowanie.pl/
            // we'd get https://${navLink.url}.testing.nocowanie.pl/
            if (currentHostname.startsWith('www')) {
                return currentHostname.replace('www.nocowanie.pl', navLink.url);
            }
            return currentHostname.replace('nocowanie.pl', navLink.url);
        } else {
            return navLink.url;
        }
    } else {
        return navLink.url;
    }
};

export const getDashboardLink = (user: User) => {
    switch (user.type) {
        case User.type.BOK:
            return '/bok/abonamenty_new/';
        case User.type.HOTELIER:
        case User.type.SUBACCOUNT:
            return '/lokalizacje/oferty/glowna/';
        case User.type.TOURIST:
            return '/profil/member/';
        case User.type.ADMIN:
            return '/administracja/';
        case User.type.REDACTOR:
            return '/redakcja/';
        case User.type.SALESMAN:
            return '/handlowiec/spotkania/wyszukiwarka/';
        default:
            return '/boklite/abonamenty_new/';
    }
};

export const logOutUser = () => {
    axios({
        url: '/plugin/Login/?action=Wyloguj',
        method: 'post',
    })
        .then(response => {
            if (response.status !== 200) {
                throw new Error('wystąpił nieoczekiwany błąd');
            }
            return response.data;
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            window.location.reload();
        });
};
