import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['wrapper']);

export const Footer = helpers.defineMultiStyleConfig({
    baseStyle: {
        wrapper: {
            bgColor: 'tertiary.900',
            color: 'white',
        },
    },
});
