import { Avatar as ChakraAvatar } from '@chakra-ui/react';

import { IconsLinear } from '@nocowanie/icons';

import { AvatarProps } from './avatar.props';

export const Avatar = ({ src, ...props }: AvatarProps = { loading: 'eager' }): JSX.Element => {
    return (
        <ChakraAvatar
            src={src}
            bg={'secondary.25'}
            icon={<IconsLinear.User color="gray.900" />}
            {...props}
        />
    );
};
