import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Flex,
    FormControl,
    FormErrorMessage,
    RequiredIndicator,
    Text,
    useBoolean,
    VStack,
} from '@chakra-ui/react';

import isEqual from 'lodash/isEqual';

import { FormConsentsProps } from './form-consents.props';

import { useTranslation } from '../../../hooks/use-translation';
import {
    AdministratorData,
    ConsentDataModel,
    ConsentNameType,
    getDefaultFormConsentsTranslation,
} from '../../../models';

export const FormConsents = forwardRef<HTMLDivElement, FormConsentsProps>(
    (
        {
            urls,
            consents,
            initiallySelectedConsents,
            translationData = getDefaultFormConsentsTranslation({
                administratorData: AdministratorData.SzallasGroup,
            }),
            translationKey,
            onValuesChanged,
            onCheckboxChanged,
            hideAdministratorData = false,
            disabled,
            ...props
        },
        ref,
    ): JSX.Element => {
        const {
            register,
            setValue,
            getValues,
            watch,
            formState: { errors },
        } = useFormContext();
        const { t } = useTranslation(translationKey);
        const formErrors = useMemo(() => errors as any, [errors]);
        const [showAdministratorData, setShowAdministratorData] = useBoolean(false);
        const [expandedItems, setExpandedItems] = useState(new Array(consents.length).fill(false));
        const [checkedItems, setCheckedItems] = useState(new Array(consents.length).fill(false));
        const allChecked = useMemo(() => checkedItems.every(Boolean), [checkedItems]);
        const isIndeterminate = useMemo(
            () => checkedItems.some(Boolean) && !allChecked,
            [checkedItems, allChecked],
        );
        const isSimplified = consents.length <= 1;

        const formData = watch();

        useEffect(() => {
            if (!onValuesChanged) {
                return;
            }

            onValuesChanged(formData);
        }, [formData]);

        useEffect(() => {
            if (!initiallySelectedConsents) return;

            const currentCheckboxes = Object.values(initiallySelectedConsents);
            if (isEqual(currentCheckboxes, checkedItems)) return;

            const checkboxes = getValues('consentCheckbox');
            if (!checkboxes) return;

            Object.keys(checkboxes).forEach(key => {
                const currentVal = initiallySelectedConsents[key as ConsentNameType];

                setValue(`consentCheckbox[${key}]`, currentVal, {
                    shouldValidate: true,
                });
            });

            setCheckedItems([...currentCheckboxes]);
        }, [initiallySelectedConsents]);

        const toggleExpandedTextVisibility = useCallback(
            (e: React.MouseEvent<HTMLElement>, index: number) => {
                e.preventDefault();
                const items = [...expandedItems];
                items[index] = !expandedItems[index];
                setExpandedItems(items);
            },
            [expandedItems],
        );

        const handleOnChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>, id: ConsentNameType, index: number) => {
                const items = [...checkedItems];
                items[index] = e.target.checked;
                setCheckedItems(items);

                if (onCheckboxChanged) {
                    onCheckboxChanged();
                }
            },
            [checkedItems, onCheckboxChanged],
        );

        const handleSelectAll = useCallback(
            (isChecked: boolean) => {
                const checkboxes = getValues('consentCheckbox');

                Object.keys(checkboxes).forEach(index => {
                    setValue(`consentCheckbox[${index}]`, isChecked, {
                        shouldValidate: true,
                    });
                });

                setCheckedItems(new Array(consents.length).fill(isChecked));
            },
            [checkedItems],
        );

        return (
            <Box py={3} borderRadius={'md'} ref={ref} {...props}>
                {isSimplified ? null : (
                    <Checkbox
                        isChecked={allChecked}
                        isDisabled={disabled}
                        isIndeterminate={isIndeterminate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSelectAll(e.target.checked)
                        }
                        mb={2}
                    >
                        <Text as="span" fontSize="sm">
                            {translationData?.selectAll}
                        </Text>
                    </Checkbox>
                )}

                <VStack pl={isSimplified ? 0 : 4} mt={isSimplified ? 0 : 3} spacing={3}>
                    {consents?.map(
                        (
                            {
                                id,
                                isRequired,
                                excerptTranslationKey,
                                expandedTextTranslationKey,
                                validationMessage,
                            }: ConsentDataModel,
                            index: number,
                        ) => (
                            <FormControl
                                isInvalid={
                                    formErrors.consentCheckbox &&
                                    formErrors.consentCheckbox[id] &&
                                    isRequired
                                }
                                key={id}
                                isRequired={isRequired}
                            >
                                <Checkbox
                                    data-testid={`consent-${id}`}
                                    as={Flex}
                                    alignItems="flex-start"
                                    lineHeight={1}
                                    isChecked={checkedItems[index]}
                                    isDisabled={disabled}
                                    {...register(`consentCheckbox.${id}`, {
                                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleOnChange(e, id, index),
                                        required: isRequired,
                                    })}
                                >
                                    <Text
                                        as="span"
                                        fontSize="xs"
                                        dangerouslySetInnerHTML={{
                                            __html: `${t(excerptTranslationKey, urls || {})}`,
                                        }}
                                    />
                                    <RequiredIndicator />
                                    {expandedTextTranslationKey && (
                                        <>
                                            {expandedItems[index] && expandedTextTranslationKey && (
                                                <Text
                                                    as="span"
                                                    fontSize="xs"
                                                    ml={1}
                                                    dangerouslySetInnerHTML={{
                                                        __html: `${t(
                                                            expandedTextTranslationKey,
                                                            urls || {},
                                                        )}`,
                                                    }}
                                                />
                                            )}

                                            <Button
                                                variant="link"
                                                fontSize={'xs'}
                                                onClick={(e: React.MouseEvent<HTMLElement>) =>
                                                    toggleExpandedTextVisibility(e, index)
                                                }
                                            >
                                                {expandedItems[index]
                                                    ? translationData?.collapse
                                                    : translationData?.expand}
                                            </Button>
                                        </>
                                    )}
                                </Checkbox>
                                <FormErrorMessage
                                    fontSize={'xs'}
                                    data-testid={`error-message-consent-${id}`}
                                >
                                    {errors?.consentCheckbox &&
                                        formErrors.consentCheckbox[id] &&
                                        isRequired &&
                                        t(validationMessage ?? 'required').toString()}
                                </FormErrorMessage>
                            </FormControl>
                        ),
                    )}
                </VStack>
                {!hideAdministratorData ? (
                    <VStack mt={3} fontSize={'xs'} align="stretch">
                        <Text>{translationData?.administratorInfo}</Text>
                        <Collapse
                            in={showAdministratorData}
                            animateOpacity
                            startingHeight={0}
                            dangerouslySetInnerHTML={{
                                __html: translationData?.administratorInfoExpanded,
                            }}
                        />
                        <Box textAlign={'right'}>
                            <Button
                                variant="link"
                                fontSize={'xs'}
                                onClick={setShowAdministratorData.toggle}
                            >
                                {showAdministratorData
                                    ? translationData?.collapse
                                    : translationData?.expand}
                            </Button>
                        </Box>
                    </VStack>
                ) : null}
            </Box>
        );
    },
);
