import { useBreakpointValue } from '@chakra-ui/media-query';

import { commonUiStateSlice, useAppSelector } from '@app/state';

export const useAppHeaderHook = (): {
    isMobileRes: boolean | undefined;
} => {
    const isMobileBrowser = useAppSelector(commonUiStateSlice.selectors.selectIsMobileVersion);
    const isMobileRes = useBreakpointValue(
        {
            base: true,
            lg: false,
        },
        {
            fallback: isMobileBrowser ? 'base' : 'lg',
        },
    );

    return {
        isMobileRes,
    };
};
