import { forwardRef } from 'react';

import { useMultiStyleConfig } from '@chakra-ui/system';

import { IconProps, IconsLinear } from '@nocowanie/icons';

import { AutocompleteBaseItem } from './autocomplete-base-item';
import { AutocompleteItemProps } from './autocomplete-item.props';

export const AutocompleteAccommodationItem = forwardRef<HTMLLIElement, any>(
    ({ itemCount, type, ...rest }: AutocompleteItemProps, ref): JSX.Element => {
        const styles = useMultiStyleConfig('AutocompleteItem', {});

        return (
            <AutocompleteBaseItem LeftIcon={IconsLinear.Building3} ref={ref} {...rest}>
                <IconsLinear.ArrowRight
                    size={'sm'}
                    color={styles.icon.color as IconProps['color']}
                />
            </AutocompleteBaseItem>
        );
    },
);

AutocompleteAccommodationItem.displayName = 'AutocompleteAccommodationItem';
