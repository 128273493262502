import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
    'container',
    'linkWrapper',
    'mask',
    'image',
    'contentWrapper',
    'links',
]);

export const FeaturedImageWrapper = helpers.defineMultiStyleConfig({
    baseStyle: {
        container: {
            position: 'relative',
            flexDirection: 'column',
            gap: 4,
            borderRadius: '2xl',
            overflow: 'hidden',
            cursor: 'pointer',
        },
        linkWrapper: {
            position: 'static',
            _after: {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
            },
        },
        mask: {
            position: 'relative',
            width: '100%',
            _after: {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background:
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.8) 15%, rgba(0, 0, 0, 0.05) 50%)',
            },
        },
        image: {
            width: '100%',
            objectFit: 'cover',
        },
        contentWrapper: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            flexDirection: 'column',
            padding: { base: 4, md: 5 },
            color: 'white',
            textShadow: '0 0 2px black',
        },
    },
});
