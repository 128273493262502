import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import {
    DownshiftState,
    useCombobox,
    UseComboboxState,
    UseComboboxStateChangeOptions,
} from 'downshift';

interface UseAutocompleteProps<T> {
    query: string;
    setQuery: Dispatch<SetStateAction<string>>;
}

export const useAutocomplete = <T>({ query, setQuery }: UseAutocompleteProps<T>) => {
    const [prevQuery, setPrevQuery] = useState<string>(query);

    const stateReducer = useCallback(
        (
            state: DownshiftState<T>,
            actionAndChanges: UseComboboxStateChangeOptions<T>,
        ): Partial<UseComboboxState<T>> => {
            const { type, changes } = actionAndChanges;

            const actionMap: Record<string, () => Partial<UseComboboxState<T>>> = {
                [useCombobox.stateChangeTypes.InputChange]: () => ({
                    ...changes,
                    isOpen: !!changes.inputValue?.length,
                }),
                [useCombobox.stateChangeTypes.InputClick]: () => ({
                    ...changes,
                    isOpen: state.isOpen ?? false,
                }),
                [useCombobox.stateChangeTypes.InputBlur]: () => {
                    return {
                        ...changes,
                    };
                },
            };

            return actionMap[type]?.() ?? changes;
        },
        [],
    );

    return {
        query,
        prevQuery,
        setQuery,
        setPrevQuery,
        stateReducer,
    };
};
