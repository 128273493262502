import { createAsyncThunk } from '@reduxjs/toolkit';

import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import { captureMessage } from '@sentry/nextjs';

import { ApiResponseModel } from '@nocowanie/gql-core';

import { SliceNameEnum } from '@app/enums';
import { accommodationHelpers } from '@app/helpers';
import { apiTypes, RequestAdditionalParamsModel } from '@app/models';
import { BestFor } from '@app/models/api/api-types';
import {
    AccommodationBaseModel,
    BreadcrumbsModel,
    LinkspaceModel,
    PointOfInterestModel,
} from '@app/models/elastic-search';
import {
    EsAccommodationBestsellerService,
    EsAccommodationRatingService,
    EsAccommodationService,
} from '@app/services';

export interface AccommodationStaticResponse {
    staticData?: Omit<AccommodationBaseModel, 'breadcrumbs' | 'links'>;
    linkSpace?: Array<LinkspaceModel>;
    breadcrumbs?: BreadcrumbsModel;
    pointOfInterest?: Array<PointOfInterestModel>;
    isBestseller?: boolean;
}

const getInternalLabels = (
    args: apiTypes.QueryAccommodationsArgs,
    internalLabels: apiTypes.InternalLabel,
): apiTypes.InternalLabel => {
    const { childrenCount, guestsCount } = args;

    const children = Number(childrenCount ?? 0);
    const adults = Number(guestsCount ?? 0);

    if (children === 0 && adults === 2) {
        internalLabels.bestFor = [...(internalLabels.bestFor ?? []), BestFor.Couple];
    }

    if (adults > 2) {
        internalLabels.bestFor = [...(internalLabels.bestFor ?? []), BestFor.Friends];
    }

    if (children > 0 && [1, 2].includes(adults)) {
        internalLabels.bestFor = [...(internalLabels.bestFor ?? []), BestFor.Family];
    }

    let key: keyof typeof internalLabels;

    // BACKWARD COMPATIBILITY
    for (key in internalLabels) {
        if (!isArray(internalLabels[key]) || isNil(internalLabels[key])) {
            continue;
        }

        (internalLabels[key] as any) = (internalLabels[key] as any).map(
            (x: { name?: string }) => x?.name ?? x,
        );
    }

    return internalLabels;
};

export const getAccommodationStatic = createAsyncThunk<
    ApiResponseModel<AccommodationStaticResponse>,
    apiTypes.QueryAccommodationArgs & RequestAdditionalParamsModel
>(
    `${SliceNameEnum.AccommodationStatic}/fetch-data`,
    async (args, { rejectWithValue }): Promise<any> => {
        try {
            const accommodationId = args.id.split('/').pop() ?? '';

            const [accommodationStaticResult, ratingsResult, bestsellerResult] =
                await Promise.allSettled([
                    EsAccommodationService.instance.loadAccommodationData(accommodationId),
                    EsAccommodationRatingService.instance.loadAccommodationRatings(accommodationId),
                    EsAccommodationBestsellerService.instance.loadBestseller(accommodationId),
                ]);

            if (
                accommodationStaticResult.status !== 'fulfilled' ||
                !accommodationStaticResult.value?.found
            ) {
                return {
                    data: {
                        staticData: null,
                    },
                };
            }

            const {
                breadcrumbs,
                links,
                checkInStart,
                checkInEnd,
                checkOutStart,
                checkOutEnd,
                internalLabel,
                ...staticData
            } = accommodationStaticResult.value._source;

            if (isNil(links)) {
                captureMessage(`Missing linkspace for NOP_ID ${accommodationId}`, 'error');
            }

            if (isNil(breadcrumbs?.items)) {
                captureMessage(`Missing breadcrumbs for NOP_ID ${accommodationId}`, 'error');
            }

            const ratings =
                ratingsResult.status === 'fulfilled'
                    ? ratingsResult.value.hits.map(({ _source }) => ({
                          ..._source,
                          average: Number(_source.average ?? 0),
                      }))
                    : undefined;
            const isBestseller =
                bestsellerResult.status === 'fulfilled' ? bestsellerResult.value.found : undefined;

            const result: AccommodationStaticResponse = {
                staticData: {
                    ...staticData,
                    ratings: !isNil(ratings) ? { collection: ratings } : undefined,
                    internalLabel: getInternalLabels(args, internalLabel),
                    checkInStart: !isNil(checkInStart)
                        ? accommodationHelpers.formatTimeFromString(checkInStart)
                        : checkInStart,
                    checkInEnd: !isNil(checkInEnd)
                        ? accommodationHelpers.formatTimeFromString(checkInEnd)
                        : checkInEnd,
                    checkOutStart: !isNil(checkOutStart)
                        ? accommodationHelpers.formatTimeFromString(checkOutStart)
                        : checkOutStart,
                    checkOutEnd: !isNil(checkOutEnd)
                        ? accommodationHelpers.formatTimeFromString(checkOutEnd)
                        : checkOutEnd,
                },
                breadcrumbs,
                linkSpace: Object.keys(isNil(links) ? [] : links)
                    .filter(x => x !== 'params') // `params` is additional object in ES generated by backend and we need to omit them in frontend logic
                    .map(key => links[key]),
                isBestseller,
            };

            // TODO: temporary commented (https://szallashugroup.atlassian.net/browse/DNI-1637)
            // if (pointOfInterest.status === 'fulfilled' && !isNil(pointOfInterest.value)) {
            //     // Open api type definition doesn't match what we get, so we have to cast.
            //     result.pointOfInterest = pointOfInterest.value as PointOfInterest[];
            // } else if (pointOfInterest.status === 'rejected') {
            //     //TODO: handle if pointOfInterest crash
            //     console.error('err', pointOfInterest.reason);
            // }
            return { data: result };
        } catch (err) {
            return rejectWithValue([err].flat());
        }
    },
);
