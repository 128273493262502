export enum AggregateRatingType {
    Approachability = 'approachability',
    Average = 'average',
    Cleanliness = 'cleanliness',
    Convenience = 'convenience',
    Price = 'price',
    Restaurant = 'restaurant',
    Service = 'service',
    Staff = 'staff',
}
