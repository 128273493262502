export const MarketingBanner = () => {
    const scripts = `
        const isBannerClosed = sessionStorage.getItem('marketing-banner-closed') === 'true';
        const banner = document.getElementById('marketing-banner');
        
        if (banner && isBannerClosed) {
            banner.style.display = 'none';
        } else {    
            document.querySelector('#marketing-banner-close-btn').addEventListener('click', () => {
                if (banner) {
                    banner.style.display = 'none';
                    sessionStorage.setItem('marketing-banner-closed', 'true');
                }
            });        
        }
    `;

    return (
        <>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                        #marketing-banner {
                            font-size: 16px;
                        }
                        .marketing-banner-content--desktop {
                            display: block;
                        }
                        .marketing-banner-content--mobile {
                            display: none;
                        }
                        @media (max-width: 767px) {
                            #banner {
                                font-size: 14px;
                            }
                            .marketing-banner-content--desktop {
                                display: none;
                            }
                            .marketing-banner-content--mobile {
                                display: block;
                            }
                        }
                    `,
                }}
            />
            <div
                id="marketing-banner"
                style={{
                    width: '100%',
                    backgroundColor: '#0050A0',
                    color: 'white',
                    padding: '10px 16px',
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    gridGap: '16px',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div className="marketing-banner-content--desktop">
                        Planujesz wakacje? Do 10 kwietnia rezerwuj nocleg taniej –
                        <span style={{ fontWeight: '700' }}> zniżki do 30%</span> w wybranych
                        obiektach z rezerwacją online!
                    </div>
                    <div className="marketing-banner-content--mobile">
                        Wakacje ze
                        <span style={{ fontWeight: '700' }}> zniżką do 30%</span> w wybranych
                        obiektach z rezerwacją online!
                    </div>
                </div>
                <button
                    id="marketing-banner-close-btn"
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        background: 'none',
                        border: 'none',
                        outline: 'none',
                        color: 'white',
                    }}
                >
                    ✕
                </button>
            </div>
            <script dangerouslySetInnerHTML={{ __html: scripts }} />
        </>
    );
};
