import { useEffect,useRef } from 'react';

import { Box } from '@chakra-ui/react';

import { ScrollableWrapperProps } from './scrollable-wrapper.props';

export const ScrollableWrapper = ({ children, ...rest }: ScrollableWrapperProps): JSX.Element => {
    const scrollableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // FIX DNI-742: Sometimes initial scroll position was not at the beginning.
        if (scrollableRef.current) {
            scrollableRef.current.scrollLeft = 0;
        }
    }, []);

    return (
        <Box
            ref={scrollableRef}
            display={'grid'}
            gap={'1rem'}
            gridTemplateRows={'auto'}
            gridAutoFlow={'column'}
            scrollSnapType={'x mandatory'}
            overflowX={'auto'}
            marginLeft={'-1rem'}
            padding={'1rem 1rem 0'}
            width={'100vw'}
            sx={{
                '> *': {
                    scrollSnapAlign: 'center',
                },
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                }
            }}
            {...rest}
        >
            {children}
        </Box>
    );
};
