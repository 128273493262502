import { IconProps, NocIconGenerator } from '../common';

export const Rocket = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Rocket',
            path: (
                <path
                    fill="#000"
                    d="M21.732 2.746a.62.62 0 0 0-.477-.476C19.997 2 19.013 2 18.032 2c-4.033 0-6.451 2.156-8.256 5H5.705A1.876 1.876 0 0 0 4.03 8.035l-1.931 3.859a.937.937 0 0 0 .84 1.356h4.054l-.878.878a1.25 1.25 0 0 0 0 1.767l1.989 1.989a1.25 1.25 0 0 0 1.768 0l.878-.878v4.056a.937.937 0 0 0 1.356.84l3.856-1.93a1.872 1.872 0 0 0 1.036-1.675v-4.079c2.836-1.808 5.001-4.234 5.001-8.245.004-.985.004-1.97-.267-3.227Zm-4.73 5.816a1.563 1.563 0 1 1 0-3.126 1.563 1.563 0 0 1 0 3.126Z"
                />
            ),
        }}
    />
);
