import { IconProps, NocIconGenerator } from '../common';

export const Sea = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Sea',
            path: (
                <path
                    stroke="#292D32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M10.474 17.549a7.312 7.312 0 0 1 4.352-1.419h6.528M20.273 7.12a.871.871 0 0 0 .391-1.357A8.704 8.704 0 0 0 5.163 11.15a.87.87 0 0 0 1.157.87l13.953-4.9ZM11.012 2.978l-.575-1.645M13.292 9.55l2.289 6.58M21.352 21.256c-2.185.453-3.534-1.74-3.534-1.74a3.821 3.821 0 0 1-3.15 1.784 4.005 4.005 0 0 1-3.143-1.785 3.822 3.822 0 0 1-3.15 1.785 4.004 4.004 0 0 1-3.125-1.767s-1.74 2.254-3.917 1.758"
                />
            ),
        }}
    />
);
