export const RegexConst = {
    PHONE_NUMBER: /\+([0-9]){2} ([0-9]){3} ([0-9]){3} ([0-9]){3}/,
    POST_CODE: /([0-9]){2}-([0-9]){3}/,
    EMAIL_ADDRESS:
        //eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    CREDIT_CARD_EXPIRATION_DATE: /[0-9]{2}\/[0-9]{2}/,
    NUMBERS_ONLY: /^[0-9]*$/,
    PHONE_NUMBER_EXTENDED: /(\d{3}[\s.-]?\d{3}[\s.-]?\d{3}|\d{9})/g,
    HTML_TAGS: /(<([^>]+)>)/gi,
    DATE_FORMAT: /(\d{4}-\d{2}-\d{2})/, // YYYY-MM-DD
};
