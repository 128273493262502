import { Button } from '@chakra-ui/button';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';

import { modifyLink } from '@app/helpers/navigation.helpers';
import { ContentLinkGroupModel, ContentLinkModel } from '@app/models';

import { AccordionMenuItemProps } from './accordion-menu-item.props';

const InnerMenuLink = ({ subLink }: { subLink: ContentLinkModel }): JSX.Element => (
    <Link href={modifyLink(subLink)} variant={'menuLinkInner'}>
        {subLink.label}
    </Link>
);

export const AccordionMenuItem = ({
    index,
    currentItemIndex,
    navLink,
    isOpen,
    onMenuClick,
}: AccordionMenuItemProps) => {
    const styles = useMultiStyleConfig('AccordionMenuItem', {});

    return currentItemIndex === null || index === currentItemIndex ? (
        <>
            <Button
                variant={'expandableMenuItem'}
                sx={{
                    justifyContent: isOpen ? 'flex-start' : 'space-between',
                    fontSize: 'md',
                    py: 2.5,
                    px: isOpen ? 2 : 4,
                    mb: isOpen ? 0 : '3px',
                    _hover: {
                        bg: 'white',
                    },
                }}
                onClick={() => onMenuClick()}
            >
                {isOpen ? <ChevronLeftIcon sx={styles.icon} /> : null}
                <VStack align={'flex-start'} gap={0}>
                    <Text as={'span'} color={'secondary.500'} fontWeight={isOpen ? 'bold' : undefined}>
                        {navLink.navLinkLabel}
                    </Text>
                    {navLink.excerpt && !isOpen ? (
                        <Text as={'span'} color={'gray.500'} fontSize={'xs'} noOfLines={1}>
                            {navLink.excerpt}
                        </Text>
                    ) : null}
                </VStack>
                {!isOpen ? <ChevronRightIcon sx={styles.icon} /> : null}
            </Button>
            {isOpen ? (
                navLink.hasContentGroups ? (
                    <VStack bg={'white'} width={'100%'} gap={0}>
                        <Accordion allowToggle width={'100%'}>
                            {navLink.navLinkContent?.map(
                                (
                                    contentGroup: ContentLinkModel | ContentLinkGroupModel,
                                    contentIndex: number,
                                ) => (
                                    <AccordionItem key={contentIndex} border={'none'}>
                                        <AccordionButton sx={styles.button}>
                                            <Box
                                                as={'span'}
                                                fontSize={'sm'}
                                                px={'1.25rem'}
                                                py={2.5}
                                            >
                                                {'contentLinksTitle' in contentGroup
                                                    ? contentGroup.contentLinksTitle
                                                    : null}
                                            </Box>
                                            <AccordionIcon sx={styles.icon} />
                                        </AccordionButton>
                                        <AccordionPanel as={VStack} gap={0.5} pl={12}>
                                            {'contentLinks' in contentGroup
                                                ? contentGroup.contentLinks.map(
                                                      (subLink: ContentLinkModel) => (
                                                          <InnerMenuLink
                                                              key={subLink.url}
                                                              subLink={subLink}
                                                          />
                                                      ),
                                                  )
                                                : null}
                                        </AccordionPanel>
                                    </AccordionItem>
                                ),
                            )}
                        </Accordion>
                    </VStack>
                ) : (
                    <VStack bg={'white'} width={'100%'} gap={0} pl={8}>
                        {navLink.navLinkContent?.map(
                            (subLink: ContentLinkModel | ContentLinkGroupModel) =>
                                'url' in subLink ? (
                                    <InnerMenuLink key={subLink.url} subLink={subLink} />
                                ) : null,
                        )}
                    </VStack>
                )
            ) : null}
        </>
    ) : null;
};
