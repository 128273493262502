import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Iterable: any;
};

export type Accommodation = Node & {
    __typename?: 'Accommodation';
    _id?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    aggregateRating: AggregateRating;
    apartments?: Maybe<Array<Maybe<Apartment>>>;
    checkInEnd?: Maybe<Scalars['String']>;
    checkInStart?: Maybe<Scalars['String']>;
    checkOutEnd?: Maybe<Scalars['String']>;
    checkOutStart?: Maybe<Scalars['String']>;
    contactPhone?: Maybe<Scalars['String']>;
    contactPhoneVisible?: Maybe<Scalars['Boolean']>;
    convertedRatesCurrency?: Maybe<Currency>;
    country?: Maybe<Country>;
    currency?: Maybe<Currency>;
    description?: Maybe<Scalars['String']>;
    district?: Maybe<District>;
    fallbackDistance?: Maybe<Scalars['Int']>;
    freeChildAge?: Maybe<Scalars['Int']>;
    foreignPartnerId?: Maybe<Scalars['Int']>;
    hotelPhone?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    images?: Maybe<Array<Maybe<Image>>>;
    internalAmenityGroups?: Maybe<Array<Maybe<InternalAmenityGroup>>>;
    internalLabel: InternalLabel;
    labels?: Maybe<Array<Maybe<Label>>>;
    languages?: Maybe<Array<Maybe<Language>>>;
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
    name: Scalars['String'];
    paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
    pets?: Maybe<Array<Maybe<Pet>>>;
    postalCode?: Maybe<Scalars['String']>;
    ratingAverage?: Maybe<Scalars['Float']>;
    ratingCount?: Maybe<Scalars['Int']>;
    ratingDescription?: Maybe<RatingDescription>;
    ratings?: Maybe<RatingPageConnection>;
    realtimeReservable?: Maybe<Scalars['Boolean']>;
    regions?: Maybe<Array<Maybe<Region>>>;
    services?: Maybe<Array<Maybe<Service>>>;
    settlement?: Maybe<Settlement>;
    stay?: Maybe<Stay>;
    streetNumber?: Maybe<Scalars['String']>;
    totalGuestCapacity?: Maybe<Scalars['Int']>;
    type?: Maybe<HotelType>;
    uniqueFeatures?: Maybe<Array<Maybe<UniqueFeature>>>;
};

export type AccommodationRatingsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
};

/** Page connection for Accommodation. */
export type AccommodationPageConnection = {
    __typename?: 'AccommodationPageConnection';
    collection?: Maybe<Array<Maybe<Accommodation>>>;
    paginationInfo: AccommodationPaginationInfo;
};

/** Information about the pagination. */
export type AccommodationPaginationInfo = {
    __typename?: 'AccommodationPaginationInfo';
    itemsPerPage: Scalars['Int'];
    lastPage: Scalars['Int'];
    totalCount: Scalars['Int'];
};

/** AgeRange type */
export type AgeRange = {
    __typename?: 'AgeRange';
    free: Scalars['Boolean'];
    fromAge: Scalars['Int'];
    id: Scalars['ID'];
    toAge: Scalars['Int'];
};

/** AggregateRating type */
export type AggregateRating = {
    __typename?: 'AggregateRating';
    approachability?: Maybe<Scalars['Float']>;
    average?: Maybe<Scalars['Float']>;
    cleanliness?: Maybe<Scalars['Float']>;
    convenience?: Maybe<Scalars['Float']>;
    price?: Maybe<Scalars['Float']>;
    restaurant?: Maybe<Scalars['Float']>;
    service?: Maybe<Scalars['Float']>;
    staff?: Maybe<Scalars['Float']>;
};

/** Amenity type */
export type Amenity = {
    __typename?: 'Amenity';
    name: Scalars['String'];
    option?: Maybe<AmenityOption>;
    type: AmenityEnum;
};

/** AmenityEnum type */
export enum AmenityEnum {
    BathroomUse = 'BathroomUse',
    Bathtub = 'Bathtub',
    CornerSeat = 'CornerSeat',
    Couch = 'Couch',
    Covered = 'Covered',
    KitchenType = 'KitchenType',
    KitchenUse = 'KitchenUse',
    LocationOfToilet = 'LocationOfToilet',
    Open = 'Open',
    PrivateBalconyTerrace = 'PrivateBalconyTerrace',
    SeparateKitchen = 'SeparateKitchen',
    Shower = 'Shower',
    Tv = 'TV',
    TheKitchenIsWellEquipped = 'TheKitchenIsWellEquipped',
    Unknown = 'Unknown',
    Wardrobe = 'Wardrobe',
    WashingMachine = 'WashingMachine',
}

/** AmenityOption type */
export type AmenityOption = {
    __typename?: 'AmenityOption';
    description?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    option: Scalars['String'];
};

/** Apartment type */
export type Apartment = {
    __typename?: 'Apartment';
    doubleBeds?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    images?: Maybe<Array<Maybe<Image>>>;
    maxAdults: Scalars['Int'];
    maxChildren: Scalars['Int'];
    maxExtraBeds?: Maybe<Scalars['Int']>;
    maxTotalGuests?: Maybe<Scalars['Int']>;
    minAdults: Scalars['Int'];
    name: Scalars['String'];
    roomTypes?: Maybe<Array<Maybe<RoomType>>>;
    services?: Maybe<Array<Maybe<Service>>>;
    singleBeds?: Maybe<Scalars['Int']>;
    size?: Maybe<Scalars['Int']>;
    variants?: Maybe<Array<Maybe<Variant>>>;
};

/** Bed type */
export type Bed = {
    __typename?: 'Bed';
    adultCount: Scalars['Int'];
    collapsedBeds?: Maybe<Scalars['Boolean']>;
    count: Scalars['Int'];
    name: Scalars['String'];
    type: BedType;
};

/** BedType type */
export enum BedType {
    BunkBed = 'BunkBed',
    Chairbed = 'Chairbed',
    DoubleBed = 'DoubleBed',
    DoubleBedCanopy = 'DoubleBedCanopy',
    ExtraLargeBed = 'ExtraLargeBed',
    PullOutCouch = 'PullOutCouch',
    SingleBedCanBePushedTogether = 'SingleBedCanBePushedTogether',
    SingleBedCannotBePushedTogether = 'SingleBedCannotBePushedTogether',
    Unknown = 'Unknown',
}

/** BestFor type */
export enum BestFor {
    Couple = 'Couple',
    Family = 'Family',
    Friends = 'Friends',
}

/** BookingGuestInput type */
export type BookingGuestInput = {
    address: Scalars['String'];
    consents: ConsentType;
    country: Scalars['String'];
    email: Scalars['String'];
    fullName: Scalars['String'];
    phone: Scalars['String'];
    settlement: Scalars['String'];
    zipCode: Scalars['String'];
};

/** BookingPetInput type */
export type BookingPetInput = {
    count: Scalars['Int'];
    pet: PetType;
};

/** BookingRoom type */
export type BookingRoom = {
    __typename?: 'BookingRoom';
    childAges: Scalars['Iterable'];
    convertedTotalPrice?: Maybe<Scalars['Float']>;
    guestCount?: Maybe<Scalars['Int']>;
    provisionType?: Maybe<ProvisionType>;
    ratePlanId?: Maybe<Scalars['Int']>;
    totalPrice?: Maybe<Scalars['Float']>;
    typeId?: Maybe<Scalars['Int']>;
};

/** BookingRoomInput type */
export type BookingRoomInput = {
    childAges: Scalars['Iterable'];
    guestCount: Scalars['Int'];
    provisionType: ProvisionType;
    ratePlanId: Scalars['Int'];
    roomTypeId: Scalars['Int'];
};

/** CancellationPolicy type */
export type CancellationPolicy = {
    __typename?: 'CancellationPolicy';
    arrivalHourFrom?: Maybe<Scalars['Int']>;
    arrivalHourTo?: Maybe<Scalars['Int']>;
    daysBeforeReservation: Scalars['Int'];
    daysCount: Scalars['Int'];
    description: Scalars['String'];
    penaltyAmount?: Maybe<Scalars['Int']>;
    penaltyUnit?: Maybe<DataUnit>;
};

/** CancellationRule type */
export type CancellationRule = {
    __typename?: 'CancellationRule';
    amount: Scalars['Int'];
    free: Scalars['Boolean'];
    from?: Maybe<Scalars['String']>;
    penalty: Scalars['Float'];
    prepaidPercentage: Scalars['Float'];
    rest: Scalars['Float'];
    to?: Maybe<Scalars['String']>;
    unit: DataUnit;
};

/** CancellationRules type */
export type CancellationRules = {
    __typename?: 'CancellationRules';
    freeCancellation: Scalars['Boolean'];
    from: Scalars['String'];
    prepaidAmount?: Maybe<Scalars['Float']>;
    prepaidRate?: Maybe<Scalars['Float']>;
    prepaidTo?: Maybe<Scalars['String']>;
    prepaidUnit?: Maybe<DataUnit>;
    ratePercentage?: Maybe<Scalars['Float']>;
    rules?: Maybe<Array<Maybe<CancellationRule>>>;
    to: Scalars['String'];
};

/** Cancellation type */
export type CancellationType = {
    __typename?: 'CancellationType';
    arriveDayHour?: Maybe<Scalars['String']>;
    arriveDayHourBefore?: Maybe<Scalars['String']>;
    daysBeforeReservation: Scalars['Int'];
    hasCardGuaranteePenalty: Scalars['Boolean'];
    numDays: Scalars['Int'];
    penaltyAmount: Scalars['Float'];
    penaltyType: DataUnit;
    policyId?: Maybe<Scalars['Int']>;
    prepaymentId?: Maybe<Scalars['Int']>;
    reservationId?: Maybe<Scalars['Int']>;
};

export type CardPaymentInitiation = Node & {
    __typename?: 'CardPaymentInitiation';
    _id: Scalars['String'];
    id: Scalars['ID'];
    paymentUrl: Scalars['String'];
    returnUrl: Scalars['String'];
};

/** CardPaymentInput type */
export type CardPaymentInput = {
    cardHolderName: Scalars['String'];
    cardNumber: Scalars['String'];
    expirationDate: Scalars['String'];
};

/** CheapestRate type */
export type CheapestRate = {
    __typename?: 'CheapestRate';
    baseRate?: Maybe<Scalars['Float']>;
    checkIn?: Maybe<Scalars['String']>;
    checkOut?: Maybe<Scalars['String']>;
    convertedBaseRate?: Maybe<Scalars['Float']>;
    convertedRate?: Maybe<Scalars['Float']>;
    discounts?: Maybe<Array<Maybe<Discount>>>;
    guests?: Maybe<Scalars['Int']>;
    provision: Provision;
    rate?: Maybe<Scalars['Float']>;
    rooms?: Maybe<Array<Maybe<CheapestRateRoom>>>;
};

/** CheapestRateRoom type */
export type CheapestRateRoom = {
    __typename?: 'CheapestRateRoom';
    childAges: Scalars['Iterable'];
    rate?: Maybe<Rate>;
};

/** ChildRate type */
export type ChildRate = {
    __typename?: 'ChildRate';
    ageRange?: Maybe<AgeRange>;
    apartment?: Maybe<Apartment>;
    rate?: Maybe<Scalars['Float']>;
    ratePlan?: Maybe<RatePlan>;
};

/** Consent type */
export type ConsentType = {
    mailing: Scalars['Boolean'];
    profiling: Scalars['Boolean'];
    szallasTouristGDPR: Scalars['Boolean'];
};

export type Counter = Node & {
    __typename?: 'Counter';
    counterValue?: Maybe<Array<Maybe<CounterValue>>>;
    id: Scalars['ID'];
    operation: Scalars['String'];
    type: Scalars['String'];
};

/** Counter Input Type */
export type CounterInputType = {
    operation: CounterOperationType;
    type: CounterType;
};

/** CounterOperationType, enum: NoOperation, Remove etc */
export enum CounterOperationType {
    NoOperation = 'NoOperation',
    Remove = 'Remove',
}

/** CounterType enum type: Type, Model, PaymentMethods etc. */
export enum CounterType {
    ProvisionTypes = 'ProvisionTypes',
    Services = 'Services',
    Tags = 'Tags',
    Type = 'Type',
}

/** CounterValue type */
export type CounterValue = {
    __typename?: 'CounterValue';
    type: Scalars['String'];
    value: Scalars['Int'];
};

/** Country type */
export type Country = {
    __typename?: 'Country';
    code: CountryCode;
    id: Scalars['ID'];
    name: Scalars['String'];
};

/** CountryCode type */
export enum CountryCode {
    Ad = 'AD',
    Ae = 'AE',
    Af = 'AF',
    Ag = 'AG',
    Ai = 'AI',
    Al = 'AL',
    Am = 'AM',
    An = 'AN',
    Ao = 'AO',
    Aq = 'AQ',
    Ar = 'AR',
    As = 'AS',
    At = 'AT',
    Au = 'AU',
    Aw = 'AW',
    Ax = 'AX',
    Az = 'AZ',
    Ba = 'BA',
    Bb = 'BB',
    Bd = 'BD',
    Be = 'BE',
    Bf = 'BF',
    Bg = 'BG',
    Bh = 'BH',
    Bi = 'BI',
    Bj = 'BJ',
    Bl = 'BL',
    Bm = 'BM',
    Bn = 'BN',
    Bo = 'BO',
    Bq = 'BQ',
    Br = 'BR',
    Bs = 'BS',
    Bt = 'BT',
    Bv = 'BV',
    Bw = 'BW',
    By = 'BY',
    Bz = 'BZ',
    Ca = 'CA',
    Cc = 'CC',
    Cd = 'CD',
    Cf = 'CF',
    Cg = 'CG',
    Ch = 'CH',
    Ci = 'CI',
    Ck = 'CK',
    Cl = 'CL',
    Cm = 'CM',
    Cn = 'CN',
    Co = 'CO',
    Cr = 'CR',
    Cs = 'CS',
    Cu = 'CU',
    Cv = 'CV',
    Cw = 'CW',
    Cx = 'CX',
    Cy = 'CY',
    Cz = 'CZ',
    De = 'DE',
    Dj = 'DJ',
    Dk = 'DK',
    Dm = 'DM',
    Do = 'DO',
    Dz = 'DZ',
    Ec = 'EC',
    Ee = 'EE',
    Eg = 'EG',
    Eh = 'EH',
    En = 'EN',
    Er = 'ER',
    Es = 'ES',
    Et = 'ET',
    Fi = 'FI',
    Fj = 'FJ',
    Fk = 'FK',
    Fm = 'FM',
    Fo = 'FO',
    Fr = 'FR',
    Ga = 'GA',
    Gb = 'GB',
    Gd = 'GD',
    Ge = 'GE',
    Gf = 'GF',
    Gg = 'GG',
    Gh = 'GH',
    Gi = 'GI',
    Gl = 'GL',
    Gm = 'GM',
    Gn = 'GN',
    Gp = 'GP',
    Gq = 'GQ',
    Gr = 'GR',
    Gs = 'GS',
    Gt = 'GT',
    Gu = 'GU',
    Gw = 'GW',
    Gy = 'GY',
    Hk = 'HK',
    Hm = 'HM',
    Hn = 'HN',
    Hr = 'HR',
    Ht = 'HT',
    Hu = 'HU',
    Id = 'ID',
    Ie = 'IE',
    Il = 'IL',
    Im = 'IM',
    In = 'IN',
    Io = 'IO',
    Iq = 'IQ',
    Ir = 'IR',
    Is = 'IS',
    It = 'IT',
    Je = 'JE',
    Jm = 'JM',
    Jo = 'JO',
    Jp = 'JP',
    Ke = 'KE',
    Kg = 'KG',
    Kh = 'KH',
    Ki = 'KI',
    Km = 'KM',
    Kn = 'KN',
    Kp = 'KP',
    Kr = 'KR',
    Kw = 'KW',
    Ky = 'KY',
    Kz = 'KZ',
    La = 'LA',
    Lb = 'LB',
    Lc = 'LC',
    Li = 'LI',
    Lk = 'LK',
    Lr = 'LR',
    Ls = 'LS',
    Lt = 'LT',
    Lu = 'LU',
    Lv = 'LV',
    Ly = 'LY',
    Ma = 'MA',
    Mc = 'MC',
    Md = 'MD',
    Me = 'ME',
    Mf = 'MF',
    Mg = 'MG',
    Mh = 'MH',
    Mk = 'MK',
    Ml = 'ML',
    Mm = 'MM',
    Mn = 'MN',
    Mo = 'MO',
    Mp = 'MP',
    Mq = 'MQ',
    Mr = 'MR',
    Ms = 'MS',
    Mt = 'MT',
    Mu = 'MU',
    Mv = 'MV',
    Mw = 'MW',
    Mx = 'MX',
    My = 'MY',
    Mz = 'MZ',
    Na = 'NA',
    Nc = 'NC',
    Ne = 'NE',
    Nf = 'NF',
    Ng = 'NG',
    Ni = 'NI',
    Nl = 'NL',
    No = 'NO',
    Np = 'NP',
    Nr = 'NR',
    Nu = 'NU',
    Nz = 'NZ',
    Om = 'OM',
    Pa = 'PA',
    Pe = 'PE',
    Pf = 'PF',
    Pg = 'PG',
    Ph = 'PH',
    Pk = 'PK',
    Pl = 'PL',
    Pm = 'PM',
    Pn = 'PN',
    Pr = 'PR',
    Ps = 'PS',
    Pt = 'PT',
    Pw = 'PW',
    Py = 'PY',
    Qa = 'QA',
    Re = 'RE',
    Ro = 'RO',
    Rs = 'RS',
    Ru = 'RU',
    Rw = 'RW',
    Sa = 'SA',
    Sb = 'SB',
    Sc = 'SC',
    Sd = 'SD',
    Se = 'SE',
    Sg = 'SG',
    Sh = 'SH',
    Si = 'SI',
    Sj = 'SJ',
    Sk = 'SK',
    Sl = 'SL',
    Sm = 'SM',
    Sn = 'SN',
    So = 'SO',
    Sr = 'SR',
    Ss = 'SS',
    St = 'ST',
    Sv = 'SV',
    Sx = 'SX',
    Sy = 'SY',
    Sz = 'SZ',
    Tc = 'TC',
    Td = 'TD',
    Tf = 'TF',
    Tg = 'TG',
    Th = 'TH',
    Tj = 'TJ',
    Tk = 'TK',
    Tl = 'TL',
    Tm = 'TM',
    Tn = 'TN',
    To = 'TO',
    Tr = 'TR',
    Tt = 'TT',
    Tv = 'TV',
    Tw = 'TW',
    Tz = 'TZ',
    Ua = 'UA',
    Ug = 'UG',
    Um = 'UM',
    Us = 'US',
    Uy = 'UY',
    Uz = 'UZ',
    Unknown = 'Unknown',
    Va = 'VA',
    Vc = 'VC',
    Ve = 'VE',
    Vg = 'VG',
    Vi = 'VI',
    Vn = 'VN',
    Vu = 'VU',
    Wf = 'WF',
    Ws = 'WS',
    Xk = 'XK',
    Ye = 'YE',
    Yt = 'YT',
    Za = 'ZA',
    Zm = 'ZM',
    Zw = 'ZW',
}

/** CreditCard type */
export enum CreditCard {
    Amex = 'Amex',
    Maestro = 'Maestro',
    MasterCard = 'MasterCard',
    Visa = 'VISA',
}

/** Currency type */
export enum Currency {
    Czk = 'CZK',
    Eur = 'EUR',
    Gbp = 'GBP',
    Huf = 'HUF',
    Pln = 'PLN',
    Ron = 'RON',
    Usd = 'USD',
    Unknown = 'Unknown',
}

/** DataUnit type */
export enum DataUnit {
    Currency = 'Currency',
    CurrencyPerGuest = 'Currency_per_guest',
    CurrencyPerNight = 'Currency_per_night',
    Night = 'Night',
    None = 'None',
    Percent = 'Percent',
    Percentage = 'Percentage',
}

/** Discount type */
export type Discount = {
    __typename?: 'Discount';
    discountInfo: Scalars['String'];
    id: Scalars['ID'];
    unit: DataUnit;
    value: Scalars['Float'];
};

/** District type */
export type District = {
    __typename?: 'District';
    hotelCount?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    name: Scalars['String'];
};

/** FallbackInput type */
export type FallbackInput = {
    distance: Scalars['Int'];
    maxResult: Scalars['Int'];
    triggerCount: Scalars['Int'];
};

/** FinalRate type */
export type FinalRateType = {
    __typename?: 'FinalRateType';
    acceptedCreditCards?: Maybe<Array<Maybe<CreditCard>>>;
    appliedDiscounts: Scalars['Iterable'];
    basePrice: Scalars['Float'];
    bookingRooms?: Maybe<Array<Maybe<BookingRoom>>>;
    cancelable: Scalars['Boolean'];
    convertedBasePrice?: Maybe<Scalars['Float']>;
    convertedCurrency?: Maybe<Currency>;
    convertedTotalPrice?: Maybe<Scalars['Float']>;
    convertedTotalPriceTax?: Maybe<Scalars['Float']>;
    currency?: Maybe<Currency>;
    discounts?: Maybe<Array<Maybe<Discount>>>;
    guestPaymentAmount: Scalars['Float'];
    hasCardGuarantee: Scalars['Boolean'];
    hasOnlinePayment: Scalars['Boolean'];
    originalCurrency?: Maybe<Currency>;
    pets?: Maybe<Array<Maybe<PetType>>>;
    prepayment?: Maybe<PrepaymentType>;
    prepaymentSummary?: Maybe<PrepaymentSummaryType>;
    realtimeReservation: Scalars['Boolean'];
    requiresGuestAddress: Scalars['Boolean'];
    reservationBasePrice: Scalars['Float'];
    totalPrice: Scalars['Float'];
    totalPriceTax: Scalars['Float'];
};

/** Guarantee type */
export type Guarantee = {
    __typename?: 'Guarantee';
    amount?: Maybe<Scalars['Int']>;
    cancellationPolicies?: Maybe<Array<Maybe<CancellationPolicy>>>;
    cancellationTexts: Scalars['Iterable'];
    deadlineDays?: Maybe<Scalars['Int']>;
    description: Scalars['String'];
    unit?: Maybe<DataUnit>;
};

/** GuestCancelReason type */
export enum GuestCancelReason {
    BetterOffer = 'better_offer',
    BetterOfferSzallas = 'better_offer_szallas',
    DirectAtHotel = 'direct_at_hotel',
    HotelCannotProvide = 'hotel_cannot_provide',
    HotelGuestCanceled = 'hotel_guest_canceled',
    HotelGuestNoPrepayment = 'hotel_guest_no_prepayment',
    HotelGuestNotArrived = 'hotel_guest_not_arrived',
    HotelMaintenance = 'hotel_maintenance',
    OnlinePaymentDeadlineExceeded = 'online_payment_deadline_exceeded',
    Other = 'other',
    PaymentOrCancellation = 'payment_or_cancellation',
    Private = 'private',
    Unknown = 'unknown',
}

/** GuestCategory type */
export type GuestCategory = {
    __typename?: 'GuestCategory';
    name: Scalars['String'];
    type: GuestCategoryEnum;
};

/** GuestCategoryEnum type */
export enum GuestCategoryEnum {
    Colleagues = 'Colleagues',
    FamilyWithOlderChildren = 'FamilyWithOlderChildren',
    FamilyWithSmallChild = 'FamilyWithSmallChild',
    Group = 'Group',
    GroupOfFriends = 'GroupOfFriends',
    IndividualTraveller = 'IndividualTraveller',
    MiddleAgedCouple = 'MiddleAgedCouple',
    OlderCouple = 'OlderCouple',
    Other = 'Other',
    Unknown = 'Unknown',
    YoungCouple = 'YoungCouple',
}

/** HotelType type */
export type HotelType = {
    __typename?: 'HotelType';
    name: Scalars['String'];
    type: HotelTypeEnum;
};

/** HotelTypeEnum type */
export enum HotelTypeEnum {
    Apartment = 'Apartment',
    Chalet = 'Chalet',
    Guesthouse = 'Guesthouse',
    HolidayHome = 'HolidayHome',
    Hostel = 'Hostel',
    Hotel = 'Hotel',
    Inn = 'Inn',
    Lighthouse = 'Lighthouse',
    Motel = 'Motel',
    Pension = 'Pension',
    Resort = 'Resort',
    RuralTourism = 'RuralTourism',
    Sanatorium = 'Sanatorium',
    StudentHostel = 'StudentHostel',
    Unknown = 'Unknown',
    Villa = 'Villa',
}

/** HouseRoomType type */
export type HouseRoomType = {
    __typename?: 'HouseRoomType';
    name: Scalars['String'];
    type: HouseRoomTypeEnum;
};

/** HouseRoomTypeEnum type */
export enum HouseRoomTypeEnum {
    Bathroom = 'Bathroom',
    Bedroom = 'Bedroom',
    DiningRoom = 'DiningRoom',
    Kitchen = 'Kitchen',
    LivingRoom = 'LivingRoom',
    LivingRoomWithOpenAmericanKitchen = 'LivingRoomWithOpenAmericanKitchen',
    Terrace = 'Terrace',
    Toilet = 'Toilet',
    Unknown = 'Unknown',
}

/** Image type */
export type Image = {
    __typename?: 'Image';
    default: Scalars['Boolean'];
    id: Scalars['ID'];
    url: Scalars['String'];
};

/** Inpayment type */
export type Inpayment = {
    __typename?: 'Inpayment';
    amount: Scalars['Float'];
    date: Scalars['String'];
    paymentMethod?: Maybe<PaymentMethod>;
};

/** InputService type */
export type InputService = {
    serviceGroup?: InputMaybe<Array<InputMaybe<ServiceType>>>;
};

/** InternalAmenityGroup type */
export type InternalAmenityGroup = {
    __typename?: 'InternalAmenityGroup';
    free?: Maybe<Scalars['Boolean']>;
    label: Scalars['String'];
    type: InternalAmenityGroupEnum;
};

/** InternalAmenityGroupEnum type */
export enum InternalAmenityGroupEnum {
    AirConditioning = 'AirConditioning',
    Catering = 'Catering',
    ChildrensArea = 'ChildrensArea',
    Grill = 'Grill',
    Jacuzzi = 'Jacuzzi',
    Kitchen = 'Kitchen',
    Parking = 'Parking',
    PetFriendly = 'PetFriendly',
    Sauna = 'Sauna',
    SwimmingPool = 'SwimmingPool',
    Tv = 'Tv',
    Unknown = 'Unknown',
    Wifi = 'Wifi',
}

/** InternalLabel type */
export type InternalLabel = {
    __typename?: 'InternalLabel';
    bestFor?: Maybe<Array<Maybe<BestFor>>>;
    provisionInformation?: Maybe<ProvisionInformationEnum>;
    quantity?: Maybe<Array<Maybe<QuantityEnum>>>;
    reservationType: ReservationTypeEnum;
};

/** Label type */
export enum Label {
    AllowRequests = 'AllowRequests',
    BabyFriendly = 'BabyFriendly',
    BonNoGuestDiscount = 'BonNoGuestDiscount',
    CampaignParticipant = 'CampaignParticipant',
    Castle = 'Castle',
    ChildFriendly = 'ChildFriendly',
    ErzsebetProgram = 'ErzsebetProgram',
    Excellent = 'Excellent',
    HasHotelYearAward = 'HasHotelYearAward',
    NewAccommodation = 'NewAccommodation',
    NocowanieCloneAccommodation = 'NocowanieCloneAccommodation',
    OnlinePayment = 'OnlinePayment',
    PetFriendly = 'PetFriendly',
    Prioritized = 'Prioritized',
    PromotedInTv = 'PromotedInTv',
    SatisfactionGuarantee = 'SatisfactionGuarantee',
    SmokingAllowed = 'SmokingAllowed',
    Superior = 'Superior',
    SzallasClub = 'SzallasClub',
    Telekom = 'Telekom',
    Unknown = 'Unknown',
    Wellness = 'Wellness',
}

/** Language type */
export type Language = {
    __typename?: 'Language';
    code: LanguageCode;
    name: Scalars['String'];
};

/** LanguageCode type */
export enum LanguageCode {
    Unknown = 'Unknown',
    Af = 'af',
    Am = 'am',
    Ar = 'ar',
    As = 'as',
    Az = 'az',
    Be = 'be',
    Bg = 'bg',
    Bn = 'bn',
    Bo = 'bo',
    Bs = 'bs',
    Ca = 'ca',
    Cs = 'cs',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dv = 'dv',
    Dz = 'dz',
    El = 'el',
    En = 'en',
    Es = 'es',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Fi = 'fi',
    Fo = 'fo',
    Fr = 'fr',
    Gd = 'gd',
    Gl = 'gl',
    Gn = 'gn',
    Gu = 'gu',
    He = 'he',
    Hi = 'hi',
    Hr = 'hr',
    Ht = 'ht',
    Hu = 'hu',
    Hy = 'hy',
    Id = 'id',
    Is = 'is',
    It = 'it',
    Ja = 'ja',
    Ka = 'ka',
    Kk = 'kk',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Ks = 'ks',
    Ky = 'ky',
    La = 'la',
    Lb = 'lb',
    Lo = 'lo',
    Lt = 'lt',
    Lv = 'lv',
    Mi = 'mi',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mr = 'mr',
    Ms = 'ms',
    Mt = 'mt',
    My = 'my',
    Ne = 'ne',
    Nl = 'nl',
    No = 'no',
    Or = 'or',
    Pa = 'pa',
    Pl = 'pl',
    Pr = 'pr',
    Pt = 'pt',
    Rm = 'rm',
    Ro = 'ro',
    Ru = 'ru',
    Sa = 'sa',
    Sb = 'sb',
    Sd = 'sd',
    Si = 'si',
    Sk = 'sk',
    Sl = 'sl',
    So = 'so',
    Sq = 'sq',
    Sr = 'sr',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Tk = 'tk',
    Tl = 'tl',
    Tn = 'tn',
    Tr = 'tr',
    Ts = 'ts',
    Tt = 'tt',
    Uk = 'uk',
    Ur = 'ur',
    Vi = 'vi',
    Xh = 'xh',
    Yi = 'yi',
    Zh = 'zh',
    Zu = 'zu',
}

/** LastPayment type. Present only if `transactionId` is passed. */
export type LastPayment = {
    __typename?: 'LastPayment';
    amount: Scalars['Float'];
    commissionAmount: Scalars['Float'];
    responseCode: Scalars['String'];
    successful: Scalars['Boolean'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Creates a CardPaymentInitiation. */
    createCardPaymentInitiation?: Maybe<CreateCardPaymentInitiationPayload>;
    /** Creates a Reservation. */
    createReservation?: Maybe<CreateReservationPayload>;
};

export type MutationCreateCardPaymentInitiationArgs = {
    input: CreateCardPaymentInitiationInput;
};

export type MutationCreateReservationArgs = {
    input: CreateReservationInput;
};

/** A node, according to the Relay specification. */
export type Node = {
    /** The id of this node. */
    id: Scalars['ID'];
};

/** OnlinePaymentDetails type */
export type OnlinePaymentDetails = {
    __typename?: 'OnlinePaymentDetails';
    inpayments?: Maybe<Array<Maybe<Inpayment>>>;
    isPaid: Scalars['Boolean'];
    lastPayment?: Maybe<LastPayment>;
    locallyPaidAmount: Scalars['Float'];
    onlinePaidAmount: Scalars['Float'];
    refund: Refund;
    transferDetails?: Maybe<TransferDetails>;
};

/** OrderByInput type */
export type OrderByInput = {
    by: SortBy;
    direction: SortDirection;
};

/** PaymentMethod type */
export enum PaymentMethod {
    Cash = 'Cash',
    CreditCard = 'CreditCard',
    PayU = 'PayU',
    Transfer = 'Transfer',
}

/** Pet type */
export type Pet = {
    __typename?: 'Pet';
    name: Scalars['String'];
    price?: Maybe<Scalars['Float']>;
    type: PetType;
};

/** PetType type */
export enum PetType {
    Cat = 'Cat',
    Dog = 'Dog',
    Unknown = 'Unknown',
}

/** PrepaymentEnum type */
export enum PrepaymentEnum {
    Currency = 'Currency',
    None = 'None',
    Unknown = 'Unknown',
}

/** PrepaymentSummary type */
export type PrepaymentSummaryType = {
    __typename?: 'PrepaymentSummaryType';
    calculatedPrice?: Maybe<Scalars['Float']>;
    cancellationText?: Maybe<Scalars['Iterable']>;
    cancellationTitle?: Maybe<Scalars['String']>;
    cancellations?: Maybe<Array<Maybe<CancellationType>>>;
    cardGuarantee?: Maybe<Scalars['String']>;
    convertedPrice: Scalars['Float'];
    deadline?: Maybe<Scalars['String']>;
    deadlineDays?: Maybe<Scalars['Int']>;
    earlyCheckIn?: Maybe<Scalars['Boolean']>;
    foreignPartnerCancellationText?: Maybe<Scalars['String']>;
    noPrepaymentNeeded: Scalars['Boolean'];
    paymentMethod?: Maybe<Scalars['String']>;
    prepaymentText?: Maybe<Scalars['String']>;
    prepaymentTitle?: Maybe<Scalars['String']>;
    price: Scalars['Float'];
    reservationCancellationText?: Maybe<Scalars['Iterable']>;
    reservationID?: Maybe<Scalars['Int']>;
    reservationPrepaymentText?: Maybe<Scalars['String']>;
    type: DataUnit;
};

/** Prepayment type */
export type PrepaymentType = {
    __typename?: 'PrepaymentType';
    amount?: Maybe<Scalars['Float']>;
    convertedAmount?: Maybe<Scalars['Float']>;
};

/** Provision type */
export type Provision = {
    __typename?: 'Provision';
    name: Scalars['String'];
    type: ProvisionType;
};

/** ProvisionInformation type */
export enum ProvisionInformationEnum {
    BreakfastIncluded = 'BreakfastIncluded',
    FullBoardIncluded = 'FullBoardIncluded',
    HalfBoardIncluded = 'HalfBoardIncluded',
    NoMealIncluded = 'NoMealIncluded',
}

/** ProvisionType type */
export enum ProvisionType {
    AllInclusive = 'AllInclusive',
    Breakfast = 'Breakfast',
    Dinner = 'Dinner',
    FullBoard = 'FullBoard',
    HalfBoard = 'HalfBoard',
    Lunch = 'Lunch',
    NoMeal = 'NoMeal',
    SoftAllInclusive = 'SoftAllInclusive',
    Unknown = 'Unknown',
}

/** QuantityEnum type */
export enum QuantityEnum {
    GoodPrice = 'GoodPrice',
    HighQuantity = 'HighQuantity',
}

export type Query = {
    __typename?: 'Query';
    accommodation?: Maybe<Accommodation>;
    accommodations?: Maybe<AccommodationPageConnection>;
    counters?: Maybe<Array<Maybe<Counter>>>;
    finalRate?: Maybe<FinalRateType>;
    node?: Maybe<Node>;
    ratings?: Maybe<RatingPageConnection>;
    reservationDetails?: Maybe<ReservationDetails>;
};

export type QueryAccommodationArgs = {
    checkIn?: InputMaybe<Scalars['String']>;
    checkOut?: InputMaybe<Scalars['String']>;
    childrenAges?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    childrenCount?: InputMaybe<Scalars['Int']>;
    guestsCount?: InputMaybe<Scalars['Int']>;
    id: Scalars['ID'];
    provisionType?: InputMaybe<ProvisionType>;
};

export type QueryAccommodationsArgs = {
    acceptPets?: InputMaybe<Scalars['Boolean']>;
    accommodationCategories?: InputMaybe<Array<InputMaybe<HotelTypeEnum>>>;
    after?: InputMaybe<Scalars['String']>;
    before?: InputMaybe<Scalars['String']>;
    cardPayment?: InputMaybe<Scalars['Boolean']>;
    catering?: InputMaybe<Array<InputMaybe<ProvisionType>>>;
    checkIn?: InputMaybe<Scalars['String']>;
    checkOut?: InputMaybe<Scalars['String']>;
    childrenAges?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    childrenCount?: InputMaybe<Scalars['Int']>;
    countryId?: InputMaybe<Scalars['ID']>;
    districtId?: InputMaybe<Scalars['ID']>;
    fallback?: InputMaybe<FallbackInput>;
    first?: InputMaybe<Scalars['Int']>;
    freeCancel?: InputMaybe<Scalars['Boolean']>;
    guestsCount?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    maximumPrice?: InputMaybe<Scalars['Int']>;
    minimumPrice?: InputMaybe<Scalars['Int']>;
    noClones?: InputMaybe<Scalars['Boolean']>;
    onlyAvailable?: InputMaybe<Scalars['Boolean']>;
    provisionType?: InputMaybe<ProvisionType>;
    ratingsAverage?: InputMaybe<Scalars['Float']>;
    regionId?: InputMaybe<Scalars['ID']>;
    roomServicesGroups?: InputMaybe<Array<InputMaybe<InputService>>>;
    services?: InputMaybe<Array<InputMaybe<ServiceType>>>;
    servicesGroups?: InputMaybe<Array<InputMaybe<InputService>>>;
    settlementId?: InputMaybe<Scalars['ID']>;
    settlementIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    sort?: InputMaybe<OrderByInput>;
    tags?: InputMaybe<Array<InputMaybe<Tag>>>;
    withoutPrepayment?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCountersArgs = {
    acceptPets?: InputMaybe<Scalars['Boolean']>;
    accommodationCategories?: InputMaybe<Array<InputMaybe<HotelTypeEnum>>>;
    after?: InputMaybe<Scalars['String']>;
    before?: InputMaybe<Scalars['String']>;
    cardPayment?: InputMaybe<Scalars['Boolean']>;
    catering?: InputMaybe<Array<InputMaybe<ProvisionType>>>;
    checkIn?: InputMaybe<Scalars['String']>;
    checkOut?: InputMaybe<Scalars['String']>;
    childrenAges?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    childrenCount?: InputMaybe<Scalars['Int']>;
    counters?: InputMaybe<Array<InputMaybe<CounterInputType>>>;
    countryId?: InputMaybe<Scalars['ID']>;
    districtId?: InputMaybe<Scalars['ID']>;
    fallback?: InputMaybe<FallbackInput>;
    first?: InputMaybe<Scalars['Int']>;
    freeCancel?: InputMaybe<Scalars['Boolean']>;
    guestsCount?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    maximumPrice?: InputMaybe<Scalars['Int']>;
    minimumPrice?: InputMaybe<Scalars['Int']>;
    onlyAvailable?: InputMaybe<Scalars['Boolean']>;
    provisionType?: InputMaybe<ProvisionType>;
    ratingsAverage?: InputMaybe<Scalars['Float']>;
    regionId?: InputMaybe<Scalars['ID']>;
    roomServicesGroups?: InputMaybe<Array<InputMaybe<InputService>>>;
    services?: InputMaybe<Array<InputMaybe<ServiceType>>>;
    servicesGroups?: InputMaybe<Array<InputMaybe<InputService>>>;
    settlementId?: InputMaybe<Scalars['ID']>;
    settlementIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    sort?: InputMaybe<OrderByInput>;
    tags?: InputMaybe<Array<InputMaybe<Tag>>>;
    withoutPrepayment?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFinalRateArgs = {
    accommodationID: Scalars['ID'];
    bookingRooms?: InputMaybe<Array<InputMaybe<BookingRoomInput>>>;
    checkIn?: InputMaybe<Scalars['String']>;
    checkOut?: InputMaybe<Scalars['String']>;
    pets?: InputMaybe<Array<InputMaybe<BookingPetInput>>>;
};

export type QueryNodeArgs = {
    id: Scalars['ID'];
};

export type QueryRatingsArgs = {
    accommodationId: Scalars['ID'];
    limit?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
};

export type QueryReservationDetailsArgs = {
    identifierCode?: InputMaybe<Scalars['String']>;
    reservationID: Scalars['Int'];
    transactionId?: InputMaybe<Scalars['String']>;
};

/** Rate type */
export type Rate = {
    __typename?: 'Rate';
    apartment?: Maybe<Apartment>;
    baseRate: Scalars['Float'];
    convertedBaseRate?: Maybe<Scalars['Float']>;
    convertedRate?: Maybe<Scalars['Float']>;
    guests: Scalars['Int'];
    listBaseRate?: Maybe<Scalars['Float']>;
    listRate?: Maybe<Scalars['Float']>;
    rate: Scalars['Float'];
    ratePlan: RatePlan;
};

/** RatePlan type */
export type RatePlan = {
    __typename?: 'RatePlan';
    description?: Maybe<Scalars['String']>;
    guarantee: Guarantee;
    id: Scalars['ID'];
    provision: Provision;
};

/** Rating type */
export type Rating = {
    __typename?: 'Rating';
    approachability: Scalars['Int'];
    average: Scalars['Float'];
    cleanliness: Scalars['Int'];
    convenience: Scalars['Int'];
    date: Scalars['String'];
    guest?: Maybe<RatingGuest>;
    hotelAnswer?: Maybe<Scalars['String']>;
    language: Language;
    negative?: Maybe<Scalars['String']>;
    positive?: Maybe<Scalars['String']>;
    price: Scalars['Int'];
    restaurant: Scalars['Int'];
    service: Scalars['Int'];
    staff: Scalars['Int'];
    stayNights: Scalars['Int'];
};

/** RatingDescription type */
export enum RatingDescription {
    Average = 'average',
    Decent = 'decent',
    Good = 'good',
    Sensational = 'sensational',
    VeryGood = 'veryGood',
}

/** RatingGuest type */
export type RatingGuest = {
    __typename?: 'RatingGuest';
    category?: Maybe<GuestCategory>;
    name?: Maybe<Scalars['String']>;
    settlement?: Maybe<Scalars['String']>;
};

/** Page connection for Rating. */
export type RatingPageConnection = {
    __typename?: 'RatingPageConnection';
    collection?: Maybe<Array<Maybe<Rating>>>;
    paginationInfo: RatingPaginationInfo;
};

/** Information about the pagination. */
export type RatingPaginationInfo = {
    __typename?: 'RatingPaginationInfo';
    itemsPerPage: Scalars['Int'];
    lastPage: Scalars['Int'];
    totalCount: Scalars['Int'];
};

/** ReasonCode type */
export enum ReasonCode {
    CutOffDaysNotReached = 'CutOffDaysNotReached',
    Failed = 'Failed',
    General = 'General',
    GuestAgeLimitNotAllowed = 'GuestAgeLimitNotAllowed',
    HotelClosed = 'HotelClosed',
    HotelFull = 'HotelFull',
    Inactive = 'Inactive',
    MaintenanceInterval = 'MaintenanceInterval',
    MaximalNightsExceeded = 'MaximalNightsExceeded',
    MinimalGuestsNotReached = 'MinimalGuestsNotReached',
    MinimalNightsNotReached = 'MinimalNightsNotReached',
    NoArrivalCheckInDate = 'NoArrivalCheckInDate',
    NoDepartureCheckOutDate = 'NoDepartureCheckOutDate',
    NoRoomsAvailable = 'NoRoomsAvailable',
    RequestNotSatisfied = 'RequestNotSatisfied',
    ReservationTooLong = 'ReservationTooLong',
    Undefined = 'Undefined',
    WrongFilters = 'WrongFilters',
}

/** Refund type */
export type Refund = {
    __typename?: 'Refund';
    accountDetailsPending: Scalars['Boolean'];
    guest?: Maybe<RefundGuest>;
    pendingRefundAmount: Scalars['Float'];
    totalRefundAmount: Scalars['Float'];
};

/** RefundGuest type */
export type RefundGuest = {
    __typename?: 'RefundGuest';
    bban?: Maybe<Scalars['String']>;
    bic?: Maybe<Scalars['String']>;
    iban?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

/** Region type */
export type Region = {
    __typename?: 'Region';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type Reservation = Node & {
    __typename?: 'Reservation';
    _id: Scalars['String'];
    currency?: Maybe<Currency>;
    discounts: Scalars['Iterable'];
    hotelEmail: Scalars['String'];
    hotelPhone: Scalars['String'];
    id: Scalars['ID'];
    identifierCode: Scalars['String'];
    isRealtimeReservation: Scalars['Boolean'];
    realtimeReservation?: Maybe<Scalars['Boolean']>;
    reservationId: Scalars['String'];
    totalPrice?: Maybe<Scalars['Float']>;
};

/** ReservationDetails type */
export type ReservationDetails = {
    __typename?: 'ReservationDetails';
    adultCount?: Maybe<Scalars['Int']>;
    arrival?: Maybe<Scalars['String']>;
    canCheckCardNumber?: Maybe<Scalars['Boolean']>;
    canConfirm?: Maybe<Scalars['Boolean']>;
    canUpdateBilling?: Maybe<Scalars['Boolean']>;
    cancellation?: Maybe<ReservationDetailsCancellation>;
    childAges: Scalars['Iterable'];
    comment?: Maybe<Scalars['String']>;
    commissionPercent?: Maybe<Scalars['Float']>;
    convertedCurrency?: Maybe<Currency>;
    convertedPrice?: Maybe<ReservationDetailsPrice>;
    departure?: Maybe<Scalars['String']>;
    guest?: Maybe<ReservationDetailsGuest>;
    guestCancelComment?: Maybe<Scalars['String']>;
    guestCancelReason?: Maybe<GuestCancelReason>;
    hasGuarantee?: Maybe<Scalars['Boolean']>;
    hasOnlinePayment: Scalars['Boolean'];
    hasRating?: Maybe<Scalars['Boolean']>;
    hotelId?: Maybe<Scalars['Int']>;
    hotelName?: Maybe<Scalars['String']>;
    hotelRefuseComment?: Maybe<Scalars['String']>;
    hotelRefuseReason?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    invoiceComment?: Maybe<Scalars['String']>;
    invoiceConfirmed?: Maybe<Scalars['Boolean']>;
    invoicePrice?: Maybe<Scalars['Float']>;
    isAllocationDecided?: Maybe<Scalars['Boolean']>;
    isAlwaysBilled?: Maybe<Scalars['Boolean']>;
    isRealtime?: Maybe<Scalars['Boolean']>;
    onlinePaymentDetails?: Maybe<OnlinePaymentDetails>;
    paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
    paymentNames: Scalars['Iterable'];
    prepayment?: Maybe<ReservationDetailsPrepayment>;
    price?: Maybe<ReservationDetailsPrice>;
    provisionType?: Maybe<Scalars['String']>;
    provisionTypeId?: Maybe<ProvisionType>;
    roomCancellations?: Maybe<Array<Maybe<ReservationDetailsCancellation>>>;
    rooms?: Maybe<Array<Maybe<ReservationDetailsRoom>>>;
    spaTaxIncluded?: Maybe<Scalars['Boolean']>;
    status?: Maybe<ReservationStatus>;
    submitDate?: Maybe<Scalars['String']>;
    taxes?: Maybe<Array<Maybe<ReservationDetailsTax>>>;
    todoType?: Maybe<Scalars['String']>;
};

/** ReservationDetailsCancellation type */
export type ReservationDetailsCancellation = {
    __typename?: 'ReservationDetailsCancellation';
    cancellationText?: Maybe<Scalars['Iterable']>;
    cancellationTitle?: Maybe<Scalars['String']>;
    cancellations?: Maybe<Array<Maybe<ReservationDetailsCancellationItem>>>;
    earlyCheckIn?: Maybe<Scalars['Boolean']>;
    foreignPartnerCancellationText?: Maybe<Scalars['String']>;
    nonCancelable?: Maybe<Scalars['Boolean']>;
    reservationCancellationText?: Maybe<Scalars['Iterable']>;
    roomTypeId?: Maybe<Scalars['Int']>;
};

/** ReservationDetailsCancellationItem type */
export type ReservationDetailsCancellationItem = {
    __typename?: 'ReservationDetailsCancellationItem';
    arriveDayHour?: Maybe<Scalars['String']>;
    arriveDayHourBefore?: Maybe<Scalars['String']>;
    daysBeforeReservation?: Maybe<Scalars['Int']>;
    hasCardGuaranteePenalty?: Maybe<Scalars['Boolean']>;
    numDays?: Maybe<Scalars['Int']>;
    penaltyAmount?: Maybe<Scalars['Int']>;
    policyId?: Maybe<Scalars['Int']>;
    prepaymentId?: Maybe<Scalars['Int']>;
    reservationId?: Maybe<Scalars['Int']>;
};

/** ReservationDetailsGuest type */
export type ReservationDetailsGuest = {
    __typename?: 'ReservationDetailsGuest';
    address?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    zipCode?: Maybe<Scalars['String']>;
};

/** ReservationDetailsPrepayment type */
export type ReservationDetailsPrepayment = {
    __typename?: 'ReservationDetailsPrepayment';
    cardGuarantee?: Maybe<Scalars['Boolean']>;
    convertedPrice?: Maybe<Scalars['Float']>;
    deadline?: Maybe<Scalars['String']>;
    deadlineDays?: Maybe<Scalars['Int']>;
    method?: Maybe<Scalars['String']>;
    noPrepaymentNeeded?: Maybe<Scalars['Boolean']>;
    prepaymentId?: Maybe<Scalars['Int']>;
    prepaymentText?: Maybe<Scalars['String']>;
    prepaymentTitle?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Float']>;
    reservationPrepaymentText?: Maybe<Scalars['String']>;
    type?: Maybe<PrepaymentEnum>;
};

/** ReservationDetailsPrice type */
export type ReservationDetailsPrice = {
    __typename?: 'ReservationDetailsPrice';
    currency?: Maybe<Currency>;
    price?: Maybe<Scalars['Float']>;
};

/** ReservationDetailsRoom type */
export type ReservationDetailsRoom = {
    __typename?: 'ReservationDetailsRoom';
    adultCount?: Maybe<Scalars['Int']>;
    childAges: Scalars['Iterable'];
    name?: Maybe<Scalars['String']>;
    ratePlanId?: Maybe<Scalars['Int']>;
    roomTypeId: Scalars['Int'];
};

/** ReservationDetailsTax type */
export type ReservationDetailsTax = {
    __typename?: 'ReservationDetailsTax';
    isIncluded?: Maybe<Scalars['Boolean']>;
    minAge?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    taxId?: Maybe<Scalars['Int']>;
    taxType?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
    valueType?: Maybe<Scalars['String']>;
};

/** ReservationStatus type */
export enum ReservationStatus {
    Active = 'Active',
    Failed = 'Failed',
    GuestCanceled = 'GuestCanceled',
    HotelPending = 'HotelPending',
    HotelRefused = 'HotelRefused',
    Unknown = 'Unknown',
}

/** ReservationTypeEnum type */
export enum ReservationTypeEnum {
    Query = 'Query',
    RealTime = 'RealTime',
}

/** Room type */
export type Room = {
    __typename?: 'Room';
    amenities?: Maybe<Array<Maybe<Amenity>>>;
    beds?: Maybe<Array<Maybe<Bed>>>;
};

/** RoomType type */
export type RoomType = {
    __typename?: 'RoomType';
    levelId: Scalars['Int'];
    rooms?: Maybe<Array<Maybe<Room>>>;
    type: HouseRoomType;
};

/** Service type */
export type Service = {
    __typename?: 'Service';
    free?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    serviceCategory?: Maybe<ServiceCategory>;
    type: ServiceType;
};

/** ServiceCategory type */
export type ServiceCategory = {
    __typename?: 'ServiceCategory';
    name: Scalars['String'];
    type: ServiceCategoryEnum;
};

/** ServiceCategoryEnum type */
export enum ServiceCategoryEnum {
    Catering = 'Catering',
    ChildFriendlyServices = 'ChildFriendlyServices',
    GeneralServices = 'GeneralServices',
    Internet = 'Internet',
    LeisureRecreation = 'LeisureRecreation',
    ParkingTravelling = 'ParkingTravelling',
    ServicesInTheRoom = 'ServicesInTheRoom',
    Unknown = 'Unknown',
    WellnessSectionRelaxation = 'WellnessSectionRelaxation',
    WellnessTypes = 'WellnessTypes',
}

/** ServiceType type */
export enum ServiceType {
    AMaximumOfTwoPetsAllowed = 'AMaximumOfTwoPetsAllowed',
    ASteepStaircaseLeadsUpstairs = 'ASteepStaircaseLeadsUpstairs',
    AccessFromCorridor = 'AccessFromCorridor',
    ActiveCard = 'ActiveCard',
    AdultFriendly = 'AdultFriendly',
    AdventureParkNearby = 'AdventureParkNearby',
    Aerobics = 'Aerobics',
    AerobicsNearby = 'AerobicsNearby',
    AirConditioning = 'AirConditioning',
    AirPump = 'AirPump',
    Airport = 'Airport',
    AirportNearby = 'AirportNearby',
    AirportTransfer = 'AirportTransfer',
    AllInclusive = 'AllInclusive',
    AllianzCancellationInsurance = 'AllianzCancellationInsurance',
    AlpbachtalSeenlandCard = 'AlpbachtalSeenlandCard',
    AlpenthermeEhrenbergTicket2HoursDay = 'AlpenthermeEhrenbergTicket2HoursDay',
    AnimationPrograms = 'AnimationPrograms',
    Aquapark = 'Aquapark',
    AquaparkNearby = 'AquaparkNearby',
    ArcadeGameRoom = 'ArcadeGameRoom',
    Archery = 'Archery',
    AromaCabin = 'AromaCabin',
    AromaChamber = 'AromaChamber',
    ArrangementOfBeds = 'ArrangementOfBeds',
    ArrivalAfter20_00Oclock = 'ArrivalAfter20_00Oclock',
    ArrivalAfterMidnight = 'ArrivalAfterMidnight',
    BabyBath = 'BabyBath',
    BabyBathPlugProtector = 'BabyBathPlugProtector',
    BabyBathTub = 'BabyBathTub',
    BabyCot = 'BabyCot',
    BabyCotAndHighChair = 'BabyCotAndHighChair',
    BabyCotCanBePutIn = 'BabyCotCanBePutIn',
    BabyCotPayable = 'BabyCotPayable',
    BabyCotUpTo1_5Years = 'BabyCotUpTo1_5Years',
    BabyCotUpTo2Years = 'BabyCotUpTo2Years',
    BabyCotUpTo2YearsAndHighChair = 'BabyCotUpTo2YearsAndHighChair',
    BabyCotUpTo3Years = 'BabyCotUpTo3Years',
    BabyFeedingSet = 'BabyFeedingSet',
    BabyFoodAndDrink = 'BabyFoodAndDrink',
    BabyFoodHeatingOption = 'BabyFoodHeatingOption',
    BabyPool = 'BabyPool',
    BabySupervision = 'BabySupervision',
    BabySupervisorPhone = 'BabySupervisorPhone',
    BabyToys = 'BabyToys',
    BadmintonNearby = 'BadmintonNearby',
    Balcony = 'Balcony',
    BalconyTerrace = 'BalconyTerrace',
    BallGames = 'BallGames',
    Bar = 'Bar',
    BarByThePool = 'BarByThePool',
    BarNearby = 'BarNearby',
    Barbecue = 'Barbecue',
    BarrierFree = 'BarrierFree',
    BarrierFreeForWheelchairs = 'BarrierFreeForWheelchairs',
    BarrierFreeParking = 'BarrierFreeParking',
    BarrierFreeRoom = 'BarrierFreeRoom',
    Basketball = 'Basketball',
    Bath = 'Bath',
    BathNearby = 'BathNearby',
    Bathrobe = 'Bathrobe',
    BathroomToiletries = 'BathroomToiletries',
    Bathtub = 'Bathtub',
    BeachBar = 'BeachBar',
    BeachFootball = 'BeachFootball',
    BeachTowel = 'BeachTowel',
    BeachTransfer = 'BeachTransfer',
    BeachVolleyball = 'BeachVolleyball',
    BeautyParlour = 'BeautyParlour',
    BedLinenIsNotProvided = 'BedLinenIsNotProvided',
    BedSheets = 'BedSheets',
    BedSheetsAndTowels = 'BedSheetsAndTowels',
    BedsReadyUponArrival = 'BedsReadyUponArrival',
    Bicycle = 'Bicycle',
    BicyclePathNearby = 'BicyclePathNearby',
    BicycleTouringRouteNearby = 'BicycleTouringRouteNearby',
    Bidet = 'Bidet',
    BigCapacityHouse = 'BigCapacityHouse',
    BikeRental = 'BikeRental',
    BikeRentalAtTheAccommodation = 'BikeRentalAtTheAccommodation',
    BikeRentalNearTheAccommodation = 'BikeRentalNearTheAccommodation',
    Billiard = 'Billiard',
    BilliardsAndTableTennis = 'BilliardsAndTableTennis',
    BioSauna = 'BioSauna',
    BoatRental = 'BoatRental',
    BoatRentalNearby = 'BoatRentalNearby',
    Bobsled = 'Bobsled',
    Books = 'Books',
    BoosterSeatForChildren = 'BoosterSeatForChildren',
    BottledWater = 'BottledWater',
    Bowling = 'Bowling',
    Breakfast = 'Breakfast',
    BreakfastHotAndColdBuffet = 'BreakfastHotAndColdBuffet',
    BreathingMonitor = 'BreathingMonitor',
    Buggy = 'Buggy',
    BungeeJumping = 'BungeeJumping',
    BusAndTruckParkingLot = 'BusAndTruckParkingLot',
    BusStop = 'BusStop',
    BusStopNearby = 'BusStopNearby',
    BusinessCenter = 'BusinessCenter',
    CddvdPlayer = 'CDDVDPlayer',
    CdPlayer = 'CDPlayer',
    CableTv = 'CableTV',
    CableTvWithGermanAndScandinavianChannels = 'CableTVWithGermanAndScandinavianChannels',
    CableTvWithGermanChannels = 'CableTVWithGermanChannels',
    CafNearby = 'CafNearby',
    Cafe = 'Cafe',
    CarRental = 'CarRental',
    CarRentalNearby = 'CarRentalNearby',
    CarbonMonoxideDetector = 'CarbonMonoxideDetector',
    CashNotAccepted = 'CashNotAccepted',
    Casino = 'Casino',
    CateringServices = 'CateringServices',
    CateringServicesOptionA = 'CateringServicesOptionA',
    CateringServicesOptionB = 'CateringServicesOptionB',
    CateringServicesOptionC = 'CateringServicesOptionC',
    CateringServicesOptionD = 'CateringServicesOptionD',
    CateringServicesOptionE = 'CateringServicesOptionE',
    CeilingFan = 'CeilingFan',
    CentralHeating = 'CentralHeating',
    ChangingTable = 'ChangingTable',
    ChildAnimationPrograms = 'ChildAnimationPrograms',
    ChildBedding = 'ChildBedding',
    ChildBike = 'ChildBike',
    ChildMenu = 'ChildMenu',
    ChildSlide = 'ChildSlide',
    ChildStepUpStool = 'ChildStepUpStool',
    ChildSupervision = 'ChildSupervision',
    ChildrensBedUpTo12Years = 'ChildrensBedUpTo12Years',
    ChildrensBikeRental = 'ChildrensBikeRental',
    ChildrensClub = 'ChildrensClub',
    ChildrensPool = 'ChildrensPool',
    Chinese = 'Chinese',
    Cinema = 'Cinema',
    CinemaNearby = 'CinemaNearby',
    CitySightseeingFlight = 'CitySightseeingFlight',
    Cleaning = 'Cleaning',
    CleaningDuringStay = 'CleaningDuringStay',
    CleaningOnlyOnWeekdays = 'CleaningOnlyOnWeekdays',
    CleaningOnlyOnWeekends = 'CleaningOnlyOnWeekends',
    CleaningToolsProducts = 'CleaningToolsProducts',
    ClosestGroceryStore = 'ClosestGroceryStore',
    ClubCard = 'ClubCard',
    ClubInnsbruckMemberCard = 'ClubInnsbruckMemberCard',
    CoffeeGrinder = 'CoffeeGrinder',
    CoffeeMaker = 'CoffeeMaker',
    CoffeeTeaSet = 'CoffeeTeaSet',
    Computer = 'Computer',
    ConferenceRoom = 'ConferenceRoom',
    ConnectorCover = 'ConnectorCover',
    ConstructionNearby = 'ConstructionNearby',
    Cooker = 'Cooker',
    CookingInCauldron = 'CookingInCauldron',
    CookingOnGrillDisc = 'CookingOnGrillDisc',
    Cookware = 'Cookware',
    CornerProtectorForFurnitures = 'CornerProtectorForFurnitures',
    Couch = 'Couch',
    Courtyard = 'Courtyard',
    Cruise = 'Cruise',
    Curling = 'Curling',
    CurlingFacilityNearby = 'CurlingFacilityNearby',
    Curtain = 'Curtain',
    DvdPlayer = 'DVDPlayer',
    DvdRental = 'DVDRental',
    DvdRentalNearby = 'DVDRentalNearby',
    DailyCleaning = 'DailyCleaning',
    Darts = 'Darts',
    DartsNearby = 'DartsNearby',
    DeckChair = 'DeckChair',
    Dehumidifier = 'Dehumidifier',
    DeskInTheRoom = 'DeskInTheRoom',
    DialUpInternetAccess = 'DialUpInternetAccess',
    DietaryMenu = 'DietaryMenu',
    DigitalTvService = 'DigitalTVService',
    DirectAccessToSkiSlopes = 'DirectAccessToSkiSlopes',
    DirectSkiTrailAccess = 'DirectSkiTrailAccess',
    DisabledParking = 'DisabledParking',
    Disco = 'Disco',
    DiscoNearby = 'DiscoNearby',
    DiscoNearbyTheAccommodation = 'DiscoNearbyTheAccommodation',
    Dishtowels = 'Dishtowels',
    Dishwasher = 'Dishwasher',
    Dryer = 'Dryer',
    DryingRack = 'DryingRack',
    EgyptianCottonSheets = 'EgyptianCottonSheets',
    ElectricCarChargingOption = 'ElectricCarChargingOption',
    ElectricCarChargingStation = 'ElectricCarChargingStation',
    ElectricCookerWithOven = 'ElectricCookerWithOven',
    ElectricCookerWithSmallOven = 'ElectricCookerWithSmallOven',
    ElectricHeating = 'ElectricHeating',
    ElectricHotPlate = 'ElectricHotPlate',
    ElectricStove = 'ElectricStove',
    Electricity = 'Electricity',
    Elevator = 'Elevator',
    EnergyCost = 'EnergyCost',
    EnergySupply220V = 'EnergySupply220V',
    EnglishSpeakingStaff = 'EnglishSpeakingStaff',
    EnvironmentalTax = 'EnvironmentalTax',
    EspressoMachine = 'EspressoMachine',
    EventsAndPartiesAreNotPermitted = 'EventsAndPartiesAreNotPermitted',
    ExchangeOffice = 'ExchangeOffice',
    ExchangeOfficeNearby = 'ExchangeOfficeNearby',
    ExpressCheckIn = 'ExpressCheckIn',
    ExpressCheckOut = 'ExpressCheckOut',
    ExternalEntranceToTheBedroom = 'ExternalEntranceToTheBedroom',
    ExtraBed = 'ExtraBed',
    ExtraBeds = 'ExtraBeds',
    ExtraFoldingBedsPayable = 'ExtraFoldingBedsPayable',
    ExtraRoomFor1PersonXrom = 'ExtraRoomFor1PersonXROM',
    ExtraRoomFor2Persons = 'ExtraRoomFor2Persons',
    ExtraTowel = 'ExtraTowel',
    FarmhouseStyleBuilding = 'FarmhouseStyleBuilding',
    FencedProperty = 'FencedProperty',
    FerryPortTransfer = 'FerryPortTransfer',
    Films = 'Films',
    FinalCleaning = 'FinalCleaning',
    FinalCleaningAndBedSheetsInCaseOfAStayOfLessThan6Nights = 'FinalCleaningAndBedSheetsInCaseOfAStayOfLessThan6Nights',
    FinalCleaningIsNotIncludedInThePrice = 'FinalCleaningIsNotIncludedInThePrice',
    FinnishSauna = 'FinnishSauna',
    FirePit = 'FirePit',
    Fireplace = 'Fireplace',
    FirewoodCanBePurchased = 'FirewoodCanBePurchased',
    FirstAndSecondFloor = 'FirstAndSecondFloor',
    FirstFloor = 'FirstFloor',
    FishPedicure = 'FishPedicure',
    Fishing = 'Fishing',
    FishingAtTheAccommodation = 'FishingAtTheAccommodation',
    FishingHouseStyleBuilding = 'FishingHouseStyleBuilding',
    FishingNearTheAccommodation = 'FishingNearTheAccommodation',
    Fitness = 'Fitness',
    FoodBasket = 'FoodBasket',
    FoodDeliveryToTheAreaOnSpecifiedDays = 'FoodDeliveryToTheAreaOnSpecifiedDays',
    Football = 'Football',
    FreeDrivewayOnTheProperty = 'FreeDrivewayOnTheProperty',
    FreeFirewood = 'FreeFirewood',
    FreeGarageOnTheProperty = 'FreeGarageOnTheProperty',
    FreeLocalPhoneCallsLimited = 'FreeLocalPhoneCallsLimited',
    FreeNewspapersInTheLobbyOnWeekdays = 'FreeNewspapersInTheLobbyOnWeekdays',
    FreeParking5MinutesAway = 'FreeParking5MinutesAway',
    FreeParkingNearby = 'FreeParkingNearby',
    FreePrivateParking = 'FreePrivateParking',
    FreeSkiCoursesSkiRentalAndSkiTicketsForChildrenBornSince2009 = 'FreeSkiCoursesSkiRentalAndSkiTicketsForChildrenBornSince2009',
    FreeSlideParkEntry = 'FreeSlideParkEntry',
    FreeThermalSpaTicket = 'FreeThermalSpaTicket',
    FreeToiletries = 'FreeToiletries',
    FreeTransferFromTrainStation = 'FreeTransferFromTrainStation',
    FreeTransferToTrainStation = 'FreeTransferToTrainStation',
    FreeUsageOfLocalIndoorAndOutdoorPools = 'FreeUsageOfLocalIndoorAndOutdoorPools',
    FreeWiFiLimitedUse = 'FreeWiFiLimitedUse',
    Freezer = 'Freezer',
    FreezerShelf = 'FreezerShelf',
    French = 'French',
    Fridge = 'Fridge',
    Games = 'Games',
    Garage = 'Garage',
    Garage1x = 'Garage1x',
    Garage1xUponRequest = 'Garage1xUponRequest',
    Garage2x = 'Garage2x',
    GarageUponRequest = 'GarageUponRequest',
    Garden = 'Garden',
    GardenFurniture = 'GardenFurniture',
    Gas = 'Gas',
    GasConvector = 'GasConvector',
    GasHeating = 'GasHeating',
    GasOrElectricStove = 'GasOrElectricStove',
    GasPoweredFridge = 'GasPoweredFridge',
    Gazebo = 'Gazebo',
    GeothermalHeating = 'GeothermalHeating',
    Geyser = 'Geyser',
    GlutenFreeDiet = 'GlutenFreeDiet',
    GolfCourse = 'GolfCourse',
    GolfSimulator = 'GolfSimulator',
    GolfSimulatorNearby = 'GolfSimulatorNearby',
    GolfTeachingOnSite = 'GolfTeachingOnSite',
    Griddle = 'Griddle',
    GrillingOptionPayable = 'GrillingOptionPayable',
    GroceryStore = 'GroceryStore',
    GroundFloor = 'GroundFloor',
    GuestCardCitiziensPassportCard = 'GuestCardCitiziensPassportCard',
    GuestCardGc1 = 'GuestCardGC1',
    GymSportHall = 'GymSportHall',
    GymSportHallNearby = 'GymSportHallNearby',
    Hdtv = 'HDTV',
    HoteldeTesztSzolg = 'HOTELDETesztSzolg',
    HairDryerOnRequestPayable = 'HairDryerOnRequestPayable',
    HairdresserBeautySalon = 'HairdresserBeautySalon',
    HairdresserBeautySalonNearby = 'HairdresserBeautySalonNearby',
    HairdressersParlour = 'HairdressersParlour',
    HairdressersWithFreeHaircut = 'HairdressersWithFreeHaircut',
    Hairdryer = 'Hairdryer',
    HalfBoard = 'HalfBoard',
    Handcart = 'Handcart',
    Hangers = 'Hangers',
    HaySauna = 'HaySauna',
    HealthCenter = 'HealthCenter',
    HealthCenterNearby = 'HealthCenterNearby',
    Heating = 'Heating',
    HerbalSauna = 'HerbalSauna',
    HighChair = 'HighChair',
    HighChairBabCotAndBabyBath = 'HighChairBabCotAndBabyBath',
    Hiking = 'Hiking',
    HikingOpportunities = 'HikingOpportunities',
    HorseRiding = 'HorseRiding',
    HorseRidingAtTheAccommodation = 'HorseRidingAtTheAccommodation',
    HorseRidingSchool5KmAway = 'HorseRidingSchool5KmAway',
    HorseRidingSchool15KmAway = 'HorseRidingSchool15KmAway',
    HotAirBalloon = 'HotAirBalloon',
    HotAirBalloonNearby = 'HotAirBalloonNearby',
    HotPlate = 'HotPlate',
    HotTub = 'HotTub',
    HotelAerobics = 'HotelAerobics',
    HotelAerobicsNearby = 'HotelAerobicsNearby',
    HotelBubbleBath = 'HotelBubbleBath',
    HotelDarts = 'HotelDarts',
    HotelDisco = 'HotelDisco',
    HotelGardenNearby = 'HotelGardenNearby',
    HotelHotTubNearby = 'HotelHotTubNearby',
    HotelHotelTransportation = 'HotelHotelTransportation',
    HouseMadeOfReinforcedConcrete = 'HouseMadeOfReinforcedConcrete',
    HouseMadeOfWoodAndBrick = 'HouseMadeOfWoodAndBrick',
    HuluAccess = 'HuluAccess',
    Humidifier = 'Humidifier',
    Hungarian = 'Hungarian',
    Hunting = 'Hunting',
    HuntingNearTheAccommodation = 'HuntingNearTheAccommodation',
    HurghadaElGounaOneWayTransfer = 'HurghadaElGounaOneWayTransfer',
    HydroMassageShowerHead = 'HydroMassageShowerHead',
    HypoallergenicBedding = 'HypoallergenicBedding',
    IPad = 'IPad',
    IPodDockingStation = 'IPodDockingStation',
    IWouldLikeToReceiveInformationAboutAirportTransfer = 'IWouldLikeToReceiveInformationAboutAirportTransfer',
    IWouldLikeToReceiveInformationAboutSkiPassDiscounts = 'IWouldLikeToReceiveInformationAboutSkiPassDiscounts',
    IWouldLikeToReceiveInformationAboutTheFoodBasket = 'IWouldLikeToReceiveInformationAboutTheFoodBasket',
    IceCave = 'IceCave',
    IceMaker = 'IceMaker',
    IceSauna = 'IceSauna',
    IceShower = 'IceShower',
    IceSkatingNearby = 'IceSkatingNearby',
    InCaseOfAStayLongerThan6DaysTheFinalCleaningIsIncludedInThePrice = 'InCaseOfAStayLongerThan6DaysTheFinalCleaningIsIncludedInThePrice',
    InCaseOfAStayOfAtLeast6DaysTheFirstSetOfBedSheetsAndTowelsAreProvided = 'InCaseOfAStayOfAtLeast6DaysTheFirstSetOfBedSheetsAndTowelsAreProvided',
    Indian = 'Indian',
    IndoorPaddlingPool = 'IndoorPaddlingPool',
    IndoorPool = 'IndoorPool',
    IndoorPoolHeated = 'IndoorPoolHeated',
    IndoorPoolPayable = 'IndoorPoolPayable',
    IndoorSaltwaterPoolHeated = 'IndoorSaltwaterPoolHeated',
    IndoorSwimmingPoolNearby = 'IndoorSwimmingPoolNearby',
    IndoorSwimmingPoolTicket = 'IndoorSwimmingPoolTicket',
    IndoorTennisCourt = 'IndoorTennisCourt',
    IndoorTennisCourtNearby = 'IndoorTennisCourtNearby',
    InformationServices = 'InformationServices',
    InfraSauna = 'InfraSauna',
    InnsbruckCard = 'InnsbruckCard',
    InternetAccess = 'InternetAccess',
    InternetCaf = 'InternetCaf',
    InternetCafNearby = 'InternetCafNearby',
    InternetConnection = 'InternetConnection',
    InternetCorner = 'InternetCorner',
    InternetInPublicAreas = 'InternetInPublicAreas',
    IronAndIroningBoard = 'IronAndIroningBoard',
    IronAndIroningBoardOnRequest = 'IronAndIroningBoardOnRequest',
    IronAndIroningBoardOnRequestPayable = 'IronAndIroningBoardOnRequestPayable',
    IroningAvailable = 'IroningAvailable',
    IroningBoard = 'IroningBoard',
    ItCanBeRentedOnlyForVacation = 'ItCanBeRentedOnlyForVacation',
    Italian = 'Italian',
    Jacuzzi = 'Jacuzzi',
    JacuzziBench = 'JacuzziBench',
    JacuzziNearby = 'JacuzziNearby',
    JacuzziPayable = 'JacuzziPayable',
    JadeMassageBed = 'JadeMassageBed',
    JetPool = 'JetPool',
    KaiserwinklCard = 'KaiserwinklCard',
    KaraokeEquipment = 'KaraokeEquipment',
    Kettle = 'Kettle',
    KeyAcquisitionFee = 'KeyAcquisitionFee',
    KeyAqusitionService = 'KeyAqusitionService',
    KidsPoolHeated = 'KidsPoolHeated',
    Kitchen = 'Kitchen',
    KitchenUtensils = 'KitchenUtensils',
    KitchenWithColdWater = 'KitchenWithColdWater',
    KitchenWithHotAndColdWater = 'KitchenWithHotAndColdWater',
    KitchenWithWellWater = 'KitchenWithWellWater',
    Kitchenette = 'Kitchenette',
    KitchenetteWithHotAndColdWater = 'KitchenetteWithHotAndColdWater',
    KneippCircuit = 'KneippCircuit',
    KosherFood = 'KosherFood',
    Lcdtv = 'LCDTV',
    Ledtv = 'LEDTV',
    Legoland2DayTicketConsecutiveDays = 'LEGOLAND2DayTicketConsecutiveDays',
    LegolandBreakfast = 'LEGOLANDBreakfast',
    LactoseFreeDiet = 'LactoseFreeDiet',
    LateCheckIn20_00To08_00 = 'LateCheckIn20_00To08_00',
    Laundry = 'Laundry',
    LaundryPayable = 'LaundryPayable',
    LectureHall = 'LectureHall',
    LeisurePool = 'LeisurePool',
    Library = 'Library',
    LimitedHotWater = 'LimitedHotWater',
    LimitedWiFiPayable = 'LimitedWiFiPayable',
    LimousineOrCarRenting = 'LimousineOrCarRenting',
    LimousineOrdering = 'LimousineOrdering',
    Lobby = 'Lobby',
    LobbyBarNearby = 'LobbyBarNearby',
    LocalSpecialties = 'LocalSpecialties',
    LocalTax = 'LocalTax',
    LocalTaxForChildren = 'LocalTaxForChildren',
    LocalTaxForChildren0To2Years = 'LocalTaxForChildren0To2Years',
    LocalTaxForChildren3To5Years = 'LocalTaxForChildren3To5Years',
    LocalTaxForChildren3To11Years = 'LocalTaxForChildren3To11Years',
    LocalTaxForChildren3To12Years = 'LocalTaxForChildren3To12Years',
    LocalTaxForChildren3To17Years = 'LocalTaxForChildren3To17Years',
    LocalTaxForChildren3To18Years = 'LocalTaxForChildren3To18Years',
    LocalTaxForChildren6To16Years = 'LocalTaxForChildren6To16Years',
    LocalTaxForChildren12To15Years = 'LocalTaxForChildren12To15Years',
    LocalTaxForChildren12To17 = 'LocalTaxForChildren12To17',
    LocatedNearAMainRoad = 'LocatedNearAMainRoad',
    LocatedOnDifferentLevels = 'LocatedOnDifferentLevels',
    LockableCloset = 'LockableCloset',
    LongTermParkingPayable = 'LongTermParkingPayable',
    LowCarbDiet = 'LowCarbDiet',
    LowCeilingHeight = 'LowCeilingHeight',
    LowCholesterolDiet = 'LowCholesterolDiet',
    LuggageRoom = 'LuggageRoom',
    LuxPackage = 'LuxPackage',
    LyingMassage = 'LyingMassage',
    Mp3Dock = 'MP3Dock',
    Manicure = 'Manicure',
    Massage = 'Massage',
    MassageBench = 'MassageBench',
    MassageInTheRoom = 'MassageInTheRoom',
    MassageNearby = 'MassageNearby',
    MassageNozzle = 'MassageNozzle',
    MassageSeats = 'MassageSeats',
    MedicalRoom = 'MedicalRoom',
    MedicinalPool = 'MedicinalPool',
    Mediterranean = 'Mediterranean',
    MemoryFoamMattress = 'MemoryFoamMattress',
    Mexican = 'Mexican',
    MicrowaveOven = 'MicrowaveOven',
    MiniBar = 'MiniBar',
    MiniFridge = 'MiniFridge',
    Minigolf = 'Minigolf',
    Minigrill = 'Minigrill',
    Mixer = 'Mixer',
    MobileBabyCot = 'MobileBabyCot',
    MobileHome = 'MobileHome',
    Modern = 'Modern',
    MonkeyBars = 'MonkeyBars',
    MotorboatRental = 'MotorboatRental',
    MountainClimbing = 'MountainClimbing',
    MountainClimbingNearby = 'MountainClimbingNearby',
    MudBath = 'MudBath',
    MultilingualStaff = 'MultilingualStaff',
    MurtalCard = 'MurtalCard',
    MushroomPicking = 'MushroomPicking',
    MusicLibrary = 'MusicLibrary',
    N1SetOfBeachTowels = 'N1SetOfBeachTowels',
    N1UmbrellaAnd2SunbedsPerApartment = 'N1UmbrellaAnd2SunbedsPerApartment',
    N1XSaunaUsage = 'N1XSaunaUsage',
    N2HoursOfSaunaUsage = 'N2HoursOfSaunaUsage',
    N2SetsOfWood = 'N2SetsOfWood',
    N2XParkingSpace = 'N2XParkingSpace',
    N2XWiFiAccess = 'N2XWiFiAccess',
    N3FunctionGameTable = 'N3FunctionGameTable',
    N6DayAquaSpaPassAbove16Years = 'N6DayAquaSpaPassAbove16Years',
    N6DaySwimmingPoolTicketForChildren3To15Years = 'N6DaySwimmingPoolTicketForChildren3To15Years',
    N10PercentDiscountAtSportHervisStAnton = 'N10PercentDiscountAtSportHervisSt_Anton',
    N10PercentDiscountOnSwarovskiCrystalWorldsTickets = 'N10PercentDiscountOnSwarovskiCrystalWorldsTickets',
    N13KgWood = 'N13KgWood',
    N15KgWood = 'N15KgWood',
    N20KgWood = 'N20KgWood',
    N24hHealthClub = 'N24hHealthClub',
    N24hPoolUse = 'N24hPoolUse',
    N10000LiterWaterTank = 'N10000LiterWaterTank',
    N20000LiterWaterTank = 'N20000LiterWaterTank',
    N30000LiterWaterTank = 'N30000LiterWaterTank',
    N40000LiterWaterTank = 'N40000LiterWaterTank',
    N50000LiterWaterTank = 'N50000LiterWaterTank',
    NappyDryer = 'NappyDryer',
    NaturSauna = 'NaturSauna',
    NaturalArea = 'NaturalArea',
    NaturalGasHeating = 'NaturalGasHeating',
    NearestRestaurant = 'NearestRestaurant',
    NeckShower = 'NeckShower',
    Netflix = 'Netflix',
    NetflixAccess = 'NetflixAccess',
    Newspaper = 'Newspaper',
    NewspapersInTheLobbyPayable = 'NewspapersInTheLobbyPayable',
    Nightclub = 'Nightclub',
    NoBabyCot = 'NoBabyCot',
    NoBedLinen = 'NoBedLinen',
    NoCleaning = 'NoCleaning',
    NoDoorOnTheBedroom = 'NoDoorOnTheBedroom',
    NoElevator = 'NoElevator',
    NoHeating = 'NoHeating',
    NoHotWater = 'NoHotWater',
    NoInsulationInTheHouse = 'NoInsulationInTheHouse',
    NoTowel = 'NoTowel',
    NonDrinkingWater = 'NonDrinkingWater',
    NonSmoking = 'NonSmoking',
    NonSmokingArea = 'NonSmokingArea',
    NotAvailableForSchools = 'NotAvailableForSchools',
    NotSuitableForYoungGroups = 'NotSuitableForYoungGroups',
    NursingPillow = 'NursingPillow',
    OfficeRental = 'OfficeRental',
    Oil = 'Oil',
    OilFueledPowerGenerator12V = 'OilFueledPowerGenerator12V',
    OilFueledPowerGenerator220V = 'OilFueledPowerGenerator220V',
    OilHeating = 'OilHeating',
    OilRadiator = 'OilRadiator',
    OnSiteParking = 'OnSiteParking',
    OnePersonSofa = 'OnePersonSofa',
    OneSkiPass = 'OneSkiPass',
    OneTimeCleaningDuringStay = 'OneTimeCleaningDuringStay',
    OnlyDogsAllowed = 'OnlyDogsAllowed',
    OnlyDogsAndCatsAllowed = 'OnlyDogsAndCatsAllowed',
    OnlyServiceAnimalsAllowed = 'OnlyServiceAnimalsAllowed',
    OnlyWomenAllowed = 'OnlyWomenAllowed',
    OpenFireplaceAvailable = 'OpenFireplaceAvailable',
    OpenProperty = 'OpenProperty',
    OpeningBlockerForFenestration = 'OpeningBlockerForFenestration',
    OpeningBlockerForFurniture = 'OpeningBlockerForFurniture',
    OrganizedSightseeing = 'OrganizedSightseeing',
    OrganizedSightseeingNearby = 'OrganizedSightseeingNearby',
    OrganizedTrip = 'OrganizedTrip',
    OrganizingCruises = 'OrganizingCruises',
    OrganizingCulturalAndSportingEvents = 'OrganizingCulturalAndSportingEvents',
    OutdoorActivities = 'OutdoorActivities',
    OutdoorFireplace = 'OutdoorFireplace',
    OutdoorFishCleaningPlace = 'OutdoorFishCleaningPlace',
    OutdoorPaddlingPool = 'OutdoorPaddlingPool',
    OutdoorPool = 'OutdoorPool',
    OutdoorPoolHeated = 'OutdoorPoolHeated',
    OutdoorPoolNearby = 'OutdoorPoolNearby',
    OutdoorSauna = 'OutdoorSauna',
    OutdoorSeasonalPool = 'OutdoorSeasonalPool',
    OutdoorSeasonalPoolHeated = 'OutdoorSeasonalPoolHeated',
    OutdoorSlide = 'OutdoorSlide',
    OutdoorSwimmingPoolNearby = 'OutdoorSwimmingPoolNearby',
    OutdoorTennisCourt = 'OutdoorTennisCourt',
    OutdoorTennisCourtNearby = 'OutdoorTennisCourtNearby',
    OutdoorWaterTank = 'OutdoorWaterTank',
    Oven = 'Oven',
    OwnJacuzzi = 'OwnJacuzzi',
    OwnPool = 'OwnPool',
    OwnRestaurant = 'OwnRestaurant',
    PuriaPremiumSpaPe = 'PURIAPremiumSpaPE',
    PaleoDiet = 'PaleoDiet',
    PanoramicViewToTheLandscape = 'PanoramicViewToTheLandscape',
    PanoramicViewToTheMountains = 'PanoramicViewToTheMountains',
    PanoramicViewToTheWaters = 'PanoramicViewToTheWaters',
    PaperTowel = 'PaperTowel',
    Paragliding = 'Paragliding',
    Park = 'Park',
    ParkNearby = 'ParkNearby',
    Parking = 'Parking',
    ParkingInTheStreet = 'ParkingInTheStreet',
    ParkingNearby = 'ParkingNearby',
    ParkingNearbyMustBeBookedInAdvance = 'ParkingNearbyMustBeBookedInAdvance',
    ParkingPlace = 'ParkingPlace',
    ParkingService = 'ParkingService',
    ParkingServicePayable = 'ParkingServicePayable',
    ParkingSpaceCenterValClaretLavachet = 'ParkingSpaceCenterValClaretLavachet',
    ParkingSpaceUponPriorRequest = 'ParkingSpaceUponPriorRequest',
    ParkingSpaceValThorensP0P1P25To7Nights = 'ParkingSpaceValThorensP0P1P25To7Nights',
    PartiallyInsulated = 'PartiallyInsulated',
    PastryShop = 'PastryShop',
    PastryShopNearby = 'PastryShopNearby',
    Patio = 'Patio',
    PayableParkingGarageNearby = 'PayableParkingGarageNearby',
    PedaloRental = 'PedaloRental',
    Pedicure = 'Pedicure',
    PelletStove = 'PelletStove',
    PetFriendly = 'PetFriendly',
    PetsAndServiceAnimalsNotAllowed = 'PetsAndServiceAnimalsNotAllowed',
    PetsAreAllowed = 'PetsAreAllowed',
    Phone = 'Phone',
    PicnicArea = 'PicnicArea',
    Pizzeria = 'Pizzeria',
    PizzeriaNearby = 'PizzeriaNearby',
    PlaneTicketReservation = 'PlaneTicketReservation',
    PlasmaTv = 'PlasmaTV',
    PlayCarpet = 'PlayCarpet',
    Playground = 'Playground',
    PlaygroundNearby = 'PlaygroundNearby',
    Playhouse = 'Playhouse',
    Playpen = 'Playpen',
    Playroom = 'Playroom',
    PlumbingSystem = 'PlumbingSystem',
    PlungePool = 'PlungePool',
    PlungeTub = 'PlungeTub',
    Pool = 'Pool',
    PoolHeating = 'PoolHeating',
    PoolHeatingUponRequestNoLaterThan7DaysBeforeArrival = 'PoolHeatingUponRequestNoLaterThan7DaysBeforeArrival',
    PoolTicket = 'PoolTicket',
    Pools = 'Pools',
    PoolsideCabanas = 'PoolsideCabanas',
    PoolsideCabanasPayable = 'PoolsideCabanasPayable',
    Port = 'Port',
    PortableRadiator = 'PortableRadiator',
    Potty = 'Potty',
    PremiumTvChannels = 'PremiumTVChannels',
    PrivateBathroomOutsideTheRoom = 'PrivateBathroomOutsideTheRoom',
    PrivateBeach = 'PrivateBeach',
    PrivateBikeStorage = 'PrivateBikeStorage',
    PrivateHotTub = 'PrivateHotTub',
    PrivateParkingPayable = 'PrivateParkingPayable',
    PrivatePier = 'PrivatePier',
    PrivateYachtMarina = 'PrivateYachtMarina',
    Pub = 'Pub',
    PubNearby = 'PubNearby',
    PublicPool = 'PublicPool',
    Radio = 'Radio',
    RadioAndCdPlayer = 'RadioAndCDPlayer',
    Reception = 'Reception',
    ReformDiet = 'ReformDiet',
    RefrigeratorOnRequest = 'RefrigeratorOnRequest',
    RegistrationFee = 'RegistrationFee',
    RelaxRoom = 'RelaxRoom',
    RentSpaAndRelaxArea = 'RentSpaAndRelaxArea',
    Restroom = 'Restroom',
    RobinsonStyleAccommodation = 'RobinsonStyleAccommodation',
    RoofGarden = 'RoofGarden',
    RoomAccommodationType = 'RoomAccommodationType',
    RoomAmenityRoomAmenityWasherDryerTrue = 'RoomAmenityRoomAmenityWasherDryerTrue',
    RoomAtAnotherAddress = 'RoomAtAnotherAddress',
    RoomBedSize = 'RoomBedSize',
    RoomBedTypes = 'RoomBedTypes',
    RoomNumberOfBedrooms = 'RoomNumberOfBedrooms',
    RoomNumberOfRooms = 'RoomNumberOfRooms',
    RoomOutlook = 'RoomOutlook',
    RoomRoomSize = 'RoomRoomSize',
    RoomSafe = 'RoomSafe',
    RoomSafeLaptopSize = 'RoomSafeLaptopSize',
    RoomScenicView = 'RoomScenicView',
    RoomService = 'RoomService',
    RoomServiceLimitedHours = 'RoomServiceLimitedHours',
    RoseSauna = 'RoseSauna',
    Rowhouse = 'Rowhouse',
    RunningTrackNearby = 'RunningTrackNearby',
    SamoCompleteprogram = 'SAMOCompleteprogram',
    SupRental = 'SUPRental',
    SafeDepositBox = 'SafeDepositBox',
    Sailing = 'Sailing',
    SailingNearby = 'SailingNearby',
    SaltCave = 'SaltCave',
    SaltCaveNearby = 'SaltCaveNearby',
    SaltChamber = 'SaltChamber',
    SaltTherapy = 'SaltTherapy',
    SalzburgCard = 'SalzburgCard',
    Sandpit = 'Sandpit',
    SatelliteDish = 'SatelliteDish',
    SatelliteDishWithGermanChannels = 'SatelliteDishWithGermanChannels',
    SatelliteTv = 'SatelliteTV',
    Sauna = 'Sauna',
    SaunaAndPoolTicket = 'SaunaAndPoolTicket',
    SaunaAndSteamBathWithBathrobe = 'SaunaAndSteamBathWithBathrobe',
    SaunaNearby = 'SaunaNearby',
    SaunaSeance = 'SaunaSeance',
    SaunaWithEssentialOils = 'SaunaWithEssentialOils',
    Saunas = 'Saunas',
    SchladmingSommercard = 'SchladmingSommercard',
    ScubaDiving = 'ScubaDiving',
    ScubaDivingNearby = 'ScubaDivingNearby',
    SecludedProperty = 'SecludedProperty',
    SecondAndThirdBabyCot = 'SecondAndThirdBabyCot',
    SecondBathroom = 'SecondBathroom',
    SecondFloor = 'SecondFloor',
    SecondSetOfBedSheets = 'SecondSetOfBedSheets',
    SecretarialServices = 'SecretarialServices',
    SecurityDeposit = 'SecurityDeposit',
    Seesaw = 'Seesaw',
    SemiBasement = 'SemiBasement',
    SemiDetachedHouse = 'SemiDetachedHouse',
    SeparateBathroom = 'SeparateBathroom',
    SeparateBedroom = 'SeparateBedroom',
    SeparateDiningRoom = 'SeparateDiningRoom',
    SeparateEntrance = 'SeparateEntrance',
    SeparateLivingRoom = 'SeparateLivingRoom',
    SeparateWorkspaceForLaptops = 'SeparateWorkspaceForLaptops',
    ServicePaketPackage = 'ServicePaketPackage',
    Shading = 'Shading',
    Shampoo = 'Shampoo',
    SharedBathroom = 'SharedBathroom',
    SharedBathroomWithWashbasinInTheRoom = 'SharedBathroomWithWashbasinInTheRoom',
    SharedFridge = 'SharedFridge',
    SharedGardenFurniture = 'SharedGardenFurniture',
    SharedIndoorPool = 'SharedIndoorPool',
    SharedKitchen = 'SharedKitchen',
    SharedSauna = 'SharedSauna',
    SharedSaunaPayable = 'SharedSaunaPayable',
    SharedUseProperty = 'SharedUseProperty',
    SharedWashingMachinePayable = 'SharedWashingMachinePayable',
    ShipRental = 'ShipRental',
    ShootingRange = 'ShootingRange',
    ShootingRangeNearby = 'ShootingRangeNearby',
    ShoppingCenter = 'ShoppingCenter',
    ShoppingCenterNearby = 'ShoppingCenterNearby',
    ShoppingService = 'ShoppingService',
    Shower = 'Shower',
    SilvrettaCard = 'SilvrettaCard',
    SkiLift = 'SkiLift',
    SkiLiftNearby = 'SkiLiftNearby',
    SkiPassAvailable = 'SkiPassAvailable',
    SkiPassFor6Or13Days = 'SkiPassFor6Or13Days',
    SkiRental = 'SkiRental',
    SkiRentalDiscount10Percent = 'SkiRentalDiscount10Percent',
    SkiRentalDiscount10PercentSportHarryStGallenkirch = 'SkiRentalDiscount10PercentSportHarryStGallenkirch',
    SkiRentalDiscount20PercentSportBruggerSlden = 'SkiRentalDiscount20PercentSportBruggerSlden',
    SkiRentalDiscount20PercentSportHuberKappl = 'SkiRentalDiscount20PercentSportHuberKappl',
    SkiRentalNearby = 'SkiRentalNearby',
    SkiStorage = 'SkiStorage',
    SkiStorageNearby = 'SkiStorageNearby',
    SkiTrailTransfer = 'SkiTrailTransfer',
    Skiing = 'Skiing',
    Skittles = 'Skittles',
    SleepNumberBedWithSelectComfortMattress = 'SleepNumberBedWithSelectComfortMattress',
    Slide = 'Slide',
    SlidePark = 'SlidePark',
    Slippers = 'Slippers',
    SmallTiledStove = 'SmallTiledStove',
    SmartTv = 'SmartTV',
    SmokingAndNonSmokingRoom = 'SmokingAndNonSmokingRoom',
    SmokingArea = 'SmokingArea',
    SmokingRoom = 'SmokingRoom',
    SnackBar = 'SnackBar',
    SnackBarNearby = 'SnackBarNearby',
    Soap = 'Soap',
    SolarEnergy12V = 'SolarEnergy12V',
    SolarEnergy220V = 'SolarEnergy220V',
    Solarium = 'Solarium',
    SolariumNearby = 'SolariumNearby',
    Soundproofing = 'Soundproofing',
    SpaTreatments = 'SpaTreatments',
    SpecialDietMenu = 'SpecialDietMenu',
    Spices = 'Spices',
    SportsEquipmentRental = 'SportsEquipmentRental',
    SportsEquipmentRentalNearby = 'SportsEquipmentRentalNearby',
    SportsField = 'SportsField',
    Squash = 'Squash',
    StablingOfOwnHorseIsPossible = 'StablingOfOwnHorseIsPossible',
    SteamChamber = 'SteamChamber',
    SteamRoom = 'SteamRoom',
    SteamRoomNearby = 'SteamRoomNearby',
    StereoSystem = 'StereoSystem',
    StoneHouse = 'StoneHouse',
    StreamCanal = 'StreamCanal',
    StreamingServices = 'StreamingServices',
    Stroller = 'Stroller',
    SunbathingTerrace = 'SunbathingTerrace',
    Sunshade = 'Sunshade',
    Surfing = 'Surfing',
    SurfingNearby = 'SurfingNearby',
    SwimmingInNature = 'SwimmingInNature',
    SwimmingInNatureNearby = 'SwimmingInNatureNearby',
    SwimmingPool = 'SwimmingPool',
    Swing = 'Swing',
    SwingAndSandpit = 'SwingAndSandpit',
    Tv = 'TV',
    Tvcrt = 'TVCRT',
    TvRoom = 'TVRoom',
    Table = 'Table',
    TableTennis = 'TableTennis',
    Tablet = 'Tablet',
    TauernSpa10PercentDiscount = 'TauernSpa10PercentDiscount',
    Taxi = 'Taxi',
    TeaCoffeeMaker = 'TeaCoffeeMaker',
    TeaInstantCoffee = 'TeaInstantCoffee',
    Telephone = 'Telephone',
    Television = 'Television',
    TelevisionPayable = 'TelevisionPayable',
    TempurPedicMattress = 'TempurPedicMattress',
    Tennis = 'Tennis',
    Tepidarium = 'Tepidarium',
    TepidariumNearby = 'TepidariumNearby',
    Terrace = 'Terrace',
    Thai = 'Thai',
    TheBedroomIsAWalkThroughRoom = 'TheBedroomIsAWalkThroughRoom',
    TheDepositIsASecurityRetainerAndWillBeRefundedUponProperReturnOfTheRentalProperty = 'TheDepositIsASecurityRetainerAndWillBeRefundedUponProperReturnOfTheRentalProperty',
    TheFeesRestrictionsDontApplyToServiceAnimals = 'TheFeesRestrictionsDontApplyToServiceAnimals',
    TheFirstSetOfSheets = 'TheFirstSetOfSheets',
    TheFirstSetOfSheetsAndTowels = 'TheFirstSetOfSheetsAndTowels',
    TheFirstSetOfTowels = 'TheFirstSetOfTowels',
    TheOwnerLivesInTheBuilding = 'TheOwnerLivesInTheBuilding',
    TheOwnerLivesOnTheProperty = 'TheOwnerLivesOnTheProperty',
    Theatre = 'Theatre',
    TheatreNearby = 'TheatreNearby',
    ThermalBathNearby = 'ThermalBathNearby',
    ThermalPool = 'ThermalPool',
    Thermometer = 'Thermometer',
    ThirdFloor = 'ThirdFloor',
    TileStove = 'TileStove',
    Toaster = 'Toaster',
    TobogganingNearby = 'TobogganingNearby',
    Toilet = 'Toilet',
    ToiletInTheYard = 'ToiletInTheYard',
    ToiletPaper = 'ToiletPaper',
    ToiletSeat = 'ToiletSeat',
    ToiletWithHotAndColdWater = 'ToiletWithHotAndColdWater',
    ToiletWithoutWashbasin = 'ToiletWithoutWashbasin',
    Toiletries = 'Toiletries',
    TourGuiding = 'TourGuiding',
    TowelAndBedclothesPayable = 'TowelAndBedclothesPayable',
    Towels = 'Towels',
    Traditional = 'Traditional',
    TrainStation = 'TrainStation',
    TrainStationNearby = 'TrainStationNearby',
    TramStop = 'TramStop',
    TramStopNearby = 'TramStopNearby',
    Trampoline = 'Trampoline',
    TranquilityRoom = 'TranquilityRoom',
    TransferFromTrainStationPayable = 'TransferFromTrainStationPayable',
    TransferToTrainStationPayable = 'TransferToTrainStationPayable',
    TurkishBathHamam = 'TurkishBathHamam',
    TwoPersonSofa = 'TwoPersonSofa',
    UnderfloorHeating = 'UnderfloorHeating',
    UnderfloorHeatingInPart = 'UnderfloorHeatingInPart',
    UnderfloorHeatingInTheBathroom = 'UnderfloorHeatingInTheBathroom',
    UnderfloorHeatingInTheEntireHouse = 'UnderfloorHeatingInTheEntireHouse',
    Unknown = 'Unknown',
    UnlimitedUsageOfOutdoorPoolFor6Days = 'UnlimitedUsageOfOutdoorPoolFor6Days',
    UsableUpTo2YearsOfAgeMaximumOf3YearsOfAge = 'UsableUpTo2YearsOfAgeMaximumOf3YearsOfAge',
    VacuumCleaner = 'VacuumCleaner',
    VeganDiet = 'VeganDiet',
    VegetarianDiet = 'VegetarianDiet',
    VendingMachine = 'VendingMachine',
    Ventilator = 'Ventilator',
    VideoGame = 'VideoGame',
    VideoGameConsole = 'VideoGameConsole',
    VideoGamePayable = 'VideoGamePayable',
    VideoGames = 'VideoGames',
    VideoLibrary = 'VideoLibrary',
    ViewToTheLake = 'ViewToTheLake',
    ViewToTheLandscape = 'ViewToTheLandscape',
    ViewToTheMountains = 'ViewToTheMountains',
    ViewToTheSea = 'ViewToTheSea',
    ViewToTheWatersAndMountains = 'ViewToTheWatersAndMountains',
    Vineyard = 'Vineyard',
    Volleyball = 'Volleyball',
    VolleyballFacilityNearby = 'VolleyballFacilityNearby',
    WaistMassage = 'WaistMassage',
    Washbasin = 'Washbasin',
    WashingMachine = 'WashingMachine',
    WashingMachineAndDryer = 'WashingMachineAndDryer',
    WasserparkRussbach = 'WasserparkRussbach',
    Water = 'Water',
    WaterDispenser = 'WaterDispenser',
    WaterMushroom = 'WaterMushroom',
    WaterSki = 'WaterSki',
    WaterSkiingNearby = 'WaterSkiingNearby',
    WaterSupplyFromWaterTank = 'WaterSupplyFromWaterTank',
    WaterfrontLocation = 'WaterfrontLocation',
    WeeklyChangeOfBedSheetsAndTowels = 'WeeklyChangeOfBedSheetsAndTowels',
    WeeklyCleaning = 'WeeklyCleaning',
    WeeklyRoomCleaning = 'WeeklyRoomCleaning',
    WelcomePackage = 'WelcomePackage',
    WellnessSectionRelaxation = 'WellnessSectionRelaxation',
    WellnessSpaNearby = 'WellnessSpaNearby',
    WellnessTowel = 'WellnessTowel',
    Wheelchair = 'Wheelchair',
    WiFi = 'WiFi',
    WiFiInPublicAreas = 'WiFiInPublicAreas',
    WiFiMaximumOf5Devices1GbWeek = 'WiFiMaximumOf5Devices1GBWeek',
    WineBar = 'WineBar',
    WineBarNearby = 'WineBarNearby',
    WineCellar = 'WineCellar',
    WineCellarNearby = 'WineCellarNearby',
    Winterized = 'Winterized',
    WiredInternetConnection = 'WiredInternetConnection',
    Wood = 'Wood',
    WoodFramedBuilding = 'WoodFramedBuilding',
    WoodenHouse = 'WoodenHouse',
    XboxRoom = 'XboxRoom',
    Yoga = 'Yoga',
}

/** Settlement type */
export type Settlement = {
    __typename?: 'Settlement';
    hotelCount: Scalars['Int'];
    id: Scalars['ID'];
    name: Scalars['String'];
};

/** SortBy type */
export enum SortBy {
    CheapestRate = 'CheapestRate',
    Score = 'Score',
}

/** SortDirection type */
export enum SortDirection {
    Ascending = 'Ascending',
    Descending = 'Descending',
    None = 'None',
}

/** Stay type */
export type Stay = {
    __typename?: 'Stay';
    available: Scalars['Boolean'];
    cheapestRate?: Maybe<CheapestRate>;
    childRates?: Maybe<Array<Maybe<ChildRate>>>;
    provisions?: Maybe<Array<Maybe<Provision>>>;
    rates?: Maybe<Array<Maybe<Rate>>>;
    reason?: Maybe<Scalars['String']>;
    reasonCode?: Maybe<ReasonCode>;
};

/** Tag type */
export enum Tag {
    AllowRequests = 'AllowRequests',
    BabyFriendly = 'BabyFriendly',
    CastleHotel = 'CastleHotel',
    OnlinePayment = 'OnlinePayment',
    PetFriendly = 'PetFriendly',
    Realtime = 'Realtime',
    Reservable = 'Reservable',
    SmokingFriendly = 'SmokingFriendly',
    Wellness = 'Wellness',
}

/** TransferDetails type */
export type TransferDetails = {
    __typename?: 'TransferDetails';
    name: Scalars['String'];
    note: Scalars['String'];
    number?: Maybe<Scalars['String']>;
};

/** Unique feature type */
export type UniqueFeature = {
    __typename?: 'UniqueFeature';
    feature: UniqueFeatureEnum;
    label: Scalars['String'];
};

/** UniqueFeatureEnum type */
export enum UniqueFeatureEnum {
    AirConditioning = 'AirConditioning',
    EcologicalAccommodation = 'EcologicalAccommodation',
    FacilitiesForHandicapped = 'FacilitiesForHandicapped',
    FreeParking = 'FreeParking',
    HygienicSafeCertificate = 'HygienicSafeCertificate',
    Internet = 'Internet',
    PetsFriendly = 'PetsFriendly',
    Pool = 'Pool',
    TouristVoucher = 'TouristVoucher',
}

/** Variant type */
export type Variant = {
    __typename?: 'Variant';
    baseRate: Scalars['Float'];
    cancellationRules: CancellationRules;
    childrenAges: Scalars['Iterable'];
    convertedBaseRate?: Maybe<Scalars['Float']>;
    convertedRate?: Maybe<Scalars['Float']>;
    discounts?: Maybe<Array<Maybe<Discount>>>;
    guests: Scalars['Int'];
    provision: Provision;
    rate: Scalars['Float'];
    ratePlan: RatePlan;
};

/** Creates a CardPaymentInitiation. */
export type CreateCardPaymentInitiationInput = {
    clientMutationId?: InputMaybe<Scalars['String']>;
    identifierCode: Scalars['String'];
    reservationId: Scalars['Int'];
    returnUrl?: InputMaybe<Scalars['String']>;
};

/** Creates a CardPaymentInitiation. */
export type CreateCardPaymentInitiationPayload = {
    __typename?: 'createCardPaymentInitiationPayload';
    cardPaymentInitiation?: Maybe<CardPaymentInitiation>;
    clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a Reservation. */
export type CreateReservationInput = {
    accommodationID: Scalars['ID'];
    cardPayment?: InputMaybe<CardPaymentInput>;
    /** Check-in date `YYYY-MM-DD`. */
    checkIn: Scalars['String'];
    /** Check-in date `YYYY-MM-DD`. */
    checkOut: Scalars['String'];
    clientMutationId?: InputMaybe<Scalars['String']>;
    comments?: InputMaybe<Scalars['String']>;
    guest: BookingGuestInput;
    paymentMethod?: InputMaybe<PaymentMethod>;
    pets?: InputMaybe<Array<BookingPetInput>>;
    rooms?: InputMaybe<Array<BookingRoomInput>>;
};

/** Creates a Reservation. */
export type CreateReservationPayload = {
    __typename?: 'createReservationPayload';
    clientMutationId?: Maybe<Scalars['String']>;
    reservation?: Maybe<Reservation>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
    resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = object, TContext = object, TArgs = object> =
    | ResolverFn<TResult, TParent, TContext, TArgs>
    | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
    TResult,
    TKey extends string,
    TParent,
    TContext,
    TArgs,
> {
    subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
    resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
    subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
    resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
    | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
    | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
    TResult,
    TKey extends string,
    TParent = object,
    TContext = object,
    TArgs = object,
> =
    | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
    | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = object, TContext = object> = (
    parent: TParent,
    context: TContext,
    info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = object, TContext = object> = (
    obj: T,
    context: TContext,
    info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
    TResult = object,
    TParent = object,
    TContext = object,
    TArgs = object,
> = (
    next: NextResolverFn<TResult>,
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
    Accommodation: ResolverTypeWrapper<Accommodation>;
    AccommodationPageConnection: ResolverTypeWrapper<AccommodationPageConnection>;
    AccommodationPaginationInfo: ResolverTypeWrapper<AccommodationPaginationInfo>;
    AgeRange: ResolverTypeWrapper<AgeRange>;
    AggregateRating: ResolverTypeWrapper<AggregateRating>;
    Amenity: ResolverTypeWrapper<Amenity>;
    AmenityEnum: AmenityEnum;
    AmenityOption: ResolverTypeWrapper<AmenityOption>;
    Apartment: ResolverTypeWrapper<Apartment>;
    Bed: ResolverTypeWrapper<Bed>;
    BedType: BedType;
    BestFor: BestFor;
    BookingGuestInput: BookingGuestInput;
    BookingPetInput: BookingPetInput;
    BookingRoom: ResolverTypeWrapper<BookingRoom>;
    BookingRoomInput: BookingRoomInput;
    Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
    CancellationPolicy: ResolverTypeWrapper<CancellationPolicy>;
    CancellationRule: ResolverTypeWrapper<CancellationRule>;
    CancellationRules: ResolverTypeWrapper<CancellationRules>;
    CancellationType: ResolverTypeWrapper<CancellationType>;
    CardPaymentInitiation: ResolverTypeWrapper<CardPaymentInitiation>;
    CardPaymentInput: CardPaymentInput;
    CheapestRate: ResolverTypeWrapper<CheapestRate>;
    CheapestRateRoom: ResolverTypeWrapper<CheapestRateRoom>;
    ChildRate: ResolverTypeWrapper<ChildRate>;
    ConsentType: ConsentType;
    Counter: ResolverTypeWrapper<Counter>;
    CounterInputType: CounterInputType;
    CounterOperationType: CounterOperationType;
    CounterType: CounterType;
    CounterValue: ResolverTypeWrapper<CounterValue>;
    Country: ResolverTypeWrapper<Country>;
    CountryCode: CountryCode;
    CreditCard: CreditCard;
    Currency: Currency;
    DataUnit: DataUnit;
    Discount: ResolverTypeWrapper<Discount>;
    District: ResolverTypeWrapper<District>;
    FallbackInput: FallbackInput;
    FinalRateType: ResolverTypeWrapper<FinalRateType>;
    Float: ResolverTypeWrapper<Scalars['Float']>;
    Guarantee: ResolverTypeWrapper<Guarantee>;
    GuestCancelReason: GuestCancelReason;
    GuestCategory: ResolverTypeWrapper<GuestCategory>;
    GuestCategoryEnum: GuestCategoryEnum;
    HotelType: ResolverTypeWrapper<HotelType>;
    HotelTypeEnum: HotelTypeEnum;
    HouseRoomType: ResolverTypeWrapper<HouseRoomType>;
    HouseRoomTypeEnum: HouseRoomTypeEnum;
    ID: ResolverTypeWrapper<Scalars['ID']>;
    Image: ResolverTypeWrapper<Image>;
    Inpayment: ResolverTypeWrapper<Inpayment>;
    InputService: InputService;
    Int: ResolverTypeWrapper<Scalars['Int']>;
    InternalAmenityGroup: ResolverTypeWrapper<InternalAmenityGroup>;
    InternalAmenityGroupEnum: InternalAmenityGroupEnum;
    InternalLabel: ResolverTypeWrapper<InternalLabel>;
    Iterable: ResolverTypeWrapper<Scalars['Iterable']>;
    Label: Label;
    Language: ResolverTypeWrapper<Language>;
    LanguageCode: LanguageCode;
    LastPayment: ResolverTypeWrapper<LastPayment>;
    Mutation: ResolverTypeWrapper<object>;
    Node:
        | ResolversTypes['Accommodation']
        | ResolversTypes['CardPaymentInitiation']
        | ResolversTypes['Counter']
        | ResolversTypes['Reservation'];
    OnlinePaymentDetails: ResolverTypeWrapper<OnlinePaymentDetails>;
    OrderByInput: OrderByInput;
    PaymentMethod: PaymentMethod;
    Pet: ResolverTypeWrapper<Pet>;
    PetType: PetType;
    PrepaymentEnum: PrepaymentEnum;
    PrepaymentSummaryType: ResolverTypeWrapper<PrepaymentSummaryType>;
    PrepaymentType: ResolverTypeWrapper<PrepaymentType>;
    Provision: ResolverTypeWrapper<Provision>;
    ProvisionInformationEnum: ProvisionInformationEnum;
    ProvisionType: ProvisionType;
    QuantityEnum: QuantityEnum;
    Query: ResolverTypeWrapper<object>;
    Rate: ResolverTypeWrapper<Rate>;
    RatePlan: ResolverTypeWrapper<RatePlan>;
    Rating: ResolverTypeWrapper<Rating>;
    RatingDescription: RatingDescription;
    RatingGuest: ResolverTypeWrapper<RatingGuest>;
    RatingPageConnection: ResolverTypeWrapper<RatingPageConnection>;
    RatingPaginationInfo: ResolverTypeWrapper<RatingPaginationInfo>;
    ReasonCode: ReasonCode;
    Refund: ResolverTypeWrapper<Refund>;
    RefundGuest: ResolverTypeWrapper<RefundGuest>;
    Region: ResolverTypeWrapper<Region>;
    Reservation: ResolverTypeWrapper<Reservation>;
    ReservationDetails: ResolverTypeWrapper<ReservationDetails>;
    ReservationDetailsCancellation: ResolverTypeWrapper<ReservationDetailsCancellation>;
    ReservationDetailsCancellationItem: ResolverTypeWrapper<ReservationDetailsCancellationItem>;
    ReservationDetailsGuest: ResolverTypeWrapper<ReservationDetailsGuest>;
    ReservationDetailsPrepayment: ResolverTypeWrapper<ReservationDetailsPrepayment>;
    ReservationDetailsPrice: ResolverTypeWrapper<ReservationDetailsPrice>;
    ReservationDetailsRoom: ResolverTypeWrapper<ReservationDetailsRoom>;
    ReservationDetailsTax: ResolverTypeWrapper<ReservationDetailsTax>;
    ReservationStatus: ReservationStatus;
    ReservationTypeEnum: ReservationTypeEnum;
    Room: ResolverTypeWrapper<Room>;
    RoomType: ResolverTypeWrapper<RoomType>;
    Service: ResolverTypeWrapper<Service>;
    ServiceCategory: ResolverTypeWrapper<ServiceCategory>;
    ServiceCategoryEnum: ServiceCategoryEnum;
    ServiceType: ServiceType;
    Settlement: ResolverTypeWrapper<Settlement>;
    SortBy: SortBy;
    SortDirection: SortDirection;
    Stay: ResolverTypeWrapper<Stay>;
    String: ResolverTypeWrapper<Scalars['String']>;
    Tag: Tag;
    TransferDetails: ResolverTypeWrapper<TransferDetails>;
    UniqueFeature: ResolverTypeWrapper<UniqueFeature>;
    UniqueFeatureEnum: UniqueFeatureEnum;
    Variant: ResolverTypeWrapper<Variant>;
    createCardPaymentInitiationInput: CreateCardPaymentInitiationInput;
    createCardPaymentInitiationPayload: ResolverTypeWrapper<CreateCardPaymentInitiationPayload>;
    createReservationInput: CreateReservationInput;
    createReservationPayload: ResolverTypeWrapper<CreateReservationPayload>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
    Accommodation: Accommodation;
    AccommodationPageConnection: AccommodationPageConnection;
    AccommodationPaginationInfo: AccommodationPaginationInfo;
    AgeRange: AgeRange;
    AggregateRating: AggregateRating;
    Amenity: Amenity;
    AmenityOption: AmenityOption;
    Apartment: Apartment;
    Bed: Bed;
    BookingGuestInput: BookingGuestInput;
    BookingPetInput: BookingPetInput;
    BookingRoom: BookingRoom;
    BookingRoomInput: BookingRoomInput;
    Boolean: Scalars['Boolean'];
    CancellationPolicy: CancellationPolicy;
    CancellationRule: CancellationRule;
    CancellationRules: CancellationRules;
    CancellationType: CancellationType;
    CardPaymentInitiation: CardPaymentInitiation;
    CardPaymentInput: CardPaymentInput;
    CheapestRate: CheapestRate;
    CheapestRateRoom: CheapestRateRoom;
    ChildRate: ChildRate;
    ConsentType: ConsentType;
    Counter: Counter;
    CounterInputType: CounterInputType;
    CounterValue: CounterValue;
    Country: Country;
    Discount: Discount;
    District: District;
    FallbackInput: FallbackInput;
    FinalRateType: FinalRateType;
    Float: Scalars['Float'];
    Guarantee: Guarantee;
    GuestCategory: GuestCategory;
    HotelType: HotelType;
    HouseRoomType: HouseRoomType;
    ID: Scalars['ID'];
    Image: Image;
    Inpayment: Inpayment;
    InputService: InputService;
    Int: Scalars['Int'];
    InternalAmenityGroup: InternalAmenityGroup;
    InternalLabel: InternalLabel;
    Iterable: Scalars['Iterable'];
    Language: Language;
    LastPayment: LastPayment;
    Mutation: object;
    Node:
        | ResolversParentTypes['Accommodation']
        | ResolversParentTypes['CardPaymentInitiation']
        | ResolversParentTypes['Counter']
        | ResolversParentTypes['Reservation'];
    OnlinePaymentDetails: OnlinePaymentDetails;
    OrderByInput: OrderByInput;
    Pet: Pet;
    PrepaymentSummaryType: PrepaymentSummaryType;
    PrepaymentType: PrepaymentType;
    Provision: Provision;
    Query: object;
    Rate: Rate;
    RatePlan: RatePlan;
    Rating: Rating;
    RatingGuest: RatingGuest;
    RatingPageConnection: RatingPageConnection;
    RatingPaginationInfo: RatingPaginationInfo;
    Refund: Refund;
    RefundGuest: RefundGuest;
    Region: Region;
    Reservation: Reservation;
    ReservationDetails: ReservationDetails;
    ReservationDetailsCancellation: ReservationDetailsCancellation;
    ReservationDetailsCancellationItem: ReservationDetailsCancellationItem;
    ReservationDetailsGuest: ReservationDetailsGuest;
    ReservationDetailsPrepayment: ReservationDetailsPrepayment;
    ReservationDetailsPrice: ReservationDetailsPrice;
    ReservationDetailsRoom: ReservationDetailsRoom;
    ReservationDetailsTax: ReservationDetailsTax;
    Room: Room;
    RoomType: RoomType;
    Service: Service;
    ServiceCategory: ServiceCategory;
    Settlement: Settlement;
    Stay: Stay;
    String: Scalars['String'];
    TransferDetails: TransferDetails;
    UniqueFeature: UniqueFeature;
    Variant: Variant;
    createCardPaymentInitiationInput: CreateCardPaymentInitiationInput;
    createCardPaymentInitiationPayload: CreateCardPaymentInitiationPayload;
    createReservationInput: CreateReservationInput;
    createReservationPayload: CreateReservationPayload;
}>;

export type AccommodationResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['Accommodation'] = ResolversParentTypes['Accommodation'],
> = ResolversObject<{
    _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    aggregateRating?: Resolver<ResolversTypes['AggregateRating'], ParentType, ContextType>;
    apartments?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['Apartment']>>>,
        ParentType,
        ContextType
    >;
    checkInEnd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    checkInStart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    checkOutEnd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    checkOutStart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    contactPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    convertedRatesCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
    country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
    currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
    description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    district?: Resolver<Maybe<ResolversTypes['District']>, ParentType, ContextType>;
    fallbackDistance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    freeChildAge?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    foreignPartnerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    hotelPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    images?: Resolver<Maybe<Array<Maybe<ResolversTypes['Image']>>>, ParentType, ContextType>;
    internalAmenityGroups?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['InternalAmenityGroup']>>>,
        ParentType,
        ContextType
    >;
    internalLabel?: Resolver<ResolversTypes['InternalLabel'], ParentType, ContextType>;
    labels?: Resolver<Maybe<Array<Maybe<ResolversTypes['Label']>>>, ParentType, ContextType>;
    languages?: Resolver<Maybe<Array<Maybe<ResolversTypes['Language']>>>, ParentType, ContextType>;
    latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    paymentMethods?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['PaymentMethod']>>>,
        ParentType,
        ContextType
    >;
    pets?: Resolver<Maybe<Array<Maybe<ResolversTypes['Pet']>>>, ParentType, ContextType>;
    postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    ratingAverage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    ratingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    ratingDescription?: Resolver<
        Maybe<ResolversTypes['RatingDescription']>,
        ParentType,
        ContextType
    >;
    ratings?: Resolver<
        Maybe<ResolversTypes['RatingPageConnection']>,
        ParentType,
        ContextType,
        Partial<AccommodationRatingsArgs>
    >;
    realtimeReservable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    regions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Region']>>>, ParentType, ContextType>;
    services?: Resolver<Maybe<Array<Maybe<ResolversTypes['Service']>>>, ParentType, ContextType>;
    settlement?: Resolver<Maybe<ResolversTypes['Settlement']>, ParentType, ContextType>;
    stay?: Resolver<Maybe<ResolversTypes['Stay']>, ParentType, ContextType>;
    streetNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    totalGuestCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    type?: Resolver<Maybe<ResolversTypes['HotelType']>, ParentType, ContextType>;
    uniqueFeatures?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['UniqueFeature']>>>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccommodationPageConnectionResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['AccommodationPageConnection'] = ResolversParentTypes['AccommodationPageConnection'],
> = ResolversObject<{
    collection?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['Accommodation']>>>,
        ParentType,
        ContextType
    >;
    paginationInfo?: Resolver<
        ResolversTypes['AccommodationPaginationInfo'],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AccommodationPaginationInfoResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['AccommodationPaginationInfo'] = ResolversParentTypes['AccommodationPaginationInfo'],
> = ResolversObject<{
    itemsPerPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    lastPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgeRangeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AgeRange'] = ResolversParentTypes['AgeRange'],
> = ResolversObject<{
    free?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    fromAge?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    toAge?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AggregateRatingResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['AggregateRating'] = ResolversParentTypes['AggregateRating'],
> = ResolversObject<{
    approachability?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    average?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    cleanliness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    convenience?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    restaurant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    service?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    staff?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AmenityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Amenity'] = ResolversParentTypes['Amenity'],
> = ResolversObject<{
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    option?: Resolver<Maybe<ResolversTypes['AmenityOption']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['AmenityEnum'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AmenityOptionResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['AmenityOption'] = ResolversParentTypes['AmenityOption'],
> = ResolversObject<{
    description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    option?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApartmentResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Apartment'] = ResolversParentTypes['Apartment'],
> = ResolversObject<{
    doubleBeds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    images?: Resolver<Maybe<Array<Maybe<ResolversTypes['Image']>>>, ParentType, ContextType>;
    maxAdults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    maxChildren?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    maxExtraBeds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    maxTotalGuests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    minAdults?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    roomTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['RoomType']>>>, ParentType, ContextType>;
    services?: Resolver<Maybe<Array<Maybe<ResolversTypes['Service']>>>, ParentType, ContextType>;
    singleBeds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    variants?: Resolver<Maybe<Array<Maybe<ResolversTypes['Variant']>>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BedResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Bed'] = ResolversParentTypes['Bed'],
> = ResolversObject<{
    adultCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    collapsedBeds?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['BedType'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BookingRoomResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BookingRoom'] = ResolversParentTypes['BookingRoom'],
> = ResolversObject<{
    childAges?: Resolver<ResolversTypes['Iterable'], ParentType, ContextType>;
    convertedTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    guestCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    provisionType?: Resolver<Maybe<ResolversTypes['ProvisionType']>, ParentType, ContextType>;
    ratePlanId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    totalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CancellationPolicyResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['CancellationPolicy'] = ResolversParentTypes['CancellationPolicy'],
> = ResolversObject<{
    arrivalHourFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    arrivalHourTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    daysBeforeReservation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    daysCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    penaltyAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    penaltyUnit?: Resolver<Maybe<ResolversTypes['DataUnit']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CancellationRuleResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['CancellationRule'] = ResolversParentTypes['CancellationRule'],
> = ResolversObject<{
    amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    free?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    penalty?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    prepaidPercentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    rest?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    unit?: Resolver<ResolversTypes['DataUnit'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CancellationRulesResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['CancellationRules'] = ResolversParentTypes['CancellationRules'],
> = ResolversObject<{
    freeCancellation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    prepaidAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    prepaidRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    prepaidTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    prepaidUnit?: Resolver<Maybe<ResolversTypes['DataUnit']>, ParentType, ContextType>;
    ratePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    rules?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['CancellationRule']>>>,
        ParentType,
        ContextType
    >;
    to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CancellationTypeResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['CancellationType'] = ResolversParentTypes['CancellationType'],
> = ResolversObject<{
    arriveDayHour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    arriveDayHourBefore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    daysBeforeReservation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    hasCardGuaranteePenalty?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    numDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    penaltyAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    penaltyType?: Resolver<ResolversTypes['DataUnit'], ParentType, ContextType>;
    policyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    prepaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    reservationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CardPaymentInitiationResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['CardPaymentInitiation'] = ResolversParentTypes['CardPaymentInitiation'],
> = ResolversObject<{
    _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    paymentUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    returnUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CheapestRateResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CheapestRate'] = ResolversParentTypes['CheapestRate'],
> = ResolversObject<{
    baseRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    checkIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    checkOut?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    convertedBaseRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    convertedRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    discounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Discount']>>>, ParentType, ContextType>;
    guests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    provision?: Resolver<ResolversTypes['Provision'], ParentType, ContextType>;
    rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    rooms?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['CheapestRateRoom']>>>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CheapestRateRoomResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['CheapestRateRoom'] = ResolversParentTypes['CheapestRateRoom'],
> = ResolversObject<{
    childAges?: Resolver<ResolversTypes['Iterable'], ParentType, ContextType>;
    rate?: Resolver<Maybe<ResolversTypes['Rate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChildRateResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ChildRate'] = ResolversParentTypes['ChildRate'],
> = ResolversObject<{
    ageRange?: Resolver<Maybe<ResolversTypes['AgeRange']>, ParentType, ContextType>;
    apartment?: Resolver<Maybe<ResolversTypes['Apartment']>, ParentType, ContextType>;
    rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    ratePlan?: Resolver<Maybe<ResolversTypes['RatePlan']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CounterResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Counter'] = ResolversParentTypes['Counter'],
> = ResolversObject<{
    counterValue?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['CounterValue']>>>,
        ParentType,
        ContextType
    >;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    operation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CounterValueResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CounterValue'] = ResolversParentTypes['CounterValue'],
> = ResolversObject<{
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CountryResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country'],
> = ResolversObject<{
    code?: Resolver<ResolversTypes['CountryCode'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DiscountResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Discount'] = ResolversParentTypes['Discount'],
> = ResolversObject<{
    discountInfo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    unit?: Resolver<ResolversTypes['DataUnit'], ParentType, ContextType>;
    value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DistrictResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['District'] = ResolversParentTypes['District'],
> = ResolversObject<{
    hotelCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FinalRateTypeResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['FinalRateType'] = ResolversParentTypes['FinalRateType'],
> = ResolversObject<{
    acceptedCreditCards?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['CreditCard']>>>,
        ParentType,
        ContextType
    >;
    appliedDiscounts?: Resolver<ResolversTypes['Iterable'], ParentType, ContextType>;
    basePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    bookingRooms?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['BookingRoom']>>>,
        ParentType,
        ContextType
    >;
    cancelable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    convertedBasePrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    convertedCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
    convertedTotalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    convertedTotalPriceTax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
    discounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Discount']>>>, ParentType, ContextType>;
    guestPaymentAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    hasCardGuarantee?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    hasOnlinePayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    originalCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
    pets?: Resolver<Maybe<Array<Maybe<ResolversTypes['PetType']>>>, ParentType, ContextType>;
    prepayment?: Resolver<Maybe<ResolversTypes['PrepaymentType']>, ParentType, ContextType>;
    prepaymentSummary?: Resolver<
        Maybe<ResolversTypes['PrepaymentSummaryType']>,
        ParentType,
        ContextType
    >;
    realtimeReservation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    requiresGuestAddress?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    reservationBasePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    totalPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    totalPriceTax?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GuaranteeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Guarantee'] = ResolversParentTypes['Guarantee'],
> = ResolversObject<{
    amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    cancellationPolicies?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['CancellationPolicy']>>>,
        ParentType,
        ContextType
    >;
    cancellationTexts?: Resolver<ResolversTypes['Iterable'], ParentType, ContextType>;
    deadlineDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    unit?: Resolver<Maybe<ResolversTypes['DataUnit']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GuestCategoryResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['GuestCategory'] = ResolversParentTypes['GuestCategory'],
> = ResolversObject<{
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['GuestCategoryEnum'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HotelTypeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['HotelType'] = ResolversParentTypes['HotelType'],
> = ResolversObject<{
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['HotelTypeEnum'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HouseRoomTypeResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['HouseRoomType'] = ResolversParentTypes['HouseRoomType'],
> = ResolversObject<{
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['HouseRoomTypeEnum'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImageResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image'],
> = ResolversObject<{
    default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InpaymentResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Inpayment'] = ResolversParentTypes['Inpayment'],
> = ResolversObject<{
    amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    paymentMethod?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InternalAmenityGroupResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['InternalAmenityGroup'] = ResolversParentTypes['InternalAmenityGroup'],
> = ResolversObject<{
    free?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['InternalAmenityGroupEnum'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InternalLabelResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['InternalLabel'] = ResolversParentTypes['InternalLabel'],
> = ResolversObject<{
    bestFor?: Resolver<Maybe<Array<Maybe<ResolversTypes['BestFor']>>>, ParentType, ContextType>;
    provisionInformation?: Resolver<
        Maybe<ResolversTypes['ProvisionInformationEnum']>,
        ParentType,
        ContextType
    >;
    quantity?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['QuantityEnum']>>>,
        ParentType,
        ContextType
    >;
    reservationType?: Resolver<ResolversTypes['ReservationTypeEnum'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface IterableScalarConfig
    extends GraphQLScalarTypeConfig<ResolversTypes['Iterable'], any> {
    name: 'Iterable';
}

export type LanguageResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Language'] = ResolversParentTypes['Language'],
> = ResolversObject<{
    code?: Resolver<ResolversTypes['LanguageCode'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LastPaymentResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LastPayment'] = ResolversParentTypes['LastPayment'],
> = ResolversObject<{
    amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    commissionAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    responseCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    successful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = ResolversObject<{
    createCardPaymentInitiation?: Resolver<
        Maybe<ResolversTypes['createCardPaymentInitiationPayload']>,
        ParentType,
        ContextType,
        RequireFields<MutationCreateCardPaymentInitiationArgs, 'input'>
    >;
    createReservation?: Resolver<
        Maybe<ResolversTypes['createReservationPayload']>,
        ParentType,
        ContextType,
        RequireFields<MutationCreateReservationArgs, 'input'>
    >;
}>;

export type NodeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node'],
> = ResolversObject<{
    __resolveType: TypeResolveFn<
        'Accommodation' | 'CardPaymentInitiation' | 'Counter' | 'Reservation',
        ParentType,
        ContextType
    >;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
}>;

export type OnlinePaymentDetailsResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['OnlinePaymentDetails'] = ResolversParentTypes['OnlinePaymentDetails'],
> = ResolversObject<{
    inpayments?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['Inpayment']>>>,
        ParentType,
        ContextType
    >;
    isPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    lastPayment?: Resolver<Maybe<ResolversTypes['LastPayment']>, ParentType, ContextType>;
    locallyPaidAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    onlinePaidAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    refund?: Resolver<ResolversTypes['Refund'], ParentType, ContextType>;
    transferDetails?: Resolver<Maybe<ResolversTypes['TransferDetails']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PetResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Pet'] = ResolversParentTypes['Pet'],
> = ResolversObject<{
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['PetType'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PrepaymentSummaryTypeResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['PrepaymentSummaryType'] = ResolversParentTypes['PrepaymentSummaryType'],
> = ResolversObject<{
    calculatedPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    cancellationText?: Resolver<Maybe<ResolversTypes['Iterable']>, ParentType, ContextType>;
    cancellationTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    cancellations?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['CancellationType']>>>,
        ParentType,
        ContextType
    >;
    cardGuarantee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    convertedPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    deadline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    deadlineDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    earlyCheckIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    foreignPartnerCancellationText?: Resolver<
        Maybe<ResolversTypes['String']>,
        ParentType,
        ContextType
    >;
    noPrepaymentNeeded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    paymentMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    prepaymentText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    prepaymentTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    reservationCancellationText?: Resolver<
        Maybe<ResolversTypes['Iterable']>,
        ParentType,
        ContextType
    >;
    reservationID?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    reservationPrepaymentText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['DataUnit'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PrepaymentTypeResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['PrepaymentType'] = ResolversParentTypes['PrepaymentType'],
> = ResolversObject<{
    amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    convertedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProvisionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Provision'] = ResolversParentTypes['Provision'],
> = ResolversObject<{
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['ProvisionType'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = ResolversObject<{
    accommodation?: Resolver<
        Maybe<ResolversTypes['Accommodation']>,
        ParentType,
        ContextType,
        RequireFields<QueryAccommodationArgs, 'id'>
    >;
    accommodations?: Resolver<
        Maybe<ResolversTypes['AccommodationPageConnection']>,
        ParentType,
        ContextType,
        Partial<QueryAccommodationsArgs>
    >;
    counters?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['Counter']>>>,
        ParentType,
        ContextType,
        Partial<QueryCountersArgs>
    >;
    finalRate?: Resolver<
        Maybe<ResolversTypes['FinalRateType']>,
        ParentType,
        ContextType,
        RequireFields<QueryFinalRateArgs, 'accommodationID'>
    >;
    node?: Resolver<
        Maybe<ResolversTypes['Node']>,
        ParentType,
        ContextType,
        RequireFields<QueryNodeArgs, 'id'>
    >;
    ratings?: Resolver<
        Maybe<ResolversTypes['RatingPageConnection']>,
        ParentType,
        ContextType,
        RequireFields<QueryRatingsArgs, 'accommodationId'>
    >;
    reservationDetails?: Resolver<
        Maybe<ResolversTypes['ReservationDetails']>,
        ParentType,
        ContextType,
        RequireFields<QueryReservationDetailsArgs, 'reservationID'>
    >;
}>;

export type RateResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Rate'] = ResolversParentTypes['Rate'],
> = ResolversObject<{
    apartment?: Resolver<Maybe<ResolversTypes['Apartment']>, ParentType, ContextType>;
    baseRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    convertedBaseRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    convertedRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    guests?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    listBaseRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    listRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    ratePlan?: Resolver<ResolversTypes['RatePlan'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RatePlanResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RatePlan'] = ResolversParentTypes['RatePlan'],
> = ResolversObject<{
    description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    guarantee?: Resolver<ResolversTypes['Guarantee'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    provision?: Resolver<ResolversTypes['Provision'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RatingResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Rating'] = ResolversParentTypes['Rating'],
> = ResolversObject<{
    approachability?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    average?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    cleanliness?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    convenience?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    guest?: Resolver<Maybe<ResolversTypes['RatingGuest']>, ParentType, ContextType>;
    hotelAnswer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    language?: Resolver<ResolversTypes['Language'], ParentType, ContextType>;
    negative?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    positive?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    restaurant?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    service?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    staff?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    stayNights?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RatingGuestResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RatingGuest'] = ResolversParentTypes['RatingGuest'],
> = ResolversObject<{
    category?: Resolver<Maybe<ResolversTypes['GuestCategory']>, ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    settlement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RatingPageConnectionResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['RatingPageConnection'] = ResolversParentTypes['RatingPageConnection'],
> = ResolversObject<{
    collection?: Resolver<Maybe<Array<Maybe<ResolversTypes['Rating']>>>, ParentType, ContextType>;
    paginationInfo?: Resolver<ResolversTypes['RatingPaginationInfo'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RatingPaginationInfoResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['RatingPaginationInfo'] = ResolversParentTypes['RatingPaginationInfo'],
> = ResolversObject<{
    itemsPerPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    lastPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RefundResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Refund'] = ResolversParentTypes['Refund'],
> = ResolversObject<{
    accountDetailsPending?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    guest?: Resolver<Maybe<ResolversTypes['RefundGuest']>, ParentType, ContextType>;
    pendingRefundAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    totalRefundAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RefundGuestResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RefundGuest'] = ResolversParentTypes['RefundGuest'],
> = ResolversObject<{
    bban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    bic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Region'] = ResolversParentTypes['Region'],
> = ResolversObject<{
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReservationResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Reservation'] = ResolversParentTypes['Reservation'],
> = ResolversObject<{
    _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
    discounts?: Resolver<ResolversTypes['Iterable'], ParentType, ContextType>;
    hotelEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    hotelPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    identifierCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    isRealtimeReservation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    realtimeReservation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    reservationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    totalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReservationDetailsResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['ReservationDetails'] = ResolversParentTypes['ReservationDetails'],
> = ResolversObject<{
    adultCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    arrival?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    canCheckCardNumber?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    canConfirm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    canUpdateBilling?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    cancellation?: Resolver<
        Maybe<ResolversTypes['ReservationDetailsCancellation']>,
        ParentType,
        ContextType
    >;
    childAges?: Resolver<ResolversTypes['Iterable'], ParentType, ContextType>;
    comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    commissionPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    convertedCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
    convertedPrice?: Resolver<
        Maybe<ResolversTypes['ReservationDetailsPrice']>,
        ParentType,
        ContextType
    >;
    departure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    guest?: Resolver<Maybe<ResolversTypes['ReservationDetailsGuest']>, ParentType, ContextType>;
    guestCancelComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    guestCancelReason?: Resolver<
        Maybe<ResolversTypes['GuestCancelReason']>,
        ParentType,
        ContextType
    >;
    hasGuarantee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    hasOnlinePayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    hasRating?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    hotelId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    hotelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    hotelRefuseComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    hotelRefuseReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    invoiceComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    invoiceConfirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    invoicePrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    isAllocationDecided?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    isAlwaysBilled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    isRealtime?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    onlinePaymentDetails?: Resolver<
        Maybe<ResolversTypes['OnlinePaymentDetails']>,
        ParentType,
        ContextType
    >;
    paymentMethods?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['PaymentMethod']>>>,
        ParentType,
        ContextType
    >;
    paymentNames?: Resolver<ResolversTypes['Iterable'], ParentType, ContextType>;
    prepayment?: Resolver<
        Maybe<ResolversTypes['ReservationDetailsPrepayment']>,
        ParentType,
        ContextType
    >;
    price?: Resolver<Maybe<ResolversTypes['ReservationDetailsPrice']>, ParentType, ContextType>;
    provisionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    provisionTypeId?: Resolver<Maybe<ResolversTypes['ProvisionType']>, ParentType, ContextType>;
    roomCancellations?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['ReservationDetailsCancellation']>>>,
        ParentType,
        ContextType
    >;
    rooms?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['ReservationDetailsRoom']>>>,
        ParentType,
        ContextType
    >;
    spaTaxIncluded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    status?: Resolver<Maybe<ResolversTypes['ReservationStatus']>, ParentType, ContextType>;
    submitDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    taxes?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['ReservationDetailsTax']>>>,
        ParentType,
        ContextType
    >;
    todoType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReservationDetailsCancellationResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['ReservationDetailsCancellation'] = ResolversParentTypes['ReservationDetailsCancellation'],
> = ResolversObject<{
    cancellationText?: Resolver<Maybe<ResolversTypes['Iterable']>, ParentType, ContextType>;
    cancellationTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    cancellations?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['ReservationDetailsCancellationItem']>>>,
        ParentType,
        ContextType
    >;
    earlyCheckIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    foreignPartnerCancellationText?: Resolver<
        Maybe<ResolversTypes['String']>,
        ParentType,
        ContextType
    >;
    nonCancelable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    reservationCancellationText?: Resolver<
        Maybe<ResolversTypes['Iterable']>,
        ParentType,
        ContextType
    >;
    roomTypeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReservationDetailsCancellationItemResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['ReservationDetailsCancellationItem'] = ResolversParentTypes['ReservationDetailsCancellationItem'],
> = ResolversObject<{
    arriveDayHour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    arriveDayHourBefore?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    daysBeforeReservation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    hasCardGuaranteePenalty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    numDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    penaltyAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    policyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    prepaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    reservationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReservationDetailsGuestResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['ReservationDetailsGuest'] = ResolversParentTypes['ReservationDetailsGuest'],
> = ResolversObject<{
    address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReservationDetailsPrepaymentResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['ReservationDetailsPrepayment'] = ResolversParentTypes['ReservationDetailsPrepayment'],
> = ResolversObject<{
    cardGuarantee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    convertedPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    deadline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    deadlineDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    method?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    noPrepaymentNeeded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    prepaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    prepaymentText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    prepaymentTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    reservationPrepaymentText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    type?: Resolver<Maybe<ResolversTypes['PrepaymentEnum']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReservationDetailsPriceResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['ReservationDetailsPrice'] = ResolversParentTypes['ReservationDetailsPrice'],
> = ResolversObject<{
    currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
    price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReservationDetailsRoomResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['ReservationDetailsRoom'] = ResolversParentTypes['ReservationDetailsRoom'],
> = ResolversObject<{
    adultCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    childAges?: Resolver<ResolversTypes['Iterable'], ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    ratePlanId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    roomTypeId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReservationDetailsTaxResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['ReservationDetailsTax'] = ResolversParentTypes['ReservationDetailsTax'],
> = ResolversObject<{
    isIncluded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    minAge?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    taxId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    taxType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    valueType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoomResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Room'] = ResolversParentTypes['Room'],
> = ResolversObject<{
    amenities?: Resolver<Maybe<Array<Maybe<ResolversTypes['Amenity']>>>, ParentType, ContextType>;
    beds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Bed']>>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoomTypeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RoomType'] = ResolversParentTypes['RoomType'],
> = ResolversObject<{
    levelId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    rooms?: Resolver<Maybe<Array<Maybe<ResolversTypes['Room']>>>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['HouseRoomType'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Service'] = ResolversParentTypes['Service'],
> = ResolversObject<{
    free?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    serviceCategory?: Resolver<Maybe<ResolversTypes['ServiceCategory']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['ServiceType'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ServiceCategoryResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['ServiceCategory'] = ResolversParentTypes['ServiceCategory'],
> = ResolversObject<{
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['ServiceCategoryEnum'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SettlementResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Settlement'] = ResolversParentTypes['Settlement'],
> = ResolversObject<{
    hotelCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StayResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Stay'] = ResolversParentTypes['Stay'],
> = ResolversObject<{
    available?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    cheapestRate?: Resolver<Maybe<ResolversTypes['CheapestRate']>, ParentType, ContextType>;
    childRates?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['ChildRate']>>>,
        ParentType,
        ContextType
    >;
    provisions?: Resolver<
        Maybe<Array<Maybe<ResolversTypes['Provision']>>>,
        ParentType,
        ContextType
    >;
    rates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Rate']>>>, ParentType, ContextType>;
    reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    reasonCode?: Resolver<Maybe<ResolversTypes['ReasonCode']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransferDetailsResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['TransferDetails'] = ResolversParentTypes['TransferDetails'],
> = ResolversObject<{
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    note?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UniqueFeatureResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['UniqueFeature'] = ResolversParentTypes['UniqueFeature'],
> = ResolversObject<{
    feature?: Resolver<ResolversTypes['UniqueFeatureEnum'], ParentType, ContextType>;
    label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VariantResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Variant'] = ResolversParentTypes['Variant'],
> = ResolversObject<{
    baseRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    cancellationRules?: Resolver<ResolversTypes['CancellationRules'], ParentType, ContextType>;
    childrenAges?: Resolver<ResolversTypes['Iterable'], ParentType, ContextType>;
    convertedBaseRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    convertedRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    discounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Discount']>>>, ParentType, ContextType>;
    guests?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    provision?: Resolver<ResolversTypes['Provision'], ParentType, ContextType>;
    rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    ratePlan?: Resolver<ResolversTypes['RatePlan'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateCardPaymentInitiationPayloadResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['createCardPaymentInitiationPayload'] = ResolversParentTypes['createCardPaymentInitiationPayload'],
> = ResolversObject<{
    cardPaymentInitiation?: Resolver<
        Maybe<ResolversTypes['CardPaymentInitiation']>,
        ParentType,
        ContextType
    >;
    clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateReservationPayloadResolvers<
    ContextType = any,
    ParentType extends
        ResolversParentTypes['createReservationPayload'] = ResolversParentTypes['createReservationPayload'],
> = ResolversObject<{
    clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    reservation?: Resolver<Maybe<ResolversTypes['Reservation']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
    Accommodation?: AccommodationResolvers<ContextType>;
    AccommodationPageConnection?: AccommodationPageConnectionResolvers<ContextType>;
    AccommodationPaginationInfo?: AccommodationPaginationInfoResolvers<ContextType>;
    AgeRange?: AgeRangeResolvers<ContextType>;
    AggregateRating?: AggregateRatingResolvers<ContextType>;
    Amenity?: AmenityResolvers<ContextType>;
    AmenityOption?: AmenityOptionResolvers<ContextType>;
    Apartment?: ApartmentResolvers<ContextType>;
    Bed?: BedResolvers<ContextType>;
    BookingRoom?: BookingRoomResolvers<ContextType>;
    CancellationPolicy?: CancellationPolicyResolvers<ContextType>;
    CancellationRule?: CancellationRuleResolvers<ContextType>;
    CancellationRules?: CancellationRulesResolvers<ContextType>;
    CancellationType?: CancellationTypeResolvers<ContextType>;
    CardPaymentInitiation?: CardPaymentInitiationResolvers<ContextType>;
    CheapestRate?: CheapestRateResolvers<ContextType>;
    CheapestRateRoom?: CheapestRateRoomResolvers<ContextType>;
    ChildRate?: ChildRateResolvers<ContextType>;
    Counter?: CounterResolvers<ContextType>;
    CounterValue?: CounterValueResolvers<ContextType>;
    Country?: CountryResolvers<ContextType>;
    Discount?: DiscountResolvers<ContextType>;
    District?: DistrictResolvers<ContextType>;
    FinalRateType?: FinalRateTypeResolvers<ContextType>;
    Guarantee?: GuaranteeResolvers<ContextType>;
    GuestCategory?: GuestCategoryResolvers<ContextType>;
    HotelType?: HotelTypeResolvers<ContextType>;
    HouseRoomType?: HouseRoomTypeResolvers<ContextType>;
    Image?: ImageResolvers<ContextType>;
    Inpayment?: InpaymentResolvers<ContextType>;
    InternalAmenityGroup?: InternalAmenityGroupResolvers<ContextType>;
    InternalLabel?: InternalLabelResolvers<ContextType>;
    Iterable?: GraphQLScalarType;
    Language?: LanguageResolvers<ContextType>;
    LastPayment?: LastPaymentResolvers<ContextType>;
    Mutation?: MutationResolvers<ContextType>;
    Node?: NodeResolvers<ContextType>;
    OnlinePaymentDetails?: OnlinePaymentDetailsResolvers<ContextType>;
    Pet?: PetResolvers<ContextType>;
    PrepaymentSummaryType?: PrepaymentSummaryTypeResolvers<ContextType>;
    PrepaymentType?: PrepaymentTypeResolvers<ContextType>;
    Provision?: ProvisionResolvers<ContextType>;
    Query?: QueryResolvers<ContextType>;
    Rate?: RateResolvers<ContextType>;
    RatePlan?: RatePlanResolvers<ContextType>;
    Rating?: RatingResolvers<ContextType>;
    RatingGuest?: RatingGuestResolvers<ContextType>;
    RatingPageConnection?: RatingPageConnectionResolvers<ContextType>;
    RatingPaginationInfo?: RatingPaginationInfoResolvers<ContextType>;
    Refund?: RefundResolvers<ContextType>;
    RefundGuest?: RefundGuestResolvers<ContextType>;
    Region?: RegionResolvers<ContextType>;
    Reservation?: ReservationResolvers<ContextType>;
    ReservationDetails?: ReservationDetailsResolvers<ContextType>;
    ReservationDetailsCancellation?: ReservationDetailsCancellationResolvers<ContextType>;
    ReservationDetailsCancellationItem?: ReservationDetailsCancellationItemResolvers<ContextType>;
    ReservationDetailsGuest?: ReservationDetailsGuestResolvers<ContextType>;
    ReservationDetailsPrepayment?: ReservationDetailsPrepaymentResolvers<ContextType>;
    ReservationDetailsPrice?: ReservationDetailsPriceResolvers<ContextType>;
    ReservationDetailsRoom?: ReservationDetailsRoomResolvers<ContextType>;
    ReservationDetailsTax?: ReservationDetailsTaxResolvers<ContextType>;
    Room?: RoomResolvers<ContextType>;
    RoomType?: RoomTypeResolvers<ContextType>;
    Service?: ServiceResolvers<ContextType>;
    ServiceCategory?: ServiceCategoryResolvers<ContextType>;
    Settlement?: SettlementResolvers<ContextType>;
    Stay?: StayResolvers<ContextType>;
    TransferDetails?: TransferDetailsResolvers<ContextType>;
    UniqueFeature?: UniqueFeatureResolvers<ContextType>;
    Variant?: VariantResolvers<ContextType>;
    createCardPaymentInitiationPayload?: CreateCardPaymentInitiationPayloadResolvers<ContextType>;
    createReservationPayload?: CreateReservationPayloadResolvers<ContextType>;
}>;
