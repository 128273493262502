import * as yup from 'yup';

export interface SummaryInputDataModel {
    reservationId: number;
    identifierCode: string;
    transactionId?: string | null;
}

export const SummaryInputDataModelValidationSchema = yup.object({
    reservationId: yup.number().required(),
    identifierCode: yup.string().required(),
    transactionId: yup.string().optional(),
});
