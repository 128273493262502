import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
} from '@chakra-ui/react';

import { IconsLinear } from '@nocowanie/icons';

import { AdaptiveDrawerProps } from './adaptive-drawer.props';

export const AdaptiveDrawer = ({
    isOpen,
    onClose,
    header,
    onCloseComplete,
    footer,
    drawerProps,
    children,
    ...props
}: AdaptiveDrawerProps) => {
    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={onCloseComplete}
            size={'lg'}
            {...drawerProps}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader
                    borderBottomWidth={'1px'}
                    fontWeight={'normal'}
                    fontSize={'md'}
                    pl={12}
                    minH={header ? undefined : '3em'}
                >
                    <DrawerCloseButton right={'auto'} left={3} top={header ? 3 : 2}>
                        <IconsLinear.ArrowLeft />
                    </DrawerCloseButton>
                    {header ? header : null}
                </DrawerHeader>
                <DrawerBody {...props}>{children}</DrawerBody>
                {footer ?? null}
            </DrawerContent>
        </Drawer>
    );
};
