import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { IncomingHttpHeaders } from 'http';

import { isMobile } from '@nocowanie/common-ui';

import { environment } from '@app/environments/environment';
import { AppStore } from '@app/state';

export const setMobile = ({
    userAgent,
    store,
    action,
}: {
    userAgent: IncomingHttpHeaders['user-agent'];
    store: AppStore;
    action: ActionCreatorWithPayload<any>;
}) => {
    const isMobileDevice = isMobile(userAgent);
    store.dispatch(action(isMobileDevice));
};

export const getAssetUrl = (path: string, baseUrl = environment.CEPH_URL): string => {
    const assetUrl = `${baseUrl}/${path}`;
    return assetUrl;
};
