import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
    'container',
    'ratingNumerical',
    'ratingDescription',
    'ratingCount',
]);

export const AverageRating = helpers.defineMultiStyleConfig({
    baseStyle: {
        container: {
            alignItems: 'center',
            alignSelf: 'flex-end',
            gap: 2,
        },
        ratingNumerical: {
            backgroundColor: 'brand.500',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            flex: '0 0 auto',
            height: 8,
            minWidth: 8,
            px: 1,
            fontSize: 'xs',
            alignItems: 'center',
            fontWeight: 'bold',
            borderRadius: 4,
        },
        ratingDescription: {
            fontWeight: 'bold',
            fontSize: 'sm',
        },
        ratingCount: {
            fontSize: 'xs',
            color: 'gray.500',
        },
    },
});
