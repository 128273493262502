import { IconProps, NocIconGenerator } from '../common';

export const Add = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Add',
            path: (
                <path
                    d="M6 12h12M12 18V6"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            ),
        }}
    />
);
