import { isEmpty, trim } from 'lodash';

import { CrossStorageClient, CrossStorageClientOptions } from 'cross-storage';

export class CrossStorage {
    private readonly client?: CrossStorageClient;
    private static _instance?: CrossStorage;

    private constructor(endpoint: string, config: CrossStorageClientOptions = {}) {
        const baseConfig: CrossStorageClientOptions = {
            timeout: 60000,
        };

        const mergedConfig = {
            ...baseConfig,
            ...config,
        };

        try {
            if (isEmpty(trim(endpoint))) {
                throw new Error('"endpoint" cannot be empty');
            }

            this.client = new CrossStorageClient(endpoint, mergedConfig);
        } catch (error) {
            // if it doesn't work on local environment - silence errors (turn them into console warnings)
            console.warn(`[${CrossStorage.name}] ${error}`);
        }
    }

    static getInstance(endpoint: string, config: CrossStorageClientOptions = {}) {
        if (!CrossStorage._instance) {
            CrossStorage._instance = new CrossStorage(endpoint, config);
        }

        return CrossStorage._instance;
    }

    async setItem(key: string, data: string | number | object | undefined) {
        if (!this.client) {
            return Promise.reject(
                `[${CrossStorage.name}] Error: cannot 'setItem'. CrossStorage was not initialized properly.`,
            );
        }

        await this.client.onConnect();

        const recordData = typeof data === 'object' ? JSON.stringify(data, null, 2) : data;

        return await this.client.set(key, recordData);
    }
}
