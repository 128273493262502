import { IconProps, NocIconGenerator } from '../common';

export const Pets = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Perts',
            path: (
                <>
                    <g
                        stroke="#000001"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        clipPath="url(#a)"
                    >
                        <path d="M4 17.5c1.1 0 2-1.12 2-2.5s-.9-2.5-2-2.5-2 1.12-2 2.5.9 2.5 2 2.5ZM8 10.5c1.1 0 2-1.12 2-2.5s-.9-2.5-2-2.5S6 6.62 6 8s.9 2.5 2 2.5ZM15 10.5c1.1 0 2-1.12 2-2.5s-.9-2.5-2-2.5-2 1.12-2 2.5.9 2.5 2 2.5ZM20 17.5c1.1 0 2-1.12 2-2.5s-.9-2.5-2-2.5-2 1.12-2 2.5.9 2.5 2 2.5ZM17 18.23c0 2.26-2.7 3.27-5 3.27s-5-1.01-5-3.27 1.67-5.73 5-5.73 5 3.47 5 5.73Z" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
