import { CreditCard } from './api/api-types';

import { environment } from '../environments/environment';

export interface CreditCardData {
    cardId: number;
    name: CreditCard;
    prefixes: string[];
    imageSrc?: string;
    digits: number[];
}

const urlPrefix = `${environment.GFX_SERVER}/checkout/credit-cards`;

export const creditCardsData: CreditCardData[] = [
    {
        cardId: 1,
        name: CreditCard.MasterCard,
        prefixes: ['51', '52', '53', '54', '55'],
        imageSrc: `${urlPrefix}/mastercard.png`,
        digits: [16],
    },
    {
        cardId: 2,
        name: CreditCard.Visa,
        prefixes: ['4'],
        imageSrc: `${urlPrefix}/visa.png`,
        digits: [13, 16],
    },
    {
        cardId: 4,
        name: CreditCard.Amex,
        prefixes: ['34', '37'],
        imageSrc: `${urlPrefix}/amex.png`,
        digits: [15],
    },
    {
        cardId: 5,
        name: CreditCard.Maestro,
        prefixes: [
            '67',
            '50',
            '56',
            '57',
            '58',
            '59',
            '60',
            '61',
            '62',
            '63',
            '64',
            '66',
            '68',
            '69',
        ],
        imageSrc: `${urlPrefix}/maestro.png`,
        digits: [12, 13, 14, 15, 16, 17, 18, 19],
    },
];
