import { AutocompleteTranslationModel, defaultAutocompleteTranslation } from '../../../models';

interface AutocompleteConfig {
    translationData: AutocompleteTranslationModel;
}

const defaultAutocompleteConfig: AutocompleteConfig = {
    translationData: defaultAutocompleteTranslation,
};

export { type AutocompleteConfig, defaultAutocompleteConfig };
