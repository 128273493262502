import { urlJoin } from 'url-join-ts';

import { CalendarAvailabilityModel } from '@app/models/nop-api';
import { NopApiServiceBase } from '@app/services/abstracts/nop-api-service.base';

export class NopApiAccommodationService extends NopApiServiceBase {
    private static _instance: NopApiAccommodationService;

    public static get instance(): NopApiAccommodationService {
        if (!this._instance) {
            this._instance = new NopApiAccommodationService();
        }

        return this._instance;
    }

    public async getAvailabilityCalendar(
        accommodationId: number,
    ): Promise<CalendarAvailabilityModel> {
        return await this.fetchNop<CalendarAvailabilityModel>(
            urlJoin('v3', 'Szallas.Hotel.GetAvailabilityCalendar'),
            {
                hotelId: accommodationId,
            },
        );
    }
}
