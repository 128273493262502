export type Maybe<T> = T | null;

export enum DataUnit {
    Currency = 'Currency',
    Night = 'Night',
    None = 'None',
    Percent = 'Percent',
    Percentage = 'Percentage',
}

export type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]> | null;
};
