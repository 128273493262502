/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Context_read } from './Context_read';
export type ListingSetting_read = {
    id?: string;
    sortAlgorithm?: ListingSetting_read.sortAlgorithm;
    context?: Array<Context_read>;
    createdAt?: string;
    updatedAt?: string;
};
export namespace ListingSetting_read {
    export enum sortAlgorithm {
        MIX = 'mix',
        NORMAL = 'normal',
    }
}

