import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['button']);

export const GuestsPicker = helpers.defineMultiStyleConfig({
    baseStyle: {
        button: {
            colorScheme: 'brand',
        },
        iconColor: 'brand.500',
    },
});
