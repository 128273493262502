export enum ImageSize {
    Xs = 'xs',
    Sm = 'sm',
    Md = 'md',
    Lg = 'lg',
    Xl = 'xl',
}

export enum CustomImageSize {
    AccommodationMain = 'accommodationMain',
}
