import { createSelector, createSlice } from '@reduxjs/toolkit';

import { DataLayerReservationTypeEnum, LoadingState } from '@nocowanie/core';

import { SliceNameEnum } from '@app/enums';
import { AccommodationStaticStateModel, apiTypes } from '@app/models';
import {
    AccommodationBaseModel,
    BreadcrumbsItemModel,
    PointOfInterestModel,
} from '@app/models/elastic-search';
import { getAccommodationStatic } from '@app/state/api-thunks';

type StateType = Omit<AccommodationBaseModel, 'breadcrumbs' | 'links'>;

const initialState: AccommodationStaticStateModel = {
    loading: LoadingState.PENDING,
    data: null,
    linkSpace: null,
    breadcrumbs: null,
    pointOfInterest: null,
    isBestseller: null,
};

const slice = createSlice({
    name: SliceNameEnum.AccommodationStatic,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAccommodationStatic.pending, state => {
            state.loading = LoadingState.PENDING;
        });
        builder.addCase(getAccommodationStatic.rejected, (state, action) => {
            state.errors = [action.error].flat();
            state.loading = LoadingState.IDLE;
            state.data = null;
        });
        builder.addCase(getAccommodationStatic.fulfilled, (state, { payload }) => {
            state.errors = null;
            state.loading = LoadingState.IDLE;

            const { staticData, linkSpace, breadcrumbs, pointOfInterest, isBestseller } =
                payload.data;

            // We save data even it may be null/undefined, why? Because this might mean that request was done
            // for nonexistent (id) accommodation, which doesn't mean that we get error,
            // just that for that particular accommodation there's no data
            state.data = staticData || null;
            state.linkSpace = linkSpace;
            state.breadcrumbs = breadcrumbs ?? null;
            state.pointOfInterest = pointOfInterest ?? null;
            state.isBestseller = isBestseller;
        });
    },
});

const { actions: sliceActions } = slice;
const actions = {
    ...sliceActions,
    getAccommodationStatic,
};

const selectSelfState = (state: {
    [SliceNameEnum.AccommodationStatic]: AccommodationStaticStateModel;
}) => {
    return state[slice.name] ?? initialState;
};

const selectAccommodation = createSelector(selectSelfState, state => state.data);
const selectLinkSpace = createSelector(selectSelfState, state => state.linkSpace);
const selectBreadcrumbs = createSelector(selectSelfState, state => state.breadcrumbs);
const selectPointOfInterest = createSelector(selectSelfState, state => state.pointOfInterest);
const selectIsBestseller = createSelector(selectSelfState, state => state.isBestseller);
// Open api types suggest that breadcrumb may not have name and/or link, but it doesn't make sense so we filter these out.
const selectBreadcrumbsList = createSelector(
    selectBreadcrumbs,
    state =>
        state?.items?.filter(crumb => crumb.name && crumb.item && crumb.position) as
            | Required<BreadcrumbsItemModel>[]
            | undefined,
);
const selectPointOfInterestList = createSelector(
    selectPointOfInterest,
    state =>
        state?.filter(poi => poi.name && poi.distanceInMeters) as
            | Required<PointOfInterestModel>[]
            | null
            | undefined,
);

// graphql indicates that image may be null, which doesn't seem to make sense, so we filter and typecast
const selectImages = (accom: StateType | null) => {
    const accomImages = (accom?.images
        ? accom.images.filter(image => !!image)
        : []) as unknown as apiTypes.Image[];
    const apartmentImages = accom?.apartments
        ? accom.apartments.flatMap(apartment => {
              return (apartment?.images
                  ? apartment.images.filter(image => !!image)
                  : []) as unknown as apiTypes.Image[];
          })
        : [];

    return [...accomImages, ...apartmentImages];
};

// filtered out potential nulls from arrays
const selectInternalAmenityGroups = (accom: StateType | null) => {
    return (
        accom?.internalAmenityGroups ? accom.internalAmenityGroups.filter(group => !!group) : null
    ) as apiTypes.Maybe<apiTypes.InternalAmenityGroup[]>;
};

// filtered out potential nulls from arrays
const selectServices = (accom: StateType | null) => {
    return (
        accom?.services
            ? accom.services.filter(service => !!service && service.name !== 'Unknown')
            : null
    ) as apiTypes.Maybe<apiTypes.Service[]>;
};

const selectors = {
    selectAccommodation,
    selectLoading: createSelector(selectSelfState, state => state.loading),
    selectErrors: createSelector(selectSelfState, state => state.errors),
    selectIsDataLoaded: createSelector(selectAccommodation, state => !!state),
    selectGalleryData: createSelector(selectAccommodation, state => ({
        images: selectImages(state),
        name: state?.name,
    })),
    selectBasicInformationData: createSelector(selectAccommodation, state => ({
        name: state?.name,
        address: state?.address,
        streetNumber: state?.streetNumber,
        postalCode: state?.postalCode,
        settlementName: state?.settlement?.name,
        ratingAverage: state?.ratingsAverage ?? state?.ratingAverage,
        ratingCount: state?.ratingCount,
        ratingDescription: state?.ratingDescription,
        internalLabel: state?.internalLabel,
        labels: state?.labels,
    })),
    selectRoomData: createSelector(selectAccommodation, state => ({
        roomCount: state?.apartments?.length || 0,
    })),
    selectLocationData: createSelector(selectAccommodation, state => ({
        latitude: state?.latitude,
        longitude: state?.longitude,
        name: state?.name,
        address: state?.address,
        streetNumber: state?.streetNumber,
        settlementName: state?.settlement?.name,
    })),
    selectMetaTagsData: createSelector(selectAccommodation, state => ({
        name: state?.name,
        mainImg: state?.images?.[0]?.url,
    })),
    selectRichSnippetsData: createSelector(selectAccommodation, state => ({
        type: (state?.type as any)?.type ?? state?.type, //BACKWARD COMPATIBILITY
        name: state?.name,
        description: state?.description,
        numberOfRooms: state?.apartments?.length,
        image: state?.images?.[0]?.url,
        addressCountry: state?.country?.code,
        addressLocality: state?.settlement?.name,
        addressRegion: state?.regions?.[1]?.name,
        postalCode: state?.postalCode,
        streetAddress:
            state?.address && state?.streetNumber
                ? `${state.address} ${state.streetNumber}`
                : state?.address,
        latitude: state?.latitude,
        longitude: state?.longitude,
        ratingAverage: state?.ratingsAverage ?? state?.ratingAverage,
        ratingCount: state?.ratingCount,
        services: selectServices(state),
        internalAmenityGroups: selectInternalAmenityGroups(state),
        labels: state?.labels,
        cheapestRate: state?.stay?.cheapestRate,
        currency: state?.currency,
        convertedCurrency: state?.convertedCurrency,
    })),
    selectAccommodationDataLayerData: createSelector(selectAccommodation, state => ({
        internalAmenityGroups: selectInternalAmenityGroups(state),
        labels: state?.labels,
        offerDestType: (state?.type as any)?.type ?? state?.type, //BACKWARD COMPATIBILITY
        offerName: state?.name,
        offerLocation: state?.settlement?.name,
        offerLocationID: state?.listings?.find(listing => listing.type === 'legacy_city')?.object
            .legacyCityId,
        offerPhotos: selectImages(state).length,
        offerReservationModel:
            state?.internalLabel?.reservationType === apiTypes.ReservationTypeEnum.RealTime
                ? DataLayerReservationTypeEnum.RealTime
                : DataLayerReservationTypeEnum.OnRequest,
        ratingCount: state?.ratingCount,
        ratingDescription: state?.ratingDescription,
    })),
    selectAmenitiesData: createSelector(selectAccommodation, state => ({
        internalAmenityGroups: selectInternalAmenityGroups(state),
        services: selectServices(state),
    })),
    selectReviewsData: createSelector(selectAccommodation, state => ({
        ratingAverage: state?.ratingsAverage ?? state?.ratingAverage,
        ratingCount: state?.ratingCount,
        ratingDescription: state?.ratingDescription,
        aggregateRating: state?.aggregateRating,
    })),
    selectRecentReviews: createSelector(selectAccommodation, state =>
        state?.ratings?.collection?.slice(0, 5),
    ),
    selectHasValueForMoneyLabel: createSelector(
        selectAccommodation,
        state =>
            !!(state?.internalLabel.quantity || []).find(
                item => item === apiTypes.QuantityEnum.GoodPrice,
            ),
    ),
    selectSeoLinksData: selectLinkSpace,
    selectBreadcrumbsData: selectBreadcrumbs,
    selectBreadcrumbsListData: selectBreadcrumbsList,
    selectPointOfInterestListData: selectPointOfInterestList,
    selectDescription: createSelector(selectAccommodation, state => ({
        description: state?.description,
    })),
    selectImportantInfoData: createSelector(selectAccommodation, state => ({
        checkInStart: state?.checkInStart,
        checkInEnd: state?.checkInEnd,
        checkOutStart: state?.checkOutStart,
        checkOutEnd: state?.checkOutEnd,
    })),
    selectContactPhoneData: createSelector(selectAccommodation, state => ({
        hotelPhone: state?.hotelPhone,
        contactPhoneVisible: state?.contactPhoneVisible,
    })),
    selectAccommodationType: createSelector(
        selectAccommodation,
        state => (state?.type as any)?.type ?? state?.type, //BACKWARD COMPATIBILITY
    ),
    selectIsBestseller,
};

export { slice, actions, selectors, initialState };
