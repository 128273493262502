import { AspectRatio, Box, Image as ChakraImage } from '@chakra-ui/react';

import { IconsLinear } from '@nocowanie/icons';

import { ImageProps } from './image.props';

// TODO: add lazyloading with some kind of indicator
export const Image = (
    { ratioProps, sources, src, ...props }: ImageProps = { loading: 'eager' },
): JSX.Element => {
    const image = (
        <picture>
            {src ? (
                <>
                    {sources?.map((el, index) => (
                        <source key={index} srcSet={el.srcSet} media={el.media} />
                    ))}
                    <ChakraImage
                        src={src}
                        width={ratioProps ? '100%' : undefined}
                        height={ratioProps ? '100%' : undefined}
                        {...props}
                    />
                </>
            ) : (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    bgColor={'gray.200'}
                    width={'100%'}
                    height={'100%'}
                >
                    <IconsLinear.Image color="gray.400" size={'8xl'} maxW={'40%'} maxH={'40%'} />
                </Box>
            )}
        </picture>
    );

    const imageWithRatio = (
        <AspectRatio w="100%" overflow={'hidden'} {...ratioProps}>
            {image}
        </AspectRatio>
    );

    return ratioProps ? imageWithRatio : image;
};
