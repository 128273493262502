import { useRouter } from 'next/router';

import { useCallback, useEffect, useMemo, useState } from 'react';

import qs from 'qs';

import { LocalStorageKey } from '@nocowanie/core';

import { accommodationHelpers } from '@app/helpers';
import { useCrossStorage } from '@app/hooks';
import { QueryAccommodationArgs } from '@app/models/api/api-types';
import { accommodationSlice, useAppDispatch, useAppSelector } from '@app/state';

export const useAccommodationQueryParams = () => {
    const { replace } = useRouter();
    const [filtersFirstRender, setFiltersFirstRender] = useState(true);
    const [pageUIFirstRender, setPageUIFirstRender] = useState(true);
    const dispatch = useAppDispatch();
    const filtersS = useAppSelector(accommodationSlice.selectors.selectFilters);
    // Selectors seem to not memoize correctly so we have to do it ourselves.
    const filters = useMemo(
        () => filtersS,
        [
            filtersS?.id,
            filtersS?.checkIn,
            filtersS?.checkOut,
            filtersS?.childrenAges,
            filtersS?.childrenCount,
            filtersS?.guestsCount,
        ],
    );

    const saveParamsToURL = useCallback(
        (params: Record<string, any>) => {
            const queryString = qs.stringify(params, { arrayFormat: 'indices' });
            const hash = window.location.hash;
            const url = `${window.location.pathname}?${queryString}${hash}`;
            replace(url, undefined, {
                shallow: true,
            });
        },
        [replace],
    );
    const crossStorageClient = useCrossStorage();
    const saveFiltersToCrossStorage = useCallback(() => {
        if (!crossStorageClient) {
            return;
        }

        const mappedParams = filters
            ? accommodationHelpers.generateLegacySearchCriteriaParams(
                  filters as Partial<QueryAccommodationArgs>,
              )
            : undefined;
        if (mappedParams) {
            crossStorageClient
                .setItem(LocalStorageKey.SearchCriteria, mappedParams)
                .catch(error => {
                    console.warn(error);
                });
        }
    }, [filters, crossStorageClient]);

    useEffect(() => {
        // since we have SSR rendering/fetching we have to prevent refetch during client (re)render
        if (pageUIFirstRender) {
            setPageUIFirstRender(false);
            return;
        }

        const updatedParams = accommodationHelpers.updateParams(window.location.search);
        saveParamsToURL(updatedParams);
    }, []);

    useEffect(() => {
        // since we have SSR rendering/fetching we have to prevent refetch during client (re)render
        if (filtersFirstRender) {
            setFiltersFirstRender(false);
            return;
        }

        dispatch(accommodationSlice.actions.fetchAccommodation());

        const updatedParams = accommodationHelpers.updateParams(
            window.location.search,
            accommodationHelpers.mapFiltersToParams(filters as QueryAccommodationArgs),
        );

        saveParamsToURL(updatedParams);

        saveFiltersToCrossStorage();
    }, [filters]);

    // Save initial filters to CrossStorage when `CrossStorage` is initialized (after the first render)
    useEffect(() => {
        if (!crossStorageClient) {
            return;
        }

        saveFiltersToCrossStorage();
    }, [crossStorageClient]);
};
