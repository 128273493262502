/**
 * Returns an optimized (reduced) image (uses WP Image Resizer)
 *
 * usage examples:
 * imageHelpers.wpresizer([360, 240], imageSrc)
 * or
 * imageHelpers.wpresizer({ratio: ImageRatio['16/9'], size: ImageSize.Sm}, imageSrc)
 */

import { IMAGE_SIZES } from '../consts';
import { ImageRatio, ImageSize } from '../enums';

type ImageSizeObject = {
    ratio: ImageRatio;
    size: ImageSize;
};

export const wpresizer = (size: [number, number] | ImageSizeObject, url: string): string => {
    const resizerPath = 'https://i.wpimg.pl';
    const imageUrl = url.replace(/^https?:\/\//, '');
    let w;
    let h;

    if (Array.isArray(size)) {
        w = size[0];
        h = size[1];
    } else {
        w = IMAGE_SIZES[size.ratio][size.size].width;
        h = IMAGE_SIZES[size.ratio][size.size].height;
    }

    return `${resizerPath}/${w}x${h}/${imageUrl}`;
};
