import { useCallback, useEffect } from 'react';

import isEqual from 'lodash/isEqual';

import { DRAWER_OPEN_CLASSNAME } from '@app/consts';
import { AppModalsEnum } from '@app/enums';
import { commonUiStateSlice, useAppDispatch, useAppSelector } from '@app/state';

const useHashChangeListener = (onHashChange: () => void) => {
    useEffect(() => {
        const controller = new AbortController();
        onHashChange();

        window.addEventListener('hashchange', onHashChange, { signal: controller.signal });

        return () => controller.abort();
    }, [onHashChange]);
};

export const useModalCloseOnBack = (modalName: AppModalsEnum, isModalOpen: boolean) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const controller = new AbortController();
        const closeOnHashChange = () => {
            const currentHash = window.location.hash.slice(1);
            const modals = currentHash ? currentHash.split(',') : [];

            if (isModalOpen && !modals.some(modal => modal.startsWith(modalName))) {
                if (sessionStorage.getItem(modalName)) {
                    sessionStorage.removeItem(modalName);
                }
                dispatch(commonUiStateSlice.actions.resetModalDataAction(modalName));
            }

            if (document.body.classList.contains(DRAWER_OPEN_CLASSNAME)) {
                document.body.classList.remove(DRAWER_OPEN_CLASSNAME);
            }
        };

        window.addEventListener('hashchange', closeOnHashChange, { signal: controller.signal });

        return () => controller.abort();
    }, [modalName, isModalOpen]);
};

export const useModalFragmentSync = (modalName: AppModalsEnum, isModalOpen: boolean) => {
    const dispatch = useAppDispatch();

    useModalCloseOnBack(modalName, isModalOpen);

    const openOnHashChange = useCallback(() => {
        if (!isModalOpen && window.location.hash.slice(1).includes(modalName)) {
            dispatch(
                commonUiStateSlice.actions.setModalDataAction({
                    modalName,
                    modalData: true,
                }),
            );
        }
    }, [modalName, isModalOpen]);

    useHashChangeListener(openOnHashChange);
};

export const useModalFragmentSyncWithData = <T>(
    modalName: AppModalsEnum,
    modalDataParser: (...args: string[]) => T | null,
) => {
    const dispatch = useAppDispatch();

    const currentModalData = useAppSelector(
        commonUiStateSlice.selectors.selectModalData(modalName),
    );

    useModalCloseOnBack(modalName, !!currentModalData);

    const openOnHashChange = useCallback(() => {
        const currentHash = window.location.hash.slice(1);
        const modals = currentHash ? currentHash.split(',') : [];
        const modalFragment = modals.find(m => m.startsWith(modalName));

        if (modalFragment) {
            const parts = modalFragment.split(':');
            let data: T | null = null;

            if (parts.length > 2) {
                data = modalDataParser(parts[1], parts[2]);
            } else if (parts[1]) {
                data = modalDataParser(parts[1]);
            }

            if (!isEqual(currentModalData, data)) {
                dispatch(
                    commonUiStateSlice.actions.setModalDataAction({
                        modalName,
                        modalData: data,
                    }),
                );
            }
        }
    }, [modalName, currentModalData, modalDataParser]);

    useHashChangeListener(openOnHashChange);
};
