/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Retrieves a User resource.
     * Retrieves a User resource.
     * @returns User Successful operation
     * @throws ApiError
     */
    public me(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/me',
            errors: {
                400: `Bad request`,
                401: `Unauthorized - Invalid or missing authentication`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
}
