import { NextMiddleware, NextResponse } from 'next/server';

import { MiddlewareFactory } from './middleware-factory.type';

// Executes multiple middlewares
// Based on https://reacthustle.com/blog/how-to-chain-multiple-middleware-functions-in-nextjs
export function mergeMiddlewares(middlewares: MiddlewareFactory[], index = 0): NextMiddleware {
    const current = middlewares[index];
    if (current) {
        const next = mergeMiddlewares(middlewares, index + 1);
        return current(next);
    }

    return () => NextResponse.next();
}
