const accommodationPl = require('./public/locales/pl/accommodation.json');
const commonPl = require('./public/locales/pl/common.json');
const checkoutPl = require('./public/locales/pl/checkout.json');
const homePl = require('./public/locales/pl/home.json');
const socialMediaPl = require('./public/locales/pl/social-media.json');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
    resources: {
        pl: {
            accommodation: accommodationPl,
            checkout: checkoutPl,
            common: commonPl,
            home: homePl,
            socialMedia: socialMediaPl,
        },
    },
    i18n: {
        defaultLocale: 'pl',
        locales: ['pl'],
        localeDetection: false,
    },
    interpolation: {
        escapeValue: false,
    },
};
