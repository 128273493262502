import React from 'react';

import { AlertProps as ChakraAlertProps, AlertTitleProps } from '@chakra-ui/react';

import { IconProps } from '../../../components';

export interface AlertProps extends ChakraAlertProps {
    title?: string;
    hideIcon?: boolean;
    titleProps?: AlertTitleProps;
    customIcon?: IconProps;
    buttons?: React.ReactNode[];
    children?: React.ReactNode;
}
