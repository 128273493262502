/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Context_jsonld_read = {
    readonly '@context'?: (string | Record<string, any>);
    readonly '@id'?: string;
    readonly '@type'?: string;
    id?: string;
    type?: Context_jsonld_read.type;
    name?: string;
};
export namespace Context_jsonld_read {
    export enum type {
        CITY = 'city',
        REGION = 'region',
        COUNTRY = 'country',
        VOIVODESHIP = 'voivodeship',
        CATEGORY = 'category',
    }
}

