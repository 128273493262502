import { urlJoin } from 'url-join-ts';

import { AutocompleteItemSCModel, EsResponseManyModel } from '@app/models/elastic-search';
import { ApiServiceBase } from '@app/services/abstracts/api-service.base';

export class ApiAutocompleteService extends ApiServiceBase {
    static #_instance: ApiAutocompleteService;

    public static get instance(): ApiAutocompleteService {
        if (!this.#_instance) {
            this.#_instance = new ApiAutocompleteService();
        }

        return this.#_instance;
    }

    public async getAutocompleteItems(
        query: string,
        limit = 5,
    ): Promise<EsResponseManyModel<AutocompleteItemSCModel>> {
        return await this.query<EsResponseManyModel<AutocompleteItemSCModel>>(
            urlJoin('autocomplete'),
            {
                query,
                limit,
            },
        );
    }
}
