import React from 'react';

import { BoxProps } from '@chakra-ui/react';

export enum StickyBarDirection {
    bottom = 'bottom',
    top = 'top',
}

export interface StickyBarProps extends BoxProps {
    direction?: StickyBarDirection;
    withShadow?: boolean;
    children: React.ReactNode;
}
