export const deepOmitBy = <T extends object>(obj: T): Partial<T> => {
    if (typeof obj === 'object')
        return Object.entries(obj)
            .filter(([k, v]) => ![undefined, null, ''].includes(v))
            .reduce((r, [key, value]) => ({ ...r, [key]: deepOmitBy(value) }), {});
    else return obj;
};

// The intersperse function is used to insert a separator between each element of an array.
// Shamelessly copied from https://github.com/lodash/lodash/issues/2339#issuecomment-585615971
export function intersperse<T>(arr: T[], separator: (n: number) => T): T[] {
    return arr.flatMap((a, i) => (i > 0 ? [separator(i), a] : [a]));
}

export const isJSON = (jsonStr: string) => {
    try {
        JSON.parse(jsonStr);
        return true;
    } catch (error) {
        return false;
    }
};
