import axios from 'axios';
import { urlJoin } from 'url-join-ts';

import { withOTLPSpan } from '@app/instrumentation/instrumentation.helpers';
import {
    EsParamsArgsModel,
    EsResponseManyModel,
    EsResponseModel,
    EsSearchArgsModel,
} from '@app/models/elastic-search';

export abstract class EsServiceBase {
    protected get EsHost(): string {
        return process.env.ELASTICSEARCH_HOST ?? '/';
    }

    protected get EsUserName(): string {
        return process.env.ELASTICSEARCH_USERNAME ?? '';
    }

    protected get EsPassword(): string {
        return process.env.ELASTICSEARCH_PASSWORD ?? '';
    }

    protected async fetchEs<TModel>(
        esSearchUrl: string,
        searchArgs?: EsSearchArgsModel,
        paramsArgs?: EsParamsArgsModel,
    ): Promise<TModel> {
        const url = urlJoin(this.EsHost, esSearchUrl);
        const response = await withOTLPSpan('fetch ElasticSearch', async span => {
            span.setAttribute('ElasticSearch URL', url);

            if (searchArgs) {
                span.setAttribute('ElasticSearch Query', JSON.stringify(searchArgs));
            }

            return await axios
                .get<TModel>(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${Buffer.from(
                            `${this.EsUserName}:${this.EsPassword}`,
                        ).toString('base64')}`,
                    },
                    data: searchArgs ? JSON.stringify(searchArgs) : undefined,
                    params: paramsArgs,
                })
                .finally(() => span.end());
        });

        return response.data;
    }

    protected async get<TModel>(
        indexName: string,
        id: number | string,
        type = '_doc',
        paramsArgs?: EsParamsArgsModel,
    ): Promise<EsResponseModel<TModel>> {
        const esURL = urlJoin(indexName, type, id) + '/';

        return this.fetchEs<EsResponseModel<TModel>>(esURL, undefined, paramsArgs);
    }

    protected async getMany<TModel>(
        indexName: string,
        searchArgs: EsSearchArgsModel,
    ): Promise<EsResponseManyModel<TModel>> {
        const esURL = urlJoin(indexName, '_search') + '/';
        const response = await this.fetchEs<{ hits?: EsResponseManyModel<TModel> }>(
            esURL,
            searchArgs,
        );

        return response?.hits as EsResponseManyModel<TModel>;
    }
}
