import { urlJoin } from 'url-join-ts';

import { NocApiClient, User } from '@nocowanie/noc-api';

import { createGQLClient, GraphQLClient } from '@nocowanie/gql-core';

import { environment } from '@app/environments/environment';
import { ReadinessCheckAccommodationsModel } from '@app/models';

import accommodationsQuery from './accommodations.query.graphql';

export class ReadinessCheckService {
    private readonly gqlClient: GraphQLClient;

    private constructor() {
        this.gqlClient = createGQLClient({
            apiUrl: urlJoin(environment.ACCOMMODATION_API_ENDPOINT, 'api', 'graphql'),
        });
    }

    private static _instance?: ReadinessCheckService;

    public static get instance(): ReadinessCheckService {
        if (!this._instance) {
            this._instance = new ReadinessCheckService();
        }

        return this._instance;
    }

    public loadAccommodations(headers?: Record<string, string>) {
        return this.gqlClient.execQuery<{
            collection: ReadinessCheckAccommodationsModel;
        }>(
            'accommodations',
            accommodationsQuery,
            {
                first: 0,
                last: 3,
            },
            headers,
        );
    }

    public async loadUser(headers?: Record<string, string>): Promise<{
        // `data` type only defines success/200 response body shape, not 4xx/5xx ones.
        data: User | null;
        status: number;
    }> {
        try {
            const apiClient = new NocApiClient({
                BASE: environment.NOC_API_END_POINT,
                HEADERS: headers,
            });
            const res = await apiClient.user.me();

            return { data: res, status: 200 };
        } catch (err: any) {
            return { data: null, status: err?.status };
        }
    }
}
