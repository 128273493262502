import { IconProps, NocIconGenerator } from '../common';

export const Clock = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'Clock',
            path: (
                <>
                    <path
                        d="M22 12c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2s10 4.48 10 10Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="m15.71 15.18-3.1-1.85c-.54-.32-.98-1.09-.98-1.72v-4.1"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            ),
        }}
    />
);
