import { Box, Portal } from '@chakra-ui/react';

import { OverlayProps } from './overlay.props';

import { motion } from 'framer-motion';

export const Overlay = ({
    children,
    isActive = false,
    ...rest
}: OverlayProps): JSX.Element | null => {
    return isActive ? (
        <Portal>
            <Box
                position={'fixed'}
                as={motion.div}
                top={0}
                left={0}
                w={'100%'}
                h={'100%'}
                backgroundColor={'blackAlpha.600'}
                zIndex={'modal'}
                transition={'0.3s ease-in'}
                animate={{
                    opacity: [0, 1],
                }}
                {...rest}
            >
                {children}
            </Box>
        </Portal>
    ) : null;
};
