import dynamic from 'next/dynamic';

import { urlMap } from '@nocowanie/core';

import { useAppHeaderHook } from './app-header.hook';

const LogoV7 = dynamic(() => import('@nocowanie/common-ui').then(c => c.LogoV7));
const Logo = dynamic(() => import('@nocowanie/common-ui').then(c => c.Logo));

export const AppHeaderLogo = ({
    isGhostVariant = false,
    isLogoV7 = true,
}: {
    isGhostVariant?: boolean;
    isLogoV7?: boolean;
}) => {
    const { isMobileRes } = useAppHeaderHook();

    const commonProps = {
        url: urlMap.home,
        zIndex: 1,
    };

    return isLogoV7 ? (
        <LogoV7
            fill={isGhostVariant ? 'white' : 'brand.500'}
            logoProps={{
                width: isMobileRes ? 120 : 204,
                height: isMobileRes ? 33 : 56,
            }}
            {...commonProps}
        />
    ) : (
        <Logo
            fill={'brand.500'}
            logoProps={{
                width: isMobileRes ? 132 : 190,
                height: isMobileRes ? 30 : 40,
            }}
            mt={isMobileRes ? '-3px' : '-4px'}
            {...commonProps}
        />
    );
};
