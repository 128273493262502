import {
    Card as ChakraCard,
    CardBody as ChakraCardBody,
    CardFooter as ChakraCardFooter,
    CardHeader as ChakraCardHeader,
    Center,
    Flex,
    Heading,
    SystemProps,
    Text,
} from '@chakra-ui/react';

import { CardProps } from './card.props';

import { Image } from '../../atoms';

const Card = ({
    badge,
    heading,
    headingSize,
    subheading,
    imageProps,
    bodyProps,
    headingProps,
    footerProps,
    ...cardProps
}: CardProps): JSX.Element => {
    return (
        <ChakraCard {...cardProps}>
            {imageProps?.src && <Image {...imageProps} objectFit="cover" />}
            <ChakraCardBody p={2} {...bodyProps}>
                <div>
                    {(heading || subheading) && (
                        <ChakraCardHeader px={0} py={2} {...headingProps}>
                            {(cardProps?.direction as SystemProps['flexDirection']) === 'column' ? (
                                <Flex justifyContent={'space-between'}>
                                    {heading && <Heading size={headingSize}>{heading}</Heading>}
                                    {badge && <Center flexShrink={0}>{badge}</Center>}
                                </Flex>
                            ) : (
                                <>
                                    {badge && <Flex pb={2}>{badge}</Flex>}
                                    {heading && <Heading size={headingSize}>{heading}</Heading>}
                                </>
                            )}
                            {subheading && <Text fontSize={'sm'}>{subheading}</Text>}
                        </ChakraCardHeader>
                    )}

                    {cardProps.children}

                    {footerProps && <ChakraCardFooter px={0} py={2} {...footerProps} />}
                </div>
            </ChakraCardBody>
        </ChakraCard>
    );
};

export { Card };
