import { IconProps, NocIconGenerator } from '../common';

export const CrossCountry = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'CrossCountry',
            path: (
                <>
                    <g
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        clipPath="url(#a)"
                    >
                        <path d="M19.408 5.83 7.513 11.006 5.082 9.786a.435.435 0 0 0-.367-.01l-2.27.976a.434.434 0 0 0-.135.707l3.258 3.26a.435.435 0 0 0 .47.096L12.3 12.31l-2.222 5.926a.436.436 0 0 0 .601.542l2.364-1.18a.435.435 0 0 0 .194-.195l3.417-6.833 4.091-1.523a1.74 1.74 0 1 0-1.332-3.216h-.005Z" />
                        <path d="M15.496 7.533 9.868 5.21a.435.435 0 0 0-.332 0l-2.08.862a.435.435 0 0 0 0 .804l4.892 2.024" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M1.333 1.333h21.333v21.333H1.333z" />
                        </clipPath>
                    </defs>
                </>
            ),
        }}
    />
);
