export const Heading = {
    sizes: {
        xs: {
            fontSize: '18px',
        },
        sm: {
            fontSize: {
                base: '18px',
                md: '20px',
            },
        },
        md: {
            fontSize: {
                base: '20px',
                md: '22px',
            },
        },
        lg: {
            fontSize: {
                base: '22px',
                md: '26px',
            },
        },
        xl: {
            fontSize: {
                base: '26px',
                md: '32px',
            },
        },
        '2xl': {
            fontSize: {
                base: '34px',
                md: '50px',
            },
        },
        '3xl': {
            fontSize: {
                base: '40px',
                md: '58px',
            },
        },
        '4xl': {
            fontSize: {
                base: '64px',
                md: '72px',
            },
        },
    },
};
