import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createWrapper } from 'next-redux-wrapper';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { parse, stringify } from 'superjson';

import { SliceNameEnum, UserSliceName } from '@app/enums';
import { environment } from '@app/environments/environment';
import { modalsMiddleware } from '@app/middlewares';

import {
    accommodationRatingsSlice,
    accommodationSlice,
    accommodationStaticSlice,
    checkoutDetailsSlice,
    checkoutReservationSlice,
    commonUiStateSlice,
    createPaymentSlice,
    homepageReducer,
    summarySlice,
    userProfileSlice,
} from './slices';

const reducers = {
    [SliceNameEnum.Accommodation]: accommodationSlice.slice.reducer,
    [SliceNameEnum.AccommodationRatings]: accommodationRatingsSlice.slice.reducer,
    [SliceNameEnum.AccommodationStatic]: accommodationStaticSlice.slice.reducer,
    [SliceNameEnum.Homepage]: homepageReducer,
    [SliceNameEnum.CommonUiState]: commonUiStateSlice.uiStateSlice.reducer,
    [SliceNameEnum.CheckoutDetails]: checkoutDetailsSlice.slice.reducer,
    [SliceNameEnum.CheckoutReservation]: checkoutReservationSlice.slice.reducer,
    [SliceNameEnum.CreatePayment]: createPaymentSlice.slice.reducer,
    [SliceNameEnum.ReservationDetails]: summarySlice.slice.reducer,
    [UserSliceName.UserProfile]: userProfileSlice.slice.reducer,
};

const makeStore = ({ reduxWrapperMiddleware = null }: any) =>
    configureStore({
        devTools: environment.REDUX_DEV_TOOLS
            ? {
                name: process.env.STORE_NAME ?? 'NOCOWANIE-STORE',
            }
            : false,
        reducer: reducers,
        middleware: getDefaultMiddleware =>
            reduxWrapperMiddleware
                ? getDefaultMiddleware().concat(reduxWrapperMiddleware).concat(modalsMiddleware)
                : getDefaultMiddleware(),
    });

type AppStore = ReturnType<typeof makeStore>;
type AppState = ReturnType<AppStore['getState']>;
type AppDispatch = AppStore['dispatch'];
export interface ActionWithPayload extends Action {
    type: string;
    payload?: any;
}
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

const useAppDispatch: () => AppDispatch = useDispatch;
const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
const nocowanieAppStateWrapper = createWrapper<AppStore>(makeStore, {
    debug: false,
    serialize: actions =>
        actions.map((action: ActionWithPayload) => ({
            ...action,
            payload: stringify(action.payload),
        })),
    deserialize: actions =>
        actions.map((action: ActionWithPayload) => ({ ...action, payload: parse(action.payload) })),
});

export { reducers, makeStore, useAppDispatch, useAppSelector, nocowanieAppStateWrapper };
export type { AppStore, AppState };
