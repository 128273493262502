import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['icon']);

export const SearchEngineMobile = helpers.defineMultiStyleConfig({
    baseStyle: {
        icon: {
            color: 'brand.500',
        },
    },
});
