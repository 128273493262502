import { IconProps, NocIconGenerator } from '../common';

export const ArrowUp = (props: IconProps): JSX.Element => (
    <NocIconGenerator
        {...props}
        createIconProps={{
            displayName: 'ArrowUp',
            path: (
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M19.92 15.05L13.4 8.53001C12.63 7.76001 11.37 7.76001 10.6 8.53001L4.07996 15.05"
                    stroke="#292D32"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            ),
        }}
    />
);
