export enum AppModalsEnum {
    Amenities = 'amenities',
    ApartmentDetails = 'apartmentDetails',
    ApartmentsList = 'apartmentsList',
    CancellationDetails = 'cancellationDetails',
    Description = 'description',
    Gallery = 'gallery',
    Location = 'location',
    Reviews = 'reviews',
}
