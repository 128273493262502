import { FormControl, FormLabel, FormLabelProps, Switch as SwitchChakra } from '@chakra-ui/react';

import { SwitchComponentProps } from './switch.props';

interface baseLabelProps extends FormLabelProps {
    label: string;
}

export const Switch = ({
    id,
    labelLeft,
    labelRight,
    labelProps,
    name,
    checked,
    onChange,
    ...props
}: SwitchComponentProps): JSX.Element => {
    const BaseLabel = ({ label, textAlign, fontWeight, ...rest }: baseLabelProps) => (
        <FormLabel
            {...labelProps}
            htmlFor={id}
            m={0}
            fontSize={'sm'}
            textAlign={textAlign}
            fontWeight={fontWeight}
        >
            {label}
        </FormLabel>
    );

    return (
        <FormControl display="flex" alignItems="center" gap={2}>
            {labelLeft && (
                <BaseLabel
                    label={labelLeft}
                    textAlign={'end'}
                    fontWeight={!checked ? 'bold' : 'normal'}
                />
            )}
            <SwitchChakra
                size="lg"
                id={id}
                name={name}
                onChange={onChange}
                checked={checked}
                {...props}
            />
            <BaseLabel label={labelRight} fontWeight={checked ? 'bold' : 'normal'} />
        </FormControl>
    );
};
