import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['icon', 'button']);

export const AccordionMenuItem = helpers.defineMultiStyleConfig({
    baseStyle: {
        icon: {
            fontSize: '2xl',
            color: 'brand.500',
        },
        button: {
            fontWeight: '300',
            py: '0',
            pl: '9',
            justifyContent: 'space-between',
            color: 'secondary.500',
            _hover: { bg: 'white' },
            _expanded: {
                fontWeight: 'bold',
            },
        },
    },
});
