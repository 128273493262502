import {
    ApolloClient,
    ApolloError,
    DocumentNode,
    gql,
    InMemoryCache,
    TypedDocumentNode,
} from '@apollo/client';
import { v4 as uuid } from 'uuid';

import { GQLOptions, GraphQLClient, Query } from './common';
import { getConstHeadersMiddleware } from './middlewares';
import { ApiResponseModel, BaseModel } from './models';

/**
 * Create new instance of ApolloClient to fetch data from API
 * @param  options Configuration options
 */
export const createGQLClient = (options: GQLOptions): GraphQLClient => {
    const { apiUrl, cacheOptions } = options;

    const cache =
        cacheOptions ??
        new InMemoryCache({
            addTypename: false,
            resultCaching: false,
        });

    const client = new ApolloClient({
        cache: cache,
        uri: apiUrl,
        defaultOptions: {
            watchQuery: { fetchPolicy: 'no-cache' },
            mutate: { fetchPolicy: 'no-cache' },
        },
    });

    /**
     * Invoke API query to fetch data from API
     * @param queryName Query name
     * @param query Query in Graph language
     * @param variables Params passed to query
     * @param headers
     */
    const execQuery: Query = <RType>(
        queryName: string,
        query: DocumentNode | TypedDocumentNode,
        variables?: Record<string, unknown>,
        headers?: Record<string, string>,
    ): Promise<ApiResponseModel<RType>> => {
        return client
            .query({
                query,
                variables,
                fetchPolicy: 'no-cache',
                context: {
                    headers: { ...getConstHeadersMiddleware(headers ?? {}) },
                },
            })
            .then(({ data }) => {
                return { data: data[queryName] };
            })
            .catch((err: ApolloError) => {
                return {
                    errors: [err.graphQLErrors, err.networkError, err.clientErrors]
                        .filter(Boolean)
                        .flat() ?? [err],
                } as any;
            });
    };

    /**
     * Invoke mutation in API
     * @param mutationName Mutation name
     * @param inputObject Object to send to API
     * @param clientMutationId Custom identifier
     * @param headers Additional headers
     */
    const execMutation = <TKey, TModel extends BaseModel<TKey>>(
        mutationName: string,
        inputObject: Partial<TModel>,
        clientMutationId: string = uuid(),
        headers?: Record<string, string>,
    ): Promise<TModel> => {
        const variables = {
            ...inputObject,
            clientMutationId,
        };

        const mutation = gql`mutation
      {
        ${mutationName}(input:${JSON.stringify(variables).replace(/"([^"]+)":/g, '$1:')})
          {
            clientMutationId
          }
      }`;

        return client
            .mutate({
                mutation,
                variables,
                context: {
                    headers: { ...getConstHeadersMiddleware(headers ?? {}) },
                },
            })
            .then(({ data }) => data)
            .catch((err: ApolloError) => ({ errors: err.graphQLErrors ?? [err] } as any));
    };

    /**
     * Close connection and dispose object
     */
    const disconnect = () => {
        client?.stop();
    };

    return { execMutation, execQuery, disconnect };
};
