import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['label', 'drawerHeader', 'rangePicker', 'button']);

export const SearchEngineMobileDrawer = helpers.defineMultiStyleConfig({
    baseStyle: {
        label: {
            fontSize: 'sm',
            fontWeight: 'bold',
            mb: 1,
        },
        drawerHeader: {
            fontSize: 'lg',
            fontWeight: 'bold',
            pb: '2',
        },
        rangePicker: {
            borderRadius: 'xl',
        },
        button: {
            colorScheme: 'brand',
        },
    },
});
