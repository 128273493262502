import { urlJoin } from 'url-join-ts';

import { ApiResponseModel } from '@nocowanie/gql-core';
import { CoreSkeletonService } from '@nocowanie/core';

import { environment } from '@app/environments/environment';

import checkoutMutation from './checkout.mutation.graphql';
import finalRatesQuery from './checkout-final-rates.query.graphql';

import { apiTypes } from '../models';

export class CheckoutService extends CoreSkeletonService<string, apiTypes.Accommodation> {
    private constructor() {
        super({
            gqlOptions: {
                apiUrl: urlJoin(environment.CHECKOUT_API_END_POINT, 'api', 'graphql'),
            },
        });
    }

    private static _instance?: CheckoutService;

    public static get instance(): CheckoutService {
        if (!this._instance) {
            this._instance = new CheckoutService();
        }

        return this._instance;
    }

    public async getFinalRate(
        args: apiTypes.QueryFinalRateArgs,
    ): Promise<ApiResponseModel<apiTypes.FinalRateType>> {
        return await this.executeQuery<apiTypes.FinalRateType>('finalRate', finalRatesQuery, {
            ...args,
        });
    }

    public async makeReservation(
        args: apiTypes.CreateReservationInput,
    ): Promise<ApiResponseModel<apiTypes.CreateReservationPayload>> {
        return await this.executeQuery<apiTypes.CreateReservationPayload>(
            'createReservation',
            checkoutMutation,
            { input: args },
        );
    }
}
