import { addDays } from 'date-fns/addDays';
import { isValid } from 'date-fns/isValid';
import { startOfToday } from 'date-fns/startOfToday';
import * as yup from 'yup';

import { dateHelpers } from '@nocowanie/core';
import { GUEST_PICKER } from '@nocowanie/common-ui';

const truthyValues = ['1', 1, true, 'true'];

const transformNumber = (value: any, min: number, max: number) => {
    if (isNaN(value)) {
        return;
    }
    return Math.min(Math.max(parseInt(value), min), max);
};

const transformDate = (value: any, minDate: Date) => {
    if (!isValid(value) || value < minDate) {
        return;
    }
    return value;
};

export const schemaWithTransforms = yup.object({
    accommodationId: yup.number().required(),
    checkInDate: yup.date().transform(value => transformDate(value, startOfToday())),
    checkOutDate: yup.date().transform(value => transformDate(value, addDays(startOfToday(), 1))),
    adultsCount: yup
        .number()
        .transform(value =>
            transformNumber(value, GUEST_PICKER.CONFIG.ADULTS.MIN, GUEST_PICKER.CONFIG.ADULTS.MAX),
        ),
    childrenCount: yup
        .number()
        .transform(value =>
            transformNumber(
                value,
                GUEST_PICKER.CONFIG.CHILDREN.MIN,
                GUEST_PICKER.CONFIG.CHILDREN.MAX,
            ),
        ),
    childrenAges: yup.array().of(
        yup
            .number()
            .required()
            .transform(value =>
                isNaN(value)
                    ? GUEST_PICKER.CONFIG.CHILD_AGE.MIN
                    : Math.min(
                          Math.max(value, GUEST_PICKER.CONFIG.CHILD_AGE.MIN),
                          GUEST_PICKER.CONFIG.CHILD_AGE.MAX,
                      ),
            ),
    ),
    freeCancel: yup
        .boolean()
        .transform((_, originalValue) => (truthyValues.includes(originalValue) ? true : undefined)),
    withoutPrepayment: yup
        .boolean()
        .transform((_, originalValue) => (truthyValues.includes(originalValue) ? true : undefined)),
    provision: yup.array().of(yup.string()),
});

export const getValidatedParams = (params: Record<string, any>) => {
    const validatedParams = schemaWithTransforms.validateSync(params, {
        abortEarly: false,
    });

    // Correct value of childrenCount
    if (
        'childrenCount' in validatedParams &&
        (validatedParams.childrenCount || GUEST_PICKER.CONFIG.CHILDREN.MIN) +
            (validatedParams?.adultsCount || GUEST_PICKER.CONFIG.ADULTS.MIN) >
            GUEST_PICKER.CONFIG.TOTAL_GUESTS.MAX
    ) {
        validatedParams.childrenCount =
            GUEST_PICKER.CONFIG.TOTAL_GUESTS.MAX -
            (validatedParams?.adultsCount || GUEST_PICKER.CONFIG.ADULTS.MIN);
    }

    // Correct values of childrenAges
    if (validatedParams?.childrenAges?.length !== validatedParams?.childrenCount) {
        validatedParams.childrenAges = validatedParams.childrenAges || [];

        const childrenCount = validatedParams?.childrenCount || GUEST_PICKER.CONFIG.CHILDREN.MIN;

        if (validatedParams?.childrenAges?.length > childrenCount) {
            validatedParams.childrenAges.length = childrenCount;
        } else {
            const childrenAgesCount = validatedParams.childrenAges.length;
            validatedParams.childrenAges.length = childrenCount;
            validatedParams.childrenAges.fill(
                GUEST_PICKER.CONFIG.CHILD_AGE.MIN,
                childrenAgesCount,
                childrenCount,
            );
        }
    }

    // Clean up params if they are incorrect
    if (!dateHelpers.isValidRange(validatedParams.checkInDate, validatedParams.checkOutDate)) {
        delete validatedParams['checkOutDate'];
        delete validatedParams['checkInDate'];
    }

    return validatedParams;
};
