import { useCallback, useRef, useState } from 'react';

export function useLineCount<T extends HTMLElement>(): {
    ref: (node: T) => void;
    lineCount: number;
} {
    const [lineCount, setLineCount] = useState<number>(0);
    const ref = useRef<T | null>(null);

    // based on https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
    const setRef = useCallback((node: T) => {
        if (node) {
            const clone = node.cloneNode() as T;
            clone.style.visibility = 'hidden';
            clone.style.position = 'absolute';
            clone.textContent = 'x'; // content doesn't matter, we just need to generate one line of content (to get line-height's value)
            document.body.append(clone);

            const lineHeight = clone.getBoundingClientRect().height;
            const nodeHeight = node.scrollHeight;
            const noOfLines = Math.trunc(nodeHeight / lineHeight);

            setLineCount(noOfLines);

            clone.remove();
        }

        // Save a reference to the node
        ref.current = node;
    }, []);

    return {
        ref: setRef,
        lineCount,
    };
}
